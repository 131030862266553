// Import React Dependencies
import React, {
  FC,
  ReactNode,
  createContext,
  useState,
} from "react";

// Declare types and interfaces
import { EType, IUser } from "../types/UserType";
import { EShopStatus, IShop } from "../types/ShopType";
import Cookies from "universal-cookie";
import { IPlan } from "../types/PlanType";

export interface IContext {
  globalSelectedBackend: string;
  globalUser: IUser;
  globalShop: IShop;
  authCheck: () => Promise<{
    isLogged: boolean;
    isFirstTime: boolean;
    type: EType;
  }>;
  authenticate: (
    userId: string,
    password: string
  ) => Promise<{
    status: boolean;
    message: string;
    isFirstTime: boolean;
    type: EType;
  }>;
  changePassword: (
    newPassword: string,
    confirmedPassword: string
  ) => Promise<{
    status: boolean;
    message: string;
  }>;
  setNewGlobalShop: (newShopDomain: string) => Promise<Boolean>;
  logOut: () => Promise<boolean>;
}

type IPropsContext = {
  children: ReactNode;
};

// Create context
export const AppContext = createContext<IContext | null>(null);

// Page main functional component
const ContextProvider: FC<IPropsContext> = ({ children }) => {
  // Use state variables
  const globalSelectedBackend =
    process.env.REACT_APP_BACKEND_GATEWAY || "https://localhost:3001";
  const [globalUser, setGlobalUser] = useState<IUser>({
    id: "",
    email: "",
    type: EType.SHOP,
    isFirstTime: false,
  });
  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const [globalShop, setGlobalShop] = useState<IShop>({
    id: "",
    domain: "",
    needsSetup: true,
    needsSync: true,
    needsTest: true,
    needsOnboarding: true,
    status: EShopStatus.installed,
    currentPlan: emptyPlan,
    createDate: "",
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
  });
  const cookies = new Cookies();

  // Internal Functions
  const authCheck = async () => {
    var logged = false;
    var isFirstTime = true;
    var type = EType.SHOP;

    await fetch(globalSelectedBackend + "/", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          logged = true;
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        isFirstTime = JSONresult.isFirstTime;
        type = JSONresult.type;
        let newShop = false;
        let foundShop = false;
        if (JSONresult.shops !== null && JSONresult.shops.length !== 0) {
          JSONresult.shops.forEach((shop: IShop) => {
            if (shop.needsSetup || shop.needsSync) {
              setGlobalShop(shop);
              cookies.set("shop", shop.domain, {
                path: "/",
                maxAge: 60 * 60 * 24 * 365,
              });
              newShop = true;
            }
          });
          if (!newShop) {
            JSONresult.shops.forEach((shop: IShop) => {
              if (shop.domain === cookies.get("shop")) {
                setGlobalShop(shop);
                foundShop = true;
              }
            });
            if (!foundShop) {
              setGlobalShop(JSONresult.shops[0]);
              cookies.set("shop", JSONresult.shops[0].domain, {
                path: "/",
                maxAge: 60 * 60 * 24 * 365,
              });
            }
          }
        } else {
          if (JSONresult.type === EType.SHOP) {
            logOut();
          }
        }
        setGlobalUser({
          id: JSONresult.id,
          email: JSONresult.email,
          type: JSONresult.type,
          isFirstTime: JSONresult.isFirstTime,
          shops:
            JSONresult.shops === null
              ? null
              : JSONresult.shops.map((shop: IShop) => {
                  return {
                    id: shop.id,
                    domain: shop.domain,
                    needsSetup: shop.needsSetup,
                    needsSync: shop.needsSync,
                    setup: shop.setup,
                    createDate: shop.createDate,
                    shopName: shop.shopName,
                    shopMainDomain: shop.shopMainDomain,
                    currentPlan: shop.currentPlan,
                  };
                }),
        });
      })
      .catch((err) => {
        console.log(err);
        logged = false;
      });

    return { isLogged: logged, isFirstTime: isFirstTime, type: type };
  };

  const authenticate = async (email: string, password: string) => {
    var logged = false;
    var message = "";
    var isFirstTime = false;
    var type = EType.SHOP;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    await fetch(globalSelectedBackend + "/login", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          logged = false;
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          logged = true;
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        isFirstTime = JSONresult.isFirstTime;
        let newShop = false;
        let foundShop = false;
        if (JSONresult.shops !== null && JSONresult.shops.length !== 0) {
          JSONresult.shops.forEach((shop: IShop) => {
            if (shop.needsSetup || shop.needsSync) {
              setGlobalShop(shop);
              cookies.set("shop", shop.domain, {
                path: "/",
                maxAge: 60 * 60 * 24 * 365,
              });
              newShop = true;
            }
          });
          if (!newShop) {
            JSONresult.shops.forEach((shop: IShop) => {
              if (shop.domain === cookies.get("shop")) {
                setGlobalShop(shop);
                foundShop = true;
              }
            });
            if (!foundShop) {
              setGlobalShop(JSONresult.shops[0]);
              cookies.set("shop", JSONresult.shops[0].domain, {
                path: "/",
                maxAge: 60 * 60 * 24 * 365,
              });
            }
          }
        } else {
          if (JSONresult.type === EType.SHOP) {
            logOut();
          }
        }
        setGlobalUser({
          id: JSONresult.id,
          email: JSONresult.email,
          type: JSONresult.type,
          isFirstTime: JSONresult.isFirstTime,
          shops:
            JSONresult.shops === null
              ? null
              : JSONresult.shops.map((shop: IShop) => {
                  return {
                    id: shop.id,
                    domain: shop.domain,
                    needsSetup: shop.needsSetup,
                    needsSync: shop.needsSync,
                    needsTest:  shop.needsTest,
                    needsOnboarding:  shop.needsOnboarding,
                    setup: shop.setup,
                    createDate: shop.createDate,
                    shopName: shop.shopName,
                    shopMainDomian: shop.shopMainDomain,
                    currentPlan: shop.currentPlan,
                  };
                }),
        });
        type = JSONresult.type;
      })
      .catch((error) => {
        logged = false;
        message = error.message;
      });

    return {
      status: logged,
      message: message,
      isFirstTime: isFirstTime,
      type: type,
    };
  };

  const changePassword = async (
    newPassword: string,
    confirmedPassword: string
  ) => {
    var changed = false;
    var message = "";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      newPassword: newPassword,
      confirmedPassword: confirmedPassword,
    });

    await fetch(globalSelectedBackend + "/changepassword", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          changed = false;
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          changed = true;
          return response.text();
        }
      })
      .catch((error) => {
        changed = false;
        message = error.message;
      });

    return {
      status: changed,
      message: message,
    };
  };

  const setNewGlobalShop = async (newShopDomain: string) => {
    let foundShop = false;
    if (globalUser.shops !== null) {
      globalUser.shops?.forEach((shop: IShop) => {
        if (shop.domain === newShopDomain) {
          setGlobalShop(shop);
          cookies.set("shop", shop.domain, {
            path: "/",
            maxAge: 60 * 60 * 24 * 365,
          });
          foundShop = true;
        }
      });
    }
    return foundShop;
  };

  const logOut = async () => {
    let loggedout = false;
    await fetch(globalSelectedBackend + "/logout", {
      method: "POST",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          loggedout = false;
          throw new Error(errorJSON.message);
        } else {
          loggedout = true;
          return response.text();
        }
      })
      .catch(() => {
        loggedout = false;
      });

    return loggedout;
  };

  // JSX Return statement
  return (
    <AppContext.Provider
      value={{
        globalSelectedBackend,
        globalUser,
        globalShop,
        authCheck,
        authenticate,
        changePassword,
        setNewGlobalShop,
        logOut,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Default exported function
export default ContextProvider;
