// Import React Dependencies
import { FC } from "react";
import React from "react";

// Import styles
import "./styles/ProgressBar.css";

// Declare types and interfaces
export type IProgresBarProps = {
  completed: number;
};

// Page main functional component
const ProgressBar: FC<IProgresBarProps> = ({ completed }) => {
  // JSX Return statement
  return (
    <React.Fragment>
      <div className="ProgressBar__outter">
        <div className="ProgressBar__inner" style={{ width: `${completed}%` }}>
          {completed > 12 ? <span>{`${Math.floor(completed)}%`}</span> : ""}
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default ProgressBar;
