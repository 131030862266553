import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext, IContext } from "../context/Context";

// Import components
import ShopifyWidget from "../components/ShopifyWidget";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_profile_filled from "../images/wizy_profile_filled.svg";
import wizy_chat_profile from "../images/wizy_chat_profile.png";
import wizy_curvy_border from "../images/wizy_curvy_border.png";
import wizy_logo_blue from "../images/wizy_logo_blue.svg";

// Import styles
import "./styles/Training.css";
import "./styles/Chat.css";
import { EMessageRole } from "../types/MessageType";
import { io } from "socket.io-client";
import { IAssistantMessage } from "../types/AssistantMessageType";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import AssistantForm from "../components/AssistantForm";
import ShopifyWidgetWrapper from "../components/ShopifyWidgetWrapper";

// Declare types and interfaces

// Page main functional component
const Training: FC = () => {
  // Use navigate
  const navigate = useNavigate();

  // Use params
  const { messageId } = useParams<{ messageId: string | undefined }>();

  // Use translation
  const { t } = useTranslation(["training"]);

  // Use context
  const { globalShop, globalSelectedBackend, globalUser } = React.useContext(
    AppContext
  ) as IContext;

  // UseState variables
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [assistantMessages, setAssistantMessages] = useState<
    IAssistantMessage[]
  >([]);
  const [newAssistantMessage, setNewAssistantMessage] = useState<string>("");

  const [assistantSocket, setAssistantSocket] = useState<any>(null);
  const [assistantInit, setAssistantInit] = useState<string>("");
  const [isAssistantLoaded, setIsAssistantLoaded] = useState<boolean>(false);

  const [testSeed, setTestSeed] = useState<number>(1);

  const cookies = new Cookies();

  // Internal Functions
  useEffect(() => {
    let socketURL =
      globalSelectedBackend +
      "/assistant?domain=" +
      globalShop.domain +
      "&type=shop";
    if (messageId) {
      socketURL += "&messageId=" + messageId;
    }
    setAssistantSocket(
      io(socketURL, {
        forceNew: true,
        autoConnect: false,
        transports: ["websocket"],
        transportOptions: {
          websocket: {
            extraHeaders: {
              "Sec-WebSocket-Key": "dGhlIHNhbXBsZSBub25jZQ==",
              "Sec-WebSocket-Version": "13",
              "Sec-WebSocket-Protocol": "chat, superchat",
            },
          },
        },
      })
    );
    setTimeout(() => {
      setIsVisible(true);
    }, 2000);
    setIsAssistantLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Define functions associated to websocket events
    function onConnect() {}
    function onDisconnect() {}
    function onMessageEvent(value: any) {
      if (value.content !== "") {
        setAssistantMessages((previous) => [
          ...previous,
          {
            content: value.content,
            createDate: value.createDate,
            role: value.role,
            name: value.name,
            functionCall: value.functionCall,
            toolCalls: value.toolCalls,
            toolCallId: value.toolCallId,
            form: value.form,
          },
        ]);
      }
    }
    function onInit(value: string) {
      setAssistantInit(value);
    }
    // Connect to assistant websocket
    if (isAssistantLoaded) {
      assistantSocket.connect();
      assistantSocket.on("connect", onConnect);
      assistantSocket.on("disconnect", onDisconnect);
      assistantSocket.on("messagesShop", onMessageEvent);
      assistantSocket.on("init", onInit);

      return () => {
        assistantSocket.off("connect", onConnect);
        assistantSocket.off("disconnect", onDisconnect);
        assistantSocket.off("messagesShop", onMessageEvent);
        assistantSocket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssistantLoaded]);

  useEffect(() => {
    if (isAssistantLoaded) {
      updateShopAssistantWebsocketId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantInit]);

  const updateShopAssistantWebsocketId = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      assistantWebsocketId: assistantInit,
    });
    await fetch(
      globalSelectedBackend +
        "/shops/" +
        globalShop.id +
        "/assistantwebsocketid",
      {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .catch((error) => {});
  };

  const handleAssistantMessageSend = () => {
    if (newAssistantMessage !== "") {
      assistantSocket.emit("messagesShop", {
        content: newAssistantMessage,
        shopDomain: globalShop.domain,
      });
      setAssistantMessages((previous) => [
        ...previous,
        {
          content: newAssistantMessage,
          createDate: new Date().toISOString(),
          role: EMessageRole.user,
          name: null,
          functionCall: null,
          toolCalls: null,
          toolCallId: null,
          form: null,
        },
      ]);
      setNewAssistantMessage("");
    }
  };

  const resetTest = async () => {
    cookies.remove("WIZY_CLIENT_" + globalShop.domain, { path: "/" });
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    setTestSeed(Math.random());
  };

  const formatDate = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${hours}:${minutes} ${year}-${month}-${day}`;
  };

  const convertToHTMLLink = (text: string): JSX.Element => {
    const urlPattern = /\[([^[\]]+)\]\((https?:\/\/\S+)\)/g;
    const replacedText = text.replace(
      urlPattern,
      (match, anchorText, url) =>
        `<a href="${url}" target="_blank">${anchorText}</a>`
    );
    return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
  };

  const isNotBlank = (value: any): boolean => {
    return value !== null && value !== undefined && value !== "";
  };

  if (isLoaded) {
    return (
      <React.Fragment>
        <div className="Training__outter">
          <div className="Training__inner">
            <div className="Training__grid__outter">
              {/* Training card */}
              <div
                className="Training__train__outter"
                id="Training__train__outter"
              >
                <div className="Training__train__inner">
                  <div className="Training__train__inner__inner">
                    <div
                      className="Training__list__title__outter"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="Training__list__title__inner">
                        <div className="Training__list__title__inner__inner">
                          <div className="Training__list__title__description">
                            <div className="Training__list__title__inner__text__title">
                              {t("Train", { ns: ["training"] })}
                            </div>
                            <div className="Training__list__title__inner__text__description">
                              {t("TrainD", { ns: ["training"] })}
                            </div>
                          </div>
                          <div className="Training__list__arrow__outter">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="60"
                              height="60"
                              viewBox="0 0 60 60"
                              preserveAspectRatio="none"
                            >
                              <polygon
                                points="29 66 28 66 38 33 28 0 29 0 39 33 29 66"
                                fill="#e6e9ed"
                              ></polygon>
                            </svg>
                          </div>
                          <div className="Training__list__create__button__outter">
                            <div className="Training__list__create__button__inner">
                              <button
                                className="Wizy__button__13"
                                style={{ fontSize: "16px" }}
                                onClick={() => {
                                  if (
                                    (document.getElementById(
                                      "Training__train__outter"
                                    ) as HTMLFormElement) !== null
                                  ) {
                                    (
                                      document.getElementById(
                                        "Training__train__outter"
                                      ) as HTMLFormElement
                                    ).style.display = "none";
                                  }
                                  if (
                                    (document.getElementById(
                                      "Training__test__inner__inner"
                                    ) as HTMLFormElement) !== null
                                  ) {
                                    (
                                      document.getElementById(
                                        "Training__test__inner__inner"
                                      ) as HTMLFormElement
                                    ).style.display = "block";
                                  }
                                }}
                              >
                                <img
                                  src={wizy_logo_white}
                                  alt="wizy_add_outline"
                                  className="Wizy__button__image__1"
                                />
                                {t("TestB", { ns: ["training"] })}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Training chat */}
                    {isAssistantLoaded ? (
                      <div
                        className="Training__train__chat__inner"
                        id="Training__train__chat__inner"
                      >
                        <div className="Training__train__navbar__outter">
                          <div className="Training__train__navbar__inner">
                            <div className="Training__train__client__outter">
                              <div className="Training__train__client__inner">
                                <div className="Training__train__chat__message__inner__bubble">
                                  <img
                                    src={wizy_logo_white}
                                    alt="wizy_profile_filled"
                                    className="Training__train__chat__message__inner__bubble__image__white"
                                  />
                                </div>
                                <div className="Training__client__inner__information">
                                  <div className="Training__client__inner__email">
                                    {t("Train", {
                                      ns: ["training"],
                                    })}
                                  </div>
                                  <div className="Training__client__inner__message">
                                    <div className="Training__client__inner__message__bubble"></div>
                                    <div className="Training__client__inner__message__inner">
                                      {t("Chat", {
                                        ns: ["training"],
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Training__train__chat__messages__outter">
                          <div className="Training__train__chat__messages__inner">
                            <div>
                              {assistantMessages
                                .filter((message: IAssistantMessage) => {
                                  if (
                                    (message.role === EMessageRole.ai ||
                                      message.role === EMessageRole.assistant ||
                                      message.role === EMessageRole.user) &&
                                    isNotBlank(message.content)
                                  ) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                })
                                .sort(
                                  (
                                    a: IAssistantMessage,
                                    b: IAssistantMessage
                                  ) =>
                                    Date.parse(a.createDate) -
                                    Date.parse(b.createDate)
                                )
                                .map((message, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="Training__train__chat__message__outter"
                                    >
                                      <div className="Training__message__inner">
                                        <div className="Training__train__chat__message__inner__bubble">
                                          {message.role ===
                                          EMessageRole.assistant ? (
                                            <img
                                              src={wizy_profile_filled}
                                              alt="wizy_profile_filled"
                                              className="Training__train__chat__message__inner__bubble__image"
                                            />
                                          ) : message.role ===
                                            EMessageRole.ai ? (
                                            <img
                                              src={wizy_logo_white}
                                              alt="wizy_logo_white"
                                              className="Training__train__chat__message__inner__bubble__image__white"
                                            />
                                          ) : message.role ===
                                            EMessageRole.user ? (
                                            <div className="ProfilePicture__inner">
                                              {globalUser.email[0].toUpperCase()}
                                              {globalUser.email[1].toUpperCase()}
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        <div className="Training__message__inner__information">
                                          <div className="Training__message__inner__email">
                                            {message.role ===
                                            EMessageRole.assistant ? (
                                              t("Support Agent", {
                                                ns: ["chat"],
                                              })
                                            ) : message.role ===
                                              EMessageRole.ai ? (
                                              "Wizybot"
                                            ) : (
                                              <></>
                                            )}
                                            &nbsp;
                                            <span
                                              style={{
                                                opacity: "0.5",
                                                fontWeight: "500",
                                                fontSize: "11px",
                                                fontStyle: "italic",
                                              }}
                                            >
                                              {formatDate(
                                                new Date(message.createDate)
                                              )}
                                            </span>
                                          </div>
                                          <div className="Training__message__inner__message">
                                            <div className="Training__message__inner__message__inner">
                                              {convertToHTMLLink(
                                                message.content
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* If the message includes a form */}
                                      {message.form ? (
                                        <AssistantForm
                                          type={message.form.type}
                                          content={message.form.content}
                                          resetTestFunction={resetTest}
                                        ></AssistantForm>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="Training__pannel__outter">
                          <div className="Training__pannel__input__outter">
                            <div className="Training__pannel__input__inner">
                              <div className="Wizy__input__4__outter">
                                <input
                                  maxLength={500}
                                  name="message"
                                  value={newAssistantMessage}
                                  type="text"
                                  className="Wizy__input__4"
                                  placeholder={t("Enter", { ns: ["chat"] })}
                                  onChange={(
                                    event: React.FormEvent<HTMLInputElement>
                                  ) => {
                                    setNewAssistantMessage(
                                      event.currentTarget.value
                                    );
                                  }}
                                  onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>
                                  ) => {
                                    if (event.key === "Enter") {
                                      handleAssistantMessageSend();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="Training__pannel__send__button__outter"
                            onClick={() => {
                              handleAssistantMessageSend();
                            }}
                          >
                            <div className="Training__pannel__send__button">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ChanSetup__send__button__image"
                              >
                                <path
                                  d="M17.4472 9.10556C17.786 9.27495 18 9.62122 18 9.99999C18 10.3788 17.786 10.725 17.4472 10.8944L3.44721 17.8944C3.09251 18.0718 2.66653 18.0228 2.36136 17.7695C2.0562 17.5162 1.92953 17.1066 2.03848 16.7253L3.46704 11.7253C3.5897 11.296 3.98209 11 4.42857 11L9 11C9.55229 11 10 10.5523 10 10C10 9.44771 9.55229 9 9 9H4.42857C3.98209 9 3.58971 8.70402 3.46705 8.27472L2.03848 3.27471C1.92953 2.8934 2.0562 2.48374 2.36136 2.23048C2.66653 1.97722 3.09251 1.92821 3.44721 2.10556L17.4472 9.10556Z"
                                  fill={"#FFFFfF"}
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={wizy_loader}
                        className="ProtectedRoute__loader"
                        alt="ProtectedRoute__loader"
                        id="ProtectedRoute__loader"
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Test card */}
              <div
                className="Training__test__inner__inner"
                id="Training__test__inner__inner"
              >
                <div
                  className="Training__list__title__outter"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="Training__list__title__inner">
                    <div className="Training__list__title__inner__inner">
                      <div className="Training__list__title__description">
                        <div className="Training__list__title__inner__text__title">
                          {t("Test", { ns: ["training"] })}
                        </div>
                        <div className="Training__list__title__inner__text__description">
                          {t("TestD", { ns: ["training"] })}
                        </div>
                      </div>
                      <div className="Training__list__arrow__outter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          viewBox="0 0 60 60"
                          preserveAspectRatio="none"
                        >
                          <polygon
                            points="29 66 28 66 38 33 28 0 29 0 39 33 29 66"
                            fill="#e6e9ed"
                          ></polygon>
                        </svg>
                      </div>
                      <div className="Training__list__create__button__outter">
                        <div className="Training__list__create__button__inner">
                          <button
                            className="Wizy__button__13"
                            style={{ fontSize: "16px" }}
                            onClick={() => {
                              if (
                                (document.getElementById(
                                  "Training__train__outter"
                                ) as HTMLFormElement) !== null
                              ) {
                                (
                                  document.getElementById(
                                    "Training__train__outter"
                                  ) as HTMLFormElement
                                ).style.display = "block";
                              }
                              if (
                                (document.getElementById(
                                  "Training__test__inner__inner"
                                ) as HTMLFormElement) !== null
                              ) {
                                (
                                  document.getElementById(
                                    "Training__test__inner__inner"
                                  ) as HTMLFormElement
                                ).style.display = "none";
                              }
                            }}
                          >
                            <img
                              src={wizy_logo_white}
                              alt="wizy_add_outline"
                              className="Wizy__button__image__1"
                            />
                            {t("TrainB", { ns: ["training"] })}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="Training__test__chat__container__outter"
                  key={testSeed}
                  style={{ opacity: isVisible ? 1 : 0 }}
                >
                  <ShopifyWidgetWrapper
                    domain={globalShop.domain}
                    isTest={true}
                    isAdmin={false}
                    isRelative={true}
                    stylesPathInner={
                      messageId
                        ? "../ShopifyWidgetInner.css"
                        : "./ShopifyWidgetInner.css"
                    }
                    stylesPathOutter={
                      messageId
                        ? "../ShopifyWidgetOutter.css"
                        : "./ShopifyWidgetOutter.css"
                    }
                    ipRegistryKey={
                      process.env.REACT_APP_CLIENT_INFO_KEY ||
                      "tdk18ncwdb68ukmd"
                    }
                    globalSelectedBackend={globalSelectedBackend}
                    chatProfileImage={wizy_chat_profile}
                    curvyBorderImage={wizy_curvy_border}
                    wizyLogoImage={wizy_logo_blue}
                    shopifyRootPath={null}
                    shopifyCurrentPath={null}
                    isDashboard={true}
                  />
                </div>
                <img
                  src={wizy_loader}
                  className="ProtectedRoute__loader"
                  alt="ProtectedRoute__loader"
                  id="ProtectedRoute__loader"
                />
                <div className="Training__test__resetbutton__container">
                  <button
                    className="Wizy__button__5"
                    style={{ height: "40px" }}
                    onClick={() => {
                      resetTest();
                    }}
                  >
                    {t("Reset", {
                      ns: ["training"],
                    })}
                  </button>
                  <button
                    className="Wizy__button__2"
                    onClick={() => {
                      navigate("/additionalinfo");
                    }}
                  >
                    {t("Previous", {
                      ns: ["training"],
                    })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Training__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function

export default Training;
