import React, { FC } from "react";
import { IRecommendationCarouselContent } from "../types/MessageType";

// Import styles
import "./styles/RecommendationCarouselLiveChat.css";

// Declare types and interfaces
type RecommendationCarouselProps = {
  content: IRecommendationCarouselContent;
  language: string;
};

// Page main functional component
const RecommendationCarouselLiveChat: FC<RecommendationCarouselProps> = (
  props
) => {
  return (
    <React.Fragment>
      <div className="RecommendationCarouselLiveChat__outter">
        <div
          className="RecommendationCarouselLiveChat__inner"
          style={{
            color: "black",
          }}
        >
          {props.content.recommendations.map((recommendation) => {
            return (
              <div className="RecommendationCarouselLiveChat__product__outter">
                <div className="RecommendationCarouselLiveChat__add__product__outter">
                  <div className="RecommendationCarouselLiveChat__add__product__image__outter">
                    <img
                      src={recommendation.imageUrl + "&&width=150"}
                      alt="RecommendationCarouselLiveChat__add__product__image"
                      className="RecommendationCarouselLiveChat__add__product__image"
                    />
                  </div>
                  <div className="RecommendationCarouselLiveChat__add__product__name__outter">
                    <a
                      className="RecommendationCarouselLiveChat__add__prouct__name__inner"
                      href={recommendation.redirectUrl}
                      target="_top"
                      rel="noopener noreferrer"
                    >
                      {recommendation.title}
                    </a>
                    <div className="RecommendationCarouselLiveChat__add__prouct__sku__inner">
                      {recommendation.price}
                    </div>
                    <a
                      href={recommendation.redirectUrl}
                      target="_top"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <div className="">
                        {props.language === "English"
                          ? "View details"
                          : props.language === "Spanish"
                          ? "Ver detalles"
                          : props.language === "French"
                          ? "Voir les détails"
                          : props.language === "Portuguese"
                          ? "Ver detalhes"
                          : props.language === "German"
                          ? "Details anzeigen"
                          : props.language === "Italian"
                          ? "Visualizza dettagli"
                          : "View details"}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default RecommendationCarouselLiveChat;
