// Import React Dependencies
import { FC, useState } from "react";
import React from "react";

// Import styles
import "./styles/CustomOfferManager.css";
import { IShop } from "../types/ShopType";
import { useTranslation } from "react-i18next";

import wizy_loader from "../images/wizy_loader.gif";
import { AppContext, IContext } from "../context/Context";

// Declare types and interfaces
export type ICustomOfferManagerProps = {
  shop: IShop;
};

export type ICustomOfferPlanForm = {
  name: string;
  messagesPerMonth: number;
  monthlyPrice: number;
  additionalMessagePrice: number;
  showcaseText: string;
  isAnnual: boolean;
};

// Page main functional component
const CustomOfferManager: FC<ICustomOfferManagerProps> = ({ shop }) => {
  const { t } = useTranslation(["customOfferManager"]);
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [inCreation, setInCreation] = useState<boolean>(false);
  const [customOfferPlanForm, setCustomOfferPlanForm] =
    useState<ICustomOfferPlanForm>(
      shop.customOfferPlan
        ? {
            name: shop.customOfferPlan.name,
            messagesPerMonth: shop.customOfferPlan.messagesPerMonth,
            monthlyPrice: shop.customOfferPlan.monthlyPrice,
            additionalMessagePrice: shop.customOfferPlan.additionalMessagePrice,
            showcaseText: shop.customOfferPlan.showcaseText,
            isAnnual: shop.customOfferPlan.isAnnual,
          }
        : {
            name: "",
            messagesPerMonth: 0,
            monthlyPrice: 0,
            additionalMessagePrice: 0,
            showcaseText: "",
            isAnnual: false,
          }
    );
  const handleFormChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setCustomOfferPlanForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toogleIsAnnual = () => {
    setCustomOfferPlanForm((prevState) => ({
      ...prevState,
      isAnnual: !customOfferPlanForm.isAnnual,
    }));
  };

  const createCustomOfferPlan = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: customOfferPlanForm.name.toLowerCase(),
      messagesPerMonth: customOfferPlanForm.messagesPerMonth,
      monthlyPrice: customOfferPlanForm.monthlyPrice,
      additionalMessagePrice: customOfferPlanForm.additionalMessagePrice,
      showcaseText: customOfferPlanForm.showcaseText,
      isCustom: true,
      isAnnual: customOfferPlanForm.isAnnual,
    });

    await fetch(
      globalSelectedBackend + "/plans/createcustomofferplan/" + shop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCustomOfferPlan = async () => {
    await fetch(
      globalSelectedBackend +
        "/plans/deletecustomofferplan/" +
        (shop.customOfferPlan ? shop.customOfferPlan.id : ""),
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCustomOfferPlan = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: customOfferPlanForm.name.toLowerCase(),
      messagesPerMonth: customOfferPlanForm.messagesPerMonth,
      monthlyPrice: customOfferPlanForm.monthlyPrice,
      additionalMessagePrice: customOfferPlanForm.additionalMessagePrice,
      showcaseText: customOfferPlanForm.showcaseText,
      isCustom: true,
      isAnnual: customOfferPlanForm.isAnnual,
    });
    await fetch(
      globalSelectedBackend +
        "/plans/updatecustomofferplan/" +
        (shop.customOfferPlan ? shop.customOfferPlan.id : ""),
      {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <div
          style={{ backgroundColor: "#eff2f6" }}
        >
          <div
            className="ChildrenSelector__inner"
            style={{ backgroundColor: "#eff2f6" }}
          >
            <div
              className="CustomOffer__inner__inner"
              style={{ marginLeft: "0px" }}
            >
              <div className="Home__dashboard__indicators__indicator__outter">
                <div className="Home__dashboard__indicators__indicator__inner">
                  <div className="Home__dashboard__indicators__indicator__inner__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                      {t("CustomOfferPlan", { ns: ["customOfferManager"] })}
                    </div>
                    {!shop.customOfferPlan && !inCreation ? (
                      <>
                        <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                          {t("NoCustomPlan", { ns: ["customOfferManager"] })}
                        </div>
                        <div className="Merchants__apply__button__outter">
                          <button
                            className="Wizy__button__2"
                            style={{ width: "200px" }}
                            onClick={() => setInCreation(true)}
                          >
                            {t("CreateCustomPlan", {
                              ns: ["customOfferManager"],
                            })}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                          {shop.customOfferPlan
                            ? shop.currentPlan.name ===
                              shop.customOfferPlan.name
                              ? t("SubscribedToCustom", {
                                  ns: ["customOfferManager"],
                                })
                              : t("EnterDetails", {
                                  ns: ["customOfferManager"],
                                })
                            : t("EnterDetails", { ns: ["customOfferManager"] })}
                        </div>
                        <div className="CustomOffer__formup__container">
                          <div className="CustomOffer__form__text">
                            {t("Name", {
                              ns: ["customOfferManager"],
                            })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="name"
                              value={customOfferPlanForm.name}
                              type="text"
                              className="Wizy__input__2"
                              style={{ marginTop: "5px" }}
                              onChange={handleFormChange}
                              disabled={
                                shop.customOfferPlan
                                  ? shop.currentPlan.name ===
                                    shop.customOfferPlan.name
                                  : false
                              }
                            />
                          </div>
                          <div className="CustomOffer__form__text">
                            {customOfferPlanForm.isAnnual
                              ? t("MessagesPerYear", {
                                  ns: ["customOfferManager"],
                                })
                              : t("MessagesPerMonth", {
                                  ns: ["customOfferManager"],
                                })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="messagesPerMonth"
                              value={customOfferPlanForm.messagesPerMonth}
                              type="text"
                              className="Wizy__input__2"
                              style={{ marginTop: "5px" }}
                              onChange={handleFormChange}
                              disabled={
                                shop.customOfferPlan
                                  ? shop.currentPlan.name ===
                                    shop.customOfferPlan.name
                                  : false
                              }
                            />
                          </div>
                          <div className="CustomOffer__form__text">
                            {customOfferPlanForm.isAnnual
                              ? t("YearlyPrice", {
                                  ns: ["customOfferManager"],
                                })
                              : t("MonthlyPrice", {
                                  ns: ["customOfferManager"],
                                })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="monthlyPrice"
                              value={customOfferPlanForm.monthlyPrice}
                              type="text"
                              className="Wizy__input__2"
                              style={{ marginTop: "5px" }}
                              onChange={handleFormChange}
                              disabled={
                                shop.customOfferPlan
                                  ? shop.currentPlan.name ===
                                    shop.customOfferPlan.name
                                  : false
                              }
                            />
                          </div>
                          <div className="CustomOffer__form__text">
                            {t("AdditionalMessagePrice", {
                              ns: ["customOfferManager"],
                            })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="additionalMessagePrice"
                              value={customOfferPlanForm.additionalMessagePrice}
                              type="text"
                              className="Wizy__input__2"
                              style={{ marginTop: "5px" }}
                              onChange={handleFormChange}
                              disabled={
                                shop.customOfferPlan
                                  ? shop.currentPlan.name ===
                                    shop.customOfferPlan.name
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className="CustomOffer__formdown__container">
                          <div className="CustomOffer__form__text">
                            {t("ShowcaseText", {
                              ns: ["customOfferManager"],
                            })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="showcaseText"
                              value={customOfferPlanForm.showcaseText}
                              type="text"
                              className="Wizy__input__2"
                              style={{ marginTop: "5px" }}
                              onChange={handleFormChange}
                              disabled={
                                shop.customOfferPlan
                                  ? shop.currentPlan.name ===
                                    shop.customOfferPlan.name
                                  : false
                              }
                            />
                          </div>
                          <div className="Home__dashboard__indicators__shop__information__visibility__outter">
                            <label className="switch1">
                              <input
                                type="checkbox"
                                checked={customOfferPlanForm.isAnnual}
                                onChange={toogleIsAnnual}
                                disabled={
                                  shop.customOfferPlan
                                    ? shop.currentPlan.name ===
                                      shop.customOfferPlan.name
                                    : false
                                }
                              />
                              <span className="slider2 round"></span>
                            </label>
                          </div>
                          {inCreation ? (
                            <>
                              <div className="CustomOffer__button__outter">
                                <button
                                  className="Wizy__button__5"
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    setInCreation(false);
                                  }}
                                  disabled={
                                    shop.customOfferPlan
                                      ? shop.currentPlan.name ===
                                        shop.customOfferPlan.name
                                      : false
                                  }
                                >
                                  {t("GoBack", { ns: ["customOfferManager"] })}
                                </button>
                              </div>
                              <div className="CustomOffer__button__outter">
                                <button
                                  className="Wizy__button__2"
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    setIsLoaded(false);
                                    createCustomOfferPlan();
                                  }}
                                  disabled={
                                    shop.customOfferPlan
                                      ? shop.currentPlan.name ===
                                        shop.customOfferPlan.name
                                      : false
                                  }
                                >
                                  {t("Submit", { ns: ["customOfferManager"] })}
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="CustomOffer__button__outter">
                                <button
                                  className="Wizy__button__5"
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    setIsLoaded(false);
                                    deleteCustomOfferPlan();
                                  }}
                                  disabled={
                                    shop.customOfferPlan
                                      ? shop.currentPlan.name ===
                                        shop.customOfferPlan.name
                                      : false
                                  }
                                >
                                  {t("Delete", { ns: ["customOfferManager"] })}
                                </button>
                              </div>
                              <div className="CustomOffer__button__outter">
                                <button
                                  className="Wizy__button__2"
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    setIsLoaded(false);
                                    updateCustomOfferPlan();
                                  }}
                                  disabled={
                                    shop.customOfferPlan
                                      ? shop.currentPlan.name ===
                                        shop.customOfferPlan.name
                                      : false
                                  }
                                >
                                  {t("Update", { ns: ["customOfferManager"] })}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div

        style={{ backgroundColor: "#eff2f6" }}
      >
        <div
          className="ChildrenSelector__inner"
          style={{ backgroundColor: "#eff2f6" }}
        >
          <div
            className="CustomOffer__inner__inner"
            style={{ marginLeft: "0px" }}
          >
            <div className="Home__dashboard__indicators__indicator__outter">
              <div className="Home__dashboard__indicators__indicator__inner">
                <div className="Home__dashboard__indicators__indicator__inner__inner">
                  <img
                    src={wizy_loader}
                    className="ProtectedRoute__loader"
                    alt="ProtectedRoute__loader"
                    id="ProtectedRoute__loader"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// Default exported function
export default CustomOfferManager;
