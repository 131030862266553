// Import React Dependencies
import React, { FC } from "react";

// Import Components
import NavbarAdmin from "./NavbarAdmin";
import BottombarAdmin from "./BottombarAdmin";

// Import styles
import "./styles/LayoutAdmin.css";

// Declare types and interfaces
interface LayoutProps {
  children: React.ReactNode;
  actualPage: string;
}

// Page main functional component
const LayoutAdmin: FC<LayoutProps> = (props) => {
  
  // JSX Return statement
  return (
    <React.Fragment>
      <NavbarAdmin />
      <div
        className="Layout__web"
        style={{
          gridTemplateColumns: "60px calc(100% - 60px)",
          paddingTop: "60px",
        }}
      >
        <div>
          <BottombarAdmin actualPage={props.actualPage} />
        </div>
        <div>{props.children}</div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default LayoutAdmin;
