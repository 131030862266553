// Import React Dependencies
import React, { FC } from "react";

// Import styles
import "./styles/ProfilePicture.css";

// Declare types and interfaces
type ProfilePictureProps = {
  firstName: string;
  lastName: string;
};

// Page main functional component
const ProfilePicture: FC<ProfilePictureProps> = ({ firstName, lastName }) => {
  // JSX Return statement
  return (
    <React.Fragment>
      <div className="ProfilePicture__outter">
        <div className="ProfilePicture__inner">
          {firstName[0].toUpperCase() + lastName[0].toUpperCase()}
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default ProfilePicture;
