// Import React Dependencies
import { FC, useEffect, useState } from "react";
import React from "react";
import ReactIframe from "./ReactIframe";
import ShopifyWidget, {
  EDataRetrievalType,
  EEmailRetrievalMethod,
  ELimitBudgetAction,
  EMainLanguage,
  ESide,
  IWidgetVariables,
} from "./ShopifyWidget";

// Declare types and interfaces
export type IShopifyWidgetWrapperProps = {
  domain: string;
  isRelative: boolean;
  ipRegistryKey: string;
  globalSelectedBackend: string;
  chatProfileImage: string;
  curvyBorderImage: string;
  wizyLogoImage: string;
  stylesPathInner: string;
  stylesPathOutter: string;
  shopifyRootPath: string | null;
  shopifyCurrentPath: string | null;
  isDashboard: boolean;
  isTest: boolean;
  isAdmin: boolean;
};

// Page main functional component
const ShopifyWidgetWrapper: FC<IShopifyWidgetWrapperProps> = ({
  domain,
  isRelative,
  ipRegistryKey,
  globalSelectedBackend,
  chatProfileImage,
  curvyBorderImage,
  wizyLogoImage,
  stylesPathInner,
  stylesPathOutter,
  shopifyRootPath,
  shopifyCurrentPath,
  isDashboard,
  isTest,
  isAdmin,
}) => {
  // Use state
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(isRelative);
  const [width, setWidth] = useState<number>(80);
  const [widget, setWidget] = useState<IWidgetVariables>({
    domain: "",
    setup: {
      primaryColor: "",
      secondaryColor: "",
      fontColor: "",
      agentName: "",
      onlinePhrase: "",
      mainLanguage: EMainLanguage.ENGLISH,
      side: ESide.RIGHT,
      paddingBottom: 0,
      paddingSide: 0,
      image: "",
      isVisible: false,
      emailRetrievalMethod: EEmailRetrievalMethod.NONE,
      dataRetrievalType: EDataRetrievalType.EMAIL,
      dataRetrievalCustomPrompt: "",
      isRedirect: false,
      redirectionLink: "",
      isOverLimitBudget: false,
      limitBudgetAction: ELimitBudgetAction.REDIRECT,
      limitBudgetRedirectionLink: "",
      hideNotificationSign: false,
      hideWizybotBanner: false,
      hideOutboundMessage: false,
      preventSaleNoteCreation: false,
      customWidgetCode: "",
      pagesToExcludeWidget: "",
    },
  });
  const [shouldShow, setShouldShow] = useState<boolean>(true);
  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const [redirectionLink, setRedirectionLink] = useState<string>("");
  const [actualDate, setActualDate] = useState<number>(0);

  // Use Effect Functions
  useEffect(() => {
    getWidgetVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Internal functions
  const getWidgetVariables = async () => {
    await fetch(
      globalSelectedBackend + "/shops/" + domain + "/widgetvariables",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setWidget({
          domain: JSONresult.domain,
          setup: {
            primaryColor: JSONresult.setup.primaryColor,
            secondaryColor: JSONresult.setup.secondaryColor,
            fontColor: JSONresult.setup.fontColor,
            agentName: JSONresult.setup.agentName,
            onlinePhrase: JSONresult.setup.onlinePhrase,
            mainLanguage: JSONresult.setup.mainLanguage,
            side: JSONresult.setup.side,
            paddingBottom: +JSONresult.setup.paddingBottom,
            paddingSide: +JSONresult.setup.paddingSide,
            image:
              JSONresult.setup.image !== "Default"
                ? globalSelectedBackend !== "https://api.wizybot.com"
                  ? "https://s3-tjlabs-wizybot-widget-images-dev.s3.amazonaws.com/" +
                    JSONresult.setup.image +
                    "?nocache=" +
                    new Date()[Symbol.toPrimitive]("number")
                  : "https://s3-tjlabs-wizybot-widget-images-prod.s3.amazonaws.com/" +
                    JSONresult.setup.image +
                    "?nocache=" +
                    new Date()[Symbol.toPrimitive]("number")
                : "",
            isVisible: JSONresult.setup.isVisible,
            emailRetrievalMethod: JSONresult.setup.emailRetrievalMethod,
            dataRetrievalType: JSONresult.setup.dataRetrievalType,
            dataRetrievalCustomPrompt:
              JSONresult.setup.dataRetrievalCustomPrompt,
            isRedirect: JSONresult.setup.isRedirect,
            redirectionLink: JSONresult.setup.redirectionLink,
            isOverLimitBudget: JSONresult.setup.isOverLimitBudget,
            limitBudgetAction: JSONresult.setup.limitBudgetAction,
            limitBudgetRedirectionLink:
              JSONresult.setup.limitBudgetRedirectionLink,
            hideNotificationSign: JSONresult.setup.hideNotificationSign,
            hideWizybotBanner: JSONresult.setup.hideWizybotBanner,
            hideOutboundMessage: JSONresult.setup.hideOutboundMessage,
            preventSaleNoteCreation: JSONresult.setup.preventSaleNoteCreation,
            customWidgetCode: JSONresult.setup.customWidgetCode,
            pagesToExcludeWidget: JSONresult.setup.pagesToExcludeWidget,
          },
        });
        // The widget should be shown if:
        var shouldShowWidget =
          isDashboard ||
          (JSONresult.setup.isVisible &&
            (shopifyCurrentPath === null ||
            JSONresult.setup.pagesToExcludeWidget === null
              ? true
              : !JSONresult.setup.pagesToExcludeWidget
                  .split(",")
                  .some((pageToExclude: string) =>
                    shopifyCurrentPath.includes(pageToExclude)
                  )));
        // Initially widget should not redirect
        var shouldRedirect = false;
        var redirectionLink = "";
        // But in case is not in the dashboard
        if (!isDashboard) {
          // The widget should be redirecting if is redirect flag
          shouldRedirect = JSONresult.setup.isRedirect;

          // The widget should redirect to this link
          redirectionLink = JSONresult.setup.redirectionLink;

          // Check limit budgests and what to do if over the limit
          if (JSONresult.setup.isOverLimitBudget) {
            if (JSONresult.setup.limitBudgetAction === "widget off") {
              shouldShowWidget = false;
            }
            if (JSONresult.setup.limitBudgetAction === "redirect") {
              shouldRedirect = true;
              redirectionLink = JSONresult.setup.limitBudgetRedirectionLink;
            }
          }
        }
        // Set vairables in state
        setRedirectionLink(redirectionLink);
        setShouldShow(shouldShowWidget);
        setIsRedirect(shouldRedirect);
        setActualDate(new Date().getTime());
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Show new message
  const showNewMessage = () => {
    if (!isOpen) {
      setWidth(280);
    }
  };

  // Hide new message
  const hideNewMessage = () => {
    if (!isOpen) {
      setWidth(80);
    }
  };

  // JSX Return statement
  if (isLoaded) {
    return !shouldShow ? (
      <></>
    ) : (
      <React.Fragment>
        {/* Import styles outside the iFrame */}
        <link
          rel="stylesheet"
          href={
            isRelative
              ? stylesPathOutter + "?randomID=" + actualDate
              : stylesPathOutter
          }
        />
        <div
          id="WizybotShopifyWidget__data__div"
          data-storesetup={JSON.stringify(widget)}
        ></div>
        {/* Insert iframe */}
        <ReactIframe
          iframetitle="wizybot-chat-iframe"
          className={
            isRelative
              ? "WizybotShopifyWidget__iframe__outter__open__relative"
              : isOpen
              ? widget.setup.side === ESide.RIGHT
                ? "WizybotShopifyWidget__iframe__outter__open__right"
                : "WizybotShopifyWidget__iframe__outter__open__left"
              : "WizybotShopifyWidget__iframe__outter__close"
          }
          id="WizybotShopifyWidget__iframe__outter__id"
          style={{
            right: !isOpen
              ? widget.setup.side === ESide.RIGHT
                ? widget.setup.paddingSide
                : "calc(100% - " + (widget.setup.paddingSide + width) + "px)"
              : "",
            bottom: !isOpen ? widget.setup.paddingBottom : "",
            width: !isOpen ? width + "px" : "",
            height: !isOpen ? "80px" : "",
          }}
        >
          <div>
            {/* Import styles */}
            <link
              rel="stylesheet"
              href={
                isRelative
                  ? stylesPathInner + "?randomID=" + actualDate
                  : stylesPathInner
              }
            />
            {/* Import fonts */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
              rel="stylesheet"
            />
            {/* Call the actual shopify widget component */}
            <ShopifyWidget
              domain={domain}
              widget={widget}
              isTest={isTest}
              isAdmin={isAdmin}
              isDashboard={isDashboard}
              isRelative={isRelative}
              ipRegistryKey={ipRegistryKey}
              globalSelectedBackend={globalSelectedBackend}
              chatProfileImage={chatProfileImage}
              curvyBorderImage={curvyBorderImage}
              wizyLogoImage={wizyLogoImage}
              shopifyRootPath={shopifyRootPath}
              shopifyCurrentPath={
                shopifyCurrentPath === null ? "/" : shopifyCurrentPath
              }
              isRedirect={isRedirect}
              redirectionLink={redirectionLink}
              showNewMessage={showNewMessage}
              hideNewMessage={hideNewMessage}
              setIsOpen={setIsOpen}
            />
          </div>
        </ReactIframe>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
};

// Default exported function
export default ShopifyWidgetWrapper;
