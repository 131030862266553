// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

// Import media
import wizy_shop_bag_filled from "../images/wizy_shop_bag_filled.svg";
import wizy_chat_profile from "../images/wizy_chat_profile.png";
import wizy_curvy_border from "../images/wizy_curvy_border.png";
import wizy_logo_blue from "../images/wizy_logo_blue.svg";

import "./styles/Test.css";
import { useTranslation } from "react-i18next";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import ShopifyWidgetWrapper from "../components/ShopifyWidgetWrapper";
// Import media

// Page main functional component
const Test: FC = () => {
  // Use history
  const navigate = useNavigate();

  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["test"]);

  // Use state variables
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });

  const cookies = new Cookies();

  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  // Internal Functions
  // Use Effect Functions
  useEffect(() => {
    if (validatorProps.validatorPrompt !== "") {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  const deleteClient = async () => {
    const clientCookie = cookies.get("WIZY_CLIENT_" + globalShop.domain);
    await fetch(globalSelectedBackend + "/clients/" + clientCookie, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt: t("Success", { ns: ["clients"] }),
            validatorTitle: t("Validation", { ns: ["clients"] }),
            functionOne: () => {
              cookies.remove("WIZY_CLIENT_" + globalShop.domain, { path: "/" });
              window.location.reload();
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: t("Error", { ns: ["clients"] }),
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error", { ns: ["clients"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  // JSX Return statement
  return (
    <React.Fragment>
      <Validator
        ref={ValidatorRef}
        functionNumber={validatorProps.functionNumber}
        functionOne={validatorProps.functionOne}
        functionOnePrompt={validatorProps.functionOnePrompt}
        functionTwo={validatorProps.functionTwo}
        functionTwoPrompt={validatorProps.functionTwoPrompt}
        validatorTitle={validatorProps.validatorTitle}
        validatorPrompt={validatorProps.validatorPrompt}
      />
      <div className="Test__outter">
        <div
          className="Test__back__outter"
          onClick={() => {
            navigate("/home");
          }}
        >
          {t("Back", { ns: ["test"] })}
        </div>
        <div
          className="Test__reset__outter"
          onClick={() => {
            setValidatorProps({
              validatorPrompt: t("DeletionD", {
                ns: ["clients"],
              }),
              validatorTitle: t("Deletion", {
                ns: ["clients"],
              }),
              functionOne: () => {
                deleteClient();
              },
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "NO",
              functionNumber: 2,
            });
          }}
        >
          {t("Reset", { ns: ["test"] })}
        </div>
        <div className="Test__banner__outter">
          <div className="Test__banner__inner">
            <div className="Test__shop__outter">
              <div className="Test__shop__image__outter">
                <img
                  src={wizy_shop_bag_filled}
                  alt="wizy_shop_bag_filled"
                  className="Test__shop__image"
                />
              </div>
              <div className="Test__shop__text">{globalShop.domain}</div>
            </div>
          </div>
        </div>

        <div className="Test__content__outter">
          <div className="Test__content__inner"></div>
          <div className="Test__content__inner"></div>
          <div className="Test__content__inner"></div>
        </div>
        <div className="Test__content__outter">
          <div className="Test__content__inner"></div>
          <div className="Test__content__inner"></div>
          <div className="Test__content__inner"></div>
        </div>
        <div className="Test__bottom__outter">
          <div className="Test__bottom__inner"></div>
        </div>
        <div>
          <ShopifyWidgetWrapper
            domain={globalShop.domain}
            isTest={true}
            isAdmin={false}
            isRelative={false}
            stylesPathInner="./ShopifyWidgetInner.css"
            stylesPathOutter="./ShopifyWidgetOutter.css"
            ipRegistryKey={
              process.env.REACT_APP_CLIENT_INFO_KEY || "tdk18ncwdb68ukmd"
            }
            globalSelectedBackend={globalSelectedBackend}
            chatProfileImage={wizy_chat_profile}
            curvyBorderImage={wizy_curvy_border}
            wizyLogoImage={wizy_logo_blue}
            shopifyRootPath={null}
            shopifyCurrentPath={null}
            isDashboard={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default Test;
