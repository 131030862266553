// Import React Dependencies
import { FC, useEffect, useState } from "react";
import React from "react";

// Import styles
import "./styles/CustomWidgetManager.css";
import { IShop } from "../types/ShopType";
import { useTranslation } from "react-i18next";

import wizy_loader from "../images/wizy_loader.gif";
import { AppContext, IContext } from "../context/Context";

// Declare types and interfaces
export type ICustomWidgetManagerProps = {
  shop: IShop;
};

export type IWidgetAdminControls = {
  hideNotificationSign: boolean;
  hideWizybotBanner: boolean;
  hideOutboundMessage: boolean;
  preventSaleNoteCreation: boolean;
  customWidgetCode: string;
  pagesToExcludeWidget: string;
  needsLanguageCheck: boolean;
  metaAvailable: boolean;
};

// Page main functional component
const CustomWidgetManager: FC<ICustomWidgetManagerProps> = ({ shop }) => {
  const { t } = useTranslation(["customWidgetManager"]);
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;
  const [widgetAdminControls, setWidgetAdminControls] =
    useState<IWidgetAdminControls>({
      hideNotificationSign: false,
      hideWizybotBanner: false,
      hideOutboundMessage: false,
      preventSaleNoteCreation: false,
      customWidgetCode: "",
      pagesToExcludeWidget: "",
      needsLanguageCheck: false,
      metaAvailable: false,
    });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Use Effect Functions
  useEffect(() => {
    getWidgetAdminControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Internal functions
  const getWidgetAdminControls = async () => {
    await fetch(
      globalSelectedBackend + "/admin/" + shop.id + "/widgetadmincontrols",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult);
        setWidgetAdminControls({
          hideNotificationSign: JSONresult.hideNotificationSign,
          hideWizybotBanner: JSONresult.hideWizybotBanner,
          hideOutboundMessage: JSONresult.hideOutboundMessage,
          preventSaleNoteCreation: JSONresult.preventSaleNoteCreation,
          customWidgetCode:
            JSONresult.customWidgetCode === null
              ? ""
              : JSONresult.customWidgetCode,
          pagesToExcludeWidget:
            JSONresult.pagesToExcludeWidget === null
              ? ""
              : JSONresult.pagesToExcludeWidget,
          needsLanguageCheck: JSONresult.needsLanguageCheck,
          metaAvailable: JSONresult.metaAvailable,
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectBoolean = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    setWidgetAdminControls((prevState) => ({
      ...prevState,
      [name]: value === "True" ? true : false,
    }));
  };

  const handleSetupChangeText = (
    event: React.FormEvent<HTMLTextAreaElement>,
    limit: number
  ) => {
    const { name, value } = event.currentTarget;
    if (value.split(" ").length <= limit) {
      setWidgetAdminControls((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const patchWidgetAdminControls = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      hideNotificationSign: widgetAdminControls.hideNotificationSign,
      hideWizybotBanner: widgetAdminControls.hideWizybotBanner,
      hideOutboundMessage: widgetAdminControls.hideOutboundMessage,
      preventSaleNoteCreation: widgetAdminControls.preventSaleNoteCreation,
      customWidgetCode:
        widgetAdminControls.customWidgetCode === ""
          ? null
          : widgetAdminControls.customWidgetCode,
      pagesToExcludeWidget:
        widgetAdminControls.pagesToExcludeWidget === ""
          ? null
          : widgetAdminControls.pagesToExcludeWidget,
      needsLanguageCheck: widgetAdminControls.needsLanguageCheck,
      metaAvailable: widgetAdminControls.metaAvailable,
    });

    await fetch(
      globalSelectedBackend + "/admin/" + shop.id + "/widgetadmincontrols",
      {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#eff2f6" }}>
          <div
            className="ChildrenSelector__inner"
            style={{ backgroundColor: "#eff2f6" }}
          >
            <div
              className="ChildrenSelector__inner__inner"
              style={{ marginLeft: "0px" }}
            >
              <div className="ChildrenSelector__selector__outter__outter">
                <div className="ChildrenSelector__selector__outter__inner">
                  {/* Title */}
                  <div className="ChildrenSelector__title">
                    {t("CustomWidgetManager", { ns: ["customWidgetManager"] })}
                  </div>
                  {/* Description */}
                  <div className="ChildrenSelector__subtitle">
                    {t("CustomWidgetManagerSubtitle", {
                      ns: ["customWidgetManager"],
                    })}
                  </div>
                  <div className="CustomWidgetManager__outter">
                    <div className="CustomWidgetManager__inner">
                      {/* Hide notification sign */}
                      <div className="Wizy__input__title">
                        {t("HideNotificationSignTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <select
                          className="Wizy__input__select__1"
                          name="hideNotificationSign"
                          onChange={handleSelectBoolean}
                          value={
                            widgetAdminControls.hideNotificationSign
                              ? "True"
                              : "False"
                          }
                        >
                          <option value="True">
                            {t("Hide", { ns: ["customWidgetManager"] })}
                          </option>
                          <option value="False">
                            {t("DontHide", { ns: ["customWidgetManager"] })}
                          </option>
                        </select>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("HideNotificationSignExample", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      {/* Hide wizybot banner */}
                      <div className="Wizy__input__title">
                        {t("HideWizybotBannerTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <select
                          className="Wizy__input__select__1"
                          name="hideWizybotBanner"
                          onChange={handleSelectBoolean}
                          value={
                            widgetAdminControls.hideWizybotBanner
                              ? "True"
                              : "False"
                          }
                        >
                          <option value="True">
                            {t("Hide", { ns: ["customWidgetManager"] })}
                          </option>
                          <option value="False">
                            {t("DontHide", { ns: ["customWidgetManager"] })}
                          </option>
                        </select>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("HideWizybotBannerExample", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                       {/* Show meta integration */}
                       <div className="Wizy__input__title">
                        {t("MetaAvailableTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <select
                          className="Wizy__input__select__1"
                          name="metaAvailable"
                          onChange={handleSelectBoolean}
                          value={
                            widgetAdminControls.metaAvailable
                              ? "True"
                              : "False"
                          }
                        >
                          <option value="True">
                            {t("DontHide", { ns: ["customWidgetManager"] })}
                          </option>
                          <option value="False">
                            {t("Hide", { ns: ["customWidgetManager"] })}
                          </option>
                        </select>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("MetaAvailableExample", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      {/* Custome widget code */}
                      <div className="Wizy__input__title">
                        {t("CustomWidgetCodeTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__textarea__outter">
                        <textarea
                          name="customWidgetCode"
                          value={widgetAdminControls.customWidgetCode}
                          className="Wizy__textarea"
                          placeholder={t("CustomWidgetCodeTitle", {
                            ns: ["customWidgetManager"],
                          })}
                          style={{
                            height: "100px",
                          }}
                          onChange={(event) => {
                            handleSetupChangeText(event, 10000);
                          }}
                        ></textarea>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("Length", { ns: ["customWidgetManager"] })}:{" "}
                        {widgetAdminControls.customWidgetCode === ""
                          ? 0
                          : widgetAdminControls.customWidgetCode.split(" ")
                              .length}{" "}
                        / 10000
                      </div>
                    </div>
                    <div className="CustomWidgetManager__inner">
                      {/* Hide outbound message */}
                      <div className="Wizy__input__title">
                        {t("HideOutboundMessageTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <select
                          className="Wizy__input__select__1"
                          name="hideOutboundMessage"
                          onChange={handleSelectBoolean}
                          value={
                            widgetAdminControls.hideOutboundMessage
                              ? "True"
                              : "False"
                          }
                        >
                          <option value="True">
                            {t("Hide", { ns: ["customWidgetManager"] })}
                          </option>
                          <option value="False">
                            {t("DontHide", { ns: ["customWidgetManager"] })}
                          </option>
                        </select>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("HideOutboundMessageExample", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      {/* Needs language check */}
                      <div className="Wizy__input__title">
                        {t("NeedsLanguageCheckTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <select
                          className="Wizy__input__select__1"
                          name="needsLanguageCheck"
                          onChange={handleSelectBoolean}
                          value={
                            widgetAdminControls.needsLanguageCheck
                              ? "True"
                              : "False"
                          }
                        >
                          <option value="True">
                            {t("Yes", { ns: ["customWidgetManager"] })}
                          </option>
                          <option value="False">
                            {t("No", { ns: ["customWidgetManager"] })}
                          </option>
                        </select>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("NeedsLanguageCheckExample", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      {/* Prevent Sale note Creation */}
                      <div className="Wizy__input__title">
                        {t("PreventSaleNoteCreationTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <select
                          className="Wizy__input__select__1"
                          name="preventSaleNoteCreation"
                          onChange={handleSelectBoolean}
                          value={
                            widgetAdminControls.preventSaleNoteCreation
                              ? "True"
                              : "False"
                          }
                        >
                          <option value="True">
                            {t("DontCreate", { ns: ["customWidgetManager"] })}
                          </option>
                          <option value="False">
                            {t("Create", { ns: ["customWidgetManager"] })}
                          </option>
                        </select>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("PreventSaleNoteCreationExample", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      {/* Pages to exclude widget */}
                      <div className="Wizy__input__title">
                        {t("PagesToExcludeWidgetTitle", {
                          ns: ["customWidgetManager"],
                        })}
                      </div>
                      <div className="Wizy__textarea__outter">
                        <textarea
                          name="pagesToExcludeWidget"
                          value={widgetAdminControls.pagesToExcludeWidget}
                          className="Wizy__textarea"
                          placeholder={t("PagesToExcludeWidgetTitle", {
                            ns: ["customWidgetManager"],
                          })}
                          style={{
                            height: "100px",
                          }}
                          onChange={(event) => {
                            handleSetupChangeText(event, 10000);
                          }}
                        ></textarea>
                      </div>
                      <div className="Wizy__input__prompt__1">
                        {t("Length", { ns: ["customWidgetManager"] })}:{" "}
                        {widgetAdminControls.pagesToExcludeWidget === ""
                          ? 0
                          : widgetAdminControls.pagesToExcludeWidget.split(" ")
                              .length}{" "}
                        / 10000
                      </div>
                    </div>
                  </div>
                  <div className="CustomWidgetManager__button__outter">
                    <button
                      className="Wizy__button__2"
                      style={{ width: "150px", height: "40px" }}
                      onClick={() => {
                        patchWidgetAdminControls();
                      }}
                    >
                      {t("Update", {
                        ns: ["customWidgetManager"],
                      })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div style={{ backgroundColor: "#eff2f6" }}>
        <div
          className="ChildrenSelector__inner"
          style={{ backgroundColor: "#eff2f6" }}
        >
          <div
            className="CustomOffer__inner__inner"
            style={{ marginLeft: "0px" }}
          >
            <div className="Home__dashboard__indicators__indicator__outter">
              <div className="Home__dashboard__indicators__indicator__inner">
                <div className="Home__dashboard__indicators__indicator__inner__inner">
                  <img
                    src={wizy_loader}
                    className="ProtectedRoute__loader"
                    alt="ProtectedRoute__loader"
                    id="ProtectedRoute__loader"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// Default exported function
export default CustomWidgetManager;
