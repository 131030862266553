// Import React Dependencies
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

// Import Media
import wizy_globe_outline from "../images/wizy_globe_outline.svg";
import wizy_arrow_down_outline from "../images/wizy_arrow_down_outline.svg";

// Import styles
import "./styles/LanguageSelector.css";

// Declare types and interfaces
type LanguageSelectorProps = {
  side: string;
  color: string;
};

// Page main functional component
const LanguageSelector: FC<LanguageSelectorProps> = ({ side, color }) => {
  // Use translation
  const { i18n } = useTranslation();

  // Use state variables
  const [isMenuHided, setIsMenuHided] = useState<boolean>(true);
  const cookies = new Cookies();

  // Internal Functions
  const toogleHidedMenu = () => {
    if (isMenuHided) {
      setIsMenuHided(false);
    } else {
      setIsMenuHided(true);
    }
  };

  const onClickLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    cookies.set("language", language, {
      path: "/",
      maxAge: 60 * 60 * 24 * 365,
    });
    toogleHidedMenu();
  };

  // JSX Return statement
  return (
    <React.Fragment>
      <div
        className={
          isMenuHided
            ? "LanguageSelector__select"
            : "LanguageSelector__selected"
        }
        style={{ color: color, borderColor: color }}
        onClick={toogleHidedMenu}
      >
        <img
          src={wizy_globe_outline}
          alt="wizy_globe_outline"
          className="LanguageSelector__select__globe__image"
          style={{
            filter:
              color === "black"
                ? "invert(100%) sepia(39%) saturate(2%) hue-rotate(108deg) brightness(0%) contrast(100%)"
                : "",
          }}
        />
        {(cookies.get("language") ? cookies.get("language") : "en")
          .toString()
          .toUpperCase()}
        <img
          src={wizy_arrow_down_outline}
          alt="wizy_arrow_down_outline"
          className="LanguageSelector__select__arrow__image"
          style={{
            filter:
              color === "black"
                ? "invert(100%) sepia(39%) saturate(2%) hue-rotate(108deg) brightness(0%) contrast(100%)"
                : "",
          }}
        />
      </div>
      {isMenuHided ? (
        ""
      ) : (
        <div
          className={
            side === "right"
              ? "LanguageSelector__select__list__right"
              : "LanguageSelector__select__list__left"
          }
        >
          <div
            className="LanguageSelector__select__item"
            onClick={() => {
              onClickLanguageChange("en");
            }}
          >
            English
          </div>
          <div
            className="LanguageSelector__select__item"
            onClick={() => {
              onClickLanguageChange("es");
            }}
          >
            Spanish
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

// Default exported function
export default LanguageSelector;
