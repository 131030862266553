// Import React Dependencies
import React, { FC } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Import Context
import Context from "../context/Context";

// Import Components
import ProtectedRoute, { ProtectedRouteProps } from "./ProtectedRoute";
import Layout from "./Layout";
import LayoutAdmin from "./LayoutAdmin";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Billing from "../pages/Billing";
import Chat from "../pages/Chat";
import Tickets from "../pages/Tickets";
import Settings from "../pages/Settings";
import MerchantSettings from "../pages/MerchantSettings";
import Test from "../pages/Test";
import HomeAdmin from "../pages/HomeAdmin";
import Merchants from "../pages/Merchants";
import ChatAdmin from "../pages/ChatAdmin";
import { EType } from "../types/UserType";
import TestAdmin from "../pages/TestAdmin";
import Products from "../pages/Products";
import AdditionalInfo from "../pages/AdditionalInfo";
import Training from "../pages/Training";
import ChatReview from "../pages/ChatReview";
import CachedResponses from "../pages/CachedResponses";
import ApproveSales from "../pages/ApproveSales";
import TwoFa from "../pages/TwoFa";

// Page main functional component
const App: FC = () => {
  // Use state variables
  const defaultProtectedRouteProps: Omit<
    ProtectedRouteProps,
    "outlet" | "protectionType"
  > = {
    authenticationPath: "/",
  };

  // JSX Return statement
  return (
    <Context>
      <Router>
        <Routes>
          <Route path="/:error?/:account?" element={<Login />} />
          <Route path="/twofa" element={<TwoFa />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="home">
                    <Home />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/homeadmin"
            element={
              <ProtectedRoute
                outlet={
                  <LayoutAdmin actualPage="home">
                    <HomeAdmin />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
                authenticationPath="/home"
              />
            }
          />
          <Route
            path="/billing/:plan?"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="home">
                    <Billing />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="chat">
                    <Chat />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/chatadmin"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <LayoutAdmin actualPage="chat">
                    <ChatAdmin />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
          <Route
            path="/tickets"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="tickets">
                    <Tickets />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/products"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="products">
                    <Products />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/additionalinfo"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="additionalinfo">
                    <AdditionalInfo />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/training/:messageId?"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="training">
                    <Training />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/merchants"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <LayoutAdmin actualPage="merchants">
                    <Merchants />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <Layout actualPage="settings">
                    <Settings />
                  </Layout>
                }
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/test"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Test />}
                protectionType={[EType.SHOP]}
              />
            }
          />
          <Route
            path="/testadmin/:id?"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<TestAdmin />}
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
          <Route
            path="/merchantsettings/:id?"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <LayoutAdmin actualPage="merchants">
                    <MerchantSettings />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
          <Route
            path="/cachedresponses/:id?"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <LayoutAdmin actualPage="merchants">
                    <CachedResponses />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
          <Route
            path="/chatreview"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <LayoutAdmin actualPage="chat">
                    <ChatReview />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
          <Route
            path="/approvesales"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  <LayoutAdmin actualPage="chat">
                    <ApproveSales />
                  </LayoutAdmin>
                }
                protectionType={[EType.SUPER_ADMINISTRATOR]}
              />
            }
          />
        </Routes>
      </Router>
    </Context>
  );
};
// Default exported function
export default App;
