// Import React Dependencies
import { FC, useEffect, useState } from "react";
import React from "react";

// Import media
import wizy_loader from "../images/wizy_loader.gif";

// Import styles
import "./styles/AddInfoModal.css";
import { useTranslation } from "react-i18next";
import { ADDITIONAL_INFO_TYPE } from "../types/AdditionalInfoType";
import TagsInput from "./TagsInput";
import SearchableMultipleDropdown, {
  IOption,
} from "./SearchableMultipleDropdown";
import { IPageMeta } from "./Pagination";
import { AppContext, IContext } from "../context/Context";
import { IProduct } from "../types/ProductType";

// Declare types and interfaces
export type IAddKnowledgeModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// Page main functional component
const AddInfoModal: FC<IAddKnowledgeModalProps> = ({ setIsOpen }) => {
  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;
  // Use translation
  const { t } = useTranslation(["additionalInfo"]);

  // Use state variables
  const [isModalLoaded, setIsModalLoaded] = useState<boolean>(true);
  const [newAdditionalInfoType, setNewAdditionalInfoType] =
    useState<ADDITIONAL_INFO_TYPE | null>(null);

  // Variables for product recommendation
  const [tags, setTags] = useState<String[]>([]);

  // Variables for general add on
  const [topic, setTopic] = useState<string>("");
  const [generalAddOncontent, setGeneralAddOnContent] = useState<string>("");

  // Variables for product add on
  const [productAddOnContent, setProductAddOnContent] = useState<string>("");

  // Variables for discount
  const [hasCode, setHasCode] = useState<boolean>(true);
  const [code, setCode] = useState<string>("");
  const [discountDescription, setDiscountDescription] = useState<string>("");

  // Variables for product select dropdown
  const [modalProductQuery, setModalProductQuery] = useState<string>("");
  const [modalProductOptions, setModalProductOptions] = useState<IOption[]>([]);
  const [selectedModalProductOptions, setSelectedModalProductOptions] =
    useState<IOption[]>([]);
  const [modalProductPageMeta, setModalProductPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  // Use effect functions
  useEffect(() => {
    getProductOptions(modalProductPageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProductQuery]);

  const getProductOptions = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/products/shop/" +
      globalShop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add filter queries to URL
    if (modalProductQuery) {
      searchURL += "&text=" + modalProductQuery;
    }
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setModalProductOptions([]);
        JSONresult.data.forEach((product: IProduct) => {
          setModalProductOptions((previous) => [
            ...previous,
            {
              id: product.id,
              label: product.displayTitle,
            },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createGeneralAddOn = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      topic: topic,
      content: generalAddOncontent,
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/generaladdon/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createProductAddOn = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      content: productAddOnContent,
      productIds: selectedModalProductOptions.map(
        (selectedModalProductOption) => selectedModalProductOption.id
      ),
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/productaddon/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createTags = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tags: tags,
      productIds: selectedModalProductOptions.map(
        (selectedModalProductOption) => selectedModalProductOption.id
      ),
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/tags/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createDiscount = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      hasCode: hasCode,
      code: code,
      description: discountDescription,
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/discount/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // JSX Return statement
  if (isModalLoaded) {
    // JSX Return statement
    return (
      <React.Fragment>
        {/* Modal background that closes when clicked */}
        <div
          className="Modal__background"
          onClick={() => {
            setIsOpen(false);
            setNewAdditionalInfoType(null);
          }}
        />
        {/* Modal container */}
        <div className="Modal__outter">
          <div className="Modal__title">{t("AddKnowledge", { ns: ["additionalInfo"] })}</div>
          <button
            className="Modal__close__button"
            onClick={() => setIsOpen(false)}
          >
            x
          </button>
          <div className="Modal__body">
            {/* Title */}
            {t("WhatInfo", { ns: ["additionalInfo"] })}
            {/* Buttons to select new additional info type */}
            <div className="Modal__typebuttons__container">
              <div
                className="Modal__typebutton"
                onClick={() => {
                  setNewAdditionalInfoType(ADDITIONAL_INFO_TYPE.GENERALADDON);
                }}
              >
                {t("isGeneralAddOn", { ns: ["additionalInfo"] })}
              </div>
              <div
                className="Modal__typebutton"
                onClick={() => {
                  setNewAdditionalInfoType(ADDITIONAL_INFO_TYPE.PRODUCTADDON);
                }}
              >
                {t("isProductAddOn", { ns: ["additionalInfo"] })}
              </div>
              <div
                className="Modal__typebutton"
                onClick={() => {
                  setNewAdditionalInfoType(ADDITIONAL_INFO_TYPE.DISCOUNT);
                }}
              >
                {t("isDiscount", { ns: ["additionalInfo"] })}
              </div>
              <div
                className="Modal__typebutton"
                onClick={() => {
                  setNewAdditionalInfoType(ADDITIONAL_INFO_TYPE.TAG);
                }}
              >
                {t("isTag", { ns: ["additionalInfo"] })}
              </div>
            </div>
            {/* Form displayed according to additional info type */}
            {newAdditionalInfoType === ADDITIONAL_INFO_TYPE.TAG ? (
              <div className="Modal__form">
                {/* If the additional information is of TAG type */}
                <div className="Modal__form__subtitle">
                  {t("TagsInstruction", { ns: ["additionalInfo"] })}
                </div>
                <TagsInput
                  tagsState={[tags, setTags]}
                  placeholder={t("TagsPlaceholder", {
                    ns: ["additionalInfo"],
                  })}
                />
                <div className="Wizy__input__prompt__1">
                  {t("AidTags", { ns: ["additionalInfo"] })}
                </div>
                <div className="Modal__form__subtitle">
                  {t("ProductForTagInstruction", { ns: ["additionalInfo"] })}
                </div>
                <SearchableMultipleDropdown
                  queryState={[modalProductQuery, setModalProductQuery]}
                  optionsState={[modalProductOptions, setModalProductOptions]}
                  selectedOptionsState={[
                    selectedModalProductOptions,
                    setSelectedModalProductOptions,
                  ]}
                  placeholder={t("DropdownPlaceholder", {
                    ns: ["additionalInfo"],
                  })}
                />
                {/* If user has already selected tags and products, show confirmation */}
                {tags.length > 0 && selectedModalProductOptions.length > 0 ? (
                  <div className="Modal__actionbuttons__container">
                    <button
                      className="Modal__gobackbutton"
                      onClick={() => {
                        setIsOpen(false);
                        setNewAdditionalInfoType(null);
                      }}
                    >
                      {t("GoBack", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                    <button
                      className="Modal__addbutton"
                      onClick={() => {
                        createTags();
                        setIsModalLoaded(false);
                      }}
                    >
                      {t("AddNewInfo", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : newAdditionalInfoType === ADDITIONAL_INFO_TYPE.GENERALADDON ? (
              <div className="Modal__form">
                {/* If the additional information is of GENERALADDON type */}
                <div className="Modal__form__subtitle">
                  {t("TopicInstruction", { ns: ["additionalInfo"] })}
                </div>
                <textarea
                  className="Wizy__textarea"
                  name="topic"
                  value={topic}
                  placeholder={t("TopicPlaceholder", {
                    ns: ["additionalInfo"],
                  })}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.currentTarget;
                    setTopic(value);
                  }}
                ></textarea>
                <div className="Modal__form__subtitle">
                  {t("NewInfoInstruction", { ns: ["additionalInfo"] })}
                </div>
                <textarea
                  className="Wizy__textarea"
                  name="content"
                  value={generalAddOncontent}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.currentTarget;
                    setGeneralAddOnContent(value);
                  }}
                  placeholder={t("NewInfoPlaceholder", {
                    ns: ["additionalInfo"],
                  })}
                ></textarea>
                {topic !== "" && generalAddOncontent !== "" ? (
                  <div className="Modal__actionbuttons__container">
                    <button
                      className="Modal__gobackbutton"
                      onClick={() => {
                        setIsOpen(false);
                        setNewAdditionalInfoType(null);
                      }}
                    >
                      {t("GoBack", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                    <button
                      className="Modal__addbutton"
                      onClick={() => {
                        createGeneralAddOn();
                        setIsModalLoaded(false);
                      }}
                    >
                      {t("AddNewInfo", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : newAdditionalInfoType === ADDITIONAL_INFO_TYPE.PRODUCTADDON ? (
              <div className="Modal__form">
                {/* If the additional information is of PRODUCTADDON type */}
                <div className="Modal__form__subtitle">
                  {t("ProductForInfoInstruction", { ns: ["additionalInfo"] })}
                </div>
                <SearchableMultipleDropdown
                  queryState={[modalProductQuery, setModalProductQuery]}
                  optionsState={[modalProductOptions, setModalProductOptions]}
                  selectedOptionsState={[
                    selectedModalProductOptions,
                    setSelectedModalProductOptions,
                  ]}
                  placeholder={t("DropdownPlaceholder", {
                    ns: ["additionalInfo"],
                  })}
                />
                <div className="Modal__form__subtitle">
                  {t("NewInfoProductInstruction", { ns: ["additionalInfo"] })}
                </div>
                <textarea
                  className="Wizy__textarea"
                  name="newInfoProducts"
                  value={productAddOnContent}
                  placeholder={t("NewInfoProductPlaceholder", {
                    ns: ["additionalInfo"],
                  })}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.currentTarget;
                    setProductAddOnContent(value);
                  }}
                ></textarea>

                {/* If user has already selected products and added information, show confirmation */}
                {productAddOnContent !== "" &&
                selectedModalProductOptions.length > 0 ? (
                  <div className="Modal__actionbuttons__container">
                    <button
                      className="Modal__gobackbutton"
                      onClick={() => {
                        setIsOpen(false);
                        setNewAdditionalInfoType(null);
                      }}
                    >
                      {t("GoBack", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                    <button
                      className="Modal__addbutton"
                      onClick={() => {
                        createProductAddOn();
                        setIsModalLoaded(false);
                      }}
                    >
                      {t("AddNewInfo", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : newAdditionalInfoType === ADDITIONAL_INFO_TYPE.DISCOUNT ? (
              <div className="Modal__form">
                <div className="Modal__form__subtitle">
                  {t("HasCodeInstruction", { ns: ["additionalInfo"] })}
                </div>
                {/* Radio buttons container */}
                <div className="Modal__hasdiscount__container">
                  {/* Yes radio button */}
                  <div
                    className="Modal__input__option__outter"
                    onClick={() => {
                      setHasCode(true);
                    }}
                  >
                    <div
                      className={
                        hasCode
                          ? "Wizy__input__option__optioned"
                          : "Wizy__input__option"
                      }
                    >
                      <div className="Wizy__input__option__circle__outter">
                        <div
                          className={
                            hasCode
                              ? "Wizy__input__option__circle__inner__selected"
                              : "Wizy__input__option__circle__inner"
                          }
                        ></div>
                        {hasCode ? (
                          <div className="Wizy__input__option__circle__filled"></div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="Wizy__input__option__text">
                        {t("Yes", { ns: ["additionalInfo"] })}
                      </div>
                    </div>
                  </div>
                  {/* No radio button */}
                  <div
                    className="Modal__input__option__outter"
                    onClick={() => {
                      setHasCode(false);
                    }}
                  >
                    <div
                      className={
                        !hasCode
                          ? "Wizy__input__option__optioned"
                          : "Wizy__input__option"
                      }
                    >
                      <div className="Wizy__input__option__circle__outter">
                        <div
                          className={
                            !hasCode
                              ? "Wizy__input__option__circle__inner__selected"
                              : "Wizy__input__option__circle__inner"
                          }
                        ></div>
                        {!hasCode ? (
                          <div className="Wizy__input__option__circle__filled"></div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="Wizy__input__option__text">
                        {t("No", { ns: ["additionalInfo"] })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Discount code field */}
                {hasCode ? (
                  <div className="Wizy__input__2__outter">
                    <input
                      name="agentName"
                      value={code}
                      type="text"
                      className="Wizy__input__2"
                      onChange={(event) => {
                        const { value } = event.currentTarget;
                        setCode(value);
                      }}
                      placeholder={t("CodePlaceholder", {
                        ns: ["additionalInfo"],
                      })}
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* Description field */}
                <div className="Modal__form__subtitle">
                  {t("DiscountDescriptionInstruction", {
                    ns: ["additionalInfo"],
                  })}
                </div>
                <textarea
                  className="Wizy__textarea"
                  name="discountDescription"
                  value={discountDescription}
                  placeholder={
                    hasCode
                      ? t("DiscountDescriptionWithCodePlaceholder", {
                          ns: ["additionalInfo"],
                        })
                      : t("DiscountDescriptionWithoutCodePlaceholder", {
                          ns: ["additionalInfo"],
                        })
                  }
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.currentTarget;
                    setDiscountDescription(value);
                  }}
                ></textarea>
                {/* If user has already input the discount information, show confirmation */}
                {(hasCode && code !== "" && discountDescription !== "") ||
                (!hasCode && discountDescription !== "") ? (
                  <div className="Modal__actionbuttons__container">
                    <button
                      className="Modal__gobackbutton"
                      onClick={() => {
                        setIsOpen(false);
                        setNewAdditionalInfoType(null);
                      }}
                    >
                      {t("GoBack", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                    <button
                      className="Modal__addbutton"
                      onClick={() => {
                        createDiscount();
                        setIsModalLoaded(false);
                      }}
                    >
                      {t("AddNewInfo", {
                        ns: ["additionalInfo"],
                      })}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* Modal background that closes when clicked */}
        <div
          className="Modal__background"
          onClick={() => {
            setIsOpen(false);
            setNewAdditionalInfoType(null);
          }}
        />
        {/* Modal container */}
        <div className="Modal__outter">
          <img
            src={wizy_loader}
            className="ProtectedRoute__loader"
            alt="ProtectedRoute__loader"
            id="ProtectedRoute__loader"
          />
        </div>
      </React.Fragment>
    );
  }
};

// Default exported function
export default AddInfoModal;
