// Import React Dependencies
import React, { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Import Media
import wizy_home_filled from "../images/wizy_home_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";
import wizy_ticket_filled from "../images/wizy_ticket_filled.svg";
import wizy_settings_filled from "../images/wizy_settings_filled.svg";
import wizy_knowledge_filled from "../images/wizy_knowledge_filled.svg";
import wizy_brain_filled from "../images/wizy_brain_filled.svg";

// Import styles
import "./styles/Bottombar.css";
import { AppContext, IContext } from "../context/Context";
import { EType } from "../types/UserType";

// Declare types and interfaces
type BottombarProps = {
  actualPage: string;
};

// Page main functional component
const Bottombar: FC<BottombarProps> = ({ actualPage }) => {
  // Use context
  const { globalShop, globalUser } = React.useContext(AppContext) as IContext;

  // Use history
  const navigate = useNavigate();

  // Use state variables

  // References needed
  const barRefDesktop = useRef<HTMLDivElement>(null);
  const barRefMobile = useRef<HTMLDivElement>(null);

  // Internal Functions
  useEffect(() => {
    switch (actualPage) {
      case "home":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "10px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "5%";
        }
        break;
      case "chat":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "70px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "25%";
        }
        break;
      case "tickets":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "130px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "45%";
        }
        break;
      case "training":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "190px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "65%";
        }
        break;
      case "additionalinfo":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "250px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "69.7%";
        }
        break;
      case "settings":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "calc(100% - 50px)";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "85%";
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualPage]);

  if (!globalShop.needsSetup && !globalShop.needsSync) {
    // JSX Return statement
    return (
      <React.Fragment>
        <div className="Bottombar__outter">
          <div
            ref={barRefDesktop}
            className="Bottombar__highlight__bar__desktop"
          ></div>
          <div
            ref={barRefMobile}
            className="Bottombar__highlight__bar__mobile"
          ></div>
          <div className="Bottombar__inner">
            {/* Home */}
            <div
              className={
                actualPage === "home"
                  ? "Bottombar__outter__item__selected"
                  : "Bottombar__outter__item"
              }
              onClick={() => {
                if (globalUser.type === EType.SUPER_ADMINISTRATOR) {
                  navigate("/homeadmin");
                } else {
                  navigate("/home");
                }
              }}
            >
              <img
                src={wizy_home_filled}
                alt="wizy_home_filled"
                className="Bottombar__item__image"
                style={{
                  filter:
                    actualPage === "home"
                      ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                      : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
                }}
              />
            </div>

            {/* Chats */}
            <div
              className={
                actualPage === "chat"
                  ? "Bottombar__outter__item__selected"
                  : "Bottombar__outter__item"
              }
              onClick={() => {
                if (globalUser.type === EType.SUPER_ADMINISTRATOR) {
                  navigate("/chatadmin");
                } else if (!globalShop.needsSetup && !globalShop.needsSync) {
                  navigate("/chat");
                }
              }}
            >
              <img
                src={wizy_chat_filled}
                alt="wizy_chat_filled"
                className="Bottombar__item__image"
                style={{
                  filter:
                    actualPage === "chat"
                      ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                      : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
                }}
              />
            </div>

            {/* Tickets */}
            <div
              className={
                actualPage === "tickets"
                  ? "Bottombar__outter__item__selected"
                  : "Bottombar__outter__item"
              }
              onClick={() => {
                if (!globalShop.needsSetup && !globalShop.needsSync) {
                  navigate("/tickets");
                }
              }}
            >
              <img
                src={wizy_ticket_filled}
                alt="wizy_ticket_filled"
                className="Bottombar__item__image"
                style={{
                  filter:
                    actualPage === "tickets"
                      ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                      : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
                }}
              />
            </div>

            {/* Training */}
            <div
              className={
                actualPage === "training"
                  ? "Bottombar__outter__item__selected"
                  : "Bottombar__outter__item"
              }
              id="Bottombar__outter__item__clients"
              onClick={() => {
                if (globalUser.type === EType.SHOP) {
                  navigate("/training");
                } else if (!globalShop.needsSetup && !globalShop.needsSync) {
                  navigate("/home");
                }
              }}
            >
              <img
                src={wizy_brain_filled}
                alt="wizy_brain_filled"
                className="Bottombar__item__image"
                style={{
                  filter:
                    actualPage === "training"
                      ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                      : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
                  width: "22px",
                }}
              />
            </div>

            {/* Additional Info */}
            <div
              className={
                actualPage === "additionalinfo"
                  ? "Bottombar__outter__item__selected"
                  : "Bottombar__outter__item"
              }
              id="Bottombar__outter__item__additionalinfo"
              onClick={() => {
                if (globalUser.type === EType.SUPER_ADMINISTRATOR) {
                  navigate("/merchants");
                } else if (!globalShop.needsSetup && !globalShop.needsSync) {
                  navigate("/additionalinfo");
                }
              }}
            >
              <img
                src={wizy_knowledge_filled}
                alt="wizy_clients_filled"
                className="Bottombar__item__image"
                style={{
                  filter:
                    actualPage === "additionalinfo"
                      ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                      : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
                }}
              />
            </div>

            {/* Settings */}
            <div
              className={
                actualPage === "settings"
                  ? "Bottombar__outter__item__settings__selected"
                  : "Bottombar__outter__item__settings"
              }
              onClick={() => {
                if (!globalShop.needsSetup && !globalShop.needsSync) {
                  navigate("/settings");
                }
              }}
            >
              <img
                src={wizy_settings_filled}
                alt="wizy_settings_filled"
                className="Bottombar__item__image"
                style={{
                  filter:
                    actualPage === "settings"
                      ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                      : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="Bottombar__needs__outter"></div>
      </React.Fragment>
    );
  }
};

// Default exported function
export default Bottombar;
