// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import SettingsComponent from "../components/SettingsComponent";

import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";

// Page main functional component
const Settings: FC = () => {
  // Use context
  const { globalShop, globalSelectedBackend, globalUser, changePassword } =
    React.useContext(AppContext) as IContext;

  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  useEffect(() => {
    if (isLoaded) {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  return (
    <React.Fragment>
      <Validator
        ref={ValidatorRef}
        functionNumber={validatorProps.functionNumber}
        functionOne={validatorProps.functionOne}
        functionOnePrompt={validatorProps.functionOnePrompt}
        functionTwo={validatorProps.functionTwo}
        functionTwoPrompt={validatorProps.functionTwoPrompt}
        validatorTitle={validatorProps.validatorTitle}
        validatorPrompt={validatorProps.validatorPrompt}
      />
      <SettingsComponent
        globalShop={globalShop}
        globalSelectedBackend={globalSelectedBackend}
        globalUser={globalUser}
        setValidatorProps={setValidatorProps}
        updateIsLoaded={() => setIsLoaded(true)}
        changePassword={changePassword}
      />
    </React.Fragment>
  );
};

// Default exported function
export default Settings;
