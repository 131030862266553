// Import React Dependencies
import { FC, useState } from "react";
import React from "react";

// Import media
import wizy_loader from "../images/wizy_loader.gif";

// Import styles
import "./styles/AddInfoModal.css";
import { useTranslation } from "react-i18next";
import { AppContext, IContext } from "../context/Context";
import { useParams } from "react-router-dom";

// Declare types and interfaces
export type IAddCacheModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storedEmbeddingMessageCount: number;
};
export type ISimilarUserMessage = {
  id: string;
  content: string;
  similarity: number;
};

const AddCacheModal: FC<IAddCacheModalProps> = ({
  setIsOpen,
  storedEmbeddingMessageCount,
}) => {
  // Use params
  const { id } = useParams<{ id: string | undefined }>();
  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["additionalInfo"]);

  // Use state variables
  const [isModalLoaded, setIsModalLoaded] = useState<boolean>(true);
  const [userMessage, setUserMessage] = useState<string>("");
  const [beforeSimilarCheck, setBeforeSimilarCheck] = useState<boolean>(true);
  const [duringSimilarUserMessagesFetch, setDuringSimilarUserMessagesFetch] =
    useState<boolean>(false);
  const [similarUserMessages, setSimilarUserMessages] = useState<
    ISimilarUserMessage[]
  >([]);
  const [selectedSimilarUserMessage, setSelectedSimilarUserMessage] =
    useState<ISimilarUserMessage>({ id: "", content: "", similarity: 20 });
  const [minSimilarity, setMinSimilarity] = useState<number>(20);
  const [messageRatio, setMessageRatio] = useState<string>("");
  const [aiResponse, setAiResponse] = useState<string>("");

  const getSimilarUserMessages = async () => {
    setDuringSimilarUserMessagesFetch(true);
    await fetch(
      globalSelectedBackend +
        "/cachedresponses/similarusermessages/" +
        id +
        "/?userMessage=" +
        userMessage,
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setSimilarUserMessages([]);
        JSONresult.forEach((similarUserMessage: ISimilarUserMessage) => {
          setSimilarUserMessages((previous) => [
            ...previous,
            {
              id: similarUserMessage.id,
              content: similarUserMessage.content,
              similarity: similarUserMessage.similarity,
            },
          ]);
        });
        setBeforeSimilarCheck(false);
        setDuringSimilarUserMessagesFetch(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMinSimilaritySelect = (
    similarUserMessage: ISimilarUserMessage
  ) => {
    setMinSimilarity(similarUserMessage.similarity);
    const messageRatio =
      (similarUserMessages.filter(
        (message) => message.similarity >= similarUserMessage.similarity
      ).length * 100 / storedEmbeddingMessageCount).toPrecision(4) + "%";
    setMessageRatio(messageRatio);
    setSelectedSimilarUserMessage(similarUserMessage);
  };

  const createCachedResponse = async () => {
    setIsModalLoaded(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userMessage: userMessage,
      minSimilarity: minSimilarity,
      aiResponse: aiResponse,
    });

    await fetch(globalSelectedBackend + "/cachedresponses/" + id, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // JSX Return statement
  if (isModalLoaded) {
    // JSX Return statement
    return (
      <React.Fragment>
        {/* Modal background that closes when clicked */}
        <div
          className="Modal__background"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        {/* Modal container */}
        <div className="Modal__outter">
          <div className="Modal__title">
            {t("AddResponse", { ns: ["cachedResponses"] })}
          </div>
          <button
            className="Modal__close__button"
            onClick={() => setIsOpen(false)}
          >
            x
          </button>
          <div className="Modal__body">
            <div className="Modal__form">
              {/* If the additional information is of GENERALADDON type */}
              <div className="Modal__form__subtitle">
                {t("UserMessageInstruction", { ns: ["cachedResponses"] })}
              </div>
              <textarea
                className="Wizy__textarea"
                name="userMessage"
                value={userMessage}
                placeholder={t("UserMessagePlaceholder", {
                  ns: ["cachedResponses"],
                })}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const { value } = event.currentTarget;
                  setUserMessage(value);
                }}
              ></textarea>
              <div
                className="CachedResponses__prev__button__outter"
                style={!beforeSimilarCheck ? { display: "none" } : {}}
              >
                {duringSimilarUserMessagesFetch ? (
                  <img
                    src={wizy_loader}
                    className="ProtectedRoute__loader"
                    alt="ProtectedRoute__loader"
                    id="ProtectedRoute__loader"
                  />
                ) : (
                  <button
                    className="Wizy__button__2"
                    style={{ width: "200px" }}
                    onClick={() => getSimilarUserMessages()}
                    disabled={userMessage.length === 0}
                  >
                    {t("CheckSimilar", {
                      ns: ["cachedResponses"],
                    })}
                  </button>
                )}
              </div>
              <div style={beforeSimilarCheck ? { display: "none" } : {}}>
                <div className="Modal__form__subtitle">
                  {t("SelectMinSimilarityInstruction", {
                    ns: ["cachedResponses"],
                  })}
                </div>
                <div className="CachedResponses__list__list__outter">
                  <div className="CachedResponses__list__list__titles__outter">
                    <div className="CachedResponses__list__list__titles__inner">
                      <div className="CachedResponses__list__list__titles__inner__inner">
                        {t("UserMessage", { ns: ["cachedResponses"] })}
                      </div>
                      <div
                        className="CachedResponses__list__list__titles__inner__inner"
                        id="CachedResponses__date__field"
                      >
                        {t("Similarity", { ns: ["cachedResponses"] })}
                      </div>
                    </div>
                  </div>
                  <div className="CachedResponses__similarmessages__list__items__outter">
                    {similarUserMessages
                      .sort(
                        (a: ISimilarUserMessage, b: ISimilarUserMessage) =>
                          b.similarity - a.similarity
                      )
                      .map((similarUserMessage, index) => {
                        return (
                          <div
                            className="CachedResponses__modal__list__list__item__outter"
                            style={
                              selectedSimilarUserMessage.id ===
                              similarUserMessage.id
                                ? { backgroundColor: "#a0b8e825" }
                                : {}
                            }
                            key={index}
                            onClick={() =>
                              handleMinSimilaritySelect(similarUserMessage)
                            }
                          >
                            <div className="CachedResponses__modal__list__list__item__inner">
                              <div
                                className="CachedResponses__modal__list__list__item__inner__inner"
                                style={{whiteSpace:"normal"}}
                                id="CachedResponses__description__field"
                              >
                                {similarUserMessage.content}
                              </div>
                              <div
                                className="CachedResponses__modal__list__list__item__inner__inner"
                                id="CachedResponses__date__field"
                              >
                                {similarUserMessage.similarity}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div
                  style={
                    selectedSimilarUserMessage.id === ""
                      ? { display: "none" }
                      : {}
                  }
                >
                  <div
                    className="Wizy__input__2__columns"
                    style={{ marginTop: "5px" }}
                  >
                    <div>
                      <div className="Wizy__input__title">
                        {t("MinSimilarity", { ns: ["cachedResponses"] })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <input
                          name="minSimilarity"
                          value={minSimilarity}
                          type="text"
                          className="Wizy__input__2"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="Wizy__input__title">
                        {t("MessageRatio", { ns: ["cachedResponses"] })}
                      </div>
                      <div className="Wizy__input__2__outter">
                        <input
                          name="messageRatio"
                          value={messageRatio}
                          type="text"
                          className="Wizy__input__2"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="Modal__form__subtitle">
                    {t("AiResponseInstruction", { ns: ["cachedResponses"] })}
                  </div>
                  <textarea
                    className="Wizy__textarea"
                    name="aiResponse"
                    value={aiResponse}
                    placeholder={t("AiResponsePlaceholder", {
                      ns: ["cachedResponses"],
                    })}
                    onChange={(
                      event: React.ChangeEvent<HTMLTextAreaElement>
                    ) => {
                      const { value } = event.currentTarget;
                      setAiResponse(value);
                    }}
                  ></textarea>
                  <div
                    className="CachedResponses__prev__button__outter"
                    style={aiResponse.length === 0 ? { display: "none" } : {}}
                  >
                    <button
                      className="Wizy__button__2"
                      style={{ width: "200px" }}
                      onClick={() => createCachedResponse()}
                    >
                      {t("CreateButton", {
                        ns: ["cachedResponses"],
                      })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* Modal background that closes when clicked */}
        <div
          className="Modal__background"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        {/* Modal container */}
        <div className="Modal__outter">
          <img
            src={wizy_loader}
            className="ProtectedRoute__loader"
            alt="ProtectedRoute__loader"
            id="ProtectedRoute__loader"
          />
        </div>
      </React.Fragment>
    );
  }
};

// Default exported function
export default AddCacheModal;
