// SetUp

export type ISetup = {
  id?: string;
  primaryColor: string;
  secondaryColor: string;
  fontColor: string;
  agentName: string;
  onlinePhrase: string;
  isGreet: boolean;
  isCode: boolean;
  discountPercentage: number;
  isCustomMessages: boolean;
  initialMessage: string;
  defaultMessage: string;
  productMessage: string;
  collectionMessage: string;
  searchMessage: string;
  pagePolicyMessage: string;
  companyContactInfo: string;
  mainLanguage: string;
  side: string;
  paddingBottom: number;
  paddingSide: number;
  image: string;
  isVisible: boolean;
  emailRetrievalMethod: string;
  dataRetrievalType: string;
  dataRetrievalCustomPrompt: string;
  emailForNotifications: string;
  isSendNotifications: boolean;
  chatbotPersonality: string;
  ticketNotificationType: string;
  hasActivationSchedule: boolean;
  activationSchedule: string;
  activationScheduleTimezone: string;
  deactivationAction: EDeactivationAction;
  isAI: boolean;
  isRedirect: boolean;
  redirectionLink: string;
  requestHelpAction: ERequestHelpAction;
  customRequestHelpPrompt: string;
  maxMessagesPerDay: number;
  hasLimitBudget: boolean;
  limitBudget: number;
  limitBudgetAction: ELimitBudgetAction;
  limitBudgetDefaultMessage: string;
  limitBudgetRedirectionLink: string;
  syncBlogs: boolean;
  hasInitialPolicy: boolean;
  initialPolicyMessage: string;
  ticketDataRetrievalType: string;
  productTagsToIncludeInKnowledge: string | null;
  productTagsToExcludeFromKnowledge: string | null;
  additionalResponseDelay: number;
  metaAvailable: boolean;
};

export enum EDeactivationAction {
  VISIBILITY = "visibility",
  AI = "ai",
  REDIRECT = "redirect",
}

export enum ERequestHelpAction {
  CREATE_TICKET = "create ticket",
  CUSTOM = "custom",
}

export enum ELimitBudgetAction {
  WIDGET_OFF = "widget off",
  AI_OFF = "ai off",
  DEFAULT_MESSAGE = "default message",
  REDIRECT = "redirect",
}
