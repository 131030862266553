// Import React Dependencies
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Import Context
import { AppContext, IContext } from "../context/Context";
import { EType } from "../types/UserType"

// Import Components
import LanguageSelector from "../components/LaguageSelector";

// Import media
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_x_outline from "../images/wizy_x_outline.svg";
import wizy_shop_bag_filled from "../images/wizy_shop_bag_filled.svg";
import wizy_profile_filled from "../images/wizy_profile_filled.svg";
import wizy_logout_filled from "../images/wizy_logout_filled.svg";
import wizy_support_filled from "../images/wizy_support_filled.svg";

// Import styles
import "./styles/Navbar.css";
import ProfilePicture from "./ProfilePicture";

// Page main functional component
const Navbar: FC = () => {
  // Use context
  const { logOut, globalUser, setNewGlobalShop, globalShop } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["navbar"]);

  // Use history
  const navigate = useNavigate();

  // Use state variables
  const [isMenuHided, setIsMenuHided] = useState<boolean>(true);

  // References needed
  const sideMenu = useRef<HTMLDivElement>(null);

  // Internal Functions
  const toogleHidedMenu = () => {
    if (isMenuHided) {
      if (sideMenu.current !== null) {
        sideMenu.current.style.right = "0%";
      }
      setIsMenuHided(false);
    } else {
      if (sideMenu.current !== null) {
        sideMenu.current.style.right = "-305px";
      }
      setIsMenuHided(true);
    }
  };

  const handleShopSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.currentTarget;
    if (await setNewGlobalShop(value)) {
      window.location.reload();
    }
  };

  // JSX Return statement
  return (
    <React.Fragment>
      <div ref={sideMenu} className="Navbar__side__menu__outter">
        <div className="Navbar_side__menu__inner">
          <div className="Navbar__side__menu__top">
            <div className="Navbar__side__menu__language__select__outter">
              <div className="Navebar__side__menu__language__select__inner">
                <LanguageSelector side="left" color="white" />
              </div>
            </div>
            <div></div>
            <div>
              <img
                src={wizy_x_outline}
                alt="wizy_x_outline"
                className="Navbar__side__menu__x"
                onClick={toogleHidedMenu}
              />
            </div>
          </div>
          <div className="Navbar__side__menu__profile__outter">
            <div className="Navbar__side__menu__profile__picture__outter">
              <div className="Navbar__side__menu__profile__picture__inner">
                <ProfilePicture
                  firstName={globalUser.email[0]}
                  lastName={globalUser.email[1]}
                />
              </div>
            </div>
            <div className="Navebar__side__menu__email">{globalUser.email}</div>
            {i18next.language === "en" ? (
              <div className="Navebar__side__menu__account">
                {" "}
                {globalShop.currentPlan.name.charAt(0).toUpperCase() +
                  globalShop.currentPlan.name.slice(1)}{" "}
                Account
              </div>
            ) : (
              <div className="Navebar__side__menu__account">
                {" "}
                Cuenta{" "}
                {globalShop.currentPlan.name.charAt(0).toUpperCase() +
                  globalShop.currentPlan.name.slice(1)}{" "}
              </div>
            )}
            <div className="Navbar__side__menu__upgrade__outter">
              <div className="Navbar__side__menu__upgrade__inner">
                <button
                  className="Wizy__button__1"
                  onClick={() => {
                    if (!globalShop.needsSetup && !globalShop.needsSync) {
                      navigate("/billing");
                    }
                    toogleHidedMenu();
                  }}
                >
                  <span style={{ fontSize: "15px", fontWeight: "600" }}>
                    {t("Upgrade", { ns: ["navbar"] })}
                  </span>
                </button>
              </div>
            </div>
            <div className="Navbar__side__menu__shop__select__outter">
              <div className="Navbar__side__menu__shop__select__inner">
                <select
                  className="Wizy__input__select"
                  name="shop"
                  onChange={handleShopSelect}
                  value={globalShop.domain}
                >
                  {globalUser.shops?.map((shop, index) => {
                    return (
                      <option key={index} value={shop.domain}>
                        {shop.domain}
                      </option>
                    );
                  })}
                </select>
                <img
                  src={wizy_shop_bag_filled}
                  alt="wizy_shop_bag_filled"
                  className="Wizy__input__select__image"
                />
              </div>
            </div>
            <div className="Navbar__side__menu__icon__buttons__outter">
              <div className="Navbar__side__menu__icon__buttons__inner">
                <div
                  className="Navbar__side__menu__icon__buttons__item"
                  onClick={() => {
                    if (!globalShop.needsSetup && !globalShop.needsSync) {
                      navigate("/settings");
                    }
                    toogleHidedMenu();
                  }}
                >
                  <img
                    src={wizy_profile_filled}
                    alt="wizy_profile_filled"
                    className="Navbar__side__menu__icon__buttons__image"
                  />
                  <div className="Navbar__side__menu__icon__buttons__text">
                    {t("Profile", { ns: ["navbar"] })}
                  </div>
                </div>
                <div
                  className="Navbar__side__menu__icon__buttons__item"
                  onClick={() => {
                    logOut().then((response) => {
                      if (response) {
                        navigate("/");
                      } else {
                        navigate("/");
                      }
                    });
                  }}
                >
                  <img
                    src={wizy_logout_filled}
                    alt="wizy_logout_filled"
                    className="Navbar__side__menu__icon__buttons__image"
                  />
                  <div className="Navbar__side__menu__icon__buttons__text">
                    {t("Logout", { ns: ["navbar"] })}
                  </div>
                </div>
                <a
                  href="https://wa.link/zb4180"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    toogleHidedMenu();
                  }}
                >
                  <div className="Navbar__side__menu__icon__buttons__item">
                    <img
                      src={wizy_support_filled}
                      alt="wizy_support_filled"
                      className="Navbar__side__menu__icon__buttons__image"
                    />
                    <div className="Navbar__side__menu__icon__buttons__text">
                      {t("Support", { ns: ["navbar"] })}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="Navbar__outter"
        style={{
          backgroundColor:
            globalShop.needsSetup || globalShop.needsSync ? "#eff2f6" : "",
          position:
            globalShop.needsSetup || globalShop.needsSync
              ? "relative"
              : "fixed",
        }}
      >
        <div className="Navbar__inner">
          <a
            href="https://app.wizybot.com"
            style={{
              textDecoration: "none",
            }}
          >
            <div
              className="Navbar__logo__outter"
              style={{
                color:
                  globalShop.needsSetup || globalShop.needsSync ? "black" : "",
              }}
            >
              <img
                src={wizy_logo_white}
                alt="wizy_logo_white"
                className="Navbar__logo__image"
                style={{
                  filter:
                    globalShop.needsSetup || globalShop.needsSync
                      ? "invert(100%) sepia(39%) saturate(2%) hue-rotate(108deg) brightness(0%) contrast(100%)"
                      : "",
                }}
              />
              Wizybot
            </div>
          </a>
          <div></div>
          <div
            className="Navbar__profile__outter"
            style={{
              display:
                globalShop.needsSetup || globalShop.needsSync ? "none" : "",
            }}
          >
            <div className="Navbar__profile__inner">
              <div className="Navbar__upgrade__button__outter">
                {globalUser.type !== EType.SUPER_ADMINISTRATOR ? (
                  
                    <button
                      className="Wizy__button__1"
                      onClick={() => {
                        if (!globalShop.needsSetup && !globalShop.needsSync) {
                          navigate("/billing");
                        }
                      }}
                    >
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>
                        {t("Upgrade", { ns: ["navbar"] })}
                      </span>
                    </button>
                  
                  ):(
                    <button className="Wizy__button__1" disabled>
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>
                        {t("Admin", { ns: ["navbar"] })}
                      </span>
                    </button>
                  )
                }
              </div>
              <div
                className="Nabvar__profile__picture__outter"
                onClick={toogleHidedMenu}
              >
                <ProfilePicture
                  firstName={globalUser.email[0]}
                  lastName={globalUser.email[1]}
                />
              </div>
              <div
                className="Navbar__profile__online__dot__outer"
                onClick={toogleHidedMenu}
              >
                <div className="Navbar__profile__online__dot__inner"></div>
              </div>
            </div>
          </div>
          <div
            className="Login__navbar__language__select__outter"
            style={{
              display:
                globalShop.needsSetup || globalShop.needsSync ? "" : "none",
            }}
          >
            <div className="Login__navabr__language__select__inner">
              <LanguageSelector side="right" color="black" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default Navbar;
