// Declare types and interfaces
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export type IQualityForm = {
  qualityScore: number | null;
  needsCorrection: boolean;
  hasBug: boolean;
  qualityNote: string | null;
};
export type IQualityFormCardProps = {
  qualityFormState: [
    IQualityForm,
    React.Dispatch<React.SetStateAction<IQualityForm>>
  ];
  updateClientQualityForm: () => Promise<void>;
  handleQualityFormChange: (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.FormEvent<HTMLTextAreaElement>
  ) => void;
};

const QualityFormCard: FC<IQualityFormCardProps> = ({
  qualityFormState: [qualityForm, setQualityForm],
  updateClientQualityForm,
  handleQualityFormChange,
}: IQualityFormCardProps) => {
  const { t } = useTranslation(["chatReview"]);

  return (
    <div className="ChatReview__showcase__information__item__outter">
      <div className="ChatReview__showcase__information__item__inner">
        <div className="Wizy__input__title">
          {t("NeedsCorrection", { ns: ["chatReview"] })}
        </div>
        <div className="ChatReview__toggle__outter">
          <label className="switch4">
            <input
              type="checkbox"
              checked={qualityForm.needsCorrection}
              onChange={() => {
                setQualityForm((prevState) => ({
                  ...prevState,
                  needsCorrection: !prevState.needsCorrection,
                }));
              }}
            />
            <span className="slider4 round"></span>
          </label>
        </div>
      </div>
      <div className="ChatReview__showcase__information__item__inner">
        <div className="Wizy__input__title">
          {t("HasBug", { ns: ["chatReview"] })}
        </div>
        <div className="ChatReview__toggle__outter">
          <label className="switch4">
            <input
              type="checkbox"
              checked={qualityForm.hasBug}
              onChange={() => {
                setQualityForm((prevState) => ({
                  ...prevState,
                  hasBug: !prevState.hasBug,
                }));
              }}
            />
            <span className="slider4 round"></span>
          </label>
        </div>
      </div>
      <div className="ChatReview__showcase__information__item__inner">
        <div className="Wizy__input__title">
          {t("Score", { ns: ["chatReview"] })}
        </div>
        <div className="ChatReview__toggle__outter">
          <select
            className="Wizy__input__select__1"
            style={{ width: "60px", height: "30px", padding: "0px" }}
            name="qualityScore"
            onChange={handleQualityFormChange}
            value={qualityForm.qualityScore ? qualityForm.qualityScore : 0}
          >
            <option value={0}>N. A</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
        </div>
      </div>
      <div className="ChatReview__showcase__note__item__inner">
        <div className="Wizy__input__title">
          {t("Note", { ns: ["chatReview"] })}
        </div>
        <div className="Wizy__textarea__outter">
          <textarea
            name="qualityNote"
            value={qualityForm.qualityNote ? qualityForm.qualityNote : ""}
            className="Wizy__textarea"
            onChange={handleQualityFormChange}
          ></textarea>
        </div>
      </div>
      {/* Submit quality form */}
      <div className="ChatReview__submit__button">
        <button
          className="Wizy__button__2"
          style={{ width: "100%" }}
          onClick={() => {
            updateClientQualityForm();
          }}
        >
          {t("Submit", { ns: ["chatReview"] })}
        </button>
      </div>
    </div>
  );
};

export default QualityFormCard;
