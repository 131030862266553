export type IWhatsAppAccount = {
  id: string;
  displayPhone: string;
  isAiActive: boolean;
  status: ESourceStatus;
  verifiedName: string;
};

export type IMessengerAccount = {
  id: string;
  pageName: string;
  isAiActive: boolean;
  status: ESourceStatus;
};

export type IInstagramAccount = {
  id: string;
  igUsername: string;
  isAiActive: boolean;
  status: ESourceStatus;
};

export enum ESourceStatus {
  ACTIVE = "active",
  DELETED = "deleted"
}
