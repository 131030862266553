// Import React dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";
import wizy_mg_outline from "../images/wizy_mg_outline.svg";
import wizy_profile_filled from "../images/wizy_profile_filled.svg";
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_mail_filled from "../images/wizy_mail_filled.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_shield_filled from "../images/wizy_shield_filled.svg";
import wizy_user_filled from "../images/wizy_user_filled.png";
import wizy_location_filled from "../images/wizy_location_filled.svg";
import wizy_computer_filled from "../images/wizy_computer_filled.svg";
import wizy_phone_filled from "../images/wizy_phone_filled.svg";
import wizy_globe_outline from "../images/wizy_globe_outline.svg";
import wizy_whatsapp_icon from "../images/wizy_whatsapp_icon.svg";
import wizy_instagram_icon from "../images/wizy_instagram_icon.svg";
import wizy_messenger_icon from "../images/wizy_messenger_icon.svg";
import wizy_shopify_icon from "../images/wizy_shopify_icon.svg";

// Import styles
import "./styles/ChatAdmin.css";

// Declare types and interfaces
import { IClient } from "../types/ClientType";
import { IShop, EShopStatus } from "../types/ShopType";
import Pagination, { IPageMeta } from "../components/Pagination";
import {
  EExtraUIComponentTypes,
  EMessageRole,
  EMessageSourceTypes,
  IMessage,
} from "../types/MessageType";
import { useTranslation } from "react-i18next";
import { AppContext, IContext } from "../context/Context";
import { IPlan } from "../types/PlanType";
import QualityFormCard, { IQualityForm } from "../components/QualityFormCard";
import SalesFormCard from "../components/SalesFormCard";
import Add2CartLiveChat from "../components/Add2CartLiveChat";
import { EMainLanguage } from "../components/ShopifyWidget";
import RecommendationCarouselLiveChat from "../components/RecommendationCarouselLiveChat";
import { IConversation, ISource } from "./Chat";
import {
  ESourceStatus,
  IInstagramAccount,
  IMessengerAccount,
  IWhatsAppAccount,
} from "../types/MetaTypes";
import ChatMedia from "../components/ChatMedia";

enum EChatOutter {
  normal,
  client,
  showcase,
}

type IFilter = {
  text: string;
  initialDate: string;
  finalDate: string;
  needsCorrection: boolean;
  hasBug: boolean;
  qualityScore: number | null;
  isSalePending: boolean;
  isSaleRejected: boolean;
  isSaleApproved: boolean;
};

const ChatAdmin: FC = () => {
  // Use translation
  const { t } = useTranslation(["chat"]);
  // Use navigate
  const navigate = useNavigate();
  // Use search params
  const [searchParams, setSearchParams] = useSearchParams();

  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  // Use state variables
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [sources, setSources] = useState<ISource[]>([]);
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const emptyShop: IShop = {
    id: "",
    domain: "",
    needsSetup: false,
    needsSync: false,
    needsTest:  false,
    needsOnboarding:  false,
    status: EShopStatus.installed,
    currentPlan: emptyPlan,
    createDate: "",
    selected: false,
    isWidgetInstalled: false,
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
  };
  const [selectedClient, setSelectedClient] = useState<IClient>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    computer: "",
    ipAddress: "",
    createDate: "",
    lastMessageDate: "",
    lastMessage: "",
    subscriptionState: false,
    isClientRead: false,
    isShopRead: false,
    isAiEnabled: false,
    websocketId: "",
    selected: false,
    shop: emptyShop,
    qualityScore: null,
    needsCorrection: false,
    hasBug: false,
    qualityNote: null,
  });
  const [clientSelected, setClientSelected] = useState<boolean>(false);
  const [filter, setFilter] = useState<IFilter>({
    text: "",
    initialDate: "",
    finalDate: "",
    needsCorrection: false,
    hasBug: false,
    qualityScore: null,
    isSalePending: false,
    isSaleRejected: false,
    isSaleApproved: false,
  });
  const [filterChange, setFilterChange] = useState<IFilter>({
    text: "",
    initialDate: "",
    finalDate: "",
    needsCorrection: false,
    hasBug: false,
    qualityScore: null,
    isSalePending: false,
    isSaleRejected: false,
    isSaleApproved: false,
  });
  const [qualityForm, setQualityForm] = useState<IQualityForm>({
    qualityScore: null,
    needsCorrection: false,
    hasBug: false,
    qualityNote: null,
  });
  const [isQualityFormLoaded, setIsQualityFormLoaded] = useState<boolean>(true);
  const [isSalesFormLoaded, setIsSalesFormLoaded] = useState<boolean>(true);

  // References needed
  const ChatOutterRef = useRef<HTMLDivElement>(null);

  // Internal functions

  useEffect(() => {
    getClients(pageMeta.take, pageMeta.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getClients(pageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setQualityForm({
      qualityScore:
        selectedClient.qualityScore !== undefined
          ? selectedClient.qualityScore
          : null,
      needsCorrection:
        selectedClient.needsCorrection !== undefined
          ? selectedClient.needsCorrection
          : false,
      hasBug:
        selectedClient.hasBug !== undefined ? selectedClient.hasBug : false,
      qualityNote:
        selectedClient.qualityNote !== undefined
          ? selectedClient.qualityNote
          : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const getClients = async (take: number, page: number) => {
    // URL to fetch all clients with messages and pagination queries
    if (searchParams.get("clientId")) {
      let searchURL =
        globalSelectedBackend +
        "/admin/clients/?take=" +
        take.toString() +
        "&page=" +
        page.toString() +
        "&clientId=" +
        searchParams.get("clientId");
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setClients([]);
          JSONresult.data.forEach((client: IClient) => {
            setClients((previous) => [
              ...previous,
              {
                id: client.id,
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                phone: client.phone,
                location: client.location,
                computer: client.computer,
                ipAddress: client.ipAddress,
                createDate: client.createDate,
                lastMessageDate: client.lastMessageDate,
                lastMessage: client.lastMessage,
                subscriptionState: client.subscriptionState,
                isClientRead: client.isClientRead,
                isShopRead: client.isShopRead,
                isAiEnabled: client.isAiEnabled,
                websocketId: client.websocketId,
                messages: client.messages,
                selected: false,
                shop: client.shop,
                qualityScore: client.qualityScore,
                needsCorrection: client.needsCorrection,
                hasBug: client.hasBug,
                qualityNote: client.qualityNote,
                sales: client.sales,
              },
            ]);
            handleClientSelect(client);
          });
          setPageMeta({
            itemCount: JSONresult.meta.itemCount,
            pageCount: JSONresult.meta.pageCount,
            page: parseInt(JSONresult.meta.page),
            take: parseInt(JSONresult.meta.take),
            hasNextPage: JSONresult.meta.hasNextPage,
            hasPreviousPage: JSONresult.meta.hasPreviousPage,
          });
          setSearchParams();
          setIsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let searchURL =
        globalSelectedBackend +
        "/admin/clients/?take=" +
        take.toString() +
        "&page=" +
        page.toString();
      // Add filter queries to URL
      if (filter?.initialDate && filter.finalDate) {
        searchURL +=
          "&initialDate=" +
          filter.initialDate +
          "&finalDate=" +
          filter.finalDate;
      }
      if (filter?.text) {
        searchURL += "&text=" + filter.text;
      }
      if (filter.qualityScore !== null) {
        searchURL += "&qualityScore=" + filter.qualityScore;
      }
      searchURL +=
        "&needsCorrection=" +
        filter.needsCorrection +
        "&hasBug=" +
        filter.hasBug;
      searchURL +=
        "&isSalePending=" +
        filter.isSalePending +
        "&isSaleRejected=" +
        filter.isSaleRejected +
        "&isSaleApproved=" +
        filter.isSaleApproved;

      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setClients([]);
          JSONresult.data.forEach((client: IClient) => {
            setClients((previous) => [
              ...previous,
              {
                id: client.id,
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                phone: client.phone,
                location: client.location,
                computer: client.computer,
                ipAddress: client.ipAddress,
                createDate: client.createDate,
                lastMessageDate: client.lastMessageDate,
                lastMessage: client.lastMessage,
                subscriptionState: client.subscriptionState,
                isClientRead: client.isClientRead,
                isShopRead: client.isShopRead,
                isAiEnabled: client.isAiEnabled,
                websocketId: client.websocketId,
                messages: client.messages,
                selected: false,
                shop: client.shop,
                qualityScore: client.qualityScore,
                needsCorrection: client.needsCorrection,
                hasBug: client.hasBug,
                qualityNote: client.qualityNote,
                sales: client.sales,
              },
            ]);
          });
          setPageMeta({
            itemCount: JSONresult.meta.itemCount,
            pageCount: JSONresult.meta.pageCount,
            page: parseInt(JSONresult.meta.page),
            take: parseInt(JSONresult.meta.take),
            hasNextPage: JSONresult.meta.hasNextPage,
            hasPreviousPage: JSONresult.meta.hasPreviousPage,
          });
          setIsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSources = async (shop: IShop): Promise<ISource[]> => {
    const newSources: ISource[] = [
      {
        sourceId: null,
        sourceTitle: shop.shopMainDomain,
        status: ESourceStatus.ACTIVE,
      },
    ];
    const response = await fetch(
      globalSelectedBackend + "/meta/accounts/" + shop.id,
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    );
    if (!response.ok) {
      throw new Error(await response.json());
    } else {
      const JSONresult = await response.json();
      (JSONresult.whatsAppAccounts as IWhatsAppAccount[]).forEach(
        (whatsAppAccount) => {
          newSources.push({
            sourceId: whatsAppAccount.id,
            sourceTitle: whatsAppAccount.verifiedName,
            status: whatsAppAccount.status,
          });
        }
      );
      (JSONresult.instagramAccounts as IInstagramAccount[]).forEach(
        (instagramAccount) => {
          newSources.push({
            sourceId: instagramAccount.id,
            sourceTitle: instagramAccount.igUsername,
            status: instagramAccount.status,
          });
        }
      );
      (JSONresult.messengerAccounts as IMessengerAccount[]).forEach(
        (messengerAccount) => {
          newSources.push({
            sourceId: messengerAccount.id,
            sourceTitle: messengerAccount.pageName,
            status: messengerAccount.status,
          });
        }
      );
    }
    return newSources;
  };

  const handleChatGrid = (state: EChatOutter) => {
    switch (state) {
      case EChatOutter.normal:
        if (ChatOutterRef.current !== null) {
          ChatOutterRef.current.style.gridTemplateColumns = "0px 1fr 0px";
        }
        break;
      case EChatOutter.client:
        if (ChatOutterRef.current !== null) {
          ChatOutterRef.current.style.gridTemplateColumns = "320px 1fr 0px";
        }
        break;
      case EChatOutter.showcase:
        if (ChatOutterRef.current !== null) {
          ChatOutterRef.current.style.gridTemplateColumns = "0px 1fr 320px";
        }
        break;
      default:
        console.log("Upps");
    }
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatTime = (milliseconds: number) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 1) {
      return days + "d";
    } else if (hours >= 1) {
      return hours + "h";
    } else if (minutes >= 1) {
      return minutes + "m";
    } else {
      return seconds + "s";
    }
  };

  const formatDate = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${hours}:${minutes} ${year}-${month}-${day}`;
  };

  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const convertToHTMLLink = (text: string): JSX.Element => {
    const urlPattern = /\[([^[\]]+)\]\(((https?|mailto):\/\/\S+|mailto:\S+)\)/g;
    const replacedLinkText = text.replace(
      urlPattern,
      (match, anchorText, url) =>
        `<a href="${url}" target="_top">${anchorText}</a>`
    );
    const boldPattern = /\*\*([^\*]+)\*\*/g;
    const replacedLinkBoldText = replacedLinkText.replace(
      boldPattern,
      (match, boldText) => `<strong>${boldText}</strong>`
    );
    const doubleLineBreakPattern = /\n\s*\n/g;
    const replacedLinkBoldLineText = replacedLinkBoldText.replace(
      doubleLineBreakPattern,
      "\n"
    );
    return (
      <div
        dangerouslySetInnerHTML={{ __html: replacedLinkBoldLineText }}
        style={{ whiteSpace: "pre-line" }}
      />
    );
  };

  const handleClientSelect = async (client: IClient) => {
    setIsLoaded(false);
    setSelectedClient({
      id: client.id,
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      phone: client.phone,
      location: client.location,
      computer: client.computer,
      ipAddress: client.ipAddress,
      createDate: client.createDate,
      lastMessageDate: client.lastMessageDate,
      lastMessage: client.lastMessage,
      subscriptionState: client.subscriptionState,
      isClientRead: client.isClientRead,
      isShopRead: client.isShopRead,
      isAiEnabled: client.isAiEnabled,
      websocketId: client.websocketId,
      messages: client.messages,
      selected: false,
      shop: client.shop,
      qualityNote: client.qualityNote,
      needsCorrection: client.needsCorrection,
      hasBug: client.hasBug,
      qualityScore: client.qualityScore,
      sales: client.sales,
    });
    if (window.innerWidth < 651) {
      handleChatGrid(EChatOutter.normal);
    }
    if (client.messages !== undefined) {
      const newSources = await getSources(client.shop!);
      setSources(newSources);
      const newMessages = client.messages.map((backendMessage): IMessage => {
        return {
          id: backendMessage.id,
          role: backendMessage.role,
          name: backendMessage.name,
          content: backendMessage.content,
          functionCall: backendMessage.functionCall,
          toolCalls: backendMessage.toolCalls,
          toolCallId: backendMessage.toolCallId,
          createDate: backendMessage.createDate,
          extraUIComponents: backendMessage.extraUIComponents
            ? JSON.parse(backendMessage.extraUIComponents)
            : null,
          sourceType: backendMessage.sourceType,
          sourceId: backendMessage.sourceId,
          hasMedia: backendMessage.hasMedia,
          status: backendMessage.status,
        };
      });
      const newConversations = getConversationsFromMessages(
        newMessages,
        newSources
      );
      setConversations(newConversations);
    }
    setClientSelected(true);
    setIsLoaded(true);
  };

  const getConversationsFromMessages = (
    messages: IMessage[],
    sources: ISource[]
  ): IConversation[] => {
    // Group messages by unique sourceIds, including when sourceId is null
    const groupedMessages: { [key: string]: IMessage[] } = messages.reduce(
      (acc, message) => {
        const key = message.sourceId === null ? "null" : message.sourceId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(message);
        return acc;
      },
      {} as { [key: string]: IMessage[] }
    );
    // Build conversations with messages inside each conversation sorted
    const conversations: IConversation[] = [];
    for (const sourceId in groupedMessages) {
      conversations.push({
        sourceId: sourceId === "null" ? null : sourceId,
        messages: groupedMessages[sourceId].sort(
          (a, b) => Date.parse(a.createDate) - Date.parse(b.createDate)
        ),
        sourceType: groupedMessages[sourceId][0].sourceType,
        selected: false,
        status:
          sourceId === "null"
            ? ESourceStatus.ACTIVE
            : sources.find((source) => source.sourceId === sourceId)!.status,
      });
    }
    // Sort conversations by which has the most recent last message
    conversations.sort(
      (a, b) =>
        Date.parse(b.messages[b.messages.length - 1].createDate) -
        Date.parse(a.messages[a.messages.length - 1].createDate)
    );
    // Set the conversation with the most recent message as selected
    if (conversations[0]) {
      conversations[0].selected = true;
    }

    return conversations;
  };

  const handleQualityFormChange = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setQualityForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConversationSelection = (conversation: IConversation) => {
    setConversations((prevConversations) =>
      prevConversations.map((prevConversation) =>
        prevConversation.sourceId === conversation.sourceId
          ? { ...prevConversation, selected: true }
          : { ...prevConversation, selected: false }
      )
    );
  };

  const updateClientQualityForm = async () => {
    setIsQualityFormLoaded(false);
    let searchURL =
      globalSelectedBackend + "/clients/" + selectedClient.id + "/quality";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      qualityScore:
        qualityForm.qualityScore !== 0 ? qualityForm.qualityScore : null,
      needsCorrection: qualityForm.needsCorrection,
      hasBug: qualityForm.hasBug,
      qualityNote:
        qualityForm.qualityNote !== "" ? qualityForm.qualityNote : null,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        const updatedClients = clients.map((client) => {
          if (client.id === JSONresult.id) {
            const updatedClient = client;
            updatedClient.qualityScore = JSONresult.qualityScore;
            updatedClient.needsCorrection = JSONresult.needsCorrection;
            updatedClient.hasBug = JSONresult.hasBug;
            updatedClient.qualityNote = JSONresult.qualityNote;
            return updatedClient;
          } else {
            return client;
          }
        });
        setClients(updatedClients);
        setIsQualityFormLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSelectedClientApprovedSales = async () => {
    setIsSalesFormLoaded(false);
    let searchURL = globalSelectedBackend + "/sale/updateapproval";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      sales: selectedClient.sales,
    });
    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        const updatedClients = clients.map((client) => {
          if (client.id === selectedClient.id) {
            const updatedClient = client;
            updatedClient.sales = JSONresult.sales;
            return updatedClient;
          } else {
            return client;
          }
        });
        setClients(updatedClients);
        setIsSalesFormLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isNotBlank = (value: any): boolean => {
    return value !== null && value !== undefined && value !== "";
  };

  const CONVERSATION_ICON_IMAGES: { [key in EMessageSourceTypes]: string } = {
    [EMessageSourceTypes.SHOPIFY_WIDGET]: wizy_shopify_icon,
    [EMessageSourceTypes.WHATSAPP]: wizy_whatsapp_icon,
    [EMessageSourceTypes.INSTAGRAM]: wizy_instagram_icon,
    [EMessageSourceTypes.MESSENGER]: wizy_messenger_icon,
  };

  const CONVERSATION_ICON_COLOR_CLASS: {
    [key in EMessageSourceTypes]: string;
  } = {
    [EMessageSourceTypes.SHOPIFY_WIDGET]: "shopify-color",
    [EMessageSourceTypes.WHATSAPP]: "whatsapp-color",
    [EMessageSourceTypes.INSTAGRAM]: "instagram-color",
    [EMessageSourceTypes.MESSENGER]: "messenger-color",
  };

  if (isLoaded) {
    return (
      <React.Fragment>
        <div ref={ChatOutterRef} className="ChatAdmin__outter">
          {/* Filter and client sidebar */}
          <div className="ChatAdmin__clients__outter">
            {/* Close sidebar */}
            <div
              className="ChatAdmin__clients__outter__close"
              onClick={() => {
                handleChatGrid(EChatOutter.normal);
              }}
            >
              {" "}
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="ChatAdmin__clients__outter__close__image"
              />
            </div>

            {/* Sidebar content */}
            <div className="ChatAdmin__clients__inner">
              {/* Approve Sales */}
              <div className="ChatAdmin__reviewmode__button">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate("/approvesales");
                  }}
                >
                  {t("ApproveSales", { ns: ["chatAdmin"] })}
                </button>
              </div>

              {/* Review mode */}
              <div className="ChatAdmin__reviewmode__button">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate("/chatreview");
                  }}
                >
                  {t("ReviewMode", { ns: ["chatAdmin"] })}
                </button>
              </div>

              {/* Filters */}
              <div className="Clients__filters__title">
                {t("Filters", { ns: ["chatAdmin"] })}
              </div>
              <div className="Clients__filters__description">
                {t("FiltersD", { ns: ["chatAdmin"] })}
              </div>
              <div className="Merchants__filters__fields__outter">
                {/* Text filter */}
                <div className="Wizy__input__title">
                  {t("Domain", { ns: ["chatAdmin"] })}
                </div>
                <div className="Wizy__input__1__outter">
                  <input
                    name="text"
                    value={filterChange.text}
                    type="text"
                    className="Wizy__input__1"
                    onChange={handleFilterChange}
                  />
                  <img
                    src={wizy_mg_outline}
                    alt="wizy_mg_outline"
                    className="Wizy__input__1__image"
                  />
                </div>
                <div className="Wizy__input__prompt__1">
                  {t("Eg", { ns: ["chatAdmin"] })}
                </div>

                {/* Date filtering */}

                <div className="Wizy__input__title">
                  {t("Date", { ns: ["chatAdmin"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.initialDate}
                  name="initialDate"
                  className="Wizy__input__date"
                  style={{ marginTop: "10px" }}
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateI", { ns: ["chatAdmin"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.finalDate}
                  name="finalDate"
                  className="Wizy__input__date"
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateF", { ns: ["chatAdmin"] })}
                </div>

                {/* Quality */}

                <div className="Wizy__input__title">
                  {t("Quality", { ns: ["chatAdmin"] })}
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="needsCorrection"
                    checked={filterChange.needsCorrection}
                    onChange={() => {
                      if (filterChange.needsCorrection) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          needsCorrection: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          needsCorrection: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("NeedsCorrection", { ns: ["chatAdmin"] })}
                  </div>
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="hasBug"
                    checked={filterChange.hasBug}
                    onChange={() => {
                      if (filterChange.hasBug) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          hasBug: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          hasBug: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("HasBug", { ns: ["chatAdmin"] })}
                  </div>
                </div>

                <div className="Wizy__input__title">
                  {t("Score", { ns: ["chatAdmin"] })}
                </div>
                <select
                  className="Wizy__input__select__1"
                  name="qualityScore"
                  onChange={(event) => {
                    const { value } = event.currentTarget;
                    setFilterChange((prevState) => ({
                      ...prevState,
                      qualityScore:
                        parseInt(value) === 0 ? null : parseInt(value),
                    }));
                  }}
                  value={
                    filterChange.qualityScore ? filterChange.qualityScore : 0
                  }
                >
                  <option value={0}>N. A</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                {/* Sale status */}
                <div className="Wizy__input__title">
                  {t("SaleStatus", { ns: ["chatAdmin"] })}
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isSalePending"
                    checked={filterChange.isSalePending}
                    onChange={() => {
                      if (filterChange.isSalePending) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSalePending: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSalePending: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("Pending", { ns: ["approveSales"] })}
                  </div>
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isSaleRejected"
                    checked={filterChange.isSaleRejected}
                    onChange={() => {
                      if (filterChange.isSaleRejected) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSaleRejected: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSaleRejected: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("Rejected", { ns: ["approveSales"] })}
                  </div>
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isSaleApproved"
                    checked={filterChange.isSaleApproved}
                    onChange={() => {
                      if (filterChange.isSaleApproved) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSaleApproved: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSaleApproved: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("Approved", { ns: ["approveSales"] })}
                  </div>
                </div>
              </div>

              {/* Apply button */}
              <div>
                <div className="Merchants__apply__button__outter">
                  <button
                    className="Wizy__button__2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setFilter(filterChange);
                    }}
                  >
                    {t("Apply", { ns: ["clients"] })}
                  </button>
                </div>
              </div>

              {/* Reset button */}
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setFilter({
                      text: "",
                      initialDate: "",
                      finalDate: "",
                      needsCorrection: false,
                      hasBug: false,
                      qualityScore: null,
                      isSalePending: false,
                      isSaleRejected: false,
                      isSaleApproved: false,
                    });
                    setFilterChange({
                      text: "",
                      initialDate: "",
                      finalDate: "",
                      needsCorrection: false,
                      hasBug: false,
                      qualityScore: null,
                      isSalePending: false,
                      isSaleRejected: false,
                      isSaleApproved: false,
                    });
                  }}
                >
                  {t("Reset", { ns: ["clients"] })}
                </button>
              </div>

              <br />

              {/* Client chats */}
              <div className="Clients__filters__title">
                {t("Chats", { ns: ["chatAdmin"] })}
              </div>
              <div className="Clients__filters__description">
                {t("ChatsD", { ns: ["chatAdmin"] })}
              </div>

              {/* Pagination */}
              <div className="Chat__clients__pagination__outter">
                <Pagination
                  pageMeta={pageMeta}
                  getElements={getClients}
                ></Pagination>
              </div>

              <div className="Chat__clients__outter__outter">
                {/* Filtering and sorting */}
                {clients
                  .sort(
                    (a: IClient, b: IClient) =>
                      Date.parse(b.lastMessageDate) -
                      Date.parse(a.lastMessageDate)
                  )
                  .map((client, index) => {
                    return (
                      <div
                        key={index}
                        className="ChatAdmin__client__outter"
                        style={
                          selectedClient.id === client.id
                            ? { backgroundColor: "#eceef1" }
                            : {}
                        }
                        onClick={() => {
                          handleClientSelect(client);
                        }}
                      >
                        <div className="Chat__client__inner">
                          <div className="ChatAdmin__client__inner__bubble">
                            {!isNotBlank(client.email)
                              ? "N"
                              : client.email[0].toUpperCase()}
                            <div
                              className="Chat__client__inner__bubble__notification"
                              style={{
                                opacity: !client.isShopRead ? "1" : "0",
                              }}
                            ></div>
                          </div>
                          <div className="ChatAdmin__client__inner__information">
                            <div className="ChatAdmin__client__inner__shop">
                              {client.shop?.domain}
                            </div>
                            <div className="ChatAdmin__client__inner__email">
                              {!isNotBlank(client.email)
                                ? t("NewClient", { ns: ["chat"] })
                                : client.email}
                            </div>
                            <div className="ChatAdmin__client__inner__message">
                              <div className="Chat__client__inner__message__bubble"></div>
                              <div className="Chat__client__inner__message__inner">
                                {client.lastMessage}
                              </div>
                            </div>
                          </div>
                          <div className="Chat__client__inner__time">
                            {formatTime(
                              Date.now() - Date.parse(client.lastMessageDate)
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Messages */}
          <div className="Chat__chat__outter">
            <div className="Chat__chat__inner">
              <div className="ChatAdmin__navbar__outter">
                <div
                  className="Chat__navbar__clients__outter__open"
                  onClick={() => {
                    handleChatGrid(EChatOutter.client);
                  }}
                >
                  {" "}
                  <img
                    src={wizy_hide_side_menu_outline}
                    alt="wizy_hide_side_menu_outline"
                    className="Chat__navbar__clients__outter__open__image"
                  />
                </div>
                <div className="Chat__navbar__inner">
                  <div
                    className="ChatAdmin__client__outter"
                    onClick={() => {
                      handleChatGrid(EChatOutter.showcase);
                    }}
                  >
                    <div
                      className="Chat__client__inner"
                      style={
                        selectedClient.sales &&
                        selectedClient.sales.filter(
                          (sale) =>
                            sale.isSale &&
                            sale.isApproved &&
                            sale.orderNumber !== null
                        ).length > 0
                          ? {
                              gridTemplateColumns:
                                "40px calc(100% - 140px) 80px 20px",
                            }
                          : {}
                      }
                    >
                      <div className="ChatAdmin__client__inner__bubble">
                        {(!isNotBlank(selectedClient.email)
                          ? clientSelected
                            ? t("NewClient", { ns: ["chatAdmin"] })
                            : t("SelectClient", { ns: ["chatAdmin"] })
                          : selectedClient.email)[0].toUpperCase()}
                        <div
                          className="Chat__client__inner__bubble__notification"
                          style={{
                            opacity: "0",
                          }}
                        ></div>
                      </div>
                      <div className="Chat__client__inner__information">
                        <div className="ChatAdmin__client__inner__shop">
                          {selectedClient.shop?.domain === ""
                            ? clientSelected
                              ? t("NewClient", { ns: ["chatAdmin"] })
                              : t("SelectShop", { ns: ["chatAdmin"] })
                            : selectedClient.shop?.domain}
                        </div>
                        <div className="ChatAdmin__client__inner__email">
                          {!isNotBlank(selectedClient.email)
                            ? clientSelected
                              ? t("NewClient", { ns: ["chatAdmin"] })
                              : t("SelectClient", { ns: ["chatAdmin"] })
                            : selectedClient.email}
                        </div>
                        <div className="ChatAdmin__client__inner__message">
                          <div className="Chat__client__inner__message__bubble"></div>
                          <div className="Chat__client__inner__message__inner">
                            {t("ErrorT", { ns: ["chat"] })}
                          </div>
                        </div>
                      </div>
                      {selectedClient.sales &&
                      selectedClient.sales.filter(
                        (sale) =>
                          sale.isSale &&
                          sale.isApproved &&
                          sale.orderNumber !== null
                      ).length > 0 ? (
                        <div className="Chat__client__inner__sale__banner">
                          <button
                            className="Wizy__button__10"
                            style={{ width: "100%" }}
                          >
                            {t("Sale!", { ns: ["chat"] })}
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {conversations.length > 0 ? (
                <div className="Chat__conversation__tabs__outter">
                  <div className="Chat__conversation__tabs__inner">
                    {conversations.map((conversation) => (
                      <div
                        className="Chat__conversation__tab__outter"
                        onClick={() =>
                          handleConversationSelection(conversation)
                        }
                      >
                        <div
                          className={
                            conversation.selected
                              ? "Chat__conversation__tab__selected"
                              : "Chat__conversation__tab"
                          }
                        >
                          <div className="Chat__conversation__tab__icon__container">
                            <div className="Chat__conversation__tab__icon">
                              <img
                                src={
                                  CONVERSATION_ICON_IMAGES[
                                    conversation.sourceType
                                  ]
                                }
                                alt="wizy_conversation_icon"
                                className={
                                  conversation.selected
                                    ? CONVERSATION_ICON_COLOR_CLASS[
                                        conversation.sourceType
                                      ]
                                    : "unselected-color"
                                }
                              />
                            </div>
                          </div>
                          <div className="Chat__conversation__tab__title__container">
                            <div className="Chat__conversation__tab__title">
                              {
                                sources.find(
                                  (x) => x.sourceId === conversation.sourceId
                                )?.sourceTitle
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="Chat__conversation__tabs__outter"></div>
              )}
              <div className="ChatAdmin__messages__outter">
                <div className="ChatAdmin__messages__inner">
                  <div>
                    {conversations
                      .find((conversation) => conversation.selected)
                      ?.messages.filter((message: IMessage) => {
                        if (
                          (message.role === EMessageRole.ai ||
                            message.role === EMessageRole.assistant ||
                            message.role === EMessageRole.user) &&
                          (isNotBlank(message.content) || message.hasMedia)
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      })
                      .map((message, index) => {
                        return (
                          <div key={index} className="Chat__message__outter">
                            <div className="Chat__message__inner">
                              <div className="Chat__message__inner__bubble">
                                {message.role === EMessageRole.assistant ? (
                                  <img
                                    src={wizy_profile_filled}
                                    alt="wizy_profile_filled"
                                    className="Chat__message__inner__bubble__image"
                                  />
                                ) : message.role === EMessageRole.ai ? (
                                  <img
                                    src={wizy_logo_white}
                                    alt="wizy_logo_white"
                                    className="Chat__message__inner__bubble__image__white"
                                  />
                                ) : (
                                  (!isNotBlank(selectedClient.email)
                                    ? clientSelected
                                      ? t("NewClient", { ns: ["chat"] })
                                      : t("Select", { ns: ["chat"] })
                                    : selectedClient.email)[0].toUpperCase()
                                )}
                              </div>
                              <div className="Chat__message__inner__information">
                                <div className="Chat__message__inner__email">
                                  {message.role === EMessageRole.assistant
                                    ? t("NewClient", { ns: ["chatAdmin"] })
                                    : message.role === EMessageRole.ai
                                    ? "Wizybot"
                                    : !isNotBlank(selectedClient.email)
                                    ? clientSelected
                                      ? t("NewClient", { ns: ["chat"] })
                                      : t("Select", { ns: ["chat"] })
                                    : selectedClient.email}{" "}
                                  &nbsp;
                                  <span
                                    style={{
                                      opacity: "0.5",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {formatDate(new Date(message.createDate))}
                                  </span>
                                </div>
                                <div className="Chat__message__inner__message">
                                  {message.content ? (
                                    <div className="Chat__message__inner__message__inner">
                                      {convertToHTMLLink(message.content)}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {message.extraUIComponents ? (
                                    message.extraUIComponents.length > 0 ? (
                                      message.extraUIComponents.map(
                                        (extraUIComponent, i) => {
                                          if (
                                            extraUIComponent.type ===
                                            EExtraUIComponentTypes.ADD_TO_CART
                                          ) {
                                            return (
                                              <Add2CartLiveChat
                                                content={
                                                  extraUIComponent.content
                                                }
                                                language={EMainLanguage.ENGLISH}
                                              />
                                            );
                                          } else if (
                                            extraUIComponent.type ===
                                            EExtraUIComponentTypes.RECOMMENDATION_CAROUSEL
                                          ) {
                                            return (
                                              <RecommendationCarouselLiveChat
                                                content={
                                                  extraUIComponent.content
                                                }
                                                language={EMainLanguage.ENGLISH}
                                              />
                                            );
                                          } else return <></>;
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {message.hasMedia && message.id ? (
                                    <ChatMedia
                                      messageId={message.id}
                                      language={
                                        selectedClient.shop?.setup?.mainLanguage
                                          ? selectedClient.shop?.setup
                                              ?.mainLanguage
                                          : EMainLanguage.ENGLISH
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Chat__showcase__outter">
            <div
              className="Chat__showcase__outter__close"
              onClick={() => {
                handleChatGrid(EChatOutter.normal);
              }}
            >
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="Chat__showcase__outter__close__image"
              />
            </div>
            <div className="Chat__showcase__inner">
              <div className="Chat__showcase__information__outter">
                <div className="Chat__showcase__infotmation__item__outter">
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_user_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.firstName === ""
                        ? t("NoName", { ns: ["chat"] })
                        : selectedClient.firstName}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_user_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.lastName === ""
                        ? t("NoLastname", { ns: ["chat"] })
                        : selectedClient.lastName}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_mail_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {!isNotBlank(selectedClient.email)
                        ? clientSelected
                          ? t("NewClient", { ns: ["chat"] })
                          : t("Select", { ns: ["chat"] })
                        : selectedClient.email}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_phone_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.phone === ""
                        ? t("NoPhone", { ns: ["chat"] })
                        : selectedClient.phone}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_location_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.location === ""
                        ? t("NoLocation", { ns: ["chat"] })
                        : selectedClient.location}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_computer_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.computer === ""
                        ? t("NoComputer", { ns: ["chat"] })
                        : selectedClient.computer}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_globe_outline}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.ipAddress === ""
                        ? t("NoIp", { ns: ["chat"] })
                        : selectedClient.ipAddress}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_calendar_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.createDate === ""
                        ? t("NoCreation", { ns: ["chat"] })
                        : formatDate2(new Date(selectedClient.createDate))}
                    </div>
                  </div>
                  <div className="Chat__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_shield_filled}
                        alt="wizy_add_outline"
                        className="Chat__showcase__information__item__image"
                      />
                    </div>
                    <div className="Chat__showcase__information__item__text">
                      {selectedClient.subscriptionState
                        ? t("Subed", { ns: ["chat"] })
                        : t("Unsubed", { ns: ["chat"] })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="Chat__showcase__information__outter">
                {isQualityFormLoaded ? (
                  <QualityFormCard
                    qualityFormState={[qualityForm, setQualityForm]}
                    updateClientQualityForm={updateClientQualityForm}
                    handleQualityFormChange={handleQualityFormChange}
                  />
                ) : (
                  <div className="Home__setup__outter">
                    <img
                      src={wizy_loader}
                      className="ProtectedRoute__loader"
                      alt="ProtectedRoute__loader"
                      id="ProtectedRoute__loader"
                    />
                  </div>
                )}
              </div>
              {selectedClient.sales &&
              selectedClient.sales.filter((sale) => sale.isSale).length > 0 ? (
                <div className="Chat__showcase__information__outter">
                  {isSalesFormLoaded ? (
                    <SalesFormCard
                      selectedClientState={[selectedClient, setSelectedClient]}
                      updateSelectedClientApprovedSales={
                        updateSelectedClientApprovedSales
                      }
                    />
                  ) : (
                    <div className="Home__setup__outter">
                      <img
                        src={wizy_loader}
                        className="ProtectedRoute__loader"
                        alt="ProtectedRoute__loader"
                        id="ProtectedRoute__loader"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

export default ChatAdmin;
