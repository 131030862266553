// Import React Dependencies
import React, { FC, useEffect, useState } from "react";

// Import styles
import "./styles/ChatMedia.css";

// Import types and interfaces
import { AppContext, IContext } from "../context/Context";

//Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_document_outline from "../images/wizy_document_outline.svg";
import wizy_downloadfile_outline from "../images/wizy_downloadfile_outline.svg";
import { EMainLanguage } from "./ShopifyWidget";
import { EMessageSourceTypes } from "../types/MessageType";

type ChatMediaProps = {
  messageId: string;
  language: string;
};

type MessageMedia = {
  sourceType: EMessageSourceTypes;
  type: string;
  data?: string;
  url?: string;
};

// Page main functional component
const ChatMedia: FC<ChatMediaProps> = (props) => {
  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [messageMedia, setMessageMedia] = useState<MessageMedia>({
    sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
    type: "",
  });

  // WHATSAPP
  const supportedImageTypes = ["image/jpeg", "image/png"];
  const supportedAudioTypes = [
    "audio/aac",
    "audio/amr",
    "audio/mpeg",
    "audio/mp4",
    "audio/ogg",
  ];
  const supportedVideoTypes = ["video/3gp", "video/mp4"];
  const supportedStickerTypes = ["image/webp"];
  const supportedDocumentTypes = [
    "text/plain",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf",
  ];
  const pdfDocumentTypes = ["application/pdf"];
  const spreadsheetsDocumentTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const slidesDocumentTypes = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const textDocumentTypes = [
    "text/plain",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  // TRANSLATIONS
  const pdfDocumentTitle: { [key in EMainLanguage]: string } = {
    [EMainLanguage.ENGLISH]: "PDF document",
    [EMainLanguage.SPANISH]: "Documento PDF",
    [EMainLanguage.PORTUGUESE]: "Documento PDF",
    [EMainLanguage.GERMAN]: "PDF-Dokument",
    [EMainLanguage.FRENCH]: "Document PDF",
    [EMainLanguage.ITALIAN]: "Documento PDF",
  };

  const spreadsheetsDocumentTitle: { [key in EMainLanguage]: string } = {
    [EMainLanguage.ENGLISH]: "Spreadsheet document",
    [EMainLanguage.SPANISH]: "Documento de hoja de cálculo",
    [EMainLanguage.PORTUGUESE]: "Documento de planilha",
    [EMainLanguage.GERMAN]: "Tabellendokument",
    [EMainLanguage.FRENCH]: "Document de feuille de calcul",
    [EMainLanguage.ITALIAN]: "Documento di foglio di calcolo",
  };

  const slidesDocumentTitle: { [key in EMainLanguage]: string } = {
    [EMainLanguage.ENGLISH]: "Slides document",
    [EMainLanguage.SPANISH]: "Documento de diapositivas",
    [EMainLanguage.PORTUGUESE]: "Documento de slides",
    [EMainLanguage.GERMAN]: "Foliendokument",
    [EMainLanguage.FRENCH]: "Document de diapositives",
    [EMainLanguage.ITALIAN]: "Documento di diapositive",
  };

  const textDocumentTitle: { [key in EMainLanguage]: string } = {
    [EMainLanguage.ENGLISH]: "Text document",
    [EMainLanguage.SPANISH]: "Documento de texto",
    [EMainLanguage.PORTUGUESE]: "Documento de texto",
    [EMainLanguage.GERMAN]: "Textdokument",
    [EMainLanguage.FRENCH]: "Document texte",
    [EMainLanguage.ITALIAN]: "Documento di testo",
  };

  useEffect(() => {
    getMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMedia = async () => {
    await fetch(
      globalSelectedBackend + "/chatrest/messagemedia/" + props.messageId,
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          setMessageMedia({
            sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
            type: "",
          });
          setIsLoaded(true);
          console.log(errorText)
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setMessageMedia(JSONresult);
        setIsLoaded(true);
      })
      .catch((err) => {
        setMessageMedia({
          sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
          type: "",
        });
        setIsLoaded(true);
        console.log(err);
      });
  };

  if (isLoaded) {
    return (
      <React.Fragment>
        <div
          className="ChatMedia__outter"
          style={
            messageMedia.type &&
            supportedStickerTypes.includes(messageMedia.type)
              ? { width: "170px" }
              : { width: "340px" }
          }
        >
          <div
            className="ChatMedia__inner"
            style={{
              color: "black",
            }}
          >
            {
              // WHATSAPP
              messageMedia.sourceType === EMessageSourceTypes.WHATSAPP ? (
                supportedImageTypes.includes(messageMedia.type) ? (
                  // WhatsApp Image
                  <img
                    src={`data:${messageMedia.type};base64,${messageMedia.data}`}
                    style={{ width: "100%" }}
                    alt=""
                  />
                ) : supportedAudioTypes.includes(messageMedia.type) ? (
                  // WhatsApp Audio
                  <audio
                    controls
                    src={`data:${messageMedia.type};base64,${messageMedia.data}`}
                  />
                ) : supportedDocumentTypes.includes(messageMedia.type) ? (
                  // WhatsApp Document
                  <div
                    className="ChatMedia__document__outter"
                    style={{ width: "100%" }}
                  >
                    <div className="ChatMedia__document__icon__outter">
                      <div className="ChatMedia__document__icon">
                        <img
                          src={wizy_document_outline}
                          alt=""
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    </div>
                    <div className="ChatMedia__document__name__outter">
                      <div className="ChatMedia__document__name">
                        {pdfDocumentTypes.includes(messageMedia.type)
                          ? pdfDocumentTitle[props.language as EMainLanguage]
                          : spreadsheetsDocumentTypes.includes(
                              messageMedia.type
                            )
                          ? spreadsheetsDocumentTitle[
                              props.language as EMainLanguage
                            ]
                          : slidesDocumentTypes.includes(messageMedia.type)
                          ? slidesDocumentTitle[props.language as EMainLanguage]
                          : textDocumentTypes.includes(messageMedia.type)
                          ? textDocumentTitle[props.language as EMainLanguage]
                          : "Attached Document"}
                      </div>
                    </div>
                    <div className="ChatMedia__document__button__outter">
                      <a
                        className="ChatMedia__document__button"
                        href={`data:${messageMedia.type};base64,${messageMedia.data}`}
                        download={
                          pdfDocumentTypes.includes(messageMedia.type)
                            ? pdfDocumentTitle[props.language as EMainLanguage]
                            : spreadsheetsDocumentTypes.includes(
                                messageMedia.type
                              )
                            ? spreadsheetsDocumentTitle[
                                props.language as EMainLanguage
                              ]
                            : slidesDocumentTypes.includes(messageMedia.type)
                            ? slidesDocumentTitle[
                                props.language as EMainLanguage
                              ]
                            : textDocumentTypes.includes(messageMedia.type)
                            ? textDocumentTitle[props.language as EMainLanguage]
                            : "Attached Document"
                        }
                      >
                        <img
                          src={wizy_downloadfile_outline}
                          alt=""
                          style={{ width: "25px", height: "25px" }}
                        />
                      </a>
                    </div>
                  </div>
                ) : supportedVideoTypes.includes(messageMedia.type) ? (
                  // WhatsApp Video
                  <video
                    controls
                    src={`data:${messageMedia.type};base64,${messageMedia.data}`}
                    style={{ width: "100%" }}
                  />
                ) : supportedStickerTypes.includes(messageMedia.type) ? (
                  // WhatsApp Sticker
                  <img
                    src={`data:${messageMedia.type};base64,${messageMedia.data}`}
                    style={{ width: "100%" }}
                    alt=""
                  />
                ) : (
                  "Media not supported"
                )
              ) : // INSTAGRAM & MESSENGER
              messageMedia.sourceType === EMessageSourceTypes.INSTAGRAM ||
                messageMedia.sourceType === EMessageSourceTypes.MESSENGER ? (
                // Image
                messageMedia.type === "image" ? (
                  <img
                    src={messageMedia.url}
                    style={{ width: "100%" }}
                    alt=""
                  />
                ) : messageMedia.type === "video" ? (
                  // Video
                  <video
                    controls
                    src={messageMedia.url}
                    style={{ width: "100%" }}
                  />
                ) : (
                  "Media not supported"
                )
              ) : messageMedia.sourceType ===
                EMessageSourceTypes.SHOPIFY_WIDGET ? (
                "Media not available"
              ) : (
                "No media type provided"
              )
            }
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="ChatMedia__outter" style={{ height: "50px" }}>
          <div
            className="ChatMedia__inner"
            style={{
              color: "black",
              width: "100%",
              height: "100%",
            }}
          >
            {
              <img
                src={wizy_loader}
                className="ProtectedRoute__loader"
                alt="ProtectedRoute__loader"
                id="ProtectedRoute__loader"
              />
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
};

// Default exported function
export default ChatMedia;
