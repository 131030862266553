// Import React Dependencies
import React, { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

// Import Context
import { AppContext, IContext } from "../context/Context";

// Import media
import wizy_loader from "../images/wizy_loader.gif";

// Import styles
import "./styles/ProtectedRoute.css";
import { EType } from "../types/UserType";

// Declare types and interfaces
export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
  protectionType: EType[];
};

// Page main functional component
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  authenticationPath,
  outlet,
  protectionType,
}) => {
  // Use context
  const { authCheck } = React.useContext(AppContext) as IContext;

  //Use state variables
  const [isAuth, setIsAuth] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Internal Functions
  useEffect(() => {
    authCheck().then((result) => {
      if (
        result.isLogged &&
        !result.isFirstTime &&
        protectionType.includes(result.type)
      ) {
        setIsAuth(true);
        setIsLoaded(true);
      } else {
        setIsAuth(false);
        setIsLoaded(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX Return statement
  if (isLoaded) {
    if (isAuth) {
      return outlet;
    } else {
      return <Navigate to={{ pathname: authenticationPath }} />;
    }
  } else {
    return (
      <div className="ProtectedRoute__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default ProtectedRoute;
