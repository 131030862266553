// Import React Dependencies
import { FC, useEffect, useState } from "react";
import React from "react";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";

// Import styles
import "./styles/WhatsAppTemplatesModal.css";
import { useTranslation } from "react-i18next";
import { AppContext, IContext } from "../context/Context";
import { IClient } from "../types/ClientType";
import { IConversation } from "../pages/Chat";
import {
  EMessageRole,
  EMessageSourceTypes,
  EMessageStatus,
  IMessage,
} from "../types/MessageType";
import { IValidatorProps } from "./Validator";

// Declare types and interfaces
export type IWhatsAppTemplatesModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClientState: [IClient, React.Dispatch<React.SetStateAction<IClient>>];
  clientsState: [IClient[], React.Dispatch<React.SetStateAction<IClient[]>>];
  conversationsState: [
    IConversation[],
    React.Dispatch<React.SetStateAction<IConversation[]>>
  ];
  setValidatorProps: React.Dispatch<React.SetStateAction<IValidatorProps>>;
};

type ITemplate = {
  id: string;
  language: string;
  category: string;
  components: any[];
  name: string;
  status: string;
  // For frontend purposes
  selected: boolean;
};

type IPaging = {
  cursors: { before: string; after: string };
  next?: string;
  previous?: string;
};

// Page main functional component
const WhatsAppTemplatesModal: FC<IWhatsAppTemplatesModalProps> = ({
  setIsOpen,
  selectedClientState: [selectedClient, setSelectedClient],
  clientsState: [clients, setClients],
  conversationsState: [conversations, setConversations],
  setValidatorProps,
}) => {
  // Use context
  const { globalSelectedBackend, globalShop } = React.useContext(
    AppContext
  ) as IContext;
  // Use translation
  const { t } = useTranslation(["chat"]);

  // Use state variables
  const [isModalLoaded, setIsModalLoaded] = useState<boolean>(false);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [paging, setPaging] = useState<IPaging>({
    cursors: { before: "", after: "" },
  });

  // Use effect
  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplates = async (cursor?: { before?: string; after?: string }) => {
    let searchURL =
      globalSelectedBackend +
      "/whatsappaccounts/templates/" +
      conversations.find((conversation) => conversation.selected)?.sourceId!;
    if (cursor?.before) {
      searchURL += "?before=" + cursor.before;
    } else if (cursor?.after) {
      searchURL += "?after=" + cursor.after;
    }
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          throw new Error(errorText);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        console.log(JSONresult);
        const newTemplates = JSONresult.data.map((template: any) => {
          const newComponents = template.components.map((component: any) => {
            if (component.type === "BODY") {
              const variablePattern = /\{\{\d+\}\}/g;
              const variableMatches = getUnique(
                component.text.match(variablePattern)
              );
              const bodyVariables = Array(variableMatches.length).fill("");
              return { ...component, bodyVariables: bodyVariables };
            } else {
              return component;
            }
          });
          return { ...template, components: newComponents, selected: false };
        });
        setTemplates(newTemplates);
        setPaging(JSONresult.paging);
        setIsModalLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTemplateSelect = (templateId: string) => {
    setTemplates((prevTemplates) =>
      prevTemplates.map((prevTemplate) => ({
        ...prevTemplate,
        selected: prevTemplate.id === templateId ? true : false,
      }))
    );
    console.log(templates);
  };

  const handleBodyVariableChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.currentTarget;
    const bodyVariableIndex = parseInt(name);
    const templateId = getSelectedTemplate()?.id;
    setTemplates((prevTemplates) =>
      prevTemplates.map((template) => {
        if (template.id === templateId) {
          const newComponents = template.components.map((component) => {
            if (component.type === "BODY") {
              const newBodyVariables = component.bodyVariables;
              newBodyVariables[bodyVariableIndex] = value;
              return { ...component, bodyVariables: newBodyVariables };
            } else {
              return component;
            }
          });
          return { ...template, components: newComponents };
        } else {
          return template;
        }
      })
    );
  };

  const canSelectedTemplateBeSent = (): boolean => {
    let result = false;
    const bodyVariables = getSelectedTemplate()?.components.find(
      (component) => component.type === "BODY"
    ).bodyVariables;
    if (bodyVariables.length === 0) {
      result = true;
    } else if (bodyVariables.every((str: string) => str.trim().length > 0)) {
      result = true;
    }
    return result;
  };

  const getUnique = (arr: any[]) => {
    const uniqueSet = new Set(arr);
    return Array.from(uniqueSet);
  };

  const getSelectedTemplate = () => {
    return templates.find((template) => template.selected);
  };

  const buildNewMessageFromTemplate = (template: ITemplate): string => {
    let newMessage = "";
    // Add the header text if it has it
    const headerComponent = template.components.find(
      (component) => component.type === "HEADER" && component.format === "TEXT"
    );
    if (headerComponent) {
      newMessage += headerComponent.text + "\n";
    }
    // Add the body if it has it
    const bodyComponent = template.components.find(
      (component) => component.type === "BODY"
    );
    if (bodyComponent) {
      let bodyWithVariables = bodyComponent.text;
      // For every variable, replace "{{i+1}}" in body text with variable text bodyVariables[i]
      for (let i = 0; i < bodyComponent.bodyVariables.length; i++) {
        const pattern = new RegExp(`\\{\\{${i + 1}\\}\\}`, "g");
        bodyWithVariables = bodyWithVariables.replace(
          pattern,
          bodyComponent.bodyVariables[i]
        );
      }
      newMessage += bodyWithVariables;
    }
    // Add the footer if it has it
    const footerComponent = template.components.find(
      (component) => component.type === "FOOTER"
    );
    if (footerComponent) {
      newMessage += "\n" + footerComponent.text + "\n";
    }
    return newMessage;
  };

  const handleMessageSend = async () => {
    if (selectedClient.id !== "") {
      if (canSelectedTemplateBeSent()) {
        // Get selected conversation
        const selectedConversation = conversations.find(
          (conversation) => conversation.selected
        );
        if (selectedConversation) {
          setIsModalLoaded(false);
          const selectedTemplate = getSelectedTemplate()!;
          const newMessage = buildNewMessageFromTemplate(selectedTemplate);
          // Send message to backend
          const response = await sendTemplateMessageToWhatsApp(
            newMessage,
            selectedClient.id,
            globalShop.domain,
            selectedConversation.sourceType,
            selectedConversation.sourceId,
            selectedTemplate
          );
          if (response.success) {
            // Update last message in the client entity of the selected client
            setClients((previous) => {
              const updatedItems = [...previous];
              updatedItems[
                clients.findIndex((client: IClient) => {
                  return selectedClient.id === client.id;
                })
              ].lastMessage = newMessage;
              return updatedItems;
            });
            // Add new message to the selected conversation
            const sentMessage: IMessage = {
              content: newMessage,
              createDate: new Date(
                Date.parse(
                  selectedConversation.messages.slice(-1)[0].createDate
                ) + 500
              ).toISOString(),
              role: EMessageRole.assistant,
              name: null,
              functionCall: null,
              toolCalls: null,
              toolCallId: null,
              extraUIComponents: [],
              sourceType: selectedConversation.sourceType,
              sourceId: selectedConversation.sourceId,
              hasMedia: false,
              status: EMessageStatus.SENT,
            };
            setConversations((prevConversations) =>
              prevConversations.map((prevConversation) =>
                prevConversation.selected
                  ? {
                      ...prevConversation,
                      messages: [...prevConversation.messages, sentMessage],
                    }
                  : prevConversation
              )
            );
            setIsOpen(false);
          } else {
            setValidatorProps({
              validatorPrompt: response.error!,
              validatorTitle: "Error",
              functionOne: () => {},
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "",
              functionNumber: 1,
            });
            setIsOpen(false);
          }
        }
      }
    }
  };

  const sendTemplateMessageToWhatsApp = async (
    content: string,
    clientId: string,
    shopDomain: string,
    sourceType: EMessageSourceTypes,
    sourceId: string | null,
    template: ITemplate
  ): Promise<{ success: boolean; error?: string }> => {
    let searchURL =
      globalSelectedBackend + "/chatrest/whatsapptemplatemessage/";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      content: content,
      clientId: clientId,
      shopDomain: shopDomain,
      sourceType: sourceType,
      sourceId: sourceId,
      template: template,
    });
    const backendResponse = await fetch(searchURL, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    });
    if (!backendResponse.ok) {
      let errorJSON = await backendResponse.json();
      return { success: false, error: errorJSON.message };
    } else {
      return backendResponse.json();
    }
  };

  // JSX Return statement
  if (isModalLoaded) {
    // JSX Return statement
    return (
      <React.Fragment>
        {/* Modal background that closes when clicked */}
        <div
          className="Modal__background"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        {/* Modal container */}
        <div className="Modal__outter">
          <div className="Modal__title">
            {t("MessageTemplates", { ns: ["whatsAppTemplatesModal"] })}
          </div>
          <button
            className="Modal__close__button"
            onClick={() => setIsOpen(false)}
          >
            x
          </button>
          <div className="Modal__body">
            {templates.length === 0 ? (
              <>
                <div className="WhatsAppTemplates__content">
                  {t("NoTemplates", { ns: ["whatsAppTemplatesModal"] })}
                </div>{" "}
                <button
                  className="Wizy__button__10"
                  style={{ width: "200px", height: "30px" }}
                  onClick={() => {
                    window.open(
                      "https://business.facebook.com/wa/manage/template-library",
                      "_blank"
                    );
                  }}
                >
                  {t("CreateTemplate", { ns: ["whatsAppTemplatesModal"] })}
                </button>
              </>
            ) : (
              <>
                <div className="WhatsAppTemplates__list__list__outter">
                  <div className="WhatsAppTemplates__list__list__header__outter">
                    <div className="WhatsAppTemplates__list__list__header__inner">
                      {/* Pagination */}
                      {paging.next || paging.previous ? (
                        <div className="WhatsAppTemplates__list__list__pagination__outter">
                          <ul className="WhatsAppTemplates__pagination__container">
                            <li>
                              <button
                                className="Wizy__button__8"
                                onClick={() =>
                                  getTemplates({
                                    before: paging.cursors.before,
                                  })
                                }
                                disabled={!paging.previous}
                                style={{ width: "50px" }}
                              >
                                <img
                                  className="WhatsAppTemplates__pagination__previous__page__arrow"
                                  src={wizy_hide_side_menu_outline}
                                  alt="Previous Page"
                                />
                              </button>
                            </li>
                            <li>
                              <button
                                className="Wizy__button__8"
                                onClick={() =>
                                  getTemplates({ after: paging.cursors.after })
                                }
                                disabled={!paging.next}
                                style={{ width: "50px" }}
                              >
                                <img
                                  className="WhatsAppTemplates__pagination__next__page__arrow"
                                  src={wizy_hide_side_menu_outline}
                                  alt="Next Page"
                                />
                              </button>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="WhatsAppTemplates__manage__button__container">
                        <div className="WhatsAppTemplates__manage__button">
                          <button
                            className="Wizy__button__10"
                            style={{ width: "200px", height: "30px" }}
                            onClick={() => {
                              window.open(
                                "https://business.facebook.com/wa/manage/template-library",
                                "_blank"
                              );
                            }}
                          >
                            {t("ManageTemplates", {
                              ns: ["whatsAppTemplatesModal"],
                            })}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="WhatsAppTemplates__list__list__titles__outter">
                    <div className="WhatsAppTemplates__list__list__titles__inner">
                      <div className="WhatsAppTemplates__list__list__titles__inner__inner">
                        {t("Name", { ns: ["whatsAppTemplatesModal"] })}
                      </div>
                      <div className="WhatsAppTemplates__list__list__titles__inner__inner">
                        {t("Status", { ns: ["whatsAppTemplatesModal"] })}
                      </div>
                      <div className="WhatsAppTemplates__list__list__titles__inner__inner">
                        {t("Body", { ns: ["whatsAppTemplatesModal"] })}
                      </div>
                    </div>
                  </div>
                  <div className="WhatsAppTemplates__list__list__items__outter">
                    {templates.map((template, index) => {
                      return (
                        <div
                          className="WhatsAppTemplates__list__list__item__outter"
                          onClick={() => {
                            handleTemplateSelect(template.id);
                          }}
                          key={index}
                        >
                          <div className="WhatsAppTemplates__list__list__item__inner">
                            <div className="WhatsAppTemplates__list__list__item__inner__inner">
                              {template.name}
                            </div>
                            <div className="WhatsAppTemplates__list__list__item__inner__inner">
                              {template.status}
                            </div>
                            <div className="WhatsAppTemplates__list__list__item__inner__inner">
                              {
                                template.components.find(
                                  (component: any) => component.type === "BODY"
                                ).text
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {getSelectedTemplate() ? (
                  <div className="WhatsAppTemplates__components__outter">
                    <div className="WhatsAppTemplates__components__inner">
                      <div className="WhatsAppTemplates__components__title">
                        {t("SelectedTemplate", {
                          ns: ["whatsAppTemplatesModal"],
                        })}
                      </div>
                      {getSelectedTemplate()!.components.map(
                        (component: any) => {
                          if (
                            component.type === "HEADER" &&
                            component.format === "TEXT"
                          ) {
                            return (
                              <>
                                <div className="Wizy__input__title">
                                  {t("Header", {
                                    ns: ["whatsAppTemplatesModal"],
                                  })}
                                </div>
                                <textarea
                                  className="Wizy__textarea"
                                  disabled={true}
                                  value={component.text}
                                ></textarea>
                              </>
                            );
                          } else if (component.type === "BODY") {
                            return (
                              <>
                                <div className="Wizy__input__title">
                                  {t("Body", {
                                    ns: ["whatsAppTemplatesModal"],
                                  })}
                                </div>
                                <textarea
                                  className="Wizy__textarea"
                                  disabled={true}
                                  value={component.text}
                                ></textarea>
                                {component.bodyVariables.length > 0 ? (
                                  <>
                                    <div className="Wizy__input__title">
                                      {t("BodyVariables", {
                                        ns: ["whatsAppTemplatesModal"],
                                      })}
                                    </div>
                                    {(component.bodyVariables as string[]).map(
                                      (bodyVariable, index) => (
                                        <div className="WhatsAppTemplate__body__variable__container">
                                          <div className="WhatsAppTemplate__body__variable__title__outter">
                                            <div className="WhatsAppTemplate__body__variable__title">
                                              {`{{${index + 1}}}`}
                                            </div>
                                          </div>
                                          <div className="WhatsAppTemplate__body__variable__input__outter">
                                            <input
                                              type="text"
                                              className="Wizy__input__2"
                                              placeholder={
                                                component.example.body_text[0][
                                                  index
                                                ]
                                              }
                                              value={bodyVariable}
                                              onChange={
                                                handleBodyVariableChange
                                              }
                                              name={index.toString()}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          } else if (component.type === "FOOTER") {
                            return (
                              <>
                                <div className="Wizy__input__title">
                                  {t("Footer", {
                                    ns: ["whatsAppTemplatesModal"],
                                  })}
                                </div>
                                <textarea
                                  className="Wizy__textarea"
                                  disabled={true}
                                  value={component.text}
                                ></textarea>
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        }
                      )}
                    </div>
                    {canSelectedTemplateBeSent() ? (
                      <button
                        className="Wizy__button__10"
                        style={{
                          width: "200px",
                          height: "30px",
                          marginBottom: "20px",
                        }}
                        onClick={() => {
                          handleMessageSend();
                        }}
                      >
                        {t("SendTemplateMessage", {
                          ns: ["whatsAppTemplatesModal"],
                        })}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* Modal background that closes when clicked */}
        <div
          className="Modal__background"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        {/* Modal container */}
        <div className="Modal__outter">
          <div className="Modal__title">
            {t("MessageTemplates", { ns: ["whatsAppTemplatesModal"] })}
          </div>
          <button
            className="Modal__close__button"
            onClick={() => setIsOpen(false)}
          >
            x
          </button>
          <div className="Modal__body" style={{ height: "70px" }}>
            <img
              src={wizy_loader}
              className="ProtectedRoute__loader"
              alt="ProtectedRoute__loader"
              id="ProtectedRoute__loader"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
};

// Default exported function
export default WhatsAppTemplatesModal;
