// Import React Dependencies
import ReactDOM from "react-dom/client";

// Import Components
import App from "./components/App";
import "./context/I18n";

// Import Styles
import "./styles/index.css";
import "./styles/WizyInput.css";
import "./styles/WizyButton.css";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
root.render(<App />);
