// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { useTranslation } from "react-i18next";

// Import Context
import { AppContext, IContext } from "../context/Context";

// Import Components
import LanguageSelector from "../components/LaguageSelector";

// Import media
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_arrow_right_tail_outline from "../images/wizy_arrow_right_tail_outline.svg";
import wizy_shop_bag_filled from "../images/wizy_shop_bag_filled.svg";
import wizy_mail_filled from "../images/wizy_mail_filled.svg";
import wizy_loader from "../images/wizy_loader.gif";

// Import styles
import "./styles/Login.css";
import { EType } from "../types/UserType";

// Declare types and interfaces
enum EMailShopify {
  mail,
  shopify,
}

enum ELogChangeForgot {
  log,
  change,
  forgot,
  register,
}

enum ELoginPromt {
  initial,
  error,
  loading,
}

enum EChangePasswordPromt {
  initial,
  error,
  loading,
}

// Page main functional component
const Login: FC = () => {
  // Use context
  const {
    authenticate,
    authCheck,
    changePassword,
    globalSelectedBackend,
    globalUser,
  } = React.useContext(AppContext) as IContext;

  // Use translation
  const { t } = useTranslation(["login"]);

  const { error, account } = useParams<{
    error: string | undefined;
    account: string | undefined;
  }>();

  // Use history
  const navigate = useNavigate();

  // Use state variables
  const [email, setEmail] = useState<string>(
    account === undefined ? "" : account
  );
  const [password, setPassword] = useState<string>("");
  const [shop, setShop] = useState<string>("");
  const [shopReset, setShopReset] = useState<string>("");
  const [shopRegister, setShopRegister] = useState<string>("");
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [mailShopifyToogle] = useState<EMailShopify>(EMailShopify.mail);
  const [logChangeForgotToogle, setLogChangeForgotToogle] =
    useState<ELogChangeForgot>(ELogChangeForgot.log);
  const [newPassword, setNewPassword] = useState<{ [name: string]: string }>({
    newPassword1: "",
    newPassword2: "",
  });
  const [changePasswordPromt, setChangePasswordPromt] =
    useState<EChangePasswordPromt>(EChangePasswordPromt.initial);
  const [loginPromt, setLoginPromt] = useState<ELoginPromt>(
    ELoginPromt.initial
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loggedUserType, setLoggedUserType] = useState<EType>(EType.SHOP);

  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  // Internal Functions
  // Use Effect Functions
  useEffect(() => {
    authCheck().then((result) => {
      setIsLoaded(true);
      if (result.isLogged && !result.isFirstTime) {
        logInRedirect(result.type);
      } else if (result.isLogged && result.isFirstTime) {
        setLoggedUserType(result.type);
        setLogChangeForgotToogle(ELogChangeForgot.change);
      } else {
        if (error) {
          if (error !== "none") {
            if (error === "error") {
              setValidatorProps({
                validatorPrompt: t("Error1", { ns: ["login"] }),
                validatorTitle: "Error",
                functionOne: () => {},
                functionOnePrompt: "OK",
                functionTwo: () => {},
                functionTwoPrompt: "",
                functionNumber: 1,
              });
            } else if (error === "error1") {
              setValidatorProps({
                validatorPrompt: t("Error2", { ns: ["login"] }),
                validatorTitle: "Error",
                functionOne: () => {},
                functionOnePrompt: "OK",
                functionTwo: () => {},
                functionTwoPrompt: "",
                functionNumber: 1,
              });
            } else if (error === "error2") {
              setValidatorProps({
                validatorPrompt: t("Error3", { ns: ["login"] }),
                validatorTitle: "Error",
                functionOne: () => {},
                functionOnePrompt: "OK",
                functionTwo: () => {},
                functionTwoPrompt: "",
                functionNumber: 1,
              });
            } else if (error === "error3") {
              setValidatorProps({
                validatorPrompt: t("Error4", { ns: ["login"] }),
                validatorTitle: "Error",
                functionOne: () => {},
                functionOnePrompt: "OK",
                functionTwo: () => {},
                functionTwoPrompt: "",
                functionNumber: 1,
              });
            }
          } else {
            if (account) {
              setValidatorProps({
                validatorPrompt: `${t("Hello", {
                  ns: ["login"],
                })} ${account} ${t("LoginP", { ns: ["login"] })}`,
                validatorTitle: t("Login", { ns: ["login"] }),
                functionOne: () => {},
                functionOnePrompt: "Ok",
                functionTwo: () => {},
                functionTwoPrompt: "",
                functionNumber: 1,
              });
            }
          }
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded) {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  const logInRedirect = (type: EType) => {
    if (type === EType.SHOP) {
      navigate("/home");
    } else {
      navigate("/homeadmin");
    }
  };

  const resetWithShopifyRedirect = () => {
    window.location.replace(
      globalSelectedBackend + "/shopifyReset?shop=" + shopReset
    );
  };

  const logInWithShopifyRedirect = () => {
    window.location.replace(
      globalSelectedBackend + "/shopifyLogin?shop=" + shop
    );
  };

  const regiterWithShopifyRedirect = () => {
    window.location.replace(
      globalSelectedBackend + "/shopifyRegister?shop=" + shopRegister
    );
  };

  // const toogleMailShopify = () => {
  //   if (mailShopifyToogle === EMailShopify.mail) {
  //     setMailShopifyToogle(EMailShopify.shopify);
  //   } else {
  //     setMailShopifyToogle(EMailShopify.mail);
  //   }
  // };

  const handleNewPasswordChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.currentTarget;
    setNewPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        <div className="Login__outter">
          <div className="Login__navbar__outter">
            <div className="Login__navbar__inner">
              <div className="Login__navbar__logo__outter">
                <div className="Login__navbar__logo__inner">
                  <img
                    src={wizy_logo_white}
                    alt="wizy_logo_blue"
                    className="Login__navbar__logo__img"
                  />
                  <div className="Login__navbar__logo__title">Wizybot</div>
                </div>
              </div>
              <div></div>
              <div className="Login__navbar__language__select__outter">
                <div className="Login__navabr__language__select__inner">
                  <LanguageSelector side="right" color="white" />
                </div>
              </div>
            </div>
          </div>
          <div className="Login__main__outter">
            <div className="Login__main__download__outter">
              <div className="Login__main__download__inner">
                <div className="Login__main__download__title">
                  {" "}
                  {logChangeForgotToogle === ELogChangeForgot.change
                    ? t("Choice", { ns: ["login"] })
                    : t("Account", { ns: ["login"] })}
                </div>
                {logChangeForgotToogle === ELogChangeForgot.change ? (
                  ""
                ) : (
                  <div className="Login__shopify__button__outter__outter">
                    <div className="Login__shopify__button__outter__1">
                      <a
                        href="https://apps.shopify.com/wizybot"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <div className="Wizy__animated__button__1__outter">
                          <img
                            src={wizy_arrow_right_tail_outline}
                            alt="wizy_shopify_logo"
                            className="Wizy__animated__button__logo__1"
                          />

                          <button className="Wizy__animated__button__1">
                            {t("Register", { ns: ["login"] })}
                          </button>
                          <div className="Wizy__animated__button__1__background"></div>
                        </div>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Login__main__login__outter">
              {logChangeForgotToogle === ELogChangeForgot.log ? (
                <div className="Login__main__login__inner">
                  <div className="Login__main__login__title">
                    {t("Login", { ns: ["login"] })}
                  </div>
                  <div className="Login__shopify__button__outter__outter">
                    <div className="Login__shopify__button__outter">
                      <a
                        href="https://apps.shopify.com/wizybot/install"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <div className="Wizy__animated__button__1__outter">
                          {mailShopifyToogle === EMailShopify.mail ? (
                            <img
                              src={wizy_shop_bag_filled}
                              alt="wizy_shopify_logo"
                              className="Wizy__animated__button__logo__1"
                            />
                          ) : (
                            <img
                              src={wizy_mail_filled}
                              alt="wizy_shopify_logo"
                              className="Wizy__animated__button__logo__1"
                            />
                          )}

                          <button className="Wizy__animated__button__1">
                            {t("LoginWith", { ns: ["login"] })}{" "}
                            {mailShopifyToogle === EMailShopify.mail
                              ? "Shopify"
                              : t("EmailA", { ns: ["login"] })}
                          </button>
                          <div className="Wizy__animated__button__1__background"></div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="Login__main__login__or__outter">
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                    <div>{t("Or", { ns: ["login"] })}</div>{" "}
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                  </div>
                  {mailShopifyToogle === EMailShopify.mail ? (
                    <div className="Login__main__mail__credentials">
                      <input
                        value={email}
                        name="email"
                        type="text"
                        className="Wizy__input"
                        placeholder={t("Email", { ns: ["login"] })}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          setEmail(event.currentTarget.value);
                        }}
                      />
                      <input
                        value={password}
                        name="password"
                        type="password"
                        className="Wizy__input"
                        placeholder={t("Password", { ns: ["login"] })}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          setPassword(event.currentTarget.value);
                        }}
                      />
                      <div className="Wizy__input__prompt">
                        {loginPromt === ELoginPromt.initial ? (
                          <div>{t("Credentials", { ns: ["login"] })}</div>
                        ) : loginPromt === ELoginPromt.loading ? (
                          <div className="loader">
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                          </div>
                        ) : (
                          <div>{t("Incorrect", { ns: ["login"] })}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {mailShopifyToogle === EMailShopify.shopify ? (
                    <div className="Login__main__shopify__credentials">
                      <input
                        value={shop}
                        name="shop"
                        type="text"
                        className="Wizy__input"
                        placeholder="shop.myshopify.com"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          setShop(event.currentTarget.value);
                        }}
                      />
                      <div className="Wizy__input__prompt">
                        {loginPromt === ELoginPromt.initial ? (
                          <div>{t("Domain", { ns: ["login"] })}</div>
                        ) : loginPromt === ELoginPromt.loading ? (
                          <div className="loader">
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                          </div>
                        ) : (
                          <div>{t("Incorrect", { ns: ["login"] })}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="Login__button__outter">
                    <button
                      className="Wizy__button__1"
                      onClick={() => {
                        setLoginPromt(ELoginPromt.loading);
                        if (mailShopifyToogle === EMailShopify.mail) {
                          authenticate(email, password).then((response) => {
                            if (response.status) {
                              if (response.isFirstTime) {
                                setLogChangeForgotToogle(
                                  ELogChangeForgot.change
                                );
                                setLoggedUserType(response.type);
                              } else {
                                logInRedirect(response.type);
                              }
                            } else {
                              setLoginPromt(ELoginPromt.error);
                            }
                          });
                        } else {
                          logInWithShopifyRedirect();
                        }
                      }}
                    >
                      {t("Login", { ns: ["login"] })}
                    </button>
                  </div>
                  <a
                    href="https://apps.shopify.com/wizybot/install"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div className="Login__main__forgot__password">
                      {t("Forgot", { ns: ["login"] })}
                    </div>
                  </a>
                  <a
                    href="https://apps.shopify.com/wizybot/install"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div className="Login__main__create__account">
                      {t("Create", { ns: ["login"] })}
                    </div>
                  </a>
                </div>
              ) : (
                ""
              )}
              {logChangeForgotToogle === ELogChangeForgot.change ? (
                <div className="Login__main__login__inner">
                  <div className="Login__main__login__title">
                    {t("New", { ns: ["login"] })}
                  </div>
                  <div className="Login__main__login__or__outter">
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                  </div>
                  <div className="Login__main__mail__credentials">
                    <div className="Wizy__input__title__1">
                      {t("Email", { ns: ["login"] })}
                    </div>
                    <input
                      value={globalUser.email}
                      name="email"
                      autoComplete="off"
                      type="text"
                      className="Wizy__input__5"
                      disabled
                    />
                    <div className="Wizy__input__title__1">
                      {t("NewP", { ns: ["login"] })}
                    </div>
                    <input
                      value={newPassword.newPassword1}
                      name="newPassword1"
                      autoComplete="off"
                      type="password"
                      className="Wizy__input__5"
                      placeholder="Password"
                      onChange={handleNewPasswordChange}
                    />
                    <div className="Wizy__input__prompt">
                      {t("SaveP", { ns: ["login"] })}
                      {changePasswordPromt === EChangePasswordPromt.initial ? (
                        ""
                      ) : changePasswordPromt ===
                        EChangePasswordPromt.loading ? (
                        <div className="loader" style={{ display: "inline" }}>
                          &nbsp;&nbsp;
                          <span className="loader__dot">.</span>
                          &nbsp;&nbsp;
                          <span className="loader__dot">.</span>
                          &nbsp;&nbsp;
                          <span className="loader__dot">.</span>
                        </div>
                      ) : (
                        <div>{t("Old", { ns: ["login"] })}</div>
                      )}
                    </div>
                  </div>
                  <div className="Login__button__outter">
                    <button
                      className="Wizy__button__1"
                      onClick={() => {
                        setChangePasswordPromt(EChangePasswordPromt.loading);

                        changePassword(
                          newPassword.newPassword1,
                          newPassword.newPassword1
                        ).then((response) => {
                          if (response.status) {
                            logInRedirect(loggedUserType);
                          } else {
                            setChangePasswordPromt(EChangePasswordPromt.error);
                          }
                        });
                      }}
                    >
                      {t("CreateA", { ns: ["login"] })}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              {logChangeForgotToogle === ELogChangeForgot.forgot ? (
                <div className="Login__main__login__inner">
                  <div className="Login__main__login__title">
                    {t("Reset", { ns: ["login"] })}
                  </div>
                  <div className="Login__shopify__button__outter__outter">
                    <div className="Login__shopify__button__outter">
                      <div
                        onClick={() => {
                          setLogChangeForgotToogle(ELogChangeForgot.log);
                        }}
                        className="Wizy__animated__button__1__outter"
                      >
                        <img
                          src={wizy_mail_filled}
                          alt="wizy_shopify_logo"
                          className="Wizy__animated__button__logo__1"
                        />

                        <button className="Wizy__animated__button__1">
                          {t("LoginWith", { ns: ["login"] })}{" "}
                          {t("EmailA", { ns: ["login"] })}
                        </button>
                        <div className="Wizy__animated__button__1__background"></div>
                      </div>
                    </div>
                  </div>
                  <div className="Login__main__login__or__outter">
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                  </div>
                  <div className="Login__main__mail__credentials">
                    <input
                      value={shopReset}
                      name="shopReset"
                      type="text"
                      className="Wizy__input"
                      placeholder="shop.myshopify.com"
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        setShopReset(event.currentTarget.value);
                      }}
                    />
                    <div className="Wizy__input__prompt">
                      {t("Domain", { ns: ["login"] })}
                    </div>
                  </div>
                  <div className="Login__button__outter">
                    <button
                      className="Wizy__button__1"
                      onClick={() => {
                        setChangePasswordPromt(EChangePasswordPromt.loading);
                        resetWithShopifyRedirect();
                      }}
                    >
                      {t("ResetB", { ns: ["login"] })}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              {logChangeForgotToogle === ELogChangeForgot.register ? (
                <div className="Login__main__login__inner">
                  <div className="Login__main__login__title">
                    {t("RegisterB", { ns: ["login"] })}
                  </div>
                  <div className="Login__shopify__button__outter__outter">
                    <div className="Login__shopify__button__outter">
                      <div
                        onClick={() => {
                          setLogChangeForgotToogle(ELogChangeForgot.log);
                        }}
                        className="Wizy__animated__button__1__outter"
                      >
                        <img
                          src={wizy_mail_filled}
                          alt="wizy_shopify_logo"
                          className="Wizy__animated__button__logo__1"
                        />

                        <button className="Wizy__animated__button__1">
                          {t("LoginWith", { ns: ["login"] })}{" "}
                          {t("EmailA", { ns: ["login"] })}
                        </button>
                        <div className="Wizy__animated__button__1__background"></div>
                      </div>
                    </div>
                  </div>
                  <div className="Login__main__login__or__outter">
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>
                    <div className="Login__main__login__or__dash">
                      ---------------------------------------------
                    </div>{" "}
                  </div>
                  <div className="Login__main__mail__credentials">
                    <input
                      value={shopRegister}
                      name="shopRegister"
                      type="text"
                      className="Wizy__input"
                      placeholder="shop.myshopify.com"
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        setShopRegister(event.currentTarget.value);
                      }}
                    />
                    <div className="Wizy__input__prompt">
                      {t("Domain", { ns: ["login"] })}
                    </div>
                  </div>
                  <div className="Login__button__outter">
                    <button
                      className="Wizy__button__1"
                      onClick={() => {
                        regiterWithShopifyRedirect();
                      }}
                    >
                      {t("RegisterB", { ns: ["login"] })}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Login__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default Login;
