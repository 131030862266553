// Import React Dependencies
import { FC, useEffect, useState } from "react";
import React from "react";
import { IShop } from "../types/ShopType";
import { IPageMeta } from "./Pagination";

// Import styles
import "./styles/ChildrenSelector.css";
import { useTranslation } from "react-i18next";
import { AppContext, IContext } from "../context/Context";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_mg_outline from "../images/wizy_mg_outline.svg";

// Declare types and interfaces
export type IChildrenSelector = {
  shop: IShop;
};

type IFilter = {
  textBilling: string;
  textLogin: string;
};

// Page main functional component
const ChildrenSelector: FC<IChildrenSelector> = ({ shop }) => {
  const { t } = useTranslation(["childrenSelector"]);
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Use state variables
  const [billingMerchants, setBillingMerchants] = useState<IShop[]>([]);
  const [loginMerchants, setLoginMerchants] = useState<IShop[]>([]);
  const [billingSelectedMerchants, setBillingSelectedMerchants] = useState<
    IShop[]
  >([]);
  const [loginSelectedMerchants, setLoginSelectedMerchants] = useState<IShop[]>(
    []
  );
  const [filterChange, setFilterChange] = useState<IFilter>({
    textBilling: "",
    textLogin: "",
  });

  const [pageMetaBilling, setPageMetaBilling] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 20,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [pageMetaLogin, setPageMetaLogin] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 20,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();

  // Use Effect Functions
  useEffect(() => {
    getBillingMerchants(pageMetaBilling.take, pageMetaBilling.page, shop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isLoaded) {
      clearTimeout(searchTimeout);
      setSearchTimeout(
        setTimeout(() => {
          getBillingMerchants(pageMetaBilling.take, pageMetaBilling.page, shop);
        }, 2000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterChange]);

  // Internal functions
  const getBillingMerchants = async (
    take: number,
    page: number,
    shop: IShop
  ) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/admin/billingmerchants/" +
      shop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add text filters
    if (filterChange.textBilling !== "") {
      searchURL += "&text=" + filterChange.textBilling;
    }
    // Search existing shops and take only the ones we need
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setBillingMerchants([]);
        console.log(JSONresult);
        JSONresult.data.forEach((merchant: IShop) => {
          setBillingMerchants((previous) => [
            ...previous,
            {
              id: merchant.id,
              domain: merchant.domain,
              needsSetup: merchant.needsSetup,
              needsSync: merchant.needsSync,
              needsTest: merchant.needsTest,
              needsOnboarding: merchant.needsOnboarding,
              status: merchant.status,
              currentPlan: merchant.currentPlan,
              customOfferPlan: merchant.customOfferPlan,
              createDate: merchant.createDate,
              selected: false,
              isWidgetInstalled: merchant.isWidgetInstalled,
              shopCurrency: merchant.shopCurrency,
              shopMainDomain: merchant.shopMainDomain,
              shopName: merchant.shopName,
              isChargeable: merchant.isChargeable,
            },
          ]);
        });
        setPageMetaBilling({
          itemCount: JSONresult.meta.itemCount,
          pageCount: JSONresult.meta.pageCount,
          page: parseInt(JSONresult.meta.page),
          take: parseInt(JSONresult.meta.take),
          hasNextPage: JSONresult.meta.hasNextPage,
          hasPreviousPage: JSONresult.meta.hasPreviousPage,
        });
        getLoginMerchants(pageMetaLogin.take, pageMetaLogin.page, shop);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoginMerchants = async (take: number, page: number, shop: IShop) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/admin/loginmerchants/" +
      shop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add text filters
    if (filterChange.textLogin !== "") {
      searchURL += "&text=" + filterChange.textLogin;
    }
    // Search existing shops and take only the ones we need
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setLoginMerchants([]);
        JSONresult.data.forEach((merchant: IShop) => {
          setLoginMerchants((previous) => [
            ...previous,
            {
              id: merchant.id,
              domain: merchant.domain,
              needsSetup: merchant.needsSetup,
              needsSync: merchant.needsSync,
              needsTest: merchant.needsTest,
              needsOnboarding: merchant.needsOnboarding,
              status: merchant.status,
              currentPlan: merchant.currentPlan,
              customOfferPlan: merchant.customOfferPlan,
              createDate: merchant.createDate,
              selected: false,
              isWidgetInstalled: merchant.isWidgetInstalled,
              shopCurrency: merchant.shopCurrency,
              shopMainDomain: merchant.shopMainDomain,
              shopName: merchant.shopName,
              isChargeable: merchant.isChargeable,
            },
          ]);
        });
        setPageMetaLogin({
          itemCount: JSONresult.meta.itemCount,
          pageCount: JSONresult.meta.pageCount,
          page: parseInt(JSONresult.meta.page),
          take: parseInt(JSONresult.meta.take),
          hasNextPage: JSONresult.meta.hasNextPage,
          hasPreviousPage: JSONresult.meta.hasPreviousPage,
        });
        getBillingSelectedMerchants(shop);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBillingSelectedMerchants = async (shop: IShop) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend + "/admin/selectedbillingmerchants/" + shop.id;
    // Search existing shops and take only the ones we need
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setBillingSelectedMerchants(JSONresult);
        getLoginSelectedMerchants(shop);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLoginSelectedMerchants = async (shop: IShop) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend + "/admin/selectedloginmerchants/" + shop.id;
    // Search existing shops and take only the ones we need
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setLoginSelectedMerchants(JSONresult);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const newBillingChild = async (childShopId: string) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      shopId: childShopId,
    });

    await fetch(globalSelectedBackend + "/admin/newbillingchild/" + shop.id, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteBillingChild = async (childShopId: string) => {
    await fetch(
      globalSelectedBackend + "/admin/deletebillingchild/" + childShopId,
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const newLoginChild = async (childShopId: string) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      shopId: childShopId,
    });

    await fetch(globalSelectedBackend + "/admin/newloginchild/" + shop.id, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteLoginChild = async (childShopId: string) => {
    await fetch(
      globalSelectedBackend + "/admin/deleteloginchild/" + childShopId,
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#eff2f6" }}>
          <div
            className="ChildrenSelector__inner"
            style={{ backgroundColor: "#eff2f6" }}
          >
            <div
              className="ChildrenSelector__inner__inner"
              style={{ marginLeft: "0px" }}
            >
              <div className="ChildrenSelector__selector__outter__outter">
                <div className="ChildrenSelector__selector__outter__inner">
                  <div className="ChildrenSelector__title">
                    {t("ChildrenSelector", { ns: ["childrenSelector"] })}
                  </div>
                  <div className="ChildrenSelector__subtitle">
                    {t("ChildrenSelectorSubtitle", { ns: ["childrenSelector"] })}
                  </div>
                  <div className="ChildrenSelector__selector__outter">
                    <div className="ChildrenSelector__billing__selector__outter">
                      <div className="ChildrenSelector__subtitle__title">
                        {" "}
                        {t("BillingChildrens", { ns: ["childrenSelector"] })}
                      </div>
                      <div className="ChildrenSelector__billing__selector__inner">
                        <div className="ChildrenSelector__billing__selector__inner__outter">
                          <div className="ChildrenSelector__billing__selector__inner__inner__title">
                            {t("Shops", { ns: ["childrenSelector"] })}
                          </div>
                          <div className="Wizy__input__1__outter">
                            <input
                              name="textBilling"
                              value={filterChange.textBilling}
                              type="text"
                              className="Wizy__input__1"
                              onChange={handleFilterChange}
                            />
                            <img
                              src={wizy_mg_outline}
                              alt="wizy_mg_outline"
                              className="Wizy__input__1__image"
                            />
                          </div>
                          <div className="ChildrenSelector__billing__selector__inner__inner__stores__outter">
                            {billingMerchants.length > 0 ? (
                              billingMerchants.map((merchant: IShop) => {
                                return (
                                  <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__outter">
                                    <div></div>
                                    <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__domain">
                                      {merchant.domain}
                                    </div>
                                    <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__add">
                                      <button
                                        className="Wizy__button__2"
                                        style={{
                                          minWidth: "30px",
                                          height: "30px",
                                          marginTop: "5px",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() => {
                                          newBillingChild(merchant.id);
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                    <div></div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="ChildrenSelector__no__stores">
                                Nuthin' But A "G" Thang
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="ChildrenSelector__billing__selector__inner__outter">
                          <div className="ChildrenSelector__billing__selector__inner__inner__title">
                            {" "}
                            {t("Children", { ns: ["childrenSelector"] })}{" "}
                          </div>
                          <div
                            className="ChildrenSelector__billing__selector__inner__inner__stores__outter"
                            style={{ height: "190px" }}
                          >
                            {billingSelectedMerchants.length > 0 ? (
                              billingSelectedMerchants.map(
                                (merchant: IShop) => {
                                  return (
                                    <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__outter">
                                      <div></div>
                                      <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__domain">
                                        {merchant.domain}
                                      </div>
                                      <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__add">
                                        <button
                                          className="Wizy__button__5"
                                          style={{
                                            minWidth: "30px",
                                            height: "30px",
                                            marginTop: "5px",
                                            marginLeft: "5px",
                                          }}
                                          onClick={() => {
                                            deleteBillingChild(merchant.id);
                                          }}
                                        >
                                          x
                                        </button>
                                      </div>
                                      <div></div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="ChildrenSelector__no__stores">
                                Nuthin' But A "G" Thang
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ChildrenSelector__login__selector__outter">
                      <div className="ChildrenSelector__login__selector__outter">
                        <div className="ChildrenSelector__subtitle__title">
                          {" "}
                          {t("LoginChildren", { ns: ["childrenSelector"] })}
                        </div>
                        <div className="ChildrenSelector__login__selector__inner">
                          <div className="ChildrenSelector__billing__selector__inner__outter">
                            <div className="ChildrenSelector__billing__selector__inner__inner__title">
                              {" "}
                              {t("Shops", { ns: ["childrenSelector"] })}{" "}
                            </div>
                            <div className="Wizy__input__1__outter">
                              <input
                                name="textLogin"
                                value={filterChange.textLogin}
                                type="text"
                                className="Wizy__input__1"
                                onChange={handleFilterChange}
                              />
                              <img
                                src={wizy_mg_outline}
                                alt="wizy_mg_outline"
                                className="Wizy__input__1__image"
                              />
                            </div>
                            <div className="ChildrenSelector__billing__selector__inner__inner__stores__outter">
                              {loginMerchants.length > 0 ? (
                                loginMerchants.map((merchant: IShop) => {
                                  return (
                                    <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__outter">
                                      <div></div>
                                      <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__domain">
                                        {merchant.domain}
                                      </div>
                                      <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__add">
                                        <button
                                          className="Wizy__button__2"
                                          style={{
                                            minWidth: "30px",
                                            height: "30px",
                                            marginTop: "5px",
                                            marginLeft: "5px",
                                          }}
                                          onClick={() => {
                                            newLoginChild(merchant.id);
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>
                                      <div></div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="ChildrenSelector__no__stores">
                                  Nuthin' But A "G" Thang
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="ChildrenSelector__billing__selector__inner__outter">
                            <div className="ChildrenSelector__billing__selector__inner__inner__title">
                              {" "}
                              {t("Children", { ns: ["childrenSelector"] })}{" "}
                            </div>
                            <div
                              className="ChildrenSelector__billing__selector__inner__inner__stores__outter"
                              style={{ height: "190px" }}
                            >
                              {loginSelectedMerchants.length > 0 ? (
                                loginSelectedMerchants.map(
                                  (merchant: IShop) => {
                                    return (
                                      <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__outter">
                                        <div></div>
                                        <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__domain">
                                          {merchant.domain}
                                        </div>
                                        <div className="ChildrenSelector__billing__selector__inner__inner__stores__item__add">
                                          <button
                                            className="Wizy__button__5"
                                            style={{
                                              minWidth: "30px",
                                              height: "30px",
                                              marginTop: "5px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              deleteLoginChild(merchant.id);
                                            }}
                                          >
                                            x
                                          </button>
                                        </div>
                                        <div></div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div className="ChildrenSelector__no__stores">
                                  Nuthin' But A "G" Thang
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div style={{ backgroundColor: "#eff2f6" }}>
        <div
          className="ChildrenSelector__inner"
          style={{ backgroundColor: "#eff2f6" }}
        >
          <div
            className="CustomOffer__inner__inner"
            style={{ marginLeft: "0px" }}
          >
            <div className="Home__dashboard__indicators__indicator__outter">
              <div className="Home__dashboard__indicators__indicator__inner">
                <div className="Home__dashboard__indicators__indicator__inner__inner">
                  <img
                    src={wizy_loader}
                    className="ProtectedRoute__loader"
                    alt="ProtectedRoute__loader"
                    id="ProtectedRoute__loader"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// Default exported function
export default ChildrenSelector;
