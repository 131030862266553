// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, IContext } from "../context/Context";
import { useTranslation } from "react-i18next";

// Import media
import wizy_mg_outline from "../images/wizy_mg_outline.svg";
import wizy_add_outline from "../images/wizy_add_outline.svg";
import wizy_arrow_right_tail_outline from "../images/wizy_arrow_right_tail_outline.svg";
import wizy_export_filled from "../images/wizy_export_filled.svg";
import wizy_ticket_filled from "../images/wizy_ticket_filled.svg";
import wizy_client_filled from "../images/wizy_clients_filled.svg";
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";
import wizy_mail_filled from "../images/wizy_mail_filled.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_shield_filled from "../images/wizy_shield_filled.svg";
import wizy_gmail_logo from "../images/wizy_gmail_logo.svg";
import wizy_logo_blue from "../images/wizy_logo_blue.svg";
import wizy_description_outline from "../images/wizy_description_outline.svg";
import wizy_loader from "../images/wizy_loader.gif";

// Import styles
import "./styles/Tickets.css";

// Declare types and interfaces
import { ITicket } from "../types/TicketType";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import Pagination, { IPageMeta } from "../components/Pagination";
import { IShop } from "../types/ShopType";

enum ETicketsOutter {
  normal,
  filter,
  showcase,
}

type IFilter = {
  text: string;
  isSolved: boolean;
  isNotSolved: boolean;
  initialDate: string;
  finalDate: string;
};

// Page main functional component
const Tickets: FC = () => {
  // Use history
  const navigate = useNavigate();

  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["tickets"]);

  // Use state variables
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<ITicket>({
    id: "",
    email: "",
    createDate: "",
    state: false,
    description: "",
    selected: false,
  });
  const [filterChange, setFilterChange] = useState<IFilter>({
    text: "",
    isSolved: true,
    isNotSolved: true,
    initialDate: "",
    finalDate: "",
  });
  const [filter, setFilter] = useState<IFilter>({
    text: "",
    isSolved: true,
    isNotSolved: true,
    initialDate: "",
    finalDate: "",
  });
  const [allTicketsState, setAllTicketsState] = useState<boolean>(false);
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  // References needed
  const TicketsOutterRef = useRef<HTMLDivElement>(null);
  const ValidatorRef = useRef<IValidator>(null);

  // Internal Functions
  // Use Effect Functions
  useEffect(() => {
    getTickets(pageMeta.take, pageMeta.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ValidatorRef.current?.setApearance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  useEffect(() => {
    getTickets(pageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getTickets = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/tickets/" +
      globalShop.id +
      "/shop/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add filter queries to URL
    if (filter?.initialDate && filter.finalDate) {
      searchURL +=
        "&initialDate=" + filter.initialDate + "&finalDate=" + filter.finalDate;
    }
    if (filter?.text) {
      searchURL += "&text=" + filter.text;
    }
    searchURL +=
      "&isSolved=" + filter.isSolved + "&isNotSolved=" + filter.isNotSolved;
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setTickets([]);
        JSONresult.data.forEach((ticket: ITicket) => {
          setTickets((previous) => [
            ...previous,
            {
              id: ticket.id,
              email: ticket.email,
              createDate: ticket.createDate,
              state: ticket.state,
              description: ticket.description,
              selected: false,
            },
          ]);
        });
        setPageMeta({
          itemCount: JSONresult.meta.itemCount,
          pageCount: JSONresult.meta.pageCount,
          page: parseInt(JSONresult.meta.page),
          take: parseInt(JSONresult.meta.take),
          hasNextPage: JSONresult.meta.hasNextPage,
          hasPreviousPage: JSONresult.meta.hasPreviousPage,
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTicketsGrid = (state: ETicketsOutter) => {
    switch (state) {
      case ETicketsOutter.normal:
        if (TicketsOutterRef.current !== null) {
          TicketsOutterRef.current.style.gridTemplateColumns = "0px 1fr 0px";
        }
        break;
      case ETicketsOutter.filter:
        if (TicketsOutterRef.current !== null) {
          TicketsOutterRef.current.style.gridTemplateColumns = "320px 1fr 0px";
        }
        break;
      case ETicketsOutter.showcase:
        if (TicketsOutterRef.current !== null) {
          TicketsOutterRef.current.style.gridTemplateColumns = "0px 1fr 320px";
        }
        break;
      default:
        console.log("Upps");
    }
  };

  const handleTicketSelect = (ticket: ITicket) => {
    setSelectedTicket(ticket);
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleTicketToogle = (id: string, newValue: boolean) => {
    setTickets((prevData) => {
      return prevData.map((obj) => {
        if (obj.id === id) {
          return { ...obj, selected: newValue };
        }
        return obj;
      });
    });
  };

  const handleTicketToogleAll = (newValue: boolean) => {
    const selectedTickets = tickets;
    if (newValue) {
      setTickets((prevData) => {
        return prevData.map((obj) => {
          if (selectedTickets.some((ticket) => ticket.id === obj.id)) {
            return { ...obj, selected: newValue };
          }
          return obj;
        });
      });
    } else {
      setTickets((prevData) => {
        return prevData.map((obj) => {
          return { ...obj, selected: newValue };
        });
      });
    }
    setAllTicketsState(newValue);
  };

  const downloadCSV = () => {
    if (
      tickets.filter((val: ITicket) => {
        if (val.selected) {
          return val;
        }
        return false;
      }).length > 0
    ) {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        "ID;Email;Date;State;Description" +
        "\n" +
        tickets
          .filter((val: ITicket) => {
            if (val.selected) {
              return val;
            }
            return false;
          })
          .map((val: ITicket) => {
            return (
              String(val.id).replace(/[^\w\s-@]/gi, "") +
              ";" +
              String(val.email).replace(/[^\w\s-@]/gi, "") +
              ";" +
              String(val.createDate).replace(/[^\w\s-@]/gi, "") +
              ";" +
              String(val.state).replace(/[^\w\s-@]/gi, "") +
              ";" +
              String(val.description).replace(/[^\w\s-@]/gi, "")
            );
          })
          .join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tickets.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const toogleTicketState = async () => {
    await fetch(
      globalSelectedBackend + "/tickets/" + selectedTicket.id + "/state",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setTickets((previous) => {
          const updatedItems = [...previous];
          updatedItems[
            tickets.findIndex((ticket: ITicket) => {
              return selectedTicket.id === ticket.id;
            })
          ].state = JSONresult.status;
          return updatedItems;
        });
        setSelectedTicket((prevState) => ({
          ...prevState,
          state: JSONresult.status,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTicket = async () => {
    await fetch(globalSelectedBackend + "/tickets/" + selectedTicket.id, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt: t("Success", { ns: ["tickets"] }),
            validatorTitle: t("Validation", { ns: ["tickets"] }),
            functionOne: () => {
              handleTicketsGrid(ETicketsOutter.normal);
              setTickets((prevItems) =>
                prevItems.filter((item) => item.id !== selectedTicket.id)
              );
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: t("Error", { ns: ["tickets"] }),
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error", { ns: ["tickets"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  const getEmailURL = (
    type: string,
    globalShop: IShop,
    selectedTicket: ITicket
  ): string => {
    let emailURL = "";
    // Email temaplates
    const subjectTextDict: { [key: string]: any } = {
      English: "Ticket {{ticketId}} response - {{shopName}}",
      Spanish: "Respuesta al ticket {{ticketId}} - {{shopName}}",
      Portuguese: "Resposta ao ticket {{ticketId}} - {{shopName}}",
      German: "Ticket {{ticketId}} Antwort - {{shopName}}",
      French: "Réponse au ticket {{ticketId}} - {{shopName}}",
      Italian: "Rispondi al biglietto {{ticketId}} - {{shopName}}",
    };
    const bodyTextDict: { [key: string]: any } = {
      English:
        "Dear customer,%0a%0aWe hope this email finds you well. We are reaching out to you from {{shopName}} regarding your recent enquiry with the following details: %0a%0a{{ticketDescription}}%0a%0a%0a%0aBest regards,%0a%0aThe {{shopName}} Team%0a%0a{{shopMainDomain}}",
      Spanish:
        "Estimado cliente,%0a%0aEsperamos que este correo electrónico le encuentre bien. Nos estamos comunicando con usted desde {{shopName}} con respecto a su reciente consulta con los siguientes detalles: %0a%0a{{ticketDescription}}%0a%0a%0a%0aSaludos cordiales,%0a%0aEl equipo de {{shopName}}%0a%0a{{shopMainDomain}}",
      Portuguese:
        "Prezado cliente,%0a%0aEsperamos que este e-mail o encontre bem. Estamos entrando em contato com você de {{shopName}} em relação à sua recente consulta com os seguintes detalhes: %0a%0a{{ticketDescription}}%0a%0a%0a%0aAtenciosamente,%0a%0aA equipe {{shopName}}%0a%0a{{shopMainDomain}}",
      German:
        "Sehr geehrte Kundin, sehr geehrter Kunde,%0a%0aWir hoffen, diese E-Mail erreicht Sie wohlauf. Wir melden uns bei Ihnen von {{shopName}} bezüglich Ihrer kürzlichen Anfrage mit folgenden Details: %0a%0a{{ticketDescription}}%0a%0a%0a%0aMit freundlichen Grüßen,%0a%0aDas Team von {{shopName}}%0a%0a{{shopMainDomain}}",
      French:
        "Cher client,%0a%0aNous espérons que cet e-mail vous trouve bien. Nous vous contactons de la part de {{shopName}} concernant votre récente demande avec les détails suivants : %0a%0a{{ticketDescription}}%0a%0a%0a%0aCordialement,%0a%0aL'équipe de {{shopName}}%0a%0a{{shopMainDomain}}",
      Italian:
        "Gentile cliente,%0a%0aCi auguriamo che questa email ti trovi bene. Ti stiamo contattando da {{shopName}} in merito alla tua recente richiesta con i seguenti dettagli: %0a%0a{{ticketDescription}}%0a%0a%0a%0aCordiali saluti,%0a%0a Il {{shopName}} Squadra%0a%0a{{shopMainDomain}}",
    };
    const subjectText = subjectTextDict[
      globalShop.setup?.mainLanguage !== undefined
        ? globalShop.setup?.mainLanguage
        : ""
    ]
      .replace(new RegExp("{{ticketId}}", "g"), selectedTicket.id)
      .replace(new RegExp("{{shopName}}", "g"), globalShop.shopName);
    const bodyText = bodyTextDict[
      globalShop.setup?.mainLanguage !== undefined
        ? globalShop.setup?.mainLanguage
        : ""
    ]
      .replace(new RegExp("{{shopName}}", "g"), globalShop.shopName)
      .replace(
        new RegExp("{{ticketDescription}}", "g"),
        selectedTicket.description
      )
      .replace(
        new RegExp("{{shopMainDomain}}", "g"),
        globalShop.shopMainDomain
      );

    if (type === "GMAIL") {
      emailURL =
        "https://mail.google.com/mail/?view=cm&to=" +
        selectedTicket.email +
        "&su=" +
        subjectText +
        "&body=" +
        bodyText;
    } else if (type === "MAILAPP") {
      emailURL =
        "mailto:" +
        selectedTicket.email +
        "?subject=" +
        subjectText +
        "&body=" +
        bodyText;
    }
    return emailURL;
  };

  if (isLoaded) {
    // JSX Return statement
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        <div ref={TicketsOutterRef} className="Tickets__outter">
          <div className="Tickets__filters__outter">
            <div
              className="Tickets__filters__outter__close"
              onClick={() => {
                handleTicketsGrid(ETicketsOutter.normal);
              }}
            >
              {" "}
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="Tickets__filters__outter__close__image"
              />
            </div>
            <div className="Tickets__filters__inner">
              <div className="Tickets__filters__title">
                {" "}
                {t("Filters", { ns: ["tickets"] })}
              </div>
              <div className="Tickets__filters__description">
                {t("FiltersD", { ns: ["tickets"] })}
              </div>
              <div className="Tickets__filters__fields__outter">
                <div className="Wizy__input__title">
                  {t("Text", { ns: ["tickets"] })}
                </div>
                <div className="Wizy__input__1__outter">
                  <input
                    name="text"
                    value={filterChange.text}
                    type="text"
                    className="Wizy__input__1"
                    onChange={handleFilterChange}
                  />
                  <img
                    src={wizy_mg_outline}
                    alt="wizy_mg_outline"
                    className="Wizy__input__1__image"
                  />
                </div>
                <div className="Wizy__input__prompt__1">
                  {t("Eg", { ns: ["tickets"] })}
                </div>
                <div className="Wizy__input__title">
                  {t("State", { ns: ["tickets"] })}
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isNotCommercialized"
                    checked={filterChange.isSolved}
                    onChange={() => {
                      if (filterChange.isSolved) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSolved: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isSolved: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("Solved", { ns: ["tickets"] })}
                  </div>
                </div>
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isNotCommercialized"
                    checked={filterChange.isNotSolved}
                    onChange={() => {
                      if (filterChange.isNotSolved) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isNotSolved: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isNotSolved: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("Unsolved", { ns: ["tickets"] })}
                  </div>
                </div>
                <div className="Wizy__input__prompt__1">
                  {t("Actual", { ns: ["tickets"] })}
                </div>
                <div className="Wizy__input__title">
                  {t("Date", { ns: ["tickets"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.initialDate}
                  name="initialDate"
                  className="Wizy__input__date"
                  style={{ marginTop: "10px" }}
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateI", { ns: ["tickets"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.finalDate}
                  name="finalDate"
                  className="Wizy__input__date"
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateF", { ns: ["tickets"] })}
                </div>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setFilter(filterChange);
                    handleTicketToogleAll(false);
                  }}
                >
                  {t("Apply", { ns: ["tickets"] })}
                </button>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setFilter({
                      text: "",
                      isSolved: true,
                      isNotSolved: true,
                      initialDate: "",
                      finalDate: "",
                    });
                    setFilterChange({
                      text: "",
                      isSolved: true,
                      isNotSolved: true,
                      initialDate: "",
                      finalDate: "",
                    });
                    handleTicketToogleAll(false);
                  }}
                >
                  {t("Reset", { ns: ["tickets"] })}
                </button>
              </div>
            </div>
          </div>
          <div className="Tickets__list__outter">
            <div className="Tickets__list__inner">
              <div className="Tickets__list__title__outter">
                <div className="Tickets__list__title__inner">
                  <div className="Tickets__list__title__inner__inner">
                    <div className="Ticket__list__title__description">
                      <div className="Tickets__list__title__inner__text__title">
                        {t("Tickets", { ns: ["tickets"] })}
                      </div>
                      <div className="Tickets__list__title__inner__text__description">
                        {t("TicketsD", { ns: ["tickets"] })}
                      </div>
                    </div>
                    <div className="Tickets__list__arrow__outter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        preserveAspectRatio="none"
                      >
                        <polygon
                          points="29 66 28 66 38 33 28 0 29 0 39 33 29 66"
                          fill="#e6e9ed"
                        ></polygon>
                      </svg>
                    </div>
                    <div
                      className="Tickets__list__add__filters__outter"
                      onClick={() => {
                        handleTicketsGrid(ETicketsOutter.filter);
                      }}
                    >
                      <img
                        src={wizy_add_outline}
                        alt="wizy_add_outline"
                        className="Tickets__list__add__filters__image"
                      />
                      {t("Filters", { ns: ["tickets"] })}
                    </div>
                    <div className="Tickets__list__create__button__outter">
                      <div className="Tickets__list__create__button__inner">
                        <button
                          className="Wizy__button__4"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            navigate("/chat");
                          }}
                        >
                          <img
                            src={wizy_client_filled}
                            alt="wizy_add_outline"
                            className="Wizy__button__image"
                          />
                          {t("Clients", { ns: ["tickets"] })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Tickets__list__list__outter">
                <div className="Tickets__list__list__header__outter">
                  <div className="Tickets__list__list__header__inner">
                    <div className="Ticket__list__list__title__description">
                      <div className="Tickets__list__list__title__inner__text__title">
                        {t("Actions", { ns: ["tickets"] })}
                      </div>
                      <div className="Tickets__list__list__selected__outter">
                        <div className="Tickets__list__list__selected__number">
                          {
                            tickets.filter((val: ITicket) => {
                              if (val.selected) {
                                return val;
                              }
                              return false;
                            }).length
                          }
                        </div>
                        {t("Selected", { ns: ["tickets"] })}
                      </div>
                    </div>
                    <div className="Tickets__list__list__arrow__outter">
                      <img
                        src={wizy_arrow_right_tail_outline}
                        alt="wizy_arrow_right_tail_outline"
                        className="Tickets__list__list__arrow__image"
                      />
                    </div>
                    <div
                      className="Tickets__list__list__export__outter"
                      onClick={downloadCSV}
                    >
                      <img
                        src={wizy_export_filled}
                        alt="wizy_export_filled"
                        className="Tickets__list__list__export__image"
                        style={{
                          filter:
                            tickets.filter((val: ITicket) => {
                              if (val.selected) {
                                return val;
                              }
                              return false;
                            }).length > 0
                              ? "invert(27%) sepia(79%) saturate(2624%) hue-rotate(211deg) brightness(97%) contrast(110%)"
                              : "",
                        }}
                      />{" "}
                      <div
                        className="Tickets__list__list__export__text"
                        style={{
                          color:
                            tickets.filter((val: ITicket) => {
                              if (val.selected) {
                                return val;
                              }
                              return false;
                            }).length > 0
                              ? "#0566ff"
                              : "",
                        }}
                      >
                        {t("Export", { ns: ["tickets"] })}
                      </div>
                    </div>

                    {/* Pagination */}
                    <div className="Clients__list__list__pagination__outter">
                      <Pagination
                        pageMeta={pageMeta}
                        getElements={getTickets}
                      ></Pagination>
                    </div>
                  </div>
                </div>
                <div className="Tickets__list__list__titles__outter">
                  <div className="Tickets__list__list__titles__inner">
                    <div
                      className="Tickets__list__list__titles__inner__inner"
                      style={{ paddingLeft: "10px" }}
                    >
                      <input
                        type="checkbox"
                        name="isNotCommercialized"
                        checked={allTicketsState}
                        onChange={() => {
                          handleTicketToogleAll(!allTicketsState);
                        }}
                      />
                    </div>
                    <div className="Tickets__list__list__titles__inner__inner">
                      {t("Code", { ns: ["tickets"] })}
                    </div>
                    <div className="Tickets__list__list__titles__inner__inner">
                      {t("Email", { ns: ["tickets"] })}
                    </div>
                    <div
                      className="Tickets__list__list__titles__inner__inner"
                      id="Tickets__date__field"
                    >
                      {t("DateC", { ns: ["tickets"] })}
                    </div>
                    <div
                      className="Tickets__list__list__titles__inner__inner"
                      id="Tickets__state__field"
                    >
                      {t("State", { ns: ["tickets"] })}
                    </div>
                    <div
                      className="Tickets__list__list__titles__inner__inner"
                      id="Tickets__description__field"
                    >
                      {t("Description", { ns: ["tickets"] })}
                    </div>
                  </div>
                </div>
                <div className="Tickets__list__list__items__outter">
                  {tickets
                    .sort(
                      (a: ITicket, b: ITicket) =>
                        Date.parse(b.createDate) - Date.parse(a.createDate)
                    )
                    .map((ticket, index) => {
                      return (
                        <div
                          className="Tickets__list__list__item__outter"
                          onClick={() => {
                            handleTicketSelect(ticket);
                            handleTicketsGrid(ETicketsOutter.showcase);
                          }}
                          key={index}
                        >
                          <div className="Tickets__list__list__item__inner">
                            <div
                              className="Tickets__list__list__item__inner__inner"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                type="checkbox"
                                name="isNotCommercialized"
                                checked={ticket.selected}
                                onChange={() => {
                                  handleTicketToogle(
                                    ticket.id,
                                    !ticket.selected
                                  );
                                }}
                              />
                            </div>
                            <div className="Tickets__list__list__code__inner__inner">
                              <img
                                src={wizy_ticket_filled}
                                alt="wizy_ticket_filled"
                                className="Tickets__list__list__code__inner__inner__image"
                              />
                              <div className="Tickets__list__list__code__inner__inner__text">
                                {ticket.id}
                              </div>
                            </div>
                            <div
                              className="Tickets__list__list__item__inner__inner"
                              style={{ color: "#0566ff" }}
                            >
                              {ticket.email}
                            </div>
                            <div
                              className="Tickets__list__list__item__inner__inner"
                              id="Tickets__date__field"
                            >
                              {formatDate2(new Date(ticket.createDate))}
                            </div>
                            <div
                              className="Tickets__list__list__id__inner__inner"
                              id="Tickets__state__field"
                            >
                              <div className="Tickets__list__list__state__inner__inner">
                                {ticket.state
                                  ? t("Solved", { ns: ["tickets"] })
                                  : t("Unsolved", { ns: ["tickets"] })}
                              </div>
                            </div>
                            <div
                              className="Tickets__list__list__item__inner__inner"
                              id="Tickets__description__field"
                            >
                              {ticket.description}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="Tickets__showcase__outter">
            <div
              className="Tickets__showcase__outter__close"
              onClick={() => {
                handleTicketsGrid(ETicketsOutter.normal);
              }}
            >
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="Tickets__showcase__outter__close__image"
              />
            </div>
            <div className="Tickets__showcase__inner">
              <div className="Tickets__showcase__information__outter">
                <div className="Tickets__showcase__infotmation__item__outter">
                  <div className="Tickets__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_ticket_filled}
                        alt="wizy_add_outline"
                        className="Tickets__showcase__information__item__image"
                      />
                    </div>
                    <div className="Tickets__showcase__information__item__text">
                      {selectedTicket.id}
                    </div>
                  </div>
                  <div className="Tickets__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_mail_filled}
                        alt="wizy_add_outline"
                        className="Tickets__showcase__information__item__image"
                      />
                    </div>
                    <div className="Tickets__showcase__information__item__text">
                      {selectedTicket.email}
                    </div>
                  </div>
                  <div className="Tickets__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_calendar_filled}
                        alt="wizy_add_outline"
                        className="Tickets__showcase__information__item__image"
                      />
                    </div>
                    <div className="Tickets__showcase__information__item__text">
                      {formatDate2(new Date(selectedTicket.createDate))}
                    </div>
                  </div>
                  <div className="Tickets__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_shield_filled}
                        alt="wizy_add_outline"
                        className="Tickets__showcase__information__item__image"
                      />
                    </div>
                    <div className="Tickets__showcase__information__item__text">
                      {selectedTicket.state
                        ? t("Solved", { ns: ["tickets"] })
                        : t("Unsolved", { ns: ["tickets"] })}
                    </div>
                  </div>
                  <div className="Tickets__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_description_outline}
                        alt="wizy_add_outline"
                        className="Tickets__showcase__information__item__image"
                      />
                    </div>
                    <div className="Tickets__showcase__information__item__text">
                      {selectedTicket.description}
                    </div>
                  </div>
                </div>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setValidatorProps({
                      validatorPrompt: t("RespondPrompt", {
                        ns: ["tickets"],
                      }),
                      validatorTitle: t("Respond using Wizybot", {
                        ns: ["tickets"],
                      }),
                      functionOne: () => {
                        navigate("/chat?ticketId=" + selectedTicket.id);
                      },
                      functionOnePrompt: t("Go to chat", {
                        ns: ["tickets"],
                      }),
                      functionTwo: () => {},
                      functionTwoPrompt: t("Go back", {
                        ns: ["tickets"],
                      }),
                      functionNumber: 2,
                    });
                  }}
                >
                  <div className="Tickets__email__button__content">
                    {t("Respond using Wizybot", { ns: ["tickets"] })}
                    <div className="Tickets__emailbutton__image__outter">
                      <img
                        src={wizy_logo_blue}
                        alt="wizy_logo_blue"
                        className="Tickets__emailbutton__image"
                      ></img>
                    </div>
                  </div>
                </button>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{
                    width: "100%",
                  }}
                  onClick={() =>
                    window.open(
                      getEmailURL("GMAIL", globalShop, selectedTicket),
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  <div className="Tickets__email__button__content">
                    {t("Respond using Gmail", { ns: ["tickets"] })}
                    <div className="Tickets__emailbutton__image__outter">
                      <img
                        src={wizy_gmail_logo}
                        alt="wizy_gmail_logo"
                        width={"20px"}
                        height={"20px"}
                      ></img>
                    </div>
                  </div>
                </button>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{
                    width: "100%",
                  }}
                  onClick={() =>
                    (window.location.href = getEmailURL(
                      "MAILAPP",
                      globalShop,
                      selectedTicket
                    ))
                  }
                >
                  <div className="Tickets__email__button__content">
                    {t("Respond using Mail App", { ns: ["tickets"] })}
                    <div className="Tickets__emailbutton__image__outter">
                      <img
                        src={wizy_mail_filled}
                        alt="wizy_mail_filled"
                        className="Tickets__emailbutton__image"
                      ></img>
                    </div>
                  </div>
                </button>
              </div>
              <div
                className="Tickets__apply__button__outter"
                onClick={() => {
                  navigate("/chat?ticketId=" + selectedTicket.id);
                }}
              >
                <button className="Wizy__button__3" style={{ width: "100%" }}>
                  {t("View", { ns: ["tickets"] })}
                </button>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={toogleTicketState}
                >
                  {t("Mark", { ns: ["tickets"] })}{" "}
                  {selectedTicket.state
                    ? t("Unsolved1", { ns: ["tickets"] })
                    : t("Solved1", { ns: ["tickets"] })}
                </button>
              </div>
              <div className="Tickets__apply__button__outter">
                <button
                  className="Wizy__button__5"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setValidatorProps({
                      validatorPrompt: t("DeletionD", {
                        ns: ["tickets"],
                      }),
                      validatorTitle: t("Deletion", {
                        ns: ["tickets"],
                      }),
                      functionOne: () => {
                        deleteTicket();
                      },
                      functionOnePrompt: "OK",
                      functionTwo: () => {},
                      functionTwoPrompt: "NO",
                      functionNumber: 2,
                    });
                  }}
                >
                  {t("Delete", { ns: ["tickets"] })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default Tickets;
