// Import React Dependencies
import React, { FC } from "react";

// Import styles
import "./styles/Add2CartLiveChat.css";

// Declare types and interfaces
import {
  EAddToCartStateActions,
  IAddToCartContent,
} from "../types/MessageType";


import { EMainLanguage } from "./ShopifyWidget";
type Add2CartProps = {
  content: IAddToCartContent;
  language: string;
};

const EAdd2CartNoneMessage: { [key in EMainLanguage]: string } = {
  [EMainLanguage.ENGLISH]:
    'An "Add to Cart form" for the product <strong>{{title}}</strong> was sent to the customer',
  [EMainLanguage.SPANISH]:
    'Un formulario de "Agregar al Carrito" para el producto <strong>{{title}}</strong> fue enviado al cliente',
  [EMainLanguage.PORTUGUESE]:
    'Um formulário de "Adicionar ao Carrinho" para o produto <strong>{{title}}</strong> foi enviado ao cliente',
  [EMainLanguage.GERMAN]:
    'Ein "In den Warenkorb"-Formular für das Produkt <strong>{{title}}</strong> wurde an den Kunden gesendet',
  [EMainLanguage.FRENCH]:
    'Un formulaire "Ajouter au panier" pour le produit <strong>{{title}}</strong> a été envoyé au client',
  [EMainLanguage.ITALIAN]:
    'Un modulo "Aggiungi al Carrello" per il prodotto <strong>{{title}}</strong> è stato inviato al cliente',
};

const EAdd2CartBoughtMessage: { [key in EMainLanguage]: string } = {
  [EMainLanguage.ENGLISH]:
    'An "Add to Cart form" for the product <strong>{{title}}</strong> was sent to the customer. The customer selected the variant <strong>{{selectedOptionValues}} (Quantity: {{selectedQuantity}})</strong>. Wizybot provided a Checkout link',
  [EMainLanguage.SPANISH]:
    'Se envió un formulario de "Agregar al Carrito" para el producto <strong>{{title}}</strong> al cliente. El cliente seleccionó la variante <strong>{{selectedOptionValues}} (Cantidad: {{selectedQuantity}})</strong>. Wizybot proporcionó un enlace de Pago',
  [EMainLanguage.PORTUGUESE]:
    'Um formulário de "Adicionar ao Carrinho" para o produto <strong>{{title}}</strong> foi enviado ao cliente. O cliente selecionou a variante <strong>{{selectedOptionValues}} (Quantidade: {{selectedQuantity}})</strong>. Wizybot forneceu um link de Checkout',
  [EMainLanguage.GERMAN]:
    'Ein "In den Warenkorb"-Formular für das Produkt <strong>{{title}}</strong> wurde an den Kunden gesendet. Der Kunde wählte die Variante <strong>{{selectedOptionValues}} (Menge: {{selectedQuantity}})</strong>. Wizybot stellte einen Checkout-Link zur Verfügung',
  [EMainLanguage.FRENCH]:
    'Un formulaire "Ajouter au panier" pour le produit <strong>{{title}}</strong> a été envoyé au client. Le client a sélectionné la variante <strong>{{selectedOptionValues}} (Quantité : {{selectedQuantity}})</strong>. Wizybot a fourni un lien de paiement',
  [EMainLanguage.ITALIAN]:
    'Un modulo "Aggiungi al Carrello" per il prodotto <strong>{{title}}</strong> è stato inviato al cliente. Il cliente ha selezionato la variante <strong>{{selectedOptionValues}} (Quantità: {{selectedQuantity}})</strong>. Wizybot ha fornito un link per il Checkout',
};

const EAdd2CartAddedMessage: { [key in EMainLanguage]: string } = {
  [EMainLanguage.ENGLISH]:
    'An "Add to Cart form" for the product <strong>{{title}}</strong> was sent to the customer. The customer selected the variant <strong>{{selectedOptionValues}} (Quantity: {{selectedQuantity}})</strong> and added it to the cart',
  [EMainLanguage.SPANISH]:
    'Se envió un formulario de "Agregar al Carrito" para el producto <strong>{{title}}</strong> al cliente. El cliente seleccionó la variante <strong>{{selectedOptionValues}} (Cantidad: {{selectedQuantity}})</strong> y lo añadió al carrito',
  [EMainLanguage.PORTUGUESE]:
    'Um formulário de "Adicionar ao Carrinho" para o produto <strong>{{title}}</strong> foi enviado ao cliente. O cliente selecionou a variante <strong>{{selectedOptionValues}} (Quantidade: {{selectedQuantity}})</strong> e adicionou ao carrinho',
  [EMainLanguage.GERMAN]:
    'Ein "In den Warenkorb"-Formular für das Produkt <strong>{{title}}</strong> wurde an den Kunden gesendet. Der Kunde wählte die Variante <strong>{{selectedOptionValues}} (Menge: {{selectedQuantity}})</strong> und fügte sie zum Warenkorb hinzu',
  [EMainLanguage.FRENCH]:
    'Un formulaire "Ajouter au panier" pour le produit <strong>{{title}}</strong> a été envoyé au client. Le client a sélectionné la variante <strong>{{selectedOptionValues}} (Quantité : {{selectedQuantity}})</strong> et l\'a ajoutée au panier',
  [EMainLanguage.ITALIAN]:
    'Un modulo "Aggiungi al Carrello" per il prodotto <strong>{{title}}</strong> è stato inviato al cliente. Il cliente ha selezionato la variante <strong>{{selectedOptionValues}} (Quantità: {{selectedQuantity}})</strong> e l\'ha aggiunta al carrello',
};


// Page main functional component
const Add2CartLiveChat: FC<Add2CartProps> = (props) => {
  // JSX Return statement

  // Helps you find the value for a nested array
  const getValueFromNestedArray = (
    nestedArray: any[],
    position: number[]
  ): any => {
    if (position.length === 1) {
      return nestedArray[position[0]];
    } else {
      const [head, ...tail] = position;
      return getValueFromNestedArray(nestedArray[head], tail);
    }
  };

  const convertToHTML = (text: string): JSX.Element => {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  };

  return (
    <React.Fragment>
      <div className="Add2CartLiveChat__message__outter">
        <div
          className="Add2CartLiveChat__message__inner"
          style={{
            color: "black",
          }}
        >
          {" "}
          {convertToHTML(
            props.content.stateAction === EAddToCartStateActions.NONE
              ? EAdd2CartNoneMessage[props.language as EMainLanguage].replace(
                  "{{title}}",
                  props.content.title
                )
              : props.content.stateAction === EAddToCartStateActions.BOUGHT
              ? EAdd2CartBoughtMessage[props.language as EMainLanguage]
                  .replace("{{title}}", props.content.title)
                  .replace(
                    "{{selectedOptionValues}}",
                    props.content.options
                      .map((option, i) => {
                        return (
                          option.name +
                          ": " +
                          option.values[
                            props.content.stateSelection.selectedOptionValues[i]
                          ]
                        );
                      })
                      .join(", ")
                  )
                  .replace(
                    "{{selectedQuantity}}",
                    props.content.stateSelection.selectedQuantity
                  )
              : props.content.stateAction ===
                EAddToCartStateActions.ADDED_TO_CART
              ? EAdd2CartAddedMessage[props.language as EMainLanguage]
                  .replace("{{title}}", props.content.title)
                  .replace(
                    "{{selectedOptionValues}}",
                    props.content.options
                      .map((option, i) => {
                        return (
                          option.name +
                          ": " +
                          option.values[
                            props.content.stateSelection.selectedOptionValues[i]
                          ]
                        );
                      })
                      .join(", ")
                  )
                  .replace(
                    "{{selectedQuantity}}",
                    props.content.stateSelection.selectedQuantity
                  )
              : "XD"
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default Add2CartLiveChat;
