// Import React dependencies
import React, { FC, useState, useEffect } from "react";
import { AppContext, IContext } from "../context/Context";
import { useTranslation } from "react-i18next";

// Import media
import wizy_ticket_filled from "../images/wizy_ticket_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";
import wizy_clients_filled from "../images/wizy_clients_filled.svg";
import wizy_loader from "../images/wizy_loader.gif";
import wizy_cart_filled from "../images/wizy_cart_filled.svg";
import wizy_dollar_filled from "../images/wizy_dollar_filled.svg";

// Import styles
import "./styles/HomeAdmin.css";

export type IAdminIndicators = {
  installedShopsCount: number;
  paidShopsCount: number;
  messagesCount: number;
  clientsCount: number;
  ticketsCount: number;
  monthlyOutboundCount: number;
  monthlyMessagesCount: number;
  monthlyClientsCount: number;
  monthlyTicketsCount: number;
  monthlyTokens: number;
  monthlyCostPerMessage: number;
  weeklyActiveUsers: number;
  monthlyRecurrentRevenue: number;
  monthlyTotalRevenue: number;
  averageQualityScore: number;
  monthlyGeneratedSales: number;
  totalGeneratedSales: number;
  averageRevenuePerAccount: number;
  weeklyReviewedChats: number;
  weeklyReviewedOrders: number;
  percentageOfCachedMessagesLastWeek: number;
};

// Page main functional component

const HomeAdmin: FC = () => {
  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  // Use translation
  const { t } = useTranslation(["homeAdmin"]);

  // UseState variables
  const [indicators, setIndicators] = useState<IAdminIndicators>({
    installedShopsCount: 0,
    weeklyActiveUsers: 0,
    paidShopsCount: 0,
    monthlyRecurrentRevenue: 0,
    monthlyTotalRevenue: 0,
    averageRevenuePerAccount: 0,
    monthlyMessagesCount: 0,
    monthlyClientsCount: 0,
    monthlyTicketsCount: 0,
    messagesCount: 0,
    clientsCount: 0,
    ticketsCount: 0,
    monthlyTokens: 0,
    monthlyCostPerMessage: 0,
    averageQualityScore: 0,
    monthlyOutboundCount: 0,
    monthlyGeneratedSales: 0,
    totalGeneratedSales: 0,
    weeklyReviewedChats: 0,
    weeklyReviewedOrders: 0,
    percentageOfCachedMessagesLastWeek: 0,
  });
  const indicatorIcons: { alt: string; src: string }[] = [
    { alt: "wizy_cart_filled", src: wizy_cart_filled },
    { alt: "wizy_cart_filled", src: wizy_cart_filled },
    { alt: "wizy_cart_filled", src: wizy_cart_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_chat_filled", src: wizy_chat_filled },
    { alt: "wizy_clients_filled", src: wizy_clients_filled },
    { alt: "wizy_ticket_filled", src: wizy_ticket_filled },
    { alt: "wizy_chat_filled", src: wizy_chat_filled },
    { alt: "wizy_clients_filled", src: wizy_clients_filled },
    { alt: "wizy_ticket_filled", src: wizy_ticket_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_chat_filled", src: wizy_chat_filled },
    { alt: "wizy_chat_filled", src: wizy_chat_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_clients_filled", src: wizy_chat_filled },
    { alt: "wizy_dollar_filled", src: wizy_dollar_filled },
    { alt: "wizy_chat_filled", src: wizy_dollar_filled },
  ];
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Use effect functions

  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboard = async () => {
    await fetch(globalSelectedBackend + "/admin/dashboard", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setIndicators({
          installedShopsCount: JSONresult.installedShopsCount,
          weeklyActiveUsers: JSONresult.weeklyActiveUsers,
          paidShopsCount: JSONresult.paidShopsCount,
          monthlyRecurrentRevenue: JSONresult.monthlyRecurrentRevenue,
          monthlyTotalRevenue: JSONresult.monthlyTotalRevenue,
          averageRevenuePerAccount: JSONresult.averageRevenuePerAccount,
          monthlyMessagesCount: JSONresult.monthlyMessagesCount,
          monthlyClientsCount: JSONresult.monthlyClientsCount,
          monthlyTicketsCount: JSONresult.monthlyTicketsCount,
          messagesCount: JSONresult.messagesCount,
          clientsCount: JSONresult.clientsCount,
          ticketsCount: JSONresult.ticketsCount,
          monthlyTokens: JSONresult.monthlyTokens,
          monthlyCostPerMessage: JSONresult.monthlyCostPerMessage,
          averageQualityScore: JSONresult.averageQualityScore,
          monthlyOutboundCount: JSONresult.monthlyOutboundCount,
          monthlyGeneratedSales: JSONresult.monthlyGeneratedSales,
          totalGeneratedSales: JSONresult.totalGeneratedSales,
          weeklyReviewedChats: JSONresult.weeklyReviewedChats,
          weeklyReviewedOrders: JSONresult.weeklyReviewedOrders,
          percentageOfCachedMessagesLastWeek: JSONresult.percentageOfCachedMessagesLastWeek
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {isLoaded ? (
        <div className="HomeAdmin__dashboard__outter">
          <div className="HomeAdmin__dashboard__inner">
            <div className="HomeAdmin__dashboard__indicators__outter">
              <div className="HomeAdmin__dashboard__indicators__indicators__outter">
                {Object.keys(indicators).map((indicatorKey, i) => (
                  <div className="HomeAdmin__dashboard__indicators__indicator__outter">
                    <div className="HomeAdmin__dashboard__indicators__indicator__inner">
                      <div className="HomeAdmin__dashboard__indicators__indicator__inner__inner">
                        <div className="HomeAdmin__dashboard__indicators__indicator__inner__innner__count">
                          {Object.values(indicators)[i]}{" "}
                          <div className="HomeAdmin__dashboard__indicators__indicator__inner__inner__image__outter">
                            <img
                              src={indicatorIcons[i].src}
                              alt={indicatorIcons[i].alt}
                              className="HomeAdmin__dashboard__indicators__indicator__inner__inner__image"
                            />
                          </div>
                        </div>
                        <div className="HomeAdmin__dashboard__indicators__indicator__inner__innner__name">
                          {t(indicatorKey, { ns: ["homeAdmin"] })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="Home__setup__outter">
          <img
            src={wizy_loader}
            className="ProtectedRoute__loader"
            alt="ProtectedRoute__loader"
            id="ProtectedRoute__loader"
          />
        </div>
      )}
    </React.Fragment>
  );
};

// Default exported function

export default HomeAdmin;
