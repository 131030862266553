// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Import media
import wizy_shield_check_filled from "../images/wizy_shield_check_filled.svg";
import wizy_loader from "../images/wizy_loader.gif";

// Import styles
import "./styles/Billing.css";
import { AppContext, IContext } from "../context/Context";
import { useNavigate, useParams } from "react-router-dom";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { EStandardPlansName, IPlan } from "../types/PlanType";

// Page main functional component
const Billing: FC = () => {
  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["billing"]);

  // Use params
  const { plan } = useParams<{
    plan: string | undefined;
  }>();

  // Use state variables
  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const [currentPlan, setCurrentPlan] = useState<IPlan>(emptyPlan);
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [standardPlans, setStandardPlans] = useState<IPlan[]>([]);
  const [customPlan, setCustomPlan] = useState<IPlan>(emptyPlan);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Use history
  const navigate = useNavigate();

  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  useEffect(() => {
    getAvailableStandardPlans();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    ValidatorRef.current?.setApearance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  const setMessage = async () => {
    if (
      globalShop.parentShopId === null ||
      globalShop.parentShopId === globalShop.id
    ) {
      if (plan !== undefined) {
        if (plan === "error") {
          setValidatorProps({
            validatorPrompt: t("Error", { ns: ["billing"] }),
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt:
              i18next.language === "en"
                ? "The " +
                  plan.charAt(0).toUpperCase() +
                  plan.slice(1) +
                  " plan was activated successfully"
                : "El plan " +
                  plan.charAt(0).toUpperCase() +
                  plan.slice(1) +
                  " fue activado exitosamente",
            validatorTitle: t("Activation", { ns: ["billing"] }),
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      }
    } else {
      setValidatorProps({
        validatorPrompt: t("ChildrenRestrictionPrompt", { ns: ["billing"] }),
        validatorTitle: t("ChildrenRestriction", { ns: ["billing"] }),
        functionOne: () => {
          navigate("/home");
        },
        functionOnePrompt: "OK",
        functionTwo: () => {},
        functionTwoPrompt: "",
        functionNumber: 1,
      });
    }
  };

  const getAvailableStandardPlans = async () => {
    await fetch(globalSelectedBackend + "/plans/availablestandardplans", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        JSONresult.forEach((plan: IPlan) => {
          setStandardPlans((previous) => [
            ...previous,
            {
              id: plan.id,
              name: plan.name,
              messagesPerMonth: plan.messagesPerMonth,
              monthlyPrice: plan.monthlyPrice,
              additionalMessagePrice: plan.additionalMessagePrice,
              showcaseText: "",
              isCustom: false,
              isAnnual: false,
            },
          ]);
        });
        getShopPlans();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShopPlans = async () => {
    await fetch(globalSelectedBackend + "/plans/shopplans/" + globalShop.id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCurrentPlan(JSONresult.currentPlan);
        if (JSONresult.customOfferPlan) {
          setCustomPlan(JSONresult.customOfferPlan);
        }
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changePlan = (plan: IPlan) => {
    window.location.replace(
      globalSelectedBackend +
        "/billings?shopId=" +
        globalShop.id +
        "&&plan=" +
        plan.name
    );
  };

  const goBacktoFree = async () => {
    await fetch(globalSelectedBackend + "/billings/" + globalShop.id, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult);
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt: t("FreeA", { ns: ["billing"] }),
            validatorTitle: t("Deactivation", { ns: ["billing"] }),
            functionOne: () => {
              window.location.reload();
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: t("Error1", { ns: ["billing"] }),
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error2", { ns: ["billing"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        <div className="Billing__outter">
          <div className="Billing__inner">
            <div className="Billing__plans__outter">
              {standardPlans
                .filter((plan) => {
                  if (
                    plan.name === "free" &&
                    globalShop.currentPlan.name === "free"
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .map((plan) => {
                  return (
                    <div className="Billing__plan__outter">
                      <div className="Billing__plan__inner">
                        <div className="Billing__plan__inner__info">
                          <div className="Billing__plan__title">
                            {t(capitalizeFirstLetter(plan.name), {
                              ns: ["billing"],
                            })}
                          </div>
                          <div className="Billing__plan__description">
                            {t(capitalizeFirstLetter(plan.name) + "D", {
                              ns: ["billing"],
                            })}
                          </div>
                          <div className="Billing__plan__attributes__outter">
                            <div className="Billing__plan__attribute">
                              <div className="Billing__plan__attribute__image__outter">
                                <img
                                  src={wizy_shield_check_filled}
                                  alt="wizy_shop_bag_filled"
                                  className="Billing__plan__attribute__image"
                                />
                              </div>
                              <div className="Billing__plan__attrinute__text">
                                {t("UpTo", { ns: ["billing"] })}{" "}
                                {plan.messagesPerMonth}{" "}
                                {t("Inbound", { ns: ["billing"] })}
                              </div>
                            </div>
                            <div className="Billing__plan__attribute">
                              <div className="Billing__plan__attribute__image__outter">
                                <img
                                  src={wizy_shield_check_filled}
                                  alt="wizy_shop_bag_filled"
                                  className="Billing__plan__attribute__image"
                                />
                              </div>
                              <div className="Billing__plan__attrinute__text">
                                {t("Unlimited", { ns: ["billing"] })}{" "}
                                {t("Outbound", { ns: ["billing"] })}
                              </div>
                            </div>
                          </div>
                          <div className="Billing__plan__attributes__outter">
                            <div className="Billing__plan__attribute">
                              <div className="Billing__plan__attribute__image__outter">
                                <img
                                  src={wizy_shield_check_filled}
                                  alt="wizy_shop_bag_filled"
                                  className="Billing__plan__attribute__image"
                                />
                              </div>
                              <div className="Billing__plan__attrinute__text">
                                {t("Unlimited", { ns: ["billing"] })}{" "}
                                {t("Clients", { ns: ["billing"] })}
                              </div>
                            </div>
                            <div className="Billing__plan__attribute">
                              <div className="Billing__plan__attribute__image__outter">
                                <img
                                  src={wizy_shield_check_filled}
                                  alt="wizy_shop_bag_filled"
                                  className="Billing__plan__attribute__image"
                                />
                              </div>
                              <div className="Billing__plan__attrinute__text">
                                {t("LiveM", { ns: ["billing"] })}
                              </div>
                            </div>
                          </div>
                          <div className="Billing__plan__attributes__outter">
                            <div className="Billing__plan__attribute">
                              <div className="Billing__plan__attribute__image__outter">
                                <img
                                  src={wizy_shield_check_filled}
                                  alt="wizy_shop_bag_filled"
                                  className="Billing__plan__attribute__image"
                                />
                              </div>
                              <div className="Billing__plan__attrinute__text">
                                {t("Unlimited", { ns: ["billing"] })}{" "}
                                {t("Tickets", { ns: ["billing"] })}
                              </div>
                            </div>
                            <div className="Billing__plan__attribute">
                              <div className="Billing__plan__attribute__image__outter">
                                <img
                                  src={wizy_shield_check_filled}
                                  alt="wizy_shop_bag_filled"
                                  className="Billing__plan__attribute__image"
                                />
                              </div>
                              <div className="Billing__plan__attrinute__text">
                                {t("LiveC", { ns: ["billing"] })}
                              </div>
                            </div>
                          </div>
                        </div>
                        {plan.name === EStandardPlansName.FREE ? (
                          <div className="Billing__plan__inner__price">
                            <div className="Billing__plan__inner__price__inner">
                              <div className="Billing__plan__price__value">
                                <div className="Billing__plan__price__value__inner">
                                  {t("FreeP", { ns: ["billing"] })}
                                </div>
                              </div>
                              <div className="Billing__plan__price__description">
                                {t("FreePD", { ns: ["billing"] })}
                              </div>
                              <div className="Billing__button__outter">
                                {currentPlan.name === EStandardPlansName.FREE ||
                                currentPlan.name ===
                                  EStandardPlansName.FROZEN ? (
                                  <button
                                    className="Wizy__button__3"
                                    style={{ width: "100%" }}
                                  >
                                    {t("Actual", { ns: ["billing"] })}
                                  </button>
                                ) : (
                                  <button
                                    className="Wizy__button__3"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      setValidatorProps({
                                        validatorPrompt: t("Membership", {
                                          ns: ["billing"],
                                        }),
                                        validatorTitle: t("Deactivation", {
                                          ns: ["billing"],
                                        }),
                                        functionOne: () => {
                                          goBacktoFree();
                                        },
                                        functionOnePrompt: "OK",
                                        functionTwo: () => {},
                                        functionTwoPrompt: "NO",
                                        functionNumber: 2,
                                      });
                                    }}
                                  >
                                    {t("Back", { ns: ["billing"] })}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="Billing__plan__inner__price">
                            <div className="Billing__plan__inner__price__inner">
                              <div className="Billing__plan__price__value">
                                <div className="Billing__plan__price__value__inner">
                                  {plan.monthlyPrice}
                                </div>
                                <div className="Billing__plan__currency__outter">
                                  <div className="Billing__plan__currency__inner">
                                    USD
                                  </div>
                                  <div className="Billing__plan__period__inner">
                                    {t("Monthly", { ns: ["billing"] })}
                                  </div>
                                </div>
                              </div>
                              <div className="Billing__plan__price__description">
                                {t("AdditionalMessagePricing", {
                                  ns: ["billing"],
                                })
                                  .replace(
                                    "{additionalMessagePrice}",
                                    plan.additionalMessagePrice.toString()
                                  )
                                  .replace(
                                    "{messagesPerMonth}",
                                    plan.messagesPerMonth.toString()
                                  )}
                              </div>
                              <div className="Billing__button__outter">
                                {currentPlan.name === plan.name ? (
                                  <button
                                    className="Wizy__button__3"
                                    style={{ width: "100%" }}
                                  >
                                    {t("Actual", { ns: ["billing"] })}
                                  </button>
                                ) : (
                                  <button
                                    className="Wizy__button__2"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      changePlan(plan);
                                    }}
                                  >
                                    {t("SignUp", { ns: ["billing"] })}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {customPlan.name !== "" ? (
                <div className="Billing__plan__outter">
                  <div className="Billing__plan__inner">
                    <div className="Billing__plan__inner__info">
                      <div className="Billing__plan__title">
                        {t(capitalizeFirstLetter(customPlan.name), {
                          ns: ["billing"],
                        })}
                      </div>
                      <div className="Billing__plan__description">
                        {customPlan.showcaseText}
                      </div>
                      <div className="Billing__plan__attributes__outter">
                        <div className="Billing__plan__attribute">
                          <div className="Billing__plan__attribute__image__outter">
                            <img
                              src={wizy_shield_check_filled}
                              alt="wizy_shop_bag_filled"
                              className="Billing__plan__attribute__image"
                            />
                          </div>
                          <div className="Billing__plan__attrinute__text">
                            {t("UpTo", { ns: ["billing"] })}{" "}
                            {customPlan.messagesPerMonth}{" "}
                            {t("Inbound", { ns: ["billing"] })}
                          </div>
                        </div>
                        <div className="Billing__plan__attribute">
                          <div className="Billing__plan__attribute__image__outter">
                            <img
                              src={wizy_shield_check_filled}
                              alt="wizy_shop_bag_filled"
                              className="Billing__plan__attribute__image"
                            />
                          </div>
                          <div className="Billing__plan__attrinute__text">
                            {t("Unlimited", { ns: ["billing"] })}{" "}
                            {t("Outbound", { ns: ["billing"] })}
                          </div>
                        </div>
                      </div>
                      <div className="Billing__plan__attributes__outter">
                        <div className="Billing__plan__attribute">
                          <div className="Billing__plan__attribute__image__outter">
                            <img
                              src={wizy_shield_check_filled}
                              alt="wizy_shop_bag_filled"
                              className="Billing__plan__attribute__image"
                            />
                          </div>
                          <div className="Billing__plan__attrinute__text">
                            {t("Unlimited", { ns: ["billing"] })}{" "}
                            {t("Clients", { ns: ["billing"] })}
                          </div>
                        </div>
                        <div className="Billing__plan__attribute">
                          <div className="Billing__plan__attribute__image__outter">
                            <img
                              src={wizy_shield_check_filled}
                              alt="wizy_shop_bag_filled"
                              className="Billing__plan__attribute__image"
                            />
                          </div>
                          <div className="Billing__plan__attrinute__text">
                            {t("LiveM", { ns: ["billing"] })}
                          </div>
                        </div>
                      </div>
                      <div className="Billing__plan__attributes__outter">
                        <div className="Billing__plan__attribute">
                          <div className="Billing__plan__attribute__image__outter">
                            <img
                              src={wizy_shield_check_filled}
                              alt="wizy_shop_bag_filled"
                              className="Billing__plan__attribute__image"
                            />
                          </div>
                          <div className="Billing__plan__attrinute__text">
                            {t("Unlimited", { ns: ["billing"] })}{" "}
                            {t("Tickets", { ns: ["billing"] })}
                          </div>
                        </div>
                        <div className="Billing__plan__attribute">
                          <div className="Billing__plan__attribute__image__outter">
                            <img
                              src={wizy_shield_check_filled}
                              alt="wizy_shop_bag_filled"
                              className="Billing__plan__attribute__image"
                            />
                          </div>
                          <div className="Billing__plan__attrinute__text">
                            {t("LiveC", { ns: ["billing"] })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Billing__plan__inner__price">
                      <div className="Billing__plan__inner__price__inner">
                        <div className="Billing__plan__price__value">
                          <div className="Billing__plan__price__value__inner">
                            {customPlan.monthlyPrice}
                          </div>
                          <div className="Billing__plan__currency__outter">
                            <div className="Billing__plan__currency__inner">
                              USD
                            </div>
                            <div className="Billing__plan__period__inner">
                              {customPlan.isAnnual
                                ? t("Yearly", { ns: ["billing"] })
                                : t("Monthly", { ns: ["billing"] })}
                            </div>
                          </div>
                        </div>
                        <div className="Billing__plan__price__description">
                          {t("AdditionalMessagePricing", {
                            ns: ["billing"],
                          })
                            .replace(
                              "{additionalMessagePrice}",
                              customPlan.additionalMessagePrice.toString()
                            )
                            .replace(
                              "{messagesPerMonth}",
                              customPlan.messagesPerMonth.toString()
                            )}
                        </div>
                        <div className="Billing__button__outter">
                          {currentPlan.name === customPlan.name ? (
                            <button
                              className="Wizy__button__3"
                              style={{ width: "100%" }}
                            >
                              {t("Actual", { ns: ["billing"] })}
                            </button>
                          ) : (
                            <button
                              className="Wizy__button__2"
                              style={{ width: "100%" }}
                              onClick={() => {
                                changePlan(customPlan);
                              }}
                            >
                              {t("SignUp", { ns: ["billing"] })}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default Billing;
