// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import { useTranslation } from "react-i18next";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";
import wizy_add_outline from "../images/wizy_add_outline.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_question_filled from "../images/wizy_question_filled.svg";
import wizy_description_outline from "../images/wizy_description_outline.svg";
import wizy_tag_filled from "../images/wizy_tag_filled.svg";
import wizy_cart_filled from "../images/wizy_cart_filled.svg";
import wizy_discount_filled from "../images/wizy_discount_filled.svg";
import wizy_mg_outline from "../images/wizy_mg_outline.svg";

// Import styles
import "./styles/AdditionalInfo.css";

// Declare types and interfaces
import {
  ADDITIONAL_INFO_TYPE,
  IAdditionalInfo,
} from "../types/AdditionalInfoType";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import Pagination, { IPageMeta } from "../components/Pagination";
import SearchableMultipleDropdown, {
  IOption,
} from "../components/SearchableMultipleDropdown";
import { IProduct } from "../types/ProductType";
import AddInfoModal from "../components/AddInfoModal";

enum EAdditionalInfoOutter {
  normal,
  filter,
  showcase,
}

type IFilter = {
  text: string;
  isGeneralAddOn: boolean;
  isProductAddOn: boolean;
  isDiscount: boolean;
  isTag: boolean;
  initialDate: string;
  finalDate: string;
};

// Page main functional component
const AdditionalInfo: FC = () => {
  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["additionalInfo"]);

  // Use state variables
  const [additionalInfo, setAdditionalInfo] = useState<IAdditionalInfo[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectedAdditionalInfo, setSelectedAdditionalInfo] =
    useState<IAdditionalInfo>({
      id: "",
      type: ADDITIONAL_INFO_TYPE.GENERALADDON,
      associatedEntityId: "",
      createDate: "",
      description: "",
      selected: false,
    });
  const [filterChange, setFilterChange] = useState<IFilter>({
    text: "",
    isGeneralAddOn: true,
    isProductAddOn: true,
    isDiscount: true,
    isTag: true,
    initialDate: "",
    finalDate: "",
  });
  const [filter, setFilter] = useState<IFilter>({
    text: "",
    isGeneralAddOn: true,
    isProductAddOn: true,
    isDiscount: true,
    isTag: true,
    initialDate: "",
    finalDate: "",
  });
  const [validatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [isAddKnowledgeModalOpen, setIsAdditionalKnowledgeModalOpen] =
    useState<boolean>(false);

  // Variables used in showcase
  const [isShowcaseLoaded, setIsShowcaseLoaded] = useState<boolean>(false);
  const [generalAddOnUpdateForm, setGeneralAddOnUpdateForm] = useState<{
    topic: string;
    content: string;
  }>({ topic: "", content: "" });
  const [productAddOnUpdateForm, setProductAddOnUpdateForm] = useState<{
    content: string;
  }>({ content: "" });
  const [tagUpdateForm, setTagUpdateForm] = useState<{ tag: string }>({
    tag: "",
  });
  const [discountUpdateForm, setDiscountUpdateForm] = useState<{
    hasCode: boolean;
    code: string;
    description: string;
  }>({ hasCode: true, code: "", description: "" });

  // Variables for product select dropdown
  const [showcaseProductQuery, setShowcaseProductQuery] = useState<string>("");
  const [showcaseProductOptions, setShowcaseProductOptions] = useState<
    IOption[]
  >([]);
  const [selectedShowcaseProductOptions, setSelectedShowcaseProductOptions] =
    useState<IOption[]>([]);
  const [showcaseProductPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  // References needed
  const AdditionalInfoOutterRef = useRef<HTMLDivElement>(null);
  const ValidatorRef = useRef<IValidator>(null);

  // Use Effect Functions
  useEffect(() => {
    getAdditionalInfo(pageMeta.take, pageMeta.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ValidatorRef.current?.setApearance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  useEffect(() => {
    getAdditionalInfo(pageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getProductOptions(showcaseProductPageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showcaseProductQuery]);

  const getProductOptions = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/products/shop/" +
      globalShop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add filter queries to URL
    if (showcaseProductQuery) {
      searchURL += "&text=" + showcaseProductQuery;
    }
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setShowcaseProductOptions([]);
        JSONresult.data.forEach((product: IProduct) => {
          setShowcaseProductOptions((previous) => [
            ...previous,
            {
              id: product.id,
              label: product.displayTitle,
            },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAdditionalInfo = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/shop/" +
      globalShop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();

    // Add filter queries to URL
    if (filter?.initialDate && filter.finalDate) {
      searchURL +=
        "&initialDate=" + filter.initialDate + "&finalDate=" + filter.finalDate;
    }
    if (filter?.text) {
      searchURL += "&text=" + filter.text;
    }
    searchURL +=
      "&isGeneralAddOn=" +
      filter.isGeneralAddOn +
      "&isProductAddOn=" +
      filter.isProductAddOn +
      "&isDiscount=" +
      filter.isDiscount +
      "&isTag=" +
      filter.isTag;

    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setAdditionalInfo([]);
        JSONresult.data.forEach((additionalInfo: IAdditionalInfo) => {
          setAdditionalInfo((previous) => [
            ...previous,
            {
              id: additionalInfo.id,
              type: additionalInfo.type,
              createDate: additionalInfo.createDate,
              description: additionalInfo.description,
              associatedEntityId: additionalInfo.associatedEntityId,
              selected: false,
            },
          ]);
        });
        setPageMeta({
          itemCount: JSONresult.meta.itemCount,
          pageCount: JSONresult.meta.pageCount,
          page: parseInt(JSONresult.meta.page),
          take: parseInt(JSONresult.meta.take),
          hasNextPage: JSONresult.meta.hasNextPage,
          hasPreviousPage: JSONresult.meta.hasPreviousPage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoaded(true);
  };

  const handleAdditionalInfoGrid = (state: EAdditionalInfoOutter) => {
    switch (state) {
      case EAdditionalInfoOutter.normal:
        if (AdditionalInfoOutterRef.current !== null) {
          AdditionalInfoOutterRef.current.style.gridTemplateColumns =
            "0px 1fr 0px";
        }
        break;
      case EAdditionalInfoOutter.filter:
        if (AdditionalInfoOutterRef.current !== null) {
          AdditionalInfoOutterRef.current.style.gridTemplateColumns =
            "320px 1fr 0px";
        }
        break;
      case EAdditionalInfoOutter.showcase:
        if (AdditionalInfoOutterRef.current !== null) {
          AdditionalInfoOutterRef.current.style.gridTemplateColumns =
            "0px 1fr 320px";
        }
        break;
      default:
        console.log("Upps");
    }
  };

  const handleAdditionalInfoSelect = async (
    additionalInfo: IAdditionalInfo
  ) => {
    setSelectedAdditionalInfo(additionalInfo);
    // Update showcase form with information of associated entity
    if (additionalInfo.type === ADDITIONAL_INFO_TYPE.GENERALADDON) {
      let searchURL =
        globalSelectedBackend +
        "/generaladdons/" +
        additionalInfo.associatedEntityId;
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setGeneralAddOnUpdateForm({
            topic: JSONresult.displayTitle,
            content: JSONresult.content,
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setIsShowcaseLoaded(true);
    } else if (additionalInfo.type === ADDITIONAL_INFO_TYPE.PRODUCTADDON) {
      let searchURL =
        globalSelectedBackend +
        "/productaddons/" +
        additionalInfo.associatedEntityId;
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setProductAddOnUpdateForm({
            content: JSONresult.content,
          });
          setSelectedShowcaseProductOptions([]);
          JSONresult.products.forEach((product: IProduct) => {
            setSelectedShowcaseProductOptions((previous) => [
              ...previous,
              {
                id: product.id,
                label: product.displayTitle,
              },
            ]);
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setIsShowcaseLoaded(true);
    } else if (additionalInfo.type === ADDITIONAL_INFO_TYPE.TAG) {
      let searchURL =
        globalSelectedBackend + "/tags/" + additionalInfo.associatedEntityId;
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setTagUpdateForm({ tag: JSONresult.displayTitle });
          setSelectedShowcaseProductOptions([]);
          JSONresult.products.forEach((product: IProduct) => {
            setSelectedShowcaseProductOptions((previous) => [
              ...previous,
              {
                id: product.id,
                label: product.displayTitle,
              },
            ]);
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setIsShowcaseLoaded(true);
    } else if (additionalInfo.type === ADDITIONAL_INFO_TYPE.DISCOUNT) {
      let searchURL =
        globalSelectedBackend +
        "/discounts/" +
        additionalInfo.associatedEntityId;
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          if (JSONresult.code !== null && JSONresult.code !== "") {
            setDiscountUpdateForm({
              hasCode: true,
              code: JSONresult.code,
              description: JSONresult.description,
            });
          } else {
            setDiscountUpdateForm({
              hasCode: false,
              code: "",
              description: JSONresult.description,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setIsShowcaseLoaded(true);
    }
  };

  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGeneralAddOnUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setGeneralAddOnUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductAddOnUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setProductAddOnUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTagUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setTagUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDiscountUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setDiscountUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const deleteGeneralAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/generaladdon/" +
      selectedAdditionalInfo.id;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateGeneralAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/generaladdon/" +
      selectedAdditionalInfo.id;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      topic: generalAddOnUpdateForm.topic,
      content: generalAddOnUpdateForm.content,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteProductAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/productaddon/" +
      selectedAdditionalInfo.id;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProductAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/productaddon/" +
      selectedAdditionalInfo.id;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      content: productAddOnUpdateForm.content,
      productIds: selectedShowcaseProductOptions.map(
        (selectedShowcaseProductOption) => selectedShowcaseProductOption.id
      ),
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTag = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/tag/" +
      selectedAdditionalInfo.id;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTag = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/tag/" +
      selectedAdditionalInfo.id;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      tag: tagUpdateForm.tag,
      productIds: selectedShowcaseProductOptions.map(
        (selectedShowcaseProductOption) => selectedShowcaseProductOption.id
      ),
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteDiscount = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/discount/" +
      selectedAdditionalInfo.id;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDiscount = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/discount/" +
      selectedAdditionalInfo.id;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      hasCode: discountUpdateForm.hasCode,
      code: discountUpdateForm.code,
      description: discountUpdateForm.description,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoaded) {
    // JSX Return statement
    return (
      <React.Fragment>
        {/* Validator */}
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        {/* Add Knowledge Modal */}
        {isAddKnowledgeModalOpen ? (
          <AddInfoModal setIsOpen={setIsAdditionalKnowledgeModalOpen} />
        ) : (
          ""
        )}
        {/* The rest of the page */}
        <div ref={AdditionalInfoOutterRef} className="AdditionalInfo__outter">
          <div className="AdditionalInfo__filters__outter">
            <div
              className="AdditionalInfo__filters__outter__close"
              onClick={() => {
                handleAdditionalInfoGrid(EAdditionalInfoOutter.normal);
              }}
            >
              {" "}
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="AdditionalInfo__filters__outter__close__image"
              />
            </div>
            {/* Filters */}
            <div className="AdditionalInfo__filters__inner">
              {/* Title */}
              <div className="AdditionalInfo__filters__title">
                {" "}
                {t("Filters", { ns: ["additionalInfo"] })}
              </div>
              {/* Description */}
              <div className="AdditionalInfo__filters__description">
                {t("FiltersD", { ns: ["additionalInfo"] })}
              </div>
              {/* Type filters */}
              <div className="AdditionalInfo__filters__fields__outter">
                <div className="Wizy__input__title">
                  {t("Text", { ns: ["additionalInfo"] })}
                </div>
                <div className="Wizy__input__1__outter">
                  <input
                    name="text"
                    value={filterChange.text}
                    type="text"
                    className="Wizy__input__1"
                    onChange={handleFilterChange}
                  />
                  <img
                    src={wizy_mg_outline}
                    alt="wizy_mg_outline"
                    className="Wizy__input__1__image"
                  />
                </div>
                <div className="Wizy__input__prompt__1">
                  {t("Eg", { ns: ["additionalInfo"] })}
                </div>
                <div className="Wizy__input__title">
                  {t("Type", { ns: ["additionalInfo"] })}
                </div>
                {/* isGeneralAddOn */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isGeneralAddOn"
                    checked={filterChange.isGeneralAddOn}
                    onChange={() => {
                      if (filterChange.isGeneralAddOn) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isGeneralAddOn: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isGeneralAddOn: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("isGeneralAddOn", { ns: ["additionalInfo"] })}
                  </div>
                </div>
                {/* isProductAddOn */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isProductAddOn"
                    checked={filterChange.isProductAddOn}
                    onChange={() => {
                      if (filterChange.isProductAddOn) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isProductAddOn: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isProductAddOn: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("isProductAddOn", { ns: ["additionalInfo"] })}
                  </div>
                </div>
                {/* isDiscount */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isDiscount"
                    checked={filterChange.isDiscount}
                    onChange={() => {
                      if (filterChange.isDiscount) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isDiscount: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isDiscount: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("isDiscount", { ns: ["additionalInfo"] })}
                  </div>
                </div>
                {/* isTag */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isTag"
                    checked={filterChange.isTag}
                    onChange={() => {
                      if (filterChange.isTag) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isTag: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isTag: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {t("isTag", { ns: ["additionalInfo"] })}
                  </div>
                </div>
                <div className="Wizy__input__prompt__1">
                  {t("Actual", { ns: ["additionalInfo"] })}
                </div>
                <div className="Wizy__input__title">
                  {t("Date", { ns: ["additionalInfo"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.initialDate}
                  name="initialDate"
                  className="Wizy__input__date"
                  style={{ marginTop: "10px" }}
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateI", { ns: ["additionalInfo"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.finalDate}
                  name="finalDate"
                  className="Wizy__input__date"
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateF", { ns: ["additionalInfo"] })}
                </div>
              </div>
              {/* Apply filters button */}
              <div className="AdditionalInfo__apply__button__outter">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setFilter(filterChange);
                    // handleTicketToogleAll(false);
                  }}
                >
                  {t("Apply", { ns: ["additionalInfo"] })}
                </button>
              </div>
              {/* Reset filters button */}
              <div className="AdditionalInfo__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setFilter({
                      text: "",
                      isGeneralAddOn: true,
                      isProductAddOn: true,
                      isDiscount: true,
                      isTag: true,
                      initialDate: "",
                      finalDate: "",
                    });
                    setFilterChange({
                      text: "",
                      isGeneralAddOn: true,
                      isProductAddOn: true,
                      isDiscount: true,
                      isTag: true,
                      initialDate: "",
                      finalDate: "",
                    });
                    // handleTicketToogleAll(false);
                  }}
                >
                  {t("Reset", { ns: ["additionalInfo"] })}
                </button>
              </div>
            </div>
          </div>
          <div className="AdditionalInfo__list__outter">
            <div className="AdditionalInfo__list__inner">
              <div className="AdditionalInfo__list__title__outter">
                <div className="AdditionalInfo__list__title__inner">
                  <div className="AdditionalInfo__list__title__inner__inner">
                    <div className="Ticket__list__title__description">
                      <div className="AdditionalInfo__list__title__inner__text__title">
                        {t("Knowledge", { ns: ["additionalInfo"] })}
                      </div>
                      <div className="AdditionalInfo__list__title__inner__text__description">
                        {t("KnowledgeD", { ns: ["additionalInfo"] })}
                      </div>
                    </div>
                    <div className="AdditionalInfo__list__arrow__outter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        preserveAspectRatio="none"
                      >
                        <polygon
                          points="29 66 28 66 38 33 28 0 29 0 39 33 29 66"
                          fill="#e6e9ed"
                        ></polygon>
                      </svg>
                    </div>
                    <div
                      className="AdditionalInfo__list__add__filters__outter"
                      onClick={() => {
                        handleAdditionalInfoGrid(EAdditionalInfoOutter.filter);
                      }}
                    >
                      <img
                        src={wizy_add_outline}
                        alt="wizy_add_outline"
                        className="AdditionalInfo__list__add__filters__image"
                      />
                      {t("Filters", { ns: ["additionalInfo"] })}
                    </div>
                    <div className="AdditionalInfo__list__create__button__outter">
                      <div className="AdditionalInfo__list__create__button__inner">
                        <button
                          className="Wizy__button__4"
                          style={{ fontSize: "16px" }}
                          onClick={() =>
                            setIsAdditionalKnowledgeModalOpen(true)
                          }
                        >
                          <img
                            src={wizy_add_outline}
                            alt="wizy_add_outline"
                            className="Wizy__button__image"
                          />
                          {t("AddKnowledge", { ns: ["additionalInfo"] })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="AdditionalInfo__list__list__outter">
                <div className="AdditionalInfo__list__list__header__outter">
                  <div className="AdditionalInfo__list__list__header__inner">
                    {/* Pagination */}
                    <div className="AdditionalInfo__list__list__pagination__outter">
                      <Pagination
                        pageMeta={pageMeta}
                        getElements={getAdditionalInfo}
                      ></Pagination>
                    </div>
                  </div>
                </div>
                <div className="AdditionalInfo__list__list__titles__outter">
                  <div className="AdditionalInfo__list__list__titles__inner">
                    <div className="AdditionalInfo__list__list__titles__inner__inner">
                      {t("Type", { ns: ["additionalInfo"] })}
                    </div>
                    <div className="AdditionalInfo__list__list__titles__inner__inner">
                      {t("Description", { ns: ["additionalInfo"] })}
                    </div>
                    <div
                      className="AdditionalInfo__list__list__titles__inner__inner"
                      id="AdditionalInfo__date__field"
                    >
                      {t("DateC", { ns: ["additionalInfo"] })}
                    </div>
                  </div>
                </div>
                <div className="AdditionalInfo__list__list__items__outter">
                  {additionalInfo
                    .sort(
                      (a: IAdditionalInfo, b: IAdditionalInfo) =>
                        Date.parse(b.createDate) - Date.parse(a.createDate)
                    )
                    .map((additionalInfo, index) => {
                      return (
                        <div
                          className="AdditionalInfo__list__list__item__outter"
                          onClick={() => {
                            setIsShowcaseLoaded(false);
                            handleAdditionalInfoGrid(
                              EAdditionalInfoOutter.showcase
                            );
                            handleAdditionalInfoSelect(additionalInfo);
                          }}
                          key={index}
                        >
                          <div className="AdditionalInfo__list__list__item__inner">
                            <div
                              className="AdditionalInfo__list__list__id__inner__inner"
                              id="AdditionalInfo__state__field"
                            >
                              <div className="AdditionalInfo__list__list__state__inner__inner">
                                {additionalInfo.type ===
                                ADDITIONAL_INFO_TYPE.GENERALADDON
                                  ? t("isGeneralAddOn", {
                                      ns: ["additionalInfo"],
                                    })
                                  : additionalInfo.type ===
                                    ADDITIONAL_INFO_TYPE.PRODUCTADDON
                                  ? t("isProductAddOn", {
                                      ns: ["additionalInfo"],
                                    })
                                  : additionalInfo.type ===
                                    ADDITIONAL_INFO_TYPE.DISCOUNT
                                  ? t("isDiscount", { ns: ["additionalInfo"] })
                                  : t("isTag", { ns: ["additionalInfo"] })}
                              </div>
                            </div>
                            <div
                              className="AdditionalInfo__list__list__item__inner__inner"
                              id="AdditionalInfo__description__field"
                            >
                              {additionalInfo.description}
                            </div>
                            <div
                              className="AdditionalInfo__list__list__item__inner__inner"
                              id="AdditionalInfo__date__field"
                            >
                              {formatDate2(new Date(additionalInfo.createDate))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="AdditionalInfo__showcase__outter">
            <div
              className="AdditionalInfo__showcase__outter__close"
              onClick={() => {
                handleAdditionalInfoGrid(EAdditionalInfoOutter.normal);
              }}
            >
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="AdditionalInfo__showcase__outter__close__image"
              />
            </div>

            {!isShowcaseLoaded ? (
              <div className="AdditionalInfo__showcase__inner">
                <img
                  src={wizy_loader}
                  className="ProtectedRoute__loader"
                  alt="ProtectedRoute__loader"
                  id="ProtectedRoute__loader"
                />
              </div>
            ) : selectedAdditionalInfo.type ===
              ADDITIONAL_INFO_TYPE.GENERALADDON ? (
              <div className="AdditionalInfo__showcase__inner">
                <div className="AdditionalInfo__showcase__information__outter">
                  <div className="AdditionalInfo__showcase__infotmation__item__outter">
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_calendar_filled}
                          alt="wizy_calendar_filled"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        {formatDate2(
                          new Date(selectedAdditionalInfo.createDate)
                        )}
                      </div>
                    </div>
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_question_filled}
                          alt="wizy_add_outline"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <textarea
                          name="topic"
                          value={generalAddOnUpdateForm.topic}
                          className="Wizy__textarea"
                          onChange={handleGeneralAddOnUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_description_outline}
                          alt="wizy_description_outline"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <textarea
                          name="content"
                          value={generalAddOnUpdateForm.content}
                          className="Wizy__textarea"
                          onChange={handleGeneralAddOnUpdateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      updateGeneralAddOn();
                    }}
                  >
                    {t("Update", { ns: ["additionalInfo"] })}
                  </button>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__5"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      deleteGeneralAddOn();
                    }}
                  >
                    {t("Delete", { ns: ["additionalInfo"] })}
                  </button>
                </div>
              </div>
            ) : selectedAdditionalInfo.type ===
              ADDITIONAL_INFO_TYPE.PRODUCTADDON ? (
              <div className="AdditionalInfo__showcase__inner">
                <div className="AdditionalInfo__showcase__information__outter">
                  <div className="AdditionalInfo__showcase__infotmation__item__outter">
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_calendar_filled}
                          alt="wizy_calendar_filled"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        {formatDate2(
                          new Date(selectedAdditionalInfo.createDate)
                        )}
                      </div>
                    </div>
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_cart_filled}
                          alt="wizy_add_outline"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <SearchableMultipleDropdown
                          queryState={[
                            showcaseProductQuery,
                            setShowcaseProductQuery,
                          ]}
                          optionsState={[
                            showcaseProductOptions,
                            setShowcaseProductOptions,
                          ]}
                          selectedOptionsState={[
                            selectedShowcaseProductOptions,
                            setSelectedShowcaseProductOptions,
                          ]}
                          placeholder={t("DropdownPlaceholder", {
                            ns: ["additionalInfo"],
                          })}
                        />
                      </div>
                    </div>
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_description_outline}
                          alt="wizy_description_outline"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <textarea
                          name="content"
                          value={productAddOnUpdateForm.content}
                          className="Wizy__textarea"
                          onChange={handleProductAddOnUpdateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      updateProductAddOn();
                    }}
                  >
                    {t("Update", { ns: ["additionalInfo"] })}
                  </button>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__5"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      deleteProductAddOn();
                    }}
                  >
                    {t("Delete", { ns: ["additionalInfo"] })}
                  </button>
                </div>
              </div>
            ) : selectedAdditionalInfo.type === ADDITIONAL_INFO_TYPE.TAG ? (
              <div className="AdditionalInfo__showcase__inner">
                <div className="AdditionalInfo__showcase__information__outter">
                  <div className="AdditionalInfo__showcase__infotmation__item__outter">
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_calendar_filled}
                          alt="wizy_calendar_filled"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        {formatDate2(
                          new Date(selectedAdditionalInfo.createDate)
                        )}
                      </div>
                    </div>
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_cart_filled}
                          alt="wizy_cart_filled"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <SearchableMultipleDropdown
                          queryState={[
                            showcaseProductQuery,
                            setShowcaseProductQuery,
                          ]}
                          optionsState={[
                            showcaseProductOptions,
                            setShowcaseProductOptions,
                          ]}
                          selectedOptionsState={[
                            selectedShowcaseProductOptions,
                            setSelectedShowcaseProductOptions,
                          ]}
                          placeholder={t("DropdownPlaceholder", {
                            ns: ["additionalInfo"],
                          })}
                        />
                      </div>
                    </div>
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_tag_filled}
                          alt="wizy_tag_filled"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <textarea
                          name="tag"
                          value={tagUpdateForm.tag}
                          className="Wizy__textarea"
                          onChange={handleTagUpdateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      updateTag();
                    }}
                  >
                    {t("Update", { ns: ["additionalInfo"] })}
                  </button>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__5"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      deleteTag();
                    }}
                  >
                    {t("Delete", { ns: ["additionalInfo"] })}
                  </button>
                </div>
              </div>
            ) : selectedAdditionalInfo.type ===
              ADDITIONAL_INFO_TYPE.DISCOUNT ? (
              <div className="AdditionalInfo__showcase__inner">
                <div className="AdditionalInfo__showcase__information__outter">
                  <div className="AdditionalInfo__showcase__infotmation__item__outter">
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_calendar_filled}
                          alt="wizy_calendar_filled"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        {formatDate2(
                          new Date(selectedAdditionalInfo.createDate)
                        )}
                      </div>
                    </div>
                    {discountUpdateForm.hasCode ? (
                      <div className="AdditionalInfo__showcase__infotmation__item__inner">
                        <div>
                          <img
                            src={wizy_discount_filled}
                            alt="wizy_discount_filled"
                            className="AdditionalInfo__showcase__information__item__image"
                          />
                        </div>
                        <div className="AdditionalInfo__showcase__information__item__text">
                          <textarea
                            name="code"
                            value={discountUpdateForm.code}
                            className="Wizy__textarea"
                            onChange={handleDiscountUpdateChange}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="AdditionalInfo__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_description_outline}
                          alt="wizy_description_outline"
                          className="AdditionalInfo__showcase__information__item__image"
                        />
                      </div>
                      <div className="AdditionalInfo__showcase__information__item__text">
                        <textarea
                          name="description"
                          value={discountUpdateForm.description}
                          className="Wizy__textarea"
                          onChange={handleDiscountUpdateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      updateDiscount();
                    }}
                  >
                    {t("Update", { ns: ["additionalInfo"] })}
                  </button>
                </div>
                <div className="AdditionalInfo__apply__button__outter">
                  <button
                    className="Wizy__button__5"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setIsShowcaseLoaded(false);
                      deleteDiscount();
                    }}
                  >
                    {t("Delete", { ns: ["additionalInfo"] })}
                  </button>
                </div>
              </div>
            ) : (
              <div className="AdditionalInfo__showcase__inner"> </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default AdditionalInfo;
