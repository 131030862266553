// Import React Dependencies
import React, { FC, useEffect, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import { EAssistantFormType } from "../types/AssistantMessageType";
import { useTranslation } from "react-i18next";

// Import styles
import "./styles/AssistantForm.css";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import SearchableMultipleDropdown, {
  IOption,
} from "./SearchableMultipleDropdown";
import { IPageMeta } from "./Pagination";
import { IProduct } from "../types/ProductType";

// Declare types and interfaces
type AssistantFormProps = {
  type: EAssistantFormType;
  content: any;
  resetTestFunction: () => {};
};

enum ECreationState {
  BEFORE = "Before",
  DURING = "During",
  AFTER = "After",
  DELETED = "Deleted",
}

// Page main functional component
const AssistantForm: FC<AssistantFormProps> = (props) => {
  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["training"]);

  // Use state variables
  const [creationState, setCreationState] = useState<ECreationState>(
    ECreationState.BEFORE
  );
  const [additionalInfoId, setAdditionalInfoId] = useState<string>("");

  // Use state variables associated to each possible update form
  // General add on
  const [generalAddOnUpdateForm, setGeneralAddOnUpdateForm] = useState<{
    topic: string;
    content: string;
  }>(
    props.type === EAssistantFormType.NEW_GENERALADDON
      ? { topic: props.content.topic, content: props.content.content }
      : { topic: "", content: "" }
  );
  // Product add on form
  const [productAddOnUpdateForm, setProductAddOnUpdateForm] = useState<{
    content: string;
  }>(
    props.type === EAssistantFormType.NEW_PRODUCTADDON
      ? { content: props.content.content }
      : { content: "" }
  );
  // Tag form
  const [tagUpdateForm, setTagUpdateForm] = useState<{ tag: string }>(
    props.type === EAssistantFormType.NEW_TAG
      ? { tag: props.content.tag }
      : { tag: "" }
  );
  // Discount form
  const [discountUpdateForm, setDiscountUpdateForm] = useState<{
    hasCode: boolean;
    code: string;
    description: string;
  }>(
    props.type === EAssistantFormType.NEW_DISCOUNT
      ? {
          hasCode: props.content.hasCode,
          code: props.content.code,
          description: props.content.description,
        }
      : { hasCode: true, code: "", description: "" }
  );

  // Use state variables for product selection component
  const [productQuery, setProductQuery] = useState<string>("");
  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState<
    IOption[]
  >(
    props.type === EAssistantFormType.NEW_PRODUCTADDON ||
      props.type === EAssistantFormType.NEW_TAG
      ? props.content.products.map((product: IProduct) => {
          return {
            id: product.id,
            label: product.displayTitle,
          };
        })
      : []
  );
  const [productPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  // Use effect functions
  useEffect(() => {
    getProductOptions(productPageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productQuery]);

  // Internal functions
  const getProductOptions = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/products/shop/" +
      globalShop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add filter queries to URL
    if (productQuery) {
      searchURL += "&text=" + productQuery;
    }
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setProductOptions([]);
        JSONresult.data.forEach((product: IProduct) => {
          setProductOptions((previous) => [
            ...previous,
            {
              id: product.id,
              label: product.displayTitle,
            },
          ]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Functions to handle changes in update forms
  const handleGeneralAddOnUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setGeneralAddOnUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductAddOnUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setProductAddOnUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTagUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setTagUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDiscountUpdateChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setDiscountUpdateForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //////////////////////////////////////////// GENERAL ADD ONS CRUD /////////////////////////////////////////

  const createGeneralAddOn = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      topic: generalAddOnUpdateForm.topic,
      content: generalAddOnUpdateForm.content,
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/generaladdon/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setAdditionalInfoId(JSONresult.additionalInfoId);
        setCreationState(ECreationState.AFTER);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteGeneralAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/generaladdon/" +
      additionalInfoId;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.DELETED);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateGeneralAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/generaladdon/" +
      additionalInfoId;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      topic: generalAddOnUpdateForm.topic,
      content: generalAddOnUpdateForm.content,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.AFTER);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////////////////////////////////////////// PRODUCT ADD ONS CRUD /////////////////////////////////////////

  const createProductAddOn = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      content: productAddOnUpdateForm.content,
      productIds: selectedProductOptions.map(
        (selectedProductOption) => selectedProductOption.id
      ),
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/productaddon/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setAdditionalInfoId(JSONresult.additionalInfoId);
        setCreationState(ECreationState.AFTER);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProductAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/productaddon/" +
      additionalInfoId;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.DELETED);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProductAddOn = async () => {
    let searchURL =
      globalSelectedBackend +
      "/additionalinfo/productaddon/" +
      additionalInfoId;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      content: productAddOnUpdateForm.content,
      productIds: selectedProductOptions.map(
        (selectedProductOption) => selectedProductOption.id
      ),
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.AFTER);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////////////////////////////////////////// TAGS CRUD /////////////////////////////////////////

  const createTag = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tags: [tagUpdateForm.tag],
      productIds: selectedProductOptions.map(
        (selectedProductOption) => selectedProductOption.id
      ),
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/tags/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setAdditionalInfoId(JSONresult.additionalInfoId);
        setCreationState(ECreationState.AFTER);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTag = async () => {
    let searchURL =
      globalSelectedBackend + "/additionalinfo/tag/" + additionalInfoId;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.DELETED);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTag = async () => {
    let searchURL =
      globalSelectedBackend + "/additionalinfo/tag/" + additionalInfoId;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      tag: tagUpdateForm.tag,
      productIds: selectedProductOptions.map(
        (selectedProductOption) => selectedProductOption.id
      ),
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.AFTER);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////////////////////////////////////////// DISCOUNTS CRUD /////////////////////////////////////////

  const createDiscount = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      hasCode: discountUpdateForm.hasCode,
      code: discountUpdateForm.code,
      description: discountUpdateForm.description,
    });

    await fetch(
      globalSelectedBackend + "/additionalinfo/discount/" + globalShop.id,
      {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setAdditionalInfoId(JSONresult.additionalInfoId);
        setCreationState(ECreationState.AFTER);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteDiscount = async () => {
    let searchURL =
      globalSelectedBackend + "/additionalinfo/discount/" + additionalInfoId;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.DELETED);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDiscount = async () => {
    let searchURL =
      globalSelectedBackend + "/additionalinfo/discount/" + additionalInfoId;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      hasCode: discountUpdateForm.hasCode,
      code: discountUpdateForm.code,
      description: discountUpdateForm.description,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCreationState(ECreationState.AFTER);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///////////////////////////////////////////////////////////////// JSX ELEMENT /////////////////////////////////////////////////////////////////////////
  return (
    <React.Fragment>
      {/* GENERAL ADD ON */}
      {props.type === EAssistantFormType.NEW_GENERALADDON ? (
        <div className="AssistantForm__container">
          <div className="AssistantForm__subtitle">
            {t("TopicInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <textarea
            maxLength={500}
            className="Wizy__textarea"
            name="topic"
            value={generalAddOnUpdateForm.topic}
            placeholder={t("TopicPlaceholder", {
              ns: ["assistantForm"],
            })}
            disabled={
              creationState === ECreationState.DURING ||
              creationState === ECreationState.DELETED
            }
            onChange={handleGeneralAddOnUpdateChange}
          ></textarea>
          <div className="AssistantForm__subtitle">
            {t("NewInfoInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <textarea
            maxLength={500}
            className="Wizy__textarea"
            name="content"
            value={generalAddOnUpdateForm.content}
            placeholder={t("NewInfoPlaceholder", {
              ns: ["assistantForm"],
            })}
            disabled={
              creationState === ECreationState.DURING ||
              creationState === ECreationState.DELETED
            }
            onChange={handleGeneralAddOnUpdateChange}
          ></textarea>

          {creationState === ECreationState.BEFORE ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  createGeneralAddOn();
                  props.resetTestFunction();
                }}
                disabled={
                  generalAddOnUpdateForm.topic === "" ||
                  generalAddOnUpdateForm.content === ""
                }
              >
                {t("CreateGeneralAddOn", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DURING ? (
            <div className="AssistantForm__button__container">
              <img
                src={wizy_loader}
                className="ProtectedRoute__loader"
                alt="ProtectedRoute__loader"
                id="ProtectedRoute__loader"
              />
            </div>
          ) : creationState === ECreationState.AFTER ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  updateGeneralAddOn();
                  props.resetTestFunction();
                }}
                disabled={
                  generalAddOnUpdateForm.topic === "" ||
                  generalAddOnUpdateForm.content === ""
                }
              >
                {t("Update", {
                  ns: ["assistantForm"],
                })}
              </button>
              <button
                className="AssistantForm__delete__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  deleteGeneralAddOn();
                  props.resetTestFunction();
                }}
              >
                {t("Delete", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DELETED ? (
            <div className="AssistantForm__button__container">
              {t("DeletedInfo", {
                ns: ["assistantForm"],
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : // PRODUCT ADD ON
      props.type === EAssistantFormType.NEW_PRODUCTADDON ? (
        <div className="AssistantForm__container">
          <div className="AssistantForm__subtitle">
            {t("ProductContentInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <textarea
            maxLength={500}
            className="Wizy__textarea"
            name="content"
            value={productAddOnUpdateForm.content}
            placeholder={t("ProductContentPlaceholder", {
              ns: ["assistantForm"],
            })}
            disabled={
              creationState === ECreationState.DURING ||
              creationState === ECreationState.DELETED
            }
            onChange={handleProductAddOnUpdateChange}
          ></textarea>
          <div className="AssistantForm__subtitle">
            {t("AddOnProductsInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <SearchableMultipleDropdown
            queryState={[productQuery, setProductQuery]}
            optionsState={[productOptions, setProductOptions]}
            selectedOptionsState={[
              selectedProductOptions,
              setSelectedProductOptions,
            ]}
            placeholder={t("DropdownPlaceholder", {
              ns: ["assistantForm"],
            })}
          />

          {creationState === ECreationState.BEFORE ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  createProductAddOn();
                  props.resetTestFunction();
                }}
                disabled={
                  productAddOnUpdateForm.content === "" ||
                  selectedProductOptions.length === 0
                }
              >
                {t("CreateProductAddOn", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DURING ? (
            <div className="AssistantForm__button__container">
              <img
                src={wizy_loader}
                className="ProtectedRoute__loader"
                alt="ProtectedRoute__loader"
                id="ProtectedRoute__loader"
              />
            </div>
          ) : creationState === ECreationState.AFTER ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  updateProductAddOn();
                  props.resetTestFunction();
                }}
                disabled={
                  productAddOnUpdateForm.content === "" ||
                  selectedProductOptions.length === 0
                }
              >
                {t("Update", {
                  ns: ["assistantForm"],
                })}
              </button>
              <button
                className="AssistantForm__delete__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  deleteProductAddOn();
                  props.resetTestFunction();
                }}
              >
                {t("Delete", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DELETED ? (
            <div className="AssistantForm__button__container">
              {t("DeletedInfo", {
                ns: ["assistantForm"],
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : // TAG
      props.type === EAssistantFormType.NEW_TAG ? (
        <div className="AssistantForm__container">
          <div className="AssistantForm__subtitle">
            {t("TagInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <textarea
            maxLength={500}
            className="Wizy__textarea"
            name="tag"
            value={tagUpdateForm.tag}
            placeholder={t("TagPlaceholder", {
              ns: ["assistantForm"],
            })}
            disabled={
              creationState === ECreationState.DURING ||
              creationState === ECreationState.DELETED
            }
            onChange={handleTagUpdateChange}
          ></textarea>
          <div className="AssistantForm__subtitle">
            {t("TagProductsInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <SearchableMultipleDropdown
            queryState={[productQuery, setProductQuery]}
            optionsState={[productOptions, setProductOptions]}
            selectedOptionsState={[
              selectedProductOptions,
              setSelectedProductOptions,
            ]}
            placeholder={t("DropdownPlaceholder", {
              ns: ["assistantForm"],
            })}
          />

          {creationState === ECreationState.BEFORE ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  createTag();
                  props.resetTestFunction();
                }}
                disabled={
                  tagUpdateForm.tag === "" ||
                  selectedProductOptions.length === 0
                }
              >
                {t("CreateTag", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DURING ? (
            <div className="AssistantForm__button__container">
              <img
                src={wizy_loader}
                className="ProtectedRoute__loader"
                alt="ProtectedRoute__loader"
                id="ProtectedRoute__loader"
              />
            </div>
          ) : creationState === ECreationState.AFTER ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  updateTag();
                  props.resetTestFunction();
                }}
                disabled={
                  tagUpdateForm.tag === "" ||
                  selectedProductOptions.length === 0
                }
              >
                {t("Update", {
                  ns: ["assistantForm"],
                })}
              </button>
              <button
                className="AssistantForm__delete__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  deleteTag();
                  props.resetTestFunction();
                }}
              >
                {t("Delete", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DELETED ? (
            <div className="AssistantForm__button__container">
              {t("DeletedRecommendation", {
                ns: ["assistantForm"],
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : // DISCOUNT
      props.type === EAssistantFormType.NEW_DISCOUNT ? (
        <div className="AssistantForm__container">
          {/* Description field */}
          <div className="AssistantForm__subtitle">
            {t("DiscountDescriptionInstruction", {
              ns: ["assistantForm"],
            })}
          </div>
          <textarea
            maxLength={500}
            className="Wizy__textarea"
            name="description"
            value={discountUpdateForm.description}
            placeholder={
              discountUpdateForm.hasCode
                ? t("DiscountDescriptionWithCodePlaceholder", {
                    ns: ["assistantForm"],
                  })
                : t("DiscountDescriptionWithoutCodePlaceholder", {
                    ns: ["assistantForm"],
                  })
            }
            onChange={handleDiscountUpdateChange}
          ></textarea>
          <div className="AssistantForm__subtitle">
            {t("HasCodeInstruction", { ns: ["assistantForm"] })}
          </div>
          {/* Radio buttons container */}
          <div className="AssistantForm__hasdiscount__container">
            {/* Yes radio button */}
            <div
              className="AssistantForm__input__option__outter"
              onClick={() => {
                setDiscountUpdateForm((prevState) => ({
                  ...prevState,
                  hasCode: true,
                }));
              }}
            >
              <div
                className={
                  discountUpdateForm.hasCode
                    ? "Wizy__input__option__optioned"
                    : "Wizy__input__option"
                }
              >
                <div className="Wizy__input__option__circle__outter">
                  <div
                    className={
                      discountUpdateForm.hasCode
                        ? "Wizy__input__option__circle__inner__selected"
                        : "Wizy__input__option__circle__inner"
                    }
                  ></div>
                  {discountUpdateForm.hasCode ? (
                    <div className="Wizy__input__option__circle__filled"></div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="Wizy__input__option__text">
                  {t("Yes", { ns: ["assistantForm"] })}
                </div>
              </div>
            </div>
            {/* No radio button */}
            <div
              className="AssistantForm__input__option__outter"
              onClick={() => {
                setDiscountUpdateForm((prevState) => ({
                  ...prevState,
                  hasCode: false,
                }));
              }}
            >
              <div
                className={
                  !discountUpdateForm.hasCode
                    ? "Wizy__input__option__optioned"
                    : "Wizy__input__option"
                }
              >
                <div className="Wizy__input__option__circle__outter">
                  <div
                    className={
                      !discountUpdateForm.hasCode
                        ? "Wizy__input__option__circle__inner__selected"
                        : "Wizy__input__option__circle__inner"
                    }
                  ></div>
                  {!discountUpdateForm.hasCode ? (
                    <div className="Wizy__input__option__circle__filled"></div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="Wizy__input__option__text">
                  {t("No", { ns: ["assistantForm"] })}
                </div>
              </div>
            </div>
          </div>
          {/* Discount code field */}
          {discountUpdateForm.hasCode ? (
            <textarea
              maxLength={500}
              className="Wizy__textarea"
              name="code"
              value={discountUpdateForm.code}
              placeholder={t("CodePlaceholder", { ns: ["assistantForm"] })}
              onChange={handleDiscountUpdateChange}
            ></textarea>
          ) : (
            ""
          )}
          {creationState === ECreationState.BEFORE ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  createDiscount();
                  props.resetTestFunction();
                }}
                disabled={
                  (discountUpdateForm.hasCode &&
                    (discountUpdateForm.code === "" ||
                      discountUpdateForm.description === "")) ||
                  (!discountUpdateForm.hasCode &&
                    discountUpdateForm.description === "")
                }
              >
                {t("CreateDiscount", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DURING ? (
            <div className="AssistantForm__button__container">
              <img
                src={wizy_loader}
                className="ProtectedRoute__loader"
                alt="ProtectedRoute__loader"
                id="ProtectedRoute__loader"
              />
            </div>
          ) : creationState === ECreationState.AFTER ? (
            <div className="AssistantForm__button__container">
              <button
                className="AssistantForm__create__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  updateDiscount();
                  props.resetTestFunction();
                }}
                disabled={
                  (discountUpdateForm.hasCode &&
                    (discountUpdateForm.code === "" ||
                      discountUpdateForm.description === "")) ||
                  (!discountUpdateForm.hasCode &&
                    discountUpdateForm.description === "")
                }
              >
                {t("Update", {
                  ns: ["assistantForm"],
                })}
              </button>
              <button
                className="AssistantForm__delete__button"
                onClick={() => {
                  setCreationState(ECreationState.DURING);
                  deleteDiscount();
                  props.resetTestFunction();
                }}
              >
                {t("Delete", {
                  ns: ["assistantForm"],
                })}
              </button>
            </div>
          ) : creationState === ECreationState.DELETED ? (
            <div className="AssistantForm__button__container">
              {t("DeletedDiscount", {
                ns: ["assistantForm"],
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

// Default exported function
export default AssistantForm;
