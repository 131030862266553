// Import React dependencies
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EShopStatus, IShop } from "../types/ShopType";
import { IClient } from "../types/ClientType";
import {
  EExtraUIComponentTypes,
  EMessageRole,
  IMessage,
  IMessageBackend,
} from "../types/MessageType";

import wizy_loader from "../images/wizy_loader.gif";
import wizy_profile_filled from "../images/wizy_profile_filled.svg";
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_mail_filled from "../images/wizy_mail_filled.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_shield_filled from "../images/wizy_shield_filled.svg";
import wizy_user_filled from "../images/wizy_user_filled.png";
import wizy_location_filled from "../images/wizy_location_filled.svg";
import wizy_computer_filled from "../images/wizy_computer_filled.svg";
import wizy_phone_filled from "../images/wizy_phone_filled.svg";
import wizy_globe_outline from "../images/wizy_globe_outline.svg";

// Import styles
import "./styles/ChatReview.css";
import { AppContext, IContext } from "../context/Context";
import { IPlan } from "../types/PlanType";
import QualityFormCard, { IQualityForm } from "../components/QualityFormCard";
import SalesFormCard from "../components/SalesFormCard";
import Add2CartLiveChat from "../components/Add2CartLiveChat";
import { EMainLanguage } from "../components/ShopifyWidget";
import RecommendationCarouselLiveChat from "../components/RecommendationCarouselLiveChat";

const ApproveSales: FC = () => {
  // Use translation
  const { t } = useTranslation(["chatReview"]);
  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [pendingSaleClientsCount, setPendingSaleClientsCount] =
    useState<number>(0);
  const [qualityForm, setQualityForm] = useState<IQualityForm>({
    qualityScore: null,
    needsCorrection: false,
    hasBug: false,
    qualityNote: null,
  });
  const [isQualityFormLoaded, setIsQualityFormLoaded] = useState<boolean>(true);
  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const emptyShop: IShop = {
    id: "",
    domain: "",
    needsSetup: false,
    needsSync: false,
    needsTest: false,
    needsOnboarding: false,
    status: EShopStatus.installed,
    currentPlan: emptyPlan,
    createDate: "",
    selected: false,
    isWidgetInstalled: false,
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
  };
  const [selectedClient, setSelectedClient] = useState<IClient>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    computer: "",
    ipAddress: "",
    createDate: "",
    lastMessageDate: "",
    lastMessage: "",
    subscriptionState: false,
    isClientRead: false,
    isShopRead: false,
    isAiEnabled: false,
    websocketId: "",
    selected: false,
    shop: emptyShop,
  });
  const [messages, setMessages] = useState<IMessage[]>([]);

  const formatDate = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${hours}:${minutes} ${year}-${month}-${day}`;
  };
  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const convertToHTMLLink = (text: string): JSX.Element => {
    const urlPattern = /\[([^[\]]+)\]\(((https?|mailto):\/\/\S+|mailto:\S+)\)/g;
    const replacedLinkText = text.replace(
      urlPattern,
      (match, anchorText, url) =>
        `<a href="${url}" target="_top">${anchorText}</a>`
    );
    const boldPattern = /\*\*([^\*]+)\*\*/g;
    const replacedLinkBoldText = replacedLinkText.replace(
      boldPattern,
      (match, boldText) => `<strong>${boldText}</strong>`
    );
    const doubleLineBreakPattern = /\n\s*\n/g;
    const replacedLinkBoldLineText = replacedLinkBoldText.replace(
      doubleLineBreakPattern,
      "\n"
    );
    return (
      <div
        dangerouslySetInnerHTML={{ __html: replacedLinkBoldLineText }}
        style={{ whiteSpace: "pre-line" }}
      />
    );
  };
  const handleQualityFormChange = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setQualityForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClient = async () => {
    // URL to fetch all clients with messages and pagination queries
    let searchURL = globalSelectedBackend + "/admin/clients/approvesales";
    // Add filter queries to URL
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setPendingSaleClientsCount(JSONresult.pendingSaleClientsCount);
        if (JSONresult.pendingSaleClientsCount !== 0) {
          setSelectedClient({
            id: JSONresult.pendingSaleClient.id,
            firstName: JSONresult.pendingSaleClient.firstName,
            lastName: JSONresult.pendingSaleClient.lastName,
            email: JSONresult.pendingSaleClient.email,
            phone: JSONresult.pendingSaleClient.phone,
            location: JSONresult.pendingSaleClient.location,
            computer: JSONresult.pendingSaleClient.computer,
            ipAddress: JSONresult.pendingSaleClient.ipAddress,
            createDate: JSONresult.pendingSaleClient.createDate,
            lastMessageDate: JSONresult.pendingSaleClient.lastMessageDate,
            lastMessage: JSONresult.pendingSaleClient.lastMessage,
            subscriptionState: JSONresult.pendingSaleClient.subscriptionState,
            isClientRead: JSONresult.pendingSaleClient.isClientRead,
            isShopRead: JSONresult.pendingSaleClient.isShopRead,
            isAiEnabled: JSONresult.pendingSaleClient.isAiEnabled,
            websocketId: JSONresult.pendingSaleClient.websocketId,
            messages: JSONresult.pendingSaleClient.messages,
            selected: true,
            shop: JSONresult.pendingSaleClient.shop,
            sales: JSONresult.pendingSaleClient.sales,
          });
          setQualityForm({
            qualityScore: JSONresult.pendingSaleClient.qualityScore,
            needsCorrection: JSONresult.pendingSaleClient.needsCorrection,
            hasBug: JSONresult.pendingSaleClient.hasBug,
            qualityNote: JSONresult.pendingSaleClient.qualityNote,
          });
          const newMessages = JSONresult.pendingSaleClient.messages.map(
            (backendMessage: IMessageBackend): IMessage => {
              return {
                id: backendMessage.id,
                role: backendMessage.role,
                name: backendMessage.name,
                content: backendMessage.content,
                functionCall: backendMessage.functionCall,
                toolCalls: backendMessage.toolCalls,
                toolCallId: backendMessage.toolCallId,
                createDate: backendMessage.createDate,
                extraUIComponents: backendMessage.extraUIComponents
                  ? JSON.parse(backendMessage.extraUIComponents)
                  : null,
                sourceType: backendMessage.sourceType,
                sourceId: backendMessage.sourceId,
                hasMedia: backendMessage.hasMedia,
                status: backendMessage.status,
              };
            }
          );
          setMessages(newMessages);
        }
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateClientQualityForm = async () => {
    setIsQualityFormLoaded(false);
    let searchURL =
      globalSelectedBackend + "/clients/" + selectedClient.id + "/quality";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      qualityScore:
        qualityForm.qualityScore !== 0 ? qualityForm.qualityScore : null,
      needsCorrection: qualityForm.needsCorrection,
      hasBug: qualityForm.hasBug,
      qualityNote:
        qualityForm.qualityNote !== "" ? qualityForm.qualityNote : null,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setIsQualityFormLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSelectedClientApprovedSales = async () => {
    let searchURL = globalSelectedBackend + "/sale/updateapproval";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      sales: selectedClient.sales,
    });
    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        getClient();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isNotBlank = (value: any): boolean => {
    return value !== null && value !== undefined && value !== "";
  };

  if (isLoaded) {
    return (
      <div className="ChatReview__outter">
        <div className="ChatReview__top__bar">
          <div className="ChatReview__row__container">
            <div className="ChatReview__row__container__inner">
              <div className="Wizy__input__title" style={{ margin: "0px" }}>
                {t("PendingSaleClients", { ns: ["approveSales"] })}
              </div>
            </div>
          </div>
          <div className="ChatReview__row__container">
            <div className="ChatReview__row__container__inner">
              <div className="Wizy__input__title" style={{ margin: "0px" }}>
                <input
                  name="pendingReviewCount"
                  value={pendingSaleClientsCount}
                  type="text"
                  className="Wizy__input__2"
                  style={{ height: "30px", width: "100px" }}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        {pendingSaleClientsCount === 0 ? (
          <div className="ChatReview__nochats__outter">
            <div className="ChatReview__nochats__text__outter">
              {t("NoChats", { ns: ["approveSales"] })}
            </div>
          </div>
        ) : (
          <div className="ChatReview__chat__container">
            {/* Messages */}
            <div className="ChatReview__chat__outter">
              <div className="ChatReview__chat__inner">
                <div className="ChatAdmin__navbar__outter">
                  <div className="Chat__navbar__inner">
                    <div className="ChatAdmin__client__outter">
                      <div className="Chat__client__inner">
                        <div className="ChatAdmin__client__inner__bubble">
                          {(selectedClient.email === ""
                            ? t("NewClient", { ns: ["chatAdmin"] })
                            : selectedClient.email)[0].toUpperCase()}
                          <div
                            className="Chat__client__inner__bubble__notification"
                            style={{
                              opacity: "0",
                            }}
                          ></div>
                        </div>
                        <div className="Chat__client__inner__information">
                          <div className="ChatAdmin__client__inner__shop">
                            {selectedClient.shop?.domain === ""
                              ? t("NewClient", { ns: ["chatAdmin"] })
                              : selectedClient.shop?.domain}
                          </div>
                          <div className="ChatAdmin__client__inner__email">
                            {selectedClient.email === ""
                              ? t("NewClient", { ns: ["chatAdmin"] })
                              : selectedClient.email}
                          </div>
                          <div className="ChatAdmin__client__inner__message">
                            <div className="Chat__client__inner__message__bubble"></div>
                            <div className="Chat__client__inner__message__inner">
                              {t("ErrorT", { ns: ["chat"] })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ChatReview__messages__outter">
                  <div className="ChatReview__messages__inner">
                    <div>
                      {messages
                        .filter((message: IMessage) => {
                          if (
                            (message.role === EMessageRole.ai ||
                              message.role === EMessageRole.assistant ||
                              message.role === EMessageRole.user) &&
                            isNotBlank(message.content)
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .sort(
                          (a: IMessage, b: IMessage) =>
                            Date.parse(a.createDate) - Date.parse(b.createDate)
                        )
                        .map((message, index) => {
                          return (
                            <div key={index} className="Chat__message__outter">
                              <div className="Chat__message__inner">
                                <div className="Chat__message__inner__bubble">
                                  {message.role === EMessageRole.assistant ? (
                                    <img
                                      src={wizy_profile_filled}
                                      alt="wizy_profile_filled"
                                      className="Chat__message__inner__bubble__image"
                                    />
                                  ) : message.role === EMessageRole.ai ? (
                                    <img
                                      src={wizy_logo_white}
                                      alt="wizy_logo_white"
                                      className="Chat__message__inner__bubble__image__white"
                                    />
                                  ) : (
                                    (selectedClient.email === ""
                                      ? t("NewClient", { ns: ["chat"] })
                                      : selectedClient.email)[0].toUpperCase()
                                  )}
                                </div>
                                <div className="Chat__message__inner__information">
                                  <div className="Chat__message__inner__email">
                                    {message.role === EMessageRole.assistant
                                      ? t("NewClient", { ns: ["chatAdmin"] })
                                      : message.role === EMessageRole.ai
                                      ? "Wizybot"
                                      : selectedClient.email === ""
                                      ? t("NewClient", { ns: ["chat"] })
                                      : selectedClient.email}{" "}
                                    &nbsp;
                                    <span
                                      style={{
                                        opacity: "0.5",
                                        fontWeight: "500",
                                        fontSize: "11px",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {formatDate(new Date(message.createDate))}
                                    </span>
                                  </div>
                                  <div className="Chat__message__inner__message">
                                    <div className="Chat__message__inner__message__inner">
                                      {convertToHTMLLink(message.content)}
                                    </div>
                                    {message.extraUIComponents ? (
                                      message.extraUIComponents.length > 0 ? (
                                        message.extraUIComponents.map(
                                          (extraUIComponent, i) => {
                                            if (
                                              extraUIComponent.type ===
                                              EExtraUIComponentTypes.ADD_TO_CART
                                            ) {
                                              return (
                                                <Add2CartLiveChat
                                                  content={
                                                    extraUIComponent.content
                                                  }
                                                  language={
                                                    EMainLanguage.ENGLISH
                                                  }
                                                />
                                              );
                                            } else if (
                                              extraUIComponent.type ===
                                              EExtraUIComponentTypes.RECOMMENDATION_CAROUSEL
                                            ) {
                                              return (
                                                <RecommendationCarouselLiveChat
                                                  content={
                                                    extraUIComponent.content
                                                  }
                                                  language={
                                                    EMainLanguage.ENGLISH
                                                  }
                                                />
                                              );
                                            } else return <></>;
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Chat__showcase__outter">
              <div
                className="Chat__showcase__inner"
                style={{ height: "calc(100vh - 140px)" }}
              >
                <div className="Chat__showcase__information__outter">
                  <div className="Chat__showcase__infotmation__item__outter">
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_user_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.firstName === ""
                          ? t("NoName", { ns: ["chat"] })
                          : selectedClient.firstName}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_user_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.lastName === ""
                          ? t("NoLastname", { ns: ["chat"] })
                          : selectedClient.lastName}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_mail_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.email === ""
                          ? t("NewClient", { ns: ["chat"] })
                          : selectedClient.email}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_phone_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.phone === ""
                          ? t("NoPhone", { ns: ["chat"] })
                          : selectedClient.phone}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_location_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.location === ""
                          ? t("NoLocation", { ns: ["chat"] })
                          : selectedClient.location}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_computer_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.computer === ""
                          ? t("NoComputer", { ns: ["chat"] })
                          : selectedClient.computer}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_globe_outline}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.ipAddress === ""
                          ? t("NoIp", { ns: ["chat"] })
                          : selectedClient.ipAddress}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_calendar_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.createDate === ""
                          ? t("NoCreation", { ns: ["chat"] })
                          : formatDate2(new Date(selectedClient.createDate))}
                      </div>
                    </div>
                    <div className="Chat__showcase__infotmation__item__inner">
                      <div>
                        <img
                          src={wizy_shield_filled}
                          alt="wizy_add_outline"
                          className="Chat__showcase__information__item__image"
                        />
                      </div>
                      <div className="Chat__showcase__information__item__text">
                        {selectedClient.subscriptionState
                          ? t("Subed", { ns: ["chat"] })
                          : t("Unsubed", { ns: ["chat"] })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Chat__showcase__information__outter">
                  {isQualityFormLoaded ? (
                    <QualityFormCard
                      qualityFormState={[qualityForm, setQualityForm]}
                      updateClientQualityForm={updateClientQualityForm}
                      handleQualityFormChange={handleQualityFormChange}
                    />
                  ) : (
                    <div className="Home__setup__outter">
                      <img
                        src={wizy_loader}
                        className="ProtectedRoute__loader"
                        alt="ProtectedRoute__loader"
                        id="ProtectedRoute__loader"
                      />
                    </div>
                  )}
                </div>
                <div className="Chat__showcase__information__outter">
                  <SalesFormCard
                    selectedClientState={[selectedClient, setSelectedClient]}
                    updateSelectedClientApprovedSales={
                      updateSelectedClientApprovedSales
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

export default ApproveSales;
