// Import React Dependencies
import React, { FC, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { io } from "socket.io-client";

// Import styles
import {
  EExtraUIComponentTypes,
  EMessageRole,
  EMessageSourceTypes,
  EMessageStatus,
  IMessage,
  IMessageBackend,
} from "../types/MessageType";
import Add2Cart from "./Add2Cart";
import ShopifyWidgetCart from "./ShopifyWidgetCart";
import RecommendationCarousel from "./RecommendationCarousel";

type ShopifyWidgetProps = {
  domain: string;
  widget: IWidgetVariables;
  isTest: boolean;
  isDashboard: boolean;
  isAdmin: boolean;
  isRelative: boolean;
  ipRegistryKey: string;
  globalSelectedBackend: string;
  chatProfileImage: string;
  curvyBorderImage: string;
  wizyLogoImage: string;
  shopifyRootPath: string | null;
  shopifyCurrentPath: string;
  isRedirect: boolean;
  redirectionLink: string;
  showNewMessage: () => void;
  hideNewMessage: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

enum EChatState {
  close = "close",
  open = "open",
}

enum EVisibleMessageState {
  visible = "visible",
  invisible = "invisible",
}

enum ECreateClientState {
  visible = "visible",
  invisible = "invisible",
}

enum ECartState {
  visible = "visible",
  invisible = "invisible",
}

enum ECreateClientBackendState {
  send = "send",
  loading = "loading",
}

// Change this in case Widget Variables tyoe changes
export type IWidgetVariables = {
  domain: string;
  setup: ISetupWidget;
};

export type ISetupWidget = {
  primaryColor: string;
  secondaryColor: string;
  fontColor: string;
  agentName: string;
  onlinePhrase: string;
  mainLanguage: EMainLanguage;
  side: ESide;
  paddingBottom: number;
  paddingSide: number;
  image: string;
  isVisible: boolean;
  emailRetrievalMethod: EEmailRetrievalMethod;
  dataRetrievalType: EDataRetrievalType;
  dataRetrievalCustomPrompt: string;
  isRedirect: boolean;
  redirectionLink: string;
  isOverLimitBudget: boolean;
  limitBudgetAction: ELimitBudgetAction;
  limitBudgetRedirectionLink: string;
  hideNotificationSign: boolean;
  hideWizybotBanner: boolean;
  hideOutboundMessage: boolean;
  preventSaleNoteCreation: boolean;
  customWidgetCode: string;
  pagesToExcludeWidget: string;
};

export type ICart = {
  totalPrice: number;
  currency: string;
  items: ICartItem[];
};

export type ICartItem = {
  id: number;
  quantity: number;
  productTitle: string;
  variantTitle: string;
  linePrice: number;
  image: string;
  url: string;
};

export enum EMainLanguage {
  SPANISH = "Spanish",
  ENGLISH = "English",
  PORTUGUESE = "Portuguese",
  FRENCH = "French",
  GERMAN = "German",
  ITALIAN = "Italian",
}

export enum ESide {
  RIGHT = "Right",
  LEFT = "Left",
}

export enum EEmailRetrievalMethod {
  MANDATORY = "Mandatory",
  SEMANTIC = "Semantic",
  NONE = "None",
}

export enum EDataRetrievalType {
  EMAIL = "Email",
  PHONE = "Phone",
  BOTH = "Both",
  CUSTOM = "Custom",
}

export enum ELimitBudgetAction {
  WIDGET_OFF = "widget off",
  AI_OFF = "ai off",
  DEFAULT_MESSAGE = "default message",
  REDIRECT = "redirect",
}

// Page main functional component
const ShopifyWidget: FC<ShopifyWidgetProps> = ({
  domain,
  widget,
  isTest,
  isDashboard,
  isAdmin,
  isRelative,
  ipRegistryKey,
  globalSelectedBackend,
  chatProfileImage,
  curvyBorderImage,
  wizyLogoImage,
  shopifyRootPath,
  isRedirect,
  redirectionLink,
  shopifyCurrentPath,
  showNewMessage,
  hideNewMessage,
  setIsOpen,
}) => {
  // Use State Variables
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
  const [init, setInit] = useState<string>("");
  const [chatState, setChatState] = useState<EChatState>(
    isRelative ? EChatState.open : EChatState.close
  );
  const [chatStateVisibility, setChatStateVisibility] = useState<EChatState>(
    isRelative ? EChatState.open : EChatState.close
  );
  const [visibleMessage, setVisibleMessage] = useState<string>("");
  const [visibleMessageState, setVisibleMessageState] =
    useState<EVisibleMessageState>(EVisibleMessageState.invisible);
  const [visibleMessageOpacity, setVisibleMessageOpacity] =
    useState<EVisibleMessageState>(EVisibleMessageState.invisible);
  const [createClientState, setCreateClientState] =
    useState<ECreateClientState>(ECreateClientState.invisible);
  const [cartState, setCartState] = useState<ECartState>(ECartState.invisible);
  const [createClientBackendState, setCreateClientBackendState] =
    useState<ECreateClientBackendState>(ECreateClientBackendState.send);
  const [isLoaded, setIsLoaded] = useState(false);
  const [socket, setSocket] = useState<any>(null);
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [subscriptionState, setSubscriptionState] = useState<boolean>(false);
  const [cart, setCart] = useState<ICart | null>(null);
  const [isCartLoaded, setIsCartLoaded] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isSendButtonActive, setIsSendButtonActive] = useState<boolean>(true);
  const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false);
  const [clientCreated, setClientCreated] = useState<boolean>(false);
  const [lastMessage, setLastMessage] = useState<any>(null);
  const ecommerceEmojis = [
    "😊",
    "🛍️",
    "📦",
    "💳",
    "🤑",
    "🛒",
    "💬",
    "👍",
    "📢",
    "📆",
    "✉️",
    "🔍",
    "🎁",
    "📱",
    "💻",
    "📋",
    "🌟",
    "💬",
    "❓",
    "💯",
  ];

  // Constants
  const cookies = new Cookies();

  // Use Effect Functions
  // Initialize Widget
  useEffect(() => {
    initializeUrlParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if cart is loaded
  useEffect(() => {
    setIsCartLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  // Check if cart is loaded
  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage.content !== "") {
        if (clientCreated) {
          setMessages((previous) => [
            ...previous,
            {
              id: lastMessage.id ? lastMessage.id : "",
              content: lastMessage.content,
              createDate: lastMessage.createDate,
              role: lastMessage.role,
              name: lastMessage.name,
              functionCall: lastMessage.functionCall,
              toolCalls: lastMessage.toolCalls,
              toolCallId: lastMessage.toolCallId,
              extraUIComponents: lastMessage.extraUIComponents
                ? JSON.parse(lastMessage.extraUIComponents)
                : null,
              sourceType: lastMessage.sourceType,
              sourceId: lastMessage.sourceId,
              hasMedia: lastMessage.hasMedia,
              status: lastMessage.status,
            },
          ]);
          setIsNewMessage(true);
          setIsTyping(false);
        } else {
          setMessages([
            {
              id: lastMessage.id ? lastMessage.id : "",
              content: lastMessage.content,
              createDate: lastMessage.createDate,
              role: lastMessage.role,
              name: lastMessage.name,
              functionCall: lastMessage.functionCall,
              toolCalls: lastMessage.toolCalls,
              toolCallId: lastMessage.toolCallId,
              extraUIComponents: lastMessage.extraUIComponents
                ? JSON.parse(lastMessage.extraUIComponents)
                : null,
              sourceType: lastMessage.sourceType,
              sourceId: lastMessage.sourceId,
              hasMedia: lastMessage.hasMedia,
              status: lastMessage.status,
            },
          ]);
          setIsNewMessage(true);
          setIsTyping(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  // When loaded listen to the socket
  useEffect(() => {
    function onConnect() {}

    function onDisconnect() {}

    function onMessageEvent(value: any) {
      setLastMessage(value);
    }

    function onTyping(value: string) {
      setIsTyping(true);
    }

    function onInit(value: string) {
      setInit(value);
    }

    if (isLoaded) {
      socket.connect();

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("messagesClient", onMessageEvent);
      socket.on("typing", onTyping);
      socket.on("init", onInit);

      // Some times people want the widget to do weird stuff, and as an inclusive company, we do not judge, we just do it
      // Example: "I want the widget to suck my client's dick"
      // This snap of code allows you to run custom javascript code from a string saved in thye db without having to do a
      // new release or keep contaminating our codebase with weird flags such as shouldSuckDick.

      // Here is an example of a string that changes the color of something inside and outside the iframe:

      // OUTSIDE OF THE IFRAME
      // document.getElementsByClassName("Test__reset__outter")[0].style.backgroundColor = "#000000";

      // INSIDE OF THE IFRAME
      // var iframe = document.getElementById('WizybotShopifyWidget__iframe__outter__id');
      // var innerDoc = iframe.contentWindow.document;
      // innerDoc.getElementsByClassName("WizybotShopifyWidget__open__button__notification")[0].style.backgroundColor = "#000000";

      // Here is an example of how to extract variables from the backend:

      // const dataDiv = document.getElementById("WizybotShopifyWidget__data__div");
      // const data = dataDiv?.dataset;
      // console.log(JSON.parse(data.storesetup).setup.isRedirect);

      if (isNotBlank(widget.setup.customWidgetCode)) {
        // eslint-disable-next-line
        eval(
          `try { ${widget.setup.customWidgetCode} } catch (e) { console.log("Error in custom widget script") }`
        );
      }

      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("messagesClient", onMessageEvent);
        socket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // When a new message is received then show it as a outbound message
  useEffect(() => {
    if (isLoaded) {
      if (chatState === EChatState.close && !widget.setup.hideOutboundMessage) {
        setVisibleMessage(messages.slice(-1)[0].content);
        setVisibleMessageState(EVisibleMessageState.visible);
        showNewMessage();
        setTimeout(() => {
          setVisibleMessageOpacity(EVisibleMessageState.visible);
        }, 100);
        setTimeout(() => {
          setVisibleMessageOpacity(EVisibleMessageState.invisible);
        }, 5000);
        setTimeout(() => {
          setVisibleMessageState(EVisibleMessageState.invisible);
          hideNewMessage();
        }, 5800);
      } else {
        if (chatState === EChatState.open) {
          socket.emit("clientRead", {
            clientId: cookies.get(
              isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
            ),
            shopDomain: domain,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMessage]);

  // Update client init when it is recieved
  useEffect(() => {
    if (isLoaded) {
      updateClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  // Internal Functions
  // Take actions based on the parameters received on the url
  const initializeUrlParameters = () => {
    // Check for params in the url
    const urlParams = new URLSearchParams(window.location.search);
    // Client conversation param
    const wizyClient = urlParams.get("wizyclient");
    // Chat state param
    const wizyOpen = urlParams.get("wizyopen");
    // If there is a wizyclient parameter
    if (wizyClient !== null) {
      cookies.remove(
        isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain,
        {
          path: "/",
        }
      );
      cookies.set(
        isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain,
        wizyClient,
        {
          path: "/",
          maxAge: 60 * 60 * 24 * 365,
        }
      );
    }
    // If there is a wizyopen true parameter
    if (wizyOpen === "true") {
      handleToggleChat();
    }
    // initializewebsocket
    initializeWebsocket();
  };

  // Initialize websocket
  const initializeWebsocket = async () => {
    const clientCookie = cookies.get(
      isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
    );
    if (clientCookie !== undefined) {
      setClientCreated(true);
    }
    setSocket(
      io(
        globalSelectedBackend +
          "/chat?domain=" +
          domain +
          "&registered=" +
          (clientCookie === undefined ? "false" : "true") +
          "&&type=client&&path=" +
          shopifyCurrentPath,
        {
          forceNew: true,
          autoConnect: false,
          transports: ["websocket"],
          transportOptions: {
            websocket: {
              extraHeaders: {
                "Sec-WebSocket-Key": "dGhlIHNhbXBsZSBub25jZQ==",
                "Sec-WebSocket-Version": "13",
                "Sec-WebSocket-Protocol": "chat, superchat",
              },
            },
          },
        }
      )
    );
    setIsLoaded(true);
  };

  // Send message
  const handleMessageSend = async () => {
    if (!isCreatingClient) {
      if (
        cookies.get(
          isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
        ) !== undefined
      ) {
        if (newMessage.replaceAll(" ", "") !== "") {
          const newMessageSaver = newMessage;
          setMessages((previous) => [
            ...previous,
            {
              content: newMessageSaver,
              createDate: new Date().toLocaleDateString(),
              role: EMessageRole.user,
              name: null,
              functionCall: null,
              toolCalls: null,
              toolCallId: null,
              extraUIComponents: [],
              sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
              sourceId: null,
              hasMedia: false,
              status: EMessageStatus.SENT,
            },
          ]);
          setNewMessage("");
          socket.emit("messagesClient", {
            content: newMessageSaver,
            clientId: cookies.get(
              isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
            ),
            isTest: isTest,
            shopDomain: domain,
            path: shopifyCurrentPath,
          });
        }
      } else {
        if (clientCreated) {
          setMessages((previous) => [
            ...previous,
            {
              id: "",
              content:
                "We are sorry, but the widget has to be tested in your own web [page] (" +
                domain +
                "). Enter now! It's already available!",
              createDate: messages[0].createDate,
              role: EMessageRole.ai,
              name: "",
              functionCall: "",
              toolCalls: "",
              toolCallId: "",
              extraUIComponents: null,
              sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
              sourceId: null,
              hasMedia: false,
              status: EMessageStatus.SENT,
            },
          ]);
          setIsNewMessage(true);
          setIsTyping(false);
        } else {
          if (widget.setup.emailRetrievalMethod === "Mandatory") {
            handleToogleCreateClient();
          } else {
            if (newMessage.replaceAll(" ", "") !== "") {
              const newMessageSaver = newMessage;
              setMessages((previous) => [
                ...previous,
                {
                  content: newMessageSaver,
                  createDate: new Date().toLocaleDateString(),
                  role: EMessageRole.user,
                  name: null,
                  functionCall: null,
                  toolCalls: null,
                  toolCallId: null,
                  extraUIComponents: [],
                  sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
                  sourceId: null,
                  whatsAppMediaId: null,
                  hasMedia: false,
                  status: EMessageStatus.SENT,
                },
              ]);
              setNewMessage("");
              await createClientAuto();
              socket.emit("messagesClient", {
                content: newMessageSaver,
                clientId: cookies.get(
                  isAdmin
                    ? "WIZY_CLIENT_ADMIN_" + domain
                    : "WIZY_CLIENT_" + domain
                ),
                isTest: isTest,
                shopDomain: domain,
                path: shopifyCurrentPath,
              });
            }
          }
        }
      }
    }
  };

  // Open and close chat
  const handleToggleChat = () => {
    if (isRedirect) {
      // Check if there is any additional text for redirection link
      // This additional text can be added dinamically from store to store using custom scripts this way:

      // const dataDiv = document.getElementById("WizybotShopifyWidget__data__div");
      // const text = "?text=Hello520there!"
      // dataDiv.setAttribute('data-additionaltextredirectionlink', text)

      const dataDiv = document.getElementById(
        "WizybotShopifyWidget__data__div"
      );
      const additionalText: any =
        dataDiv?.dataset.additionaltextredirectionlink;

      // Add additional text if is the cahse
      if (additionalText) {
        window.open(redirectionLink + additionalText, "_blank");
      } else {
        window.open(redirectionLink, "_blank");
      }
    } else {
      if (chatState === EChatState.close) {
        setChatState(EChatState.open);
        setChatStateVisibility(EChatState.open);
        setIsOpen(true);
        setIsNewMessage(false);
        try {
          socket.emit("clientRead", {
            clientId: cookies.get(
              isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
            ),
            shopDomain: domain,
          });
        } catch (e) {}
      } else {
        setChatState(EChatState.close);
        setTimeout(() => {
          setIsOpen(false);
          setChatStateVisibility(EChatState.close);
        }, 800);
      }
    }
  };

  // Open and close obligatory create client prompt
  const handleToogleCreateClient = () => {
    if (createClientState === ECreateClientState.invisible) {
      setCreateClientState(ECreateClientState.visible);
      setIsNewMessage(false);
      setIsSendButtonActive(false);
    } else {
      setIsSendButtonActive(true);
      setCreateClientState(ECreateClientState.invisible);
    }
  };

  // Open and close cart view from widget
  const handleToggleCartState = async () => {
    if (cartState === ECartState.invisible) {
      getCart().then(() => {
        setCartState(ECartState.visible);
      });
      setIsSendButtonActive(false);
    } else {
      setIsSendButtonActive(true);
      setCartState(ECartState.invisible);
    }
  };

  // Check whether or not a variable is blank
  const isNotBlank = (value: any): boolean => {
    return value !== null && value !== undefined && value !== "";
  };

  // Update client init
  const updateClient = async () => {
    if (
      cookies.get(
        isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
      ) !== undefined
    ) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        websocketId: init,
      });
      await fetch(
        globalSelectedBackend +
          "/clients/" +
          cookies.get(
            isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
          ) +
          "/websocketid",
        {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          credentials: "include",
          redirect: "follow",
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          if (JSONresult.messages.length > 0) {
            setMessages([]);
            JSONresult.messages
              .sort(
                (a: IMessageBackend, b: IMessageBackend) =>
                  Date.parse(a.createDate) - Date.parse(b.createDate)
              )
              .filter((message: IMessageBackend) => {
                if (
                  (message.role === EMessageRole.ai ||
                    message.role === EMessageRole.assistant ||
                    message.role === EMessageRole.user) &&
                  isNotBlank(message.content)
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .forEach((message: IMessageBackend) => {
                setMessages((previous) => [
                  ...previous,
                  {
                    id: message.id,
                    content: message.content,
                    createDate: message.createDate,
                    role: message.role,
                    name: message.name,
                    functionCall: message.functionCall,
                    toolCalls: message.toolCalls,
                    toolCallId: message.toolCallId,
                    extraUIComponents: message.extraUIComponents
                      ? JSON.parse(message.extraUIComponents)
                      : null,
                    sourceType: message.sourceType,
                    sourceId: message.sourceId,
                    hasMedia: message.hasMedia,
                    status: message.status,
                  },
                ]);
              });
            setIsNewMessage(!JSONresult.isClientRead);
          }
        })
        .catch((error) => {
          console.log(error);
          cookies.remove(
            isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
          );
        });
    }
  };

  // Create client sale option
  const createSaleOption = async (clientId: string) => {
    if (
      !widget.setup.preventSaleNoteCreation &&
      shopifyRootPath !== null &&
      !isDashboard
    ) {
      await fetch(shopifyRootPath + "cart/update.js", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          attributes: { wizybot: true },
          note: "Wizybot sale!",
        }),
      })
        .then(async (response) => {
          await fetch(shopifyRootPath + "cart.js", {
            method: "GET",
          })
            .then((response) => {
              return response.json();
            })
            .then(async (data) => {
              var raw = JSON.stringify({
                cartId: data.token.split("?")[0],
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              await fetch(
                globalSelectedBackend +
                  "/sale/" +
                  domain +
                  "/" +
                  clientId +
                  "/createsale",
                {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  credentials: "include",
                  redirect: "follow",
                }
              )
                .then(async (response) => {
                  if (!response.ok) {
                    let errorText = await response.text();
                    let errorJSON = JSON.parse(errorText);
                    throw new Error(errorJSON.message);
                  } else {
                    return response.text();
                  }
                })
                .then((result) => JSON.parse(result))
                .then((JSONresult) => {})
                .catch((error) => {
                  console.log("Error:", error);
                });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("not in shopify");
    }
  };

  // Create client from obligatory prompt
  const createClient = async () => {
    setIsCreatingClient(true);
    if (
      cookies.get(
        isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
      ) === undefined &&
      (email.includes("@") || widget.setup.dataRetrievalType === "Phone") &&
      (phone !== "" || widget.setup.dataRetrievalType === "Email")
    ) {
      setCreateClientBackendState(ECreateClientBackendState.loading);
      const clientInfo = JSON.parse(await getClientIpInfo());
      setEmailError(false);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firstName: "",
        lastName: "",
        email: email,
        phone: phone,
        location:
          clientInfo.location.city +
          ", " +
          clientInfo.location.country.name +
          " " +
          clientInfo.location.country.flag.emoji,
        computer:
          window.navigator.platform === undefined
            ? ""
            : window.navigator.platform,
        ipAddress: clientInfo.ip,
        lastMessageDate: messages[0].createDate,
        lastMessage: messages[0].content,
        subscriptionState: subscriptionState,
        isShopRead: false,
        isClientRead: true,
        isAiEnabled: true,
        websocketId: init,
        isTest: isTest,
      });

      await fetch(globalSelectedBackend + "/clients/" + domain, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          cookies.set(
            isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain,
            JSONresult.id,
            {
              path: "/",
              maxAge: 60 * 60 * 24 * 365,
            }
          );
          handleToogleCreateClient();
          createSaleOption(JSONresult.id);
          setCreateClientBackendState(ECreateClientBackendState.send);
          setClientCreated(true);
          setIsCreatingClient(false);
          handleMessageSend();
        })
        .catch((error) => {
          console.log(error);
          setIsCreatingClient(false);
        });
    } else {
      setEmailError(true);
      setPhoneError(true);
      setIsCreatingClient(false);
      setCreateClientBackendState(ECreateClientBackendState.send);
    }
  };

  // Create client automatically from the start of the conversation
  const createClientAuto = async () => {
    setIsCreatingClient(true);
    if (
      cookies.get(
        isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain
      ) === undefined
    ) {
      setCreateClientBackendState(ECreateClientBackendState.loading);
      const clientInfo = JSON.parse(await getClientIpInfo());
      setEmailError(false);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location:
          clientInfo.location.city +
          ", " +
          clientInfo.location.country.name +
          " " +
          clientInfo.location.country.flag.emoji,
        computer:
          window.navigator.platform === undefined
            ? ""
            : window.navigator.platform,
        ipAddress: clientInfo.ip,
        lastMessageDate: messages[0].createDate,
        lastMessage: messages[0].content,
        subscriptionState: subscriptionState,
        isShopRead: false,
        isClientRead: true,
        isAiEnabled: true,
        isTest: isTest,
        websocketId: init,
      });

      await fetch(globalSelectedBackend + "/clients/" + domain, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          cookies.set(
            isAdmin ? "WIZY_CLIENT_ADMIN_" + domain : "WIZY_CLIENT_" + domain,
            JSONresult.id,
            {
              path: "/",
              maxAge: 60 * 60 * 24 * 365,
            }
          );
          createSaleOption(JSONresult.id);
          setCreateClientBackendState(ECreateClientBackendState.send);
          setClientCreated(true);
          setIsCreatingClient(false);
        })
        .catch((error) => {
          console.log(error);
          setIsCreatingClient(false);
        });
    } else {
      setEmailError(true);
      setPhoneError(true);
      setIsCreatingClient(false);
      setCreateClientBackendState(ECreateClientBackendState.send);
    }
  };

  // Get Client info from the ip (location. country, flag)
  const getClientIpInfo = async (): Promise<string> => {
    let clientInfo = "";
    await fetch("https://api.ipregistry.co/?key=" + ipRegistryKey, {
      method: "GET",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        clientInfo = JSON.stringify(JSONresult);
      })
      .catch((err) => {
        console.log(err);
      });
    return clientInfo;
  };

  // Change link format

  const convertToHTMLLink = (text: string): JSX.Element => {
    const urlPattern = /\[([^[\]]+)\]\(((https?|mailto):\/\/\S+|mailto:\S+)\)/g;
    const replacedLinkText = text.replace(
      urlPattern,
      (match, anchorText, url) =>
        `<a href="${url}" target="_top">${anchorText}</a>`
    );
    const boldPattern = /\*\*([^\*]+)\*\*/g;
    const replacedLinkBoldText = replacedLinkText.replace(
      boldPattern,
      (match, boldText) => `<strong>${boldText}</strong>`
    );
    const doubleLineBreakPattern = /\n\s*\n/g;
    const replacedLinkBoldLineText = replacedLinkBoldText.replace(
      doubleLineBreakPattern,
      "\n"
    );
    return (
      <div
        dangerouslySetInnerHTML={{ __html: replacedLinkBoldLineText }}
        style={{ whiteSpace: "pre-line" }}
      />
    );
  };
  // Add random emoji from the list
  const addRandomNiceEmoji = () => {
    const randomIndex = Math.floor(Math.random() * ecommerceEmojis.length);
    const randomEmoji = ecommerceEmojis[randomIndex];
    setNewMessage(newMessage + randomEmoji);
  };

  // Get cart content in the moment
  const getCart = async () => {
    if (shopifyRootPath !== null) {
      setIsCartLoaded(false);
      await fetch(shopifyRootPath + "cart.js", {
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setCart({
            totalPrice: data.total_price,
            currency: data.currency,
            items: data.items.map((item: any) => {
              return {
                id: item.id,
                quantity: item.quantity,
                productTitle: item.product_title,
                variantTitle: item.variant_title,
                linePrice: item.line_price,
                image: item.image,
                url: item.url,
              };
            }),
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsCartLoaded(true);
        });
    } else {
      console.log("Not in shopify");
      setIsCartLoaded(true);
    }
  };

  // Update cart content with new quantity
  const updateCart = async (cartToUpdate: ICart) => {
    setIsCartLoaded(false);
    if (shopifyRootPath !== null && cartToUpdate !== null) {
      const updates = cartToUpdate.items.reduce(
        (a, v) => ({ ...a, [v.id]: v.quantity }),
        {}
      );
      fetch(shopifyRootPath + "cart/update.js", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ updates }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setCart({
            totalPrice: data.total_price,
            currency: data.currency,
            items: data.items.map((item: any) => {
              return {
                id: item.id,
                quantity: item.quantity,
                productTitle: item.product_title,
                variantTitle: item.variant_title,
                linePrice: item.line_price,
                image: item.image,
                url: item.url,
              };
            }),
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsCartLoaded(true);
        });
    } else {
      console.log("Not in shopify");
      setIsCartLoaded(true);
    }
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <div
          className="WizybotShopifyWidget__open__button__outter"
          style={{
            right:
              widget.setup.side === ESide.RIGHT ? "0px" : "calc(100% - 80px)",
            visibility:
              chatStateVisibility === EChatState.close ? "visible" : "hidden",
            opacity: chatStateVisibility === EChatState.close ? "1" : "0",
          }}
        >
          <div
            className="WizybotShopifyWidget__open__button__inner"
            style={{
              background:
                "linear-gradient(135deg, " +
                widget.setup.primaryColor +
                " 0%, " +
                widget.setup.secondaryColor +
                " 100%)",
            }}
            onClick={() => {
              if (chatState === EChatState.close) {
                handleToggleChat();
              } else {
                handleMessageSend();
              }
            }}
          >
            <div
              className="WizybotShopifyWidget__open__button__notification rise-shake"
              style={{
                opacity:
                  chatStateVisibility === EChatState.close && isNewMessage
                    ? "1"
                    : "0",
                display: widget.setup.hideNotificationSign ? "none" : "block",
              }}
            >
              !
            </div>
            {isRedirect &&
            (redirectionLink.includes("wa") ||
              redirectionLink.includes("whatsapp")) ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="50"
                height="50"
                fill="none"
                className="WizybotShopifyWidget__open__button__image"
              >
                <path
                  d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"
                  fill={widget.setup.fontColor}
                />
              </svg>
            ) : isRedirect && redirectionLink.includes("m.me") ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="50"
                height="50"
                fill="none"
                className="WizybotShopifyWidget__open__button__image"
              >
                <path
                  d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"
                  fill={widget.setup.fontColor}
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="WizybotShopifyWidget__open__button__image"
              >
                <path
                  d="M2 5C2 3.89543 2.89543 3 4 3H11C12.1046 3 13 3.89543 13 5V9C13 10.1046 12.1046 11 11 11H9L6 14V11H4C2.89543 11 2 10.1046 2 9V5Z"
                  fill={widget.setup.fontColor}
                />
                <path
                  d="M15 7V9C15 11.2091 13.2091 13 11 13H9.82843L8.06173 14.7667C8.34154 14.9156 8.66091 15 9 15H11L14 18V15H16C17.1046 15 18 14.1046 18 13V9C18 7.89543 17.1046 7 16 7H15Z"
                  fill={widget.setup.fontColor}
                />
              </svg>
            )}
          </div>
          {visibleMessageState === EVisibleMessageState.visible ? (
            <div
              className="WizybotShopifyWidget__new__visible__message__outter"
              style={{
                opacity:
                  visibleMessageOpacity === EVisibleMessageState.visible
                    ? "1"
                    : "0",
                left: widget.setup.side === "Left" ? "-100%" : "0",
                transform:
                  widget.setup.side === "Left"
                    ? "translate(130px, -100%)"
                    : "translate(-110%, -100%)",
              }}
            >
              <div className="WisybotShopifyWidget__new__visible__message__inner">
                {visibleMessage}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="WizybotShopifyWidget__outter__outter"
          style={{
            position: "absolute",
            visibility:
              chatStateVisibility === EChatState.close ? "hidden" : "visible",
            opacity: chatStateVisibility === EChatState.close ? "0" : "1",
          }}
        >
          <div
            className={
              chatState === EChatState.open
                ? widget.setup.side === "Left"
                  ? "WizybotShopifyWidget__outter__open__left"
                  : "WizybotShopifyWidget__outter__open__right"
                : "WizybotShopifyWidget__outter__close"
            }
          >
            <div
              className="WizybotShopifyWidget__header"
              style={{
                background:
                  "linear-gradient(135deg, " +
                  widget.setup.primaryColor +
                  " 0%, " +
                  widget.setup.secondaryColor +
                  " 100%)",
              }}
            >
              <div className="WizybotShopifyWidget__header__inner">
                <div className="WizybotShopifyWidget__header__inner__inner">
                  <div className="WizybotShopifyWidget__header__picture__name">
                    <img
                      src={
                        widget.setup.image === ""
                          ? chatProfileImage
                          : widget.setup.image
                      }
                      alt="wizy_chat_profile"
                      className="WizybotShopifyWidget__header__profile__image"
                    />
                    <div className="WizybotShopifyWidget__header__name__outter">
                      <div
                        className="WizybotShopifyWidget__header__chat__with"
                        style={{ color: widget.setup.fontColor }}
                      >
                        {widget.setup.mainLanguage === "English"
                          ? "Chat with"
                          : widget.setup.mainLanguage === "Spanish"
                          ? "Habla con"
                          : widget.setup.mainLanguage === "French"
                          ? "Parler avec"
                          : widget.setup.mainLanguage === "Portuguese"
                          ? "Conversar com"
                          : widget.setup.mainLanguage === "German"
                          ? "Chatten Sie mit"
                          : widget.setup.mainLanguage === "Italian"
                          ? "Chat con"
                          : "Chat with"}
                      </div>
                      <div
                        className="WizybotShopifyWidget__header__agent__name"
                        style={{ color: widget.setup.fontColor }}
                      >
                        {" "}
                        {widget.setup.agentName}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="WizybotShopifyWidget__header__close__arrow__outter">
                    {createClientState === ECreateClientState.visible ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="WizybotShopifyWidget__header__options__dots__image"
                        onClick={() => {
                          handleToogleCreateClient();
                        }}
                      >
                        <path
                          d="M2 5C2 3.89543 2.89543 3 4 3H11C12.1046 3 13 3.89543 13 5V9C13 10.1046 12.1046 11 11 11H9L6 14V11H4C2.89543 11 2 10.1046 2 9V5Z"
                          fill={widget.setup.fontColor}
                        />
                        <path
                          d="M15 7V9C15 11.2091 13.2091 13 11 13H9.82843L8.06173 14.7667C8.34154 14.9156 8.66091 15 9 15H11L14 18V15H16C17.1046 15 18 14.1046 18 13V9C18 7.89543 17.1046 7 16 7H15Z"
                          fill={widget.setup.fontColor}
                        />
                      </svg>
                    ) : cartState === ECartState.visible ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="WizybotShopifyWidget__header__options__dots__image"
                        onClick={() => {
                          handleToggleCartState();
                        }}
                      >
                        <path
                          d="M2 5C2 3.89543 2.89543 3 4 3H11C12.1046 3 13 3.89543 13 5V9C13 10.1046 12.1046 11 11 11H9L6 14V11H4C2.89543 11 2 10.1046 2 9V5Z"
                          fill={widget.setup.fontColor}
                        />
                        <path
                          d="M15 7V9C15 11.2091 13.2091 13 11 13H9.82843L8.06173 14.7667C8.34154 14.9156 8.66091 15 9 15H11L14 18V15H16C17.1046 15 18 14.1046 18 13V9C18 7.89543 17.1046 7 16 7H15Z"
                          fill={widget.setup.fontColor}
                        />
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="WizybotShopifyWidget__header__options__dots__image"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          handleToggleCartState();
                        }}
                      >
                        <path
                          d="M3 1C2.44772 1 2 1.44772 2 2C2 2.55228 2.44772 3 3 3H4.21922L4.52478 4.22224C4.52799 4.23637 4.5315 4.25039 4.5353 4.26429L5.89253 9.69321L4.99995 10.5858C3.74002 11.8457 4.63235 14 6.41416 14H15C15.5522 14 16 13.5523 16 13C16 12.4477 15.5522 12 15 12L6.41417 12L7.41416 11H14C14.3788 11 14.725 10.786 14.8944 10.4472L17.8944 4.44721C18.0494 4.13723 18.0329 3.76909 17.8507 3.47427C17.6684 3.17945 17.3466 3 17 3H6.28078L5.97014 1.75746C5.85885 1.3123 5.45887 1 5 1H3Z"
                          fill={widget.setup.fontColor}
                        />
                        <path
                          d="M16 16.5C16 17.3284 15.3284 18 14.5 18C13.6716 18 13 17.3284 13 16.5C13 15.6716 13.6716 15 14.5 15C15.3284 15 16 15.6716 16 16.5Z"
                          fill={widget.setup.fontColor}
                        />
                        <path
                          d="M6.5 18C7.32843 18 8 17.3284 8 16.5C8 15.6716 7.32843 15 6.5 15C5.67157 15 5 15.6716 5 16.5C5 17.3284 5.67157 18 6.5 18Z"
                          fill={widget.setup.fontColor}
                        />
                      </svg>
                    )}
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="WizybotShopifyWidget__header__close__arrow__image"
                      onClick={() => {
                        if (!isRelative) {
                          handleToggleChat();
                        }
                      }}
                    >
                      <path
                        d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                        fill={widget.setup.fontColor}
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="WizybotShopifyWidget__online__phrase__outter">
                <div className="WizybotShopifyWidget__online__dot__outter">
                  <div className="WizybotShopifyWidget__online__dot__inner"></div>
                </div>
                <div
                  className="WizybotShopifyWidget__online__phrase"
                  style={{ color: widget.setup.fontColor }}
                >
                  {widget.setup.onlinePhrase}
                </div>
              </div>
              <img
                src={curvyBorderImage}
                alt="wizy_curvy_border"
                className="WizybotShopifyWidget__headder__wave"
              />
            </div>
            <div
              className={
                createClientState === ECreateClientState.invisible
                  ? "WizybotShopifyWidget__email__requirement__outter__close"
                  : "WizybotShopifyWidget__email__requirement__outter__open"
              }
            >
              <div className="WizybotShopifyWidget__email__requirement__inner">
                {widget.setup.dataRetrievalType === "Email" ||
                widget.setup.dataRetrievalType === "Both" ? (
                  <div>
                    <div className="WizybotShopifyWidget__email__input__title">
                      Email
                    </div>
                    <div className="WizybotShopifyWidget__email__input__2__outter">
                      <input
                        type="text"
                        className="WizybotShopifyWidget__email__input__2"
                        value={email}
                        onChange={(event) => {
                          const { value } = event.currentTarget;
                          setEmail(value);
                        }}
                        style={{
                          border: emailError ? "1px solid #ff3e3e" : "",
                        }}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === "Enter") {
                            if (
                              createClientBackendState ===
                                ECreateClientBackendState.send &&
                              !isCreatingClient
                            ) {
                              createClient();
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="WizybotShopifyWidget__email__input__prompt__1">
                      Eg: jhon.doe@email.com
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {widget.setup.dataRetrievalType === "Phone" ||
                widget.setup.dataRetrievalType === "Both" ? (
                  <div>
                    <div className="WizybotShopifyWidget__email__input__title">
                      {widget.setup.mainLanguage === "English"
                        ? "Phone"
                        : widget.setup.mainLanguage === "Spanish"
                        ? "Teléfono"
                        : widget.setup.mainLanguage === "French"
                        ? "Téléphone"
                        : widget.setup.mainLanguage === "Portuguese"
                        ? "Telefone"
                        : widget.setup.mainLanguage === "German"
                        ? "Telefon"
                        : widget.setup.mainLanguage === "Italian"
                        ? "Telefono"
                        : "Phone"}
                    </div>
                    <div className="WizybotShopifyWidget__email__input__2__outter">
                      <input
                        type="text"
                        className="WizybotShopifyWidget__email__input__2"
                        value={phone}
                        onChange={(event) => {
                          const { value } = event.currentTarget;
                          setPhone(value);
                        }}
                        style={{
                          border: phoneError ? "1px solid #ff3e3e" : "",
                        }}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === "Enter") {
                            if (
                              createClientBackendState ===
                                ECreateClientBackendState.send &&
                              !isCreatingClient
                            ) {
                              createClient();
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="WizybotShopifyWidget__email__input__prompt__1">
                      Eg: +573215484843
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="WizybotShopifyWidget__email__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isNotCommercialized"
                    checked={subscriptionState}
                    onChange={() => {
                      setSubscriptionState(!subscriptionState);
                    }}
                  />
                  <div className="WizybotShopifyWidget__email__input__checkbox__text">
                    {widget.setup.mainLanguage === "English"
                      ? "Sign up for our newsletter"
                      : widget.setup.mainLanguage === "Spanish"
                      ? "Suscríbete a nuestro boletín"
                      : widget.setup.mainLanguage === "French"
                      ? "Inscrivez-vous à notre newsletter"
                      : widget.setup.mainLanguage === "Portuguese"
                      ? "Assine a nossa newsletter"
                      : widget.setup.mainLanguage === "German"
                      ? "Melden Sie sich für unseren Newsletter an"
                      : widget.setup.mainLanguage === "Italian"
                      ? "Iscriviti alla nostra newsletter"
                      : "Sign up for our newsletter"}
                  </div>
                </div>
                <div className="WizybotShopifyWidget__email__button__outter">
                  <button
                    className="WizybotShopifyWidget__email__button"
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(135deg, " +
                        widget.setup.primaryColor +
                        " 0%, " +
                        widget.setup.secondaryColor +
                        " 100%)",
                      color: widget.setup.fontColor,
                    }}
                    onClick={() => {
                      if (
                        createClientBackendState ===
                          ECreateClientBackendState.send &&
                        !isCreatingClient
                      ) {
                        createClient();
                      }
                    }}
                  >
                    {createClientBackendState === ECreateClientBackendState.send
                      ? widget.setup.mainLanguage === "English"
                        ? "Send"
                        : widget.setup.mainLanguage === "Spanish"
                        ? "Enviar"
                        : widget.setup.mainLanguage === "French"
                        ? "Envoyer"
                        : widget.setup.mainLanguage === "Portuguese"
                        ? "Enviar"
                        : widget.setup.mainLanguage === "German"
                        ? "Schicken"
                        : widget.setup.mainLanguage === "Italian"
                        ? "Inviare"
                        : "Send"
                      : widget.setup.mainLanguage === "English"
                      ? "Loading..."
                      : widget.setup.mainLanguage === "Spanish"
                      ? "Cargando..."
                      : widget.setup.mainLanguage === "French"
                      ? "Chargement..."
                      : widget.setup.mainLanguage === "Portuguese"
                      ? "Carregando..."
                      : widget.setup.mainLanguage === "German"
                      ? "Wird geladen..."
                      : widget.setup.mainLanguage === "Italian"
                      ? "Caricamento..."
                      : "Loading..."}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={
                cartState === ECartState.invisible
                  ? "WizybotShopifyWidget__cart__outter__close"
                  : "WizybotShopifyWidget__cart__outter__open"
              }
            >
              {cart !== null && isCartLoaded ? (
                <ShopifyWidgetCart
                  cart={cart}
                  updateCart={updateCart}
                  language={widget.setup.mainLanguage}
                  agentName={widget.setup.agentName}
                  closeCart={handleToggleCartState}
                />
              ) : isCartLoaded ? (
                <div>
                  <div>
                    <div className="WizybotShopifyWidget__cart__outter__products">
                      <div className="WizybotShopifyWidget__cart__outter__no__products">
                        {widget.setup.mainLanguage === "English"
                          ? "Hello there! It seems you have not added anything to your cart, why don't you ask us for some recommendations?"
                          : widget.setup.mainLanguage === "Spanish"
                          ? "¡Hola! Parece que no has añadido nada a tu carrito, ¿por qué no nos pides algunas recomendaciones?"
                          : widget.setup.mainLanguage === "French"
                          ? "Bonjour! Il semble que vous n'avez rien ajouté à votre panier, pourquoi ne pas nous demander des recommandations?"
                          : widget.setup.mainLanguage === "Portuguese"
                          ? "Olá! Parece que você não adicionou nada ao seu carrinho, por que não nos pede algumas recomendações?"
                          : widget.setup.mainLanguage === "German"
                          ? "Hallo! Es scheint, dass Sie nichts in Ihren Warenkorb gelegt haben. Warum fragen Sie uns nicht nach einigen Empfehlungen?"
                          : widget.setup.mainLanguage === "Italian"
                          ? "Ciao! Sembra che tu non abbia aggiunto nulla al carrello, perché non ci chiedi qualche consiglio?"
                          : "Hello there! It seems you have not added anything to your cart, why don't you ask us for some recommendations?"}
                        <div className="WizybotShopifyWidget__cart__inner__estimated__button__outter">
                          <button
                            className="WizybotShopifyWidget__cart__inner__estimated__no__products__button"
                            style={{ width: "100%" }}
                            onClick={handleToggleCartState}
                          >
                            {widget.setup.mainLanguage === "English"
                              ? "Chat with"
                              : widget.setup.mainLanguage === "Spanish"
                              ? "Habla con"
                              : widget.setup.mainLanguage === "French"
                              ? "Parler avec"
                              : widget.setup.mainLanguage === "Portuguese"
                              ? "Conversar com"
                              : widget.setup.mainLanguage === "German"
                              ? "Chatten Sie mit"
                              : widget.setup.mainLanguage === "Italian"
                              ? "Chat con"
                              : "Chat with"}{" "}
                            {widget.setup.agentName}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="WizybotShopifyWidget__cart__outter__checkout">
                      <div className="WizybotShopifyWidget__cart__outter__estimated">
                        <div className="WizybotShopifyWidget__cart__inner__estimated">
                          {widget.setup.mainLanguage === "English"
                            ? "Estimated total"
                            : widget.setup.mainLanguage === "Spanish"
                            ? "Total estimado"
                            : widget.setup.mainLanguage === "French"
                            ? "Total estimé"
                            : widget.setup.mainLanguage === "Portuguese"
                            ? "Total estimado"
                            : widget.setup.mainLanguage === "German"
                            ? "Geschätzte Gesamtsumme"
                            : widget.setup.mainLanguage === "Italian"
                            ? "Totale stimato"
                            : "Estimated total"}
                        </div>{" "}
                        <div className="WizybotShopifyWidget__cart__inner__estimated__price">
                          $0.00
                        </div>
                      </div>
                      <div className="WizybotShopifyWidget__cart__inner__estimated__button__outter">
                        <a
                          href="/cart"
                          target="_top"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <button
                            className="WizybotShopifyWidget__cart__inner__estimated__button"
                            style={{ width: "100%" }}
                          >
                            {widget.setup.mainLanguage === "English"
                              ? "Pay"
                              : widget.setup.mainLanguage === "Spanish"
                              ? "Pagar"
                              : widget.setup.mainLanguage === "French"
                              ? "Payer"
                              : widget.setup.mainLanguage === "Portuguese"
                              ? "Pagar"
                              : widget.setup.mainLanguage === "German"
                              ? "Bezahlen"
                              : widget.setup.mainLanguage === "Italian"
                              ? "Pagare"
                              : "Pay"}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <div className="WizybotShopifyWidget__cart__outter__products">
                      <div
                        className="WizybotShopifyWidget__cart__outter__no__products"
                        style={{ width: "fit-content" }}
                      >
                        <div className="loader"></div>
                      </div>
                    </div>
                    <div className="WizybotShopifyWidget__cart__outter__checkout">
                      <div className="WizybotShopifyWidget__cart__outter__estimated">
                        <div className="WizybotShopifyWidget__cart__inner__estimated">
                          {widget.setup.mainLanguage === "English"
                            ? "Estimated total"
                            : widget.setup.mainLanguage === "Spanish"
                            ? "Total estimado"
                            : widget.setup.mainLanguage === "French"
                            ? "Total estimé"
                            : widget.setup.mainLanguage === "Portuguese"
                            ? "Total estimado"
                            : widget.setup.mainLanguage === "German"
                            ? "Geschätzte Gesamtsumme"
                            : widget.setup.mainLanguage === "Italian"
                            ? "Totale stimato"
                            : "Estimated total"}
                        </div>{" "}
                        <div className="WizybotShopifyWidget__cart__inner__estimated__price">
                          <div
                            className="loader"
                            style={{
                              position: "absolute",
                              transform: "translate(-100%, -50%)",
                              left: "100%",
                              top: "50%",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="WizybotShopifyWidget__cart__inner__estimated__button__outter">
                        <a
                          href="/cart"
                          target="_top"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <button
                            className="WizybotShopifyWidget__cart__inner__estimated__button"
                            style={{ width: "100%" }}
                          >
                            {widget.setup.mainLanguage === "English"
                              ? "Pay"
                              : widget.setup.mainLanguage === "Spanish"
                              ? "Pagar"
                              : widget.setup.mainLanguage === "French"
                              ? "Payer"
                              : widget.setup.mainLanguage === "Portuguese"
                              ? "Pagar"
                              : widget.setup.mainLanguage === "German"
                              ? "Bezahlen"
                              : widget.setup.mainLanguage === "Italian"
                              ? "Pagare"
                              : "Pay"}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="WizybotShopifyWidget__chat__outter">
              <div className="WizybotShopifyWidget__chat__inner">
                {messages.map((message, index) => {
                  return (
                    <div style={{ width: "100%" }} key={index}>
                      <div className="WizybotShopifyWidget__chat__message__outter">
                        <div
                          className="WizybotShopifyWidget__chat__message__inner"
                          style={{
                            marginLeft:
                              message.role === EMessageRole.user ? "auto" : "",
                            background:
                              message.role === EMessageRole.user
                                ? "linear-gradient(135deg, " +
                                  widget.setup.primaryColor +
                                  " 0%, " +
                                  widget.setup.secondaryColor +
                                  " 100%)"
                                : "",
                            color:
                              message.role === EMessageRole.user
                                ? widget.setup.fontColor
                                : "black",
                          }}
                        >
                          {convertToHTMLLink(message.content)}
                        </div>
                      </div>
                      {message.extraUIComponents ? (
                        message.extraUIComponents.length > 0 ? (
                          message.extraUIComponents.map(
                            (extraUIComponent, i) => {
                              if (
                                extraUIComponent.type ===
                                EExtraUIComponentTypes.ADD_TO_CART
                              ) {
                                return (
                                  <Add2Cart
                                    content={extraUIComponent.content}
                                    extraUIComponentIndex={i}
                                    globalSelectedBackend={
                                      globalSelectedBackend
                                    }
                                    domain={domain}
                                    shopifyRootPath={shopifyRootPath}
                                    messageId={message.id ? message.id : ""}
                                    language={widget.setup.mainLanguage}
                                    openCart={handleToggleCartState}
                                  />
                                );
                              } else if (
                                extraUIComponent.type ===
                                EExtraUIComponentTypes.RECOMMENDATION_CAROUSEL
                              ) {
                                return (
                                  <RecommendationCarousel
                                    content={extraUIComponent.content}
                                    language={widget.setup.mainLanguage}
                                  />
                                );
                              } else return <></>;
                            }
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
                {isTyping ? (
                  <div
                    className="WizybotShopifyWidget__chat__message__outter"
                    key="LoadingDots"
                  >
                    <div
                      className="WizybotShopifyWidget__chat__message__inner"
                      style={{
                        color: "black",
                      }}
                    >
                      <div className="loader"></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="WizybotShopifyWidget__input__outter">
              <div
                className="WizybotShopifyWidget__send__button__outter"
                style={{
                  background:
                    "linear-gradient(135deg, " +
                    widget.setup.primaryColor +
                    " 0%, " +
                    widget.setup.secondaryColor +
                    " 100%)",
                  opacity: isSendButtonActive ? "1" : "0",
                  visibility: isSendButtonActive ? "visible" : "hidden",
                }}
                onClick={() => {
                  if (chatState === EChatState.close) {
                    handleToggleChat();
                  } else {
                    handleMessageSend();
                  }
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="WizybotShopifyWidget__open__button__image"
                  style={{
                    transform: "scale(1) translate(-50%, -50%)",
                  }}
                >
                  <path
                    d="M17.4472 9.10556C17.786 9.27495 18 9.62122 18 9.99999C18 10.3788 17.786 10.725 17.4472 10.8944L3.44721 17.8944C3.09251 18.0718 2.66653 18.0228 2.36136 17.7695C2.0562 17.5162 1.92953 17.1066 2.03848 16.7253L3.46704 11.7253C3.5897 11.296 3.98209 11 4.42857 11L9 11C9.55229 11 10 10.5523 10 10C10 9.44771 9.55229 9 9 9H4.42857C3.98209 9 3.58971 8.70402 3.46705 8.27472L2.03848 3.27471C1.92953 2.8934 2.0562 2.48374 2.36136 2.23048C2.66653 1.97722 3.09251 1.92821 3.44721 2.10556L17.4472 9.10556Z"
                    fill={widget.setup.fontColor}
                  />
                </svg>
              </div>
              <div className="WizybotShopifyWidget__input__inner">
                <div className="WizybotShopifyWidget__chat__input__outter">
                  <input
                    name="message"
                    value={newMessage}
                    type="text"
                    className="WizybotShopifyWidget__chat__input"
                    placeholder={
                      widget.setup.mainLanguage === "English"
                        ? "Enter your message..."
                        : widget.setup.mainLanguage === "Spanish"
                        ? "Ingrese su mensaje.."
                        : widget.setup.mainLanguage === "French"
                        ? "Entrez votre message..."
                        : widget.setup.mainLanguage === "Portuguese"
                        ? "Digite sua mensagem..."
                        : widget.setup.mainLanguage === "German"
                        ? "Geben Sie eine Nachricht ein..."
                        : widget.setup.mainLanguage === "Italian"
                        ? "Inserisci il tuo messaggio..."
                        : "Enter your message..."
                    }
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                      setNewMessage(event.currentTarget.value);
                    }}
                    onKeyDown={(
                      event: React.KeyboardEvent<HTMLInputElement>
                    ) => {
                      if (event.key === "Enter") {
                        handleMessageSend();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="WizybotShopifyWidget__emojis__propaganda__outter">
              <div className="WizybotShopifyWidget__emojis__propaganda__inner">
                <div
                  className="WizybotShopifyWidget__emojis__outter"
                  onClick={addRandomNiceEmoji}
                >
                  <svg
                    id="ic_emojiSwitch"
                    fill="#000000"
                    height="20"
                    viewBox="0 0 24 24"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="WizybotShopifyWidget__emojis__image"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path>
                  </svg>
                </div>
                <div
                  className="WizybotShopifyWidget__propaganda__outter"
                  style={{
                    visibility: widget.setup.hideWizybotBanner
                      ? "hidden"
                      : "visible",
                  }}
                >
                  <div className="WizybotShopifyWidget__propaganda__text">
                    POWERED BY
                  </div>
                  <a
                    href="https://apps.shopify.com/wizybot"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div className="WizybotShopifyWidget__propaganda__logo__outter">
                      {" "}
                      <img
                        src={wizyLogoImage}
                        alt="wizy_logo_blue"
                        className="WizybotShopifyWidget__propaganda__logo__image"
                      />{" "}
                      <div className="WizybotShopifyWidget__propaganda__logo__text">
                        Wizybot
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
};

// Default exported function
export default ShopifyWidget;
