// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";
import wizy_add_outline from "../images/wizy_add_outline.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_question_filled from "../images/wizy_question_filled.svg";
import wizy_description_outline from "../images/wizy_description_outline.svg";

// Import styles
import "./styles/CachedResponses.css";

// Declare types and interfaces
import { EShopStatus, IShop } from "../types/ShopType";
import { IPlan } from "../types/PlanType";
import ProgressBar from "../components/ProgressBar";
import AddCacheModal from "../components/AddCacheModal";
import wizy_mg_outline from "../images/wizy_mg_outline.svg";
import Pagination, { IPageMeta } from "../components/Pagination";
import { ICachedResponse } from "../types/CachedResponseType";

enum ECachedResponsesOutter {
  normal,
  filter,
  showcase,
}

type IFilter = {
  text: string;
  initialDate: string;
  finalDate: string;
};

// Page main functional component
const CachedResponses: FC = () => {
  // Use parameters
  const { id } = useParams<{ id: string | undefined }>();

  // Use translation
  const { t } = useTranslation(["cachedResponses"]);

  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const [globalShop, setGlobalShop] = useState<IShop>({
    id: "",
    domain: "",
    needsSetup: false,
    needsSync: false,
    needsTest:  false,
    needsOnboarding:  false,
    status: EShopStatus.uninstalled,
    currentPlan: emptyPlan,
    createDate: "",
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
    storeMessageEmbeddings: false,
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isStoreMessagesLoading, setIsStoreMessagesLoading] =
    useState<boolean>(false);
  const [messageEmbeddingsPercentage, setMessageEmbeddingsPercentage] =
    useState<number>(0);
  const [isAddCacheModalOpen, setIsAddCacheModalOpen] =
    useState<boolean>(false);
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [cachedResponses, setCachedResponses] = useState<ICachedResponse[]>([]);
  const [selectedCachedResponse, setSelectedCachedResponse] =
    useState<ICachedResponse>({
      id: "",
      userMessage: "",
      aiResponse: "",
      minSimilarity: 0,
      createDate: "",
      selected: false,
    });
  const [isShowcaseLoaded, setIsShowcaseLoaded] = useState<boolean>(true);
  const [storedEmbeddingMessageCount, setStoredEmbeddingMessageCount] =
    useState<number>(0);

  const [updateCachedResponseForm, setUpdateCachedResponseForm] = useState<{
    aiResponse: string;
  }>({ aiResponse: "" });

  const CachedResponsesOutterRef = useRef<HTMLDivElement>(null);

  //Internal functions
  // Use Effect Functions
  useEffect(() => {
    getGlobalShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (globalShop.storeMessageEmbeddings) {
      getStoredEmbeddingMessageCount();
    } else {
      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalShop]);

  const getGlobalShop = async () => {
    await fetch(globalSelectedBackend + "/admin/merchantshop/" + id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setGlobalShop({
          id: JSONresult.id,
          domain: JSONresult.domain,
          needsSetup: JSONresult.needsSetup,
          needsSync: JSONresult.needsSync,
          needsTest:  JSONresult.needsTest,
          needsOnboarding:  JSONresult.needsOnboarding,
          status: JSONresult.status,
          currentPlan: JSONresult.currentPlan,
          customOfferPlan: JSONresult.customOfferPlan,
          createDate: JSONresult.createDate,
          shopCurrency: JSONresult.shopCurrency,
          shopName: JSONresult.shopName,
          shopMainDomain: JSONresult.shopMainDomain,
          storeMessageEmbeddings: JSONresult.storeMessageEmbeddings,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [filterChange, setFilterChange] = useState<IFilter>({
    text: "",
    initialDate: "",
    finalDate: "",
  });
  const [filter, setFilter] = useState<IFilter>({
    text: "",
    initialDate: "",
    finalDate: "",
  });

  const formatDate2 = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  const startStoreMessageEmbeddings = async () => {
    setIsStoreMessagesLoading(true);
    await fetch(
      globalSelectedBackend +
        "/cachedresponses/startstoremessageembeddings/" +
        globalShop.id,
      {
        method: "POST",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then(async (JSONresult) => {
        if (JSONresult.status) {
          while (true) {
            const messageEmbeddingsStatus = await getMessageEmbeddingStatus();
            setMessageEmbeddingsPercentage(messageEmbeddingsStatus.percentage);
            if (messageEmbeddingsStatus.done) {
              window.location.reload();
            }
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMessageEmbeddingStatus = async (): Promise<{
    done: boolean;
    percentage: number;
  }> => {
    let messageEmbeddingStatus = { done: false, percentage: 0 };
    await fetch(
      globalSelectedBackend +
        "/cachedresponses/messageembeddingstatus/" +
        globalShop.id,
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then(async (JSONresult) => {
        messageEmbeddingStatus = {
          done: JSONresult.done,
          percentage: JSONresult.percentage,
        };
      })
      .catch((err) => {
        console.log(err);
      });
    return messageEmbeddingStatus;
  };

  const handleCachedResponsesGrid = (state: ECachedResponsesOutter) => {
    switch (state) {
      case ECachedResponsesOutter.normal:
        if (CachedResponsesOutterRef.current !== null) {
          CachedResponsesOutterRef.current.style.gridTemplateColumns =
            "0px 1fr 0px";
        }
        break;
      case ECachedResponsesOutter.filter:
        if (CachedResponsesOutterRef.current !== null) {
          CachedResponsesOutterRef.current.style.gridTemplateColumns =
            "320px 1fr 0px";
        }
        break;
      case ECachedResponsesOutter.showcase:
        if (CachedResponsesOutterRef.current !== null) {
          CachedResponsesOutterRef.current.style.gridTemplateColumns =
            "0px 1fr 320px";
        }
        break;
      default:
        console.log("Upps");
    }
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getStoredEmbeddingMessageCount = async () => {
    await fetch(
      globalSelectedBackend +
        "/cachedresponses/storedembeddingmessagecount/" +
        globalShop.id,
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => {
        setStoredEmbeddingMessageCount(parseInt(result));
        getCachedResponses(pageMeta.take, pageMeta.page);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCachedResponses = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/cachedresponses/shop/" +
      globalShop.id +
      "/?take=" +
      take.toString() +
      "&page=" +
      page.toString();

    // Add filter queries to URL
    if (filter?.initialDate && filter.finalDate) {
      searchURL +=
        "&initialDate=" + filter.initialDate + "&finalDate=" + filter.finalDate;
    }
    if (filter?.text) {
      searchURL += "&text=" + filter.text;
    }

    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCachedResponses([]);
        JSONresult.data.forEach((cachedResponse: ICachedResponse) => {
          setCachedResponses((previous) => [
            ...previous,
            {
              id: cachedResponse.id,
              userMessage: cachedResponse.userMessage,
              aiResponse: cachedResponse.aiResponse,
              minSimilarity: cachedResponse.minSimilarity,
              createDate: cachedResponse.createDate,
              selected: false,
            },
          ]);
        });
        setPageMeta({
          itemCount: JSONresult.meta.itemCount,
          pageCount: JSONresult.meta.pageCount,
          page: parseInt(JSONresult.meta.page),
          take: parseInt(JSONresult.meta.take),
          hasNextPage: JSONresult.meta.hasNextPage,
          hasPreviousPage: JSONresult.meta.hasPreviousPage,
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCachedResponseSelect = (cachedResponse: ICachedResponse) => {
    setSelectedCachedResponse(cachedResponse);
    setUpdateCachedResponseForm({ aiResponse: cachedResponse.aiResponse });
  };

  const handleUpdateFormChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;
    setUpdateCachedResponseForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateCachedResponse = async () => {
    let searchURL =
      globalSelectedBackend + "/cachedresponses/" + selectedCachedResponse.id;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      aiResponse: updateCachedResponseForm.aiResponse,
    });

    await fetch(searchURL, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCachedResponse = async () => {
    let searchURL =
      globalSelectedBackend + "/cachedresponses/" + selectedCachedResponse.id;
    await fetch(searchURL, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => window.location.reload())
      .catch((err) => {
        console.log(err);
      });
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        {!globalShop.storeMessageEmbeddings ? (
          <>
            <div className="CachedResponses__prev__outter">
              <div className="CachedResponses__prev__inner">
                <div className="CachedResponses__prev__inner__inner">
                  <div className="CachedResponses__prev__inner__inner__title">
                    {t("CachedResponses", { ns: ["cachedResponses"] })}
                  </div>
                  <div className="CachedResponses__prev__inner__inner__description">
                    {isStoreMessagesLoading
                      ? t("Loading", { ns: ["cachedResponses"] })
                      : t("NoCache", { ns: ["cachedResponses"] })}
                  </div>
                  <div
                    className="CachedResponses__prev__button__outter"
                    style={isStoreMessagesLoading ? { display: "none" } : {}}
                  >
                    <button
                      className="Wizy__button__2"
                      style={{ width: "200px" }}
                      onClick={() => startStoreMessageEmbeddings()}
                    >
                      {t("Activate", {
                        ns: ["cachedResponses"],
                      })}
                    </button>
                  </div>
                  <div
                    style={
                      !isStoreMessagesLoading
                        ? { display: "none" }
                        : { marginTop: "5px" }
                    }
                  >
                    <ProgressBar
                      completed={messageEmbeddingsPercentage}
                    ></ProgressBar>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Add Knowledge Modal */}
            {isAddCacheModalOpen ? (
              <AddCacheModal
                setIsOpen={setIsAddCacheModalOpen}
                storedEmbeddingMessageCount={storedEmbeddingMessageCount}
              />
            ) : (
              ""
            )}
            {/* The rest of the page */}
            <div
              ref={CachedResponsesOutterRef}
              className="CachedResponses__outter"
            >
              <div className="CachedResponses__filters__outter">
                <div
                  className="CachedResponses__filters__outter__close"
                  onClick={() => {
                    handleCachedResponsesGrid(ECachedResponsesOutter.normal);
                  }}
                >
                  {" "}
                  <img
                    src={wizy_hide_side_menu_outline}
                    alt="wizy_hide_side_menu_outline"
                    className="CachedResponses__filters__outter__close__image"
                  />
                </div>
                {/* Filters */}
                <div className="CachedResponses__filters__inner">
                  {/* Title */}
                  <div className="CachedResponses__filters__title">
                    {" "}
                    {t("Filters", { ns: ["cachedResponses"] })}
                  </div>
                  {/* Description */}
                  <div className="CachedResponses__filters__description">
                    {t("FiltersD", { ns: ["cachedResponses"] })}
                  </div>
                  {/* Type filters */}
                  <div className="CachedResponses__filters__fields__outter">
                    <div className="Wizy__input__title">
                      {t("Text", { ns: ["cachedResponses"] })}
                    </div>
                    <div className="Wizy__input__1__outter">
                      <input
                        name="text"
                        value={filterChange.text}
                        type="text"
                        className="Wizy__input__1"
                        onChange={handleFilterChange}
                      />
                      <img
                        src={wizy_mg_outline}
                        alt="wizy_mg_outline"
                        className="Wizy__input__1__image"
                      />
                    </div>
                    <div className="Wizy__input__prompt__1">
                      {t("Eg", { ns: ["cachedResponses"] })}
                    </div>
                    <div className="Wizy__input__title">
                      {t("Date", { ns: ["cachedResponses"] })}
                    </div>
                    <input
                      type="date"
                      value={filterChange.initialDate}
                      name="initialDate"
                      className="Wizy__input__date"
                      style={{ marginTop: "10px" }}
                      onChange={handleFilterChange}
                    />
                    <div className="Wizy__input__prompt__1">
                      {t("DateI", { ns: ["cachedResponses"] })}
                    </div>
                    <input
                      type="date"
                      value={filterChange.finalDate}
                      name="finalDate"
                      className="Wizy__input__date"
                      onChange={handleFilterChange}
                    />
                    <div className="Wizy__input__prompt__1">
                      {t("DateF", { ns: ["cachedResponses"] })}
                    </div>
                  </div>
                  {/* Apply filters button */}
                  <div className="CachedResponses__apply__button__outter">
                    <button
                      className="Wizy__button__2"
                      style={{ width: "100%" }}
                      onClick={() => {
                        setFilter(filterChange);
                        // handleTicketToogleAll(false);
                      }}
                    >
                      {t("Apply", { ns: ["cachedResponses"] })}
                    </button>
                  </div>
                  {/* Reset filters button */}
                  <div className="CachedResponses__apply__button__outter">
                    <button
                      className="Wizy__button__3"
                      style={{ width: "100%" }}
                      onClick={() => {
                        setFilter({
                          text: "",
                          initialDate: "",
                          finalDate: "",
                        });
                        setFilterChange({
                          text: "",
                          initialDate: "",
                          finalDate: "",
                        });
                        // handleTicketToogleAll(false);
                      }}
                    >
                      {t("Reset", { ns: ["cachedResponses"] })}
                    </button>
                  </div>
                </div>
              </div>
              <div className="CachedResponses__list__outter">
                <div className="CachedResponses__list__inner">
                  <div className="CachedResponses__list__title__outter">
                    <div className="CachedResponses__list__title__inner">
                      <div className="CachedResponses__list__title__inner__inner">
                        <div className="CachedResponses__list__title__description">
                          <div className="CachedResponses__list__title__inner__text__title">
                            {t("CachedResponses", { ns: ["cachedResponses"] })}
                          </div>
                          <div className="CachedResponses__list__title__inner__text__description">
                            {t("CachedResponsesD", { ns: ["cachedResponses"] })}
                          </div>
                        </div>
                        <div className="CachedResponses__list__arrow__outter">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            preserveAspectRatio="none"
                          >
                            <polygon
                              points="29 66 28 66 38 33 28 0 29 0 39 33 29 66"
                              fill="#e6e9ed"
                            ></polygon>
                          </svg>
                        </div>
                        <div
                          className="CachedResponses__list__add__filters__outter"
                          onClick={() => {
                            handleCachedResponsesGrid(
                              ECachedResponsesOutter.filter
                            );
                          }}
                        >
                          <img
                            src={wizy_add_outline}
                            alt="wizy_add_outline"
                            className="CachedResponses__list__add__filters__image"
                          />
                          {t("Filters", { ns: ["cachedResponses"] })}
                        </div>
                        <div className="CachedResponses__list__create__button__outter">
                          <div className="CachedResponses__list__create__button__inner">
                            <button
                              className="Wizy__button__4"
                              style={{ fontSize: "16px" }}
                              onClick={() => setIsAddCacheModalOpen(true)}
                            >
                              <img
                                src={wizy_add_outline}
                                alt="wizy_add_outline"
                                className="Wizy__button__image"
                              />
                              {t("AddResponse", { ns: ["cachedResponses"] })}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="CachedResponses__list__list__outter">
                    <div className="CachedResponses__list__list__header__outter">
                      <div className="CachedResponses__list__list__header__inner">
                        {/* Pagination */}
                        <div className="CachedResponses__list__list__pagination__outter">
                          <Pagination
                            pageMeta={pageMeta}
                            getElements={getCachedResponses}
                          ></Pagination>
                        </div>
                      </div>
                    </div>
                    <div className="CachedResponses__list__list__titles__outter">
                      <div className="CachedResponses__list__list__titles__inner">
                        <div className="CachedResponses__list__list__titles__inner__inner">
                          {t("UserMessage", { ns: ["cachedResponses"] })}
                        </div>
                        <div
                          className="CachedResponses__list__list__titles__inner__inner"
                          id="CachedResponses__date__field"
                        >
                          {t("DateC", { ns: ["cachedResponses"] })}
                        </div>
                      </div>
                    </div>
                    <div className="CachedResponses__list__list__items__outter">
                      {cachedResponses
                        .sort(
                          (a: ICachedResponse, b: ICachedResponse) =>
                            Date.parse(b.createDate) - Date.parse(a.createDate)
                        )
                        .map((cachedResponse, index) => {
                          return (
                            <div
                              className="CachedResponses__list__list__item__outter"
                              onClick={() => {
                                handleCachedResponsesGrid(
                                  ECachedResponsesOutter.showcase
                                );
                                handleCachedResponseSelect(cachedResponse);
                              }}
                              key={index}
                            >
                              <div className="CachedResponses__list__list__item__inner">
                                <div
                                  className="CachedResponses__list__list__item__inner__inner"
                                  id="CachedResponses__description__field"
                                >
                                  {cachedResponse.userMessage}
                                </div>
                                <div
                                  className="CachedResponses__list__list__item__inner__inner"
                                  id="CachedResponses__date__field"
                                >
                                  {formatDate2(
                                    new Date(cachedResponse.createDate)
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="CachedResponses__showcase__outter">
                <div
                  className="CachedResponses__showcase__outter__close"
                  onClick={() => {
                    handleCachedResponsesGrid(ECachedResponsesOutter.normal);
                  }}
                >
                  <img
                    src={wizy_hide_side_menu_outline}
                    alt="wizy_hide_side_menu_outline"
                    className="CachedResponses__showcase__outter__close__image"
                  />
                </div>

                {!isShowcaseLoaded ? (
                  <div className="CachedResponses__showcase__inner">
                    <img
                      src={wizy_loader}
                      className="ProtectedRoute__loader"
                      alt="ProtectedRoute__loader"
                      id="ProtectedRoute__loader"
                    />
                  </div>
                ) : (
                  <div className="CachedResponses__showcase__inner">
                    <div className="CachedResponses__showcase__information__outter">
                      <div className="CachedResponses__showcase__infotmation__item__outter">
                        <div className="CachedResponses__showcase__infotmation__item__inner">
                          <div>
                            <img
                              src={wizy_calendar_filled}
                              alt="wizy_calendar_filled"
                              className="CachedResponses__showcase__information__item__image"
                            />
                          </div>
                          <div className="CachedResponses__showcase__information__item__text">
                            {formatDate2(
                              new Date(selectedCachedResponse.createDate)
                            )}
                          </div>
                        </div>
                        <div className="CachedResponses__showcase__infotmation__item__inner">
                          <div>
                            <img
                              src={wizy_question_filled}
                              alt="wizy_add_outline"
                              className="CachedResponses__showcase__information__item__image"
                            />
                          </div>
                          <div className="CachedResponses__showcase__information__item__text">
                            <div className="Wizy__input__6__outter">
                              {selectedCachedResponse.userMessage}
                            </div>
                          </div>
                        </div>
                        <div className="CachedResponses__showcase__infotmation__item__inner">
                          <div>
                            <img
                              src={wizy_description_outline}
                              alt="wizy_description_outline"
                              className="CachedResponses__showcase__information__item__image"
                            />
                          </div>
                          <div className="CachedResponses__showcase__information__item__text">
                            <textarea
                              name="aiResponse"
                              value={updateCachedResponseForm.aiResponse}
                              className="Wizy__textarea"
                              onChange={handleUpdateFormChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CachedResponses__apply__button__outter">
                      <button
                        className="Wizy__button__2"
                        style={{ width: "100%" }}
                        onClick={() => {
                          setIsShowcaseLoaded(false);
                          updateCachedResponse();
                        }}
                      >
                        {t("Update", { ns: ["cachedResponses"] })}
                      </button>
                    </div>
                    <div className="CachedResponses__apply__button__outter">
                      <button
                        className="Wizy__button__5"
                        style={{ width: "100%" }}
                        onClick={() => {
                          setIsShowcaseLoaded(false);
                          deleteCachedResponse();
                        }}
                      >
                        {t("Delete", { ns: ["cachedResponses"] })}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <div className="Setting__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default CachedResponses;
