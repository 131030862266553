import React, { FC, useState } from "react";

// Import media
import wizy_chevron_down_outline from "../images/wizy_chevron_down_outline.svg";
import wizy_chevron_up_outline from "../images/wizy_chevron_up_outline.svg";

// Import styles
import "./styles/SearchableMultipleDropdown.css";
import { useTranslation } from "react-i18next";

// Declare types and interfaces
export type IOption = {
  id: string;
  label: string;
};

export type ISearchableMultipleDropdownProps = {
  queryState: [string, React.Dispatch<React.SetStateAction<string>>]
  optionsState: [IOption[], React.Dispatch<React.SetStateAction<IOption[]>>];
  selectedOptionsState: [
    IOption[],
    React.Dispatch<React.SetStateAction<IOption[]>>
  ];
  placeholder: string;
};

// Page main functional component
const SearchableMultipleDropdown: FC<ISearchableMultipleDropdownProps> = ({
  queryState: [query, setQuery],
  optionsState: [options, setOptions],
  selectedOptionsState: [selectedOptions, setSelectedOptions],
  placeholder,
}: ISearchableMultipleDropdownProps) => {
  // Use translation
  const { t } = useTranslation(["dropdown"]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const filter = (
    options: IOption[],
    selectedOptions: IOption[],
    query: string
  ): IOption[] => {
    const filteredOptions = options
      .filter((option) => !selectedOptions.map((option)=>option.id).includes(option.id))
      .filter(
        (option) => option.label.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    return filteredOptions;
  };

  // Function to remove tag when the x is pressed
  const removeSelectedOption = (index: number) => {
    setSelectedOptions(selectedOptions.filter((el, i) => i !== index));
  };

  return (
    <div className="dropdown">
      <div className="Dropdown__input__container" onClick={() => setIsOpen(!isOpen)}>
        {selectedOptions.map((option, index) => (
          <div className="Dropdown__element">
            {option.label}
            <span
              className="Dropdown__remove__button"
              onClick={() => removeSelectedOption(index)}
            >
              <svg viewBox="0 0 14 14" className="Dropdown__remove__icon">
                <path d="M4 4l6 6m0-6l-6 6" />
              </svg>
            </span>
          </div>
        ))}
        <input
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            const { value } = event.currentTarget;
            setQuery(value);
            if (value !== "") {
              setIsOpen(true);
            }
          }}
          value={query}
          name="query"
          type="text"
          className="Dropdown__input"
          placeholder={placeholder}
        />
        <img
          className="Dropdown__chevron__image"
          src={isOpen ? wizy_chevron_down_outline : wizy_chevron_up_outline}
          alt="Wizybot"
        ></img>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options, selectedOptions, query).length > 0 ? (
          filter(options, selectedOptions, query).map((option, index) => {
            return (
              <div
                className={`option`}
                key={option.id}
                onClick={() => {
                  setSelectedOptions([...selectedOptions, option]);
                }}
              >
                {option.label}
              </div>
            );
          })
        ) : (
          <div className={`option`}>
            {t("NotFound", { ns: ["dropdown"] })}"{query}"
          </div>
        )}
      </div>
    </div>
  );
};

// Default exported function
export default SearchableMultipleDropdown;
