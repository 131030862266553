// Import React Dependencies
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Import Media
import wizy_chat_profile from "../images/wizy_chat_profile.png";
import wizy_curvy_border from "../images/wizy_curvy_border.png";
import wizy_logo_blue from "../images/wizy_logo_blue.svg";

// Import styles
import "./styles/ChatSetup.css";

// Declare types and interfaces
import {
  IMessage,
  EMessageRole,
  EMessageSourceTypes,
  EMessageStatus,
} from "../types/MessageType";
type ChatSetupProps = {
  primaryColor: string;
  secondaryColor: string;
  fontColor: string;
  agentName: string;
  onlinePhrase: string;
  mainLanguage: string;
  image: string;
};

// Page main functional component
const ChatSetup: FC<ChatSetupProps> = ({
  primaryColor,
  secondaryColor,
  fontColor,
  agentName,
  onlinePhrase,
  mainLanguage,
  image,
}) => {
  // Use translation
  const { t } = useTranslation(["chatSetup"]);

  // Use state variables
  const [messages, setMessages] = useState<IMessage[]>([
    {
      content: t("Message1", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.user,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message2", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.assistant,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message3", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.user,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message4", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.assistant,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message5", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.user,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message6", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.assistant,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message7", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.user,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message8", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.assistant,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message9", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.user,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message10", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.assistant,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message11", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.user,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
    {
      content: t("Message12", { ns: ["chatSetup"] }),
      createDate: "May 9 13:10",
      role: EMessageRole.assistant,
      name: null,
      functionCall: null,
      toolCalls: null,
      toolCallId: null,
      extraUIComponents: null,
      sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
      sourceId: null,
      hasMedia: false,
      status: EMessageStatus.SENT,
    },
  ]);
  const [newMessage, setNewMessage] = useState<string>("");
  const ecommerceEmojis = [
    "😊",
    "🛍️",
    "📦",
    "💳",
    "🤑",
    "🛒",
    "💬",
    "👍",
    "📢",
    "📆",
    "✉️",
    "🔍",
    "🎁",
    "📱",
    "💻",
    "📋",
    "🌟",
    "💬",
    "❓",
    "💯",
  ];

  // Internal Functions
  const handleMessageSend = () => {
    if (newMessage !== "") {
      let messagesAux = messages;
      messagesAux.push({
        content: newMessage,
        createDate: "May 9 13:10",
        role: EMessageRole.user,
        name: null,
        functionCall: null,
        toolCalls: null,
        toolCallId: null,
        extraUIComponents: null,
        sourceType: EMessageSourceTypes.SHOPIFY_WIDGET,
        sourceId: null,
        hasMedia: false,
        status: EMessageStatus.SENT,
      });
      setMessages(messagesAux);
      setNewMessage("");
    }
  };

  const addRandomNiceEmoji = () => {
    const randomIndex = Math.floor(Math.random() * ecommerceEmojis.length);
    const randomEmoji = ecommerceEmojis[randomIndex];
    setNewMessage(newMessage + randomEmoji);
  };

  // JSX Return statement
  return (
    <React.Fragment>
      <div className="ChatSetup__outter">
        <div
          className="ChatSetup__header"
          style={{
            background:
              "linear-gradient(135deg, " +
              primaryColor +
              " 0%, " +
              secondaryColor +
              " 100%)",
          }}
        >
          <div className="ChatSetup__header__inner">
            <div className="ChatSetup__header__inner__inner">
              <div className="ChatSetup__header__picture__name">
                <img
                  src={image === "" ? wizy_chat_profile : image}
                  alt="wizy_chat_profile"
                  className="ChatSetup__header__profile__image"
                />
                <div className="ChatSetup__header__name__outter">
                  <div
                    className="ChatSetup__header__chat__with"
                    style={{ color: fontColor }}
                  >
                    {mainLanguage === "English"
                      ? "Chat with"
                      : mainLanguage === "Spanish"
                      ? "Habla con"
                      : mainLanguage === "French"
                      ? "Parler avec"
                      : mainLanguage === "Portuguese"
                      ? "Conversar com"
                      : mainLanguage === "German"
                      ? "Chatten Sie mit"
                      : mainLanguage === "Italian"
                      ? "Chat con"
                      : "Chat with"}
                  </div>
                  <div
                    className="ChatSetup__header__agent__name"
                    style={{ color: fontColor }}
                  >
                    {" "}
                    {agentName}{" "}
                  </div>
                </div>
              </div>
              <div className="ChatSetup__header__close__arrow__outter">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  className="ChatSetup__header__options__dots__image"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 6C8.89543 6 8 5.10457 8 4C8 2.89543 8.89543 2 10 2C11.1046 2 12 2.89543 12 4C12 5.10457 11.1046 6 10 6Z"
                    fill={fontColor}
                  />
                  <path
                    d="M10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z"
                    fill={fontColor}
                  />
                  <path
                    d="M10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18Z"
                    fill={fontColor}
                  />
                </svg>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ChatSetup__header__close__arrow__image"
                >
                  <path
                    d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                    fill={fontColor}
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="ChatSetup__online__phrase__outter">
            <div className="ChatSetup__online__dot__outter">
              <div className="ChatSetup__online__dot__inner"></div>
            </div>
            <div
              className="ChatSetup__online__phrase"
              style={{ color: fontColor }}
            >
              {onlinePhrase}
            </div>
          </div>
          <img
            src={wizy_curvy_border}
            alt="wizy_curvy_border"
            className="ChatSetup__headder__wave"
          />
        </div>
        <div className="ChatSetup__chat__outter">
          <div className="ChatSetup__chat__inner">
            {messages.map((message, index) => {
              return (
                <div className="ChatSetup__chat__message__outter" key={index}>
                  <div
                    className="ChatSetup__chat__message__inner"
                    style={{
                      marginLeft:
                        message.role === EMessageRole.user ? "auto" : "",
                      background:
                        message.role === EMessageRole.user
                          ? "linear-gradient(135deg, " +
                            primaryColor +
                            " 0%, " +
                            secondaryColor +
                            " 100%)"
                          : "",
                      color:
                        message.role === EMessageRole.user
                          ? fontColor
                          : "black",
                    }}
                  >
                    {message.content}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="ChatSetup__input__outter">
          <div className="ChatSetup__input__inner">
            <div className="Wizy__input__3__outter">
              <input
                name="message"
                value={newMessage}
                type="text"
                className="Wizy__input__3"
                placeholder={
                  mainLanguage === "English"
                    ? "Enter your message..."
                    : mainLanguage === "Spanish"
                    ? "Ingrese su mensaje.."
                    : mainLanguage === "French"
                    ? "Entrez votre message..."
                    : mainLanguage === "Portuguese"
                    ? "Digite sua mensagem..."
                    : mainLanguage === "German"
                    ? "Geben Sie eine Nachricht ein..."
                    : mainLanguage === "Italian"
                    ? "Inserisci il tuo messaggio.."
                    : "Enter your message..."
                }
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  setNewMessage(event.currentTarget.value);
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === "Enter") {
                    handleMessageSend();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="ChatSetup__emojis__propaganda__outter">
          <div className="ChatSetup__emojis__propaganda__inner">
            <div
              className="ChatSetup__emojis__outter"
              onClick={addRandomNiceEmoji}
            >
              <svg
                id="ic_emojiSwitch"
                fill="#000000"
                height="20"
                viewBox="0 0 24 24"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="ChatSetup__emojis__image"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path>
              </svg>
            </div>
            <div className="ChatSetup__propaganda__outter">
              <div className="ChatSetup__propaganda__text">POWERED BY</div>
              <a
                href="https://www.wizybot.com"
                style={{
                  textDecoration: "none",
                }}
              >
                <div className="ChatSetup__propaganda__logo__outter">
                  {" "}
                  <img
                    src={wizy_logo_blue}
                    alt="wizy_logo_blue"
                    className="ChatSetup__propaganda__logo__image"
                  />{" "}
                  <div className="ChatSetup__propaganda__logo__text">
                    Wizybot
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          className="ChanSetup__send__button__outter"
          style={{
            background:
              "linear-gradient(135deg, " +
              primaryColor +
              " 0%, " +
              secondaryColor +
              " 100%)",
          }}
          onClick={handleMessageSend}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ChanSetup__send__button__image"
          >
            <path
              d="M17.4472 9.10556C17.786 9.27495 18 9.62122 18 9.99999C18 10.3788 17.786 10.725 17.4472 10.8944L3.44721 17.8944C3.09251 18.0718 2.66653 18.0228 2.36136 17.7695C2.0562 17.5162 1.92953 17.1066 2.03848 16.7253L3.46704 11.7253C3.5897 11.296 3.98209 11 4.42857 11L9 11C9.55229 11 10 10.5523 10 10C10 9.44771 9.55229 9 9 9H4.42857C3.98209 9 3.58971 8.70402 3.46705 8.27472L2.03848 3.27471C1.92953 2.8934 2.0562 2.48374 2.36136 2.23048C2.66653 1.97722 3.09251 1.92821 3.44721 2.10556L17.4472 9.10556Z"
              fill={fontColor}
            />
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default ChatSetup;
