// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import i18next from "i18next";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_news_curve from "../images/wizy_news_curve.svg";
import wizy_ticket_filled from "../images/wizy_ticket_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";
import wizy_clients_filled from "../images/wizy_clients_filled.svg";
import wizy_ai_filled from "../images/wizy_ai_filled.svg";
import wizy_logo_blue from "../images/wizy_logo_blue.svg";
import wizy_dollar_filled from "../images/wizy_dollar_filled.svg";
import wizy_scale_filled from "../images/wizy_scale_filled.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";

// Import styles
import "./styles/Home.css";

// Declare types and interfaces
import {
  EDeactivationAction,
  ELimitBudgetAction,
  ERequestHelpAction,
  ISetup,
} from "../types/SetupType";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { INews } from "../types/NewsType";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProgressBar from "../components/ProgressBar";
export enum EInteract {
  greet,
  code,
}

export enum EConfirmation {
  email,
  sms,
  both,
  other,
}

export enum EInvoice {
  physical,
  online,
  both,
}

export enum EPayment {
  credit,
  debit,
  cash,
  transfer,
  other,
}

export type ISetupErrors = {
  agentName: boolean;
  onlinePhrase: boolean;
  isGreet: boolean;
  isCode: boolean;
  discountPercentage: boolean;
  initialMessage: boolean;
  companyContactInfo: boolean;
  mainLanguage: boolean;
  isWidget: boolean;
  isWidgetNoInstall: boolean;
};

export type IShopIndicators = {
  monthlyWizybotSales: number;
  monthlyCharges: number;
  monthlyOutboundCount: number;
  monthlyAiMessageCount: number;
  monthlyClientCount: number;
  monthlyTicketCount: number;
  initialBillingDate: string | null;
  finalBillingDate: string | null;
  maxInbound: number;
};

// Page main functional component
const Home: FC = () => {
  // Use history
  const navigate = useNavigate();

  // Use context
  const { globalShop, globalSelectedBackend, globalUser } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["home"]);

  // Use state variables
  const [actualStep, setActualStep] = useState<number>(0);
  const [setup] = useState<ISetup>({
    primaryColor: "#161635",
    secondaryColor: "#3F7AD9",
    fontColor: "#FFFFFF",
    agentName:
      globalShop.domain.split(".")[0].slice(0, 1).toUpperCase() +
      globalShop.domain.split(".")[0].slice(1),
    onlinePhrase: t("OnlineP", { ns: ["home"] }),
    isCode: false,
    isGreet: true,
    discountPercentage: 1,
    isCustomMessages: false,
    initialMessage: t("InitialM", { ns: ["home"] }),
    defaultMessage: t("DefaultM", { ns: ["home"] }),
    productMessage: t("ProductM", { ns: ["home"] }),
    collectionMessage: t("CollectionM", { ns: ["home"] }),
    searchMessage: t("SearchM", { ns: ["home"] }),
    pagePolicyMessage: t("PageM", { ns: ["home"] }),
    companyContactInfo: globalUser.email,
    mainLanguage: "English",
    side: "Right",
    paddingBottom: 15,
    paddingSide: 15,
    image: "Default",
    isVisible: true,
    emailRetrievalMethod: "None",
    dataRetrievalType: "Email",
    dataRetrievalCustomPrompt: "",
    emailForNotifications: globalUser.email,
    isSendNotifications: true,
    chatbotPersonality: t("ChatbotP", { ns: ["home"] }),
    ticketNotificationType: "Daily",
    hasActivationSchedule: false,
    activationSchedule: "",
    activationScheduleTimezone: "-5",
    deactivationAction: EDeactivationAction.VISIBILITY,
    isAI: true,
    isRedirect: false,
    redirectionLink: "",
    requestHelpAction: ERequestHelpAction.CREATE_TICKET,
    customRequestHelpPrompt: "",
    maxMessagesPerDay: 25,
    hasLimitBudget: false,
    limitBudget: 5000,
    limitBudgetAction: ELimitBudgetAction.AI_OFF,
    limitBudgetDefaultMessage: "",
    limitBudgetRedirectionLink: "",
    syncBlogs: false,
    hasInitialPolicy: false,
    initialPolicyMessage: "",
    ticketDataRetrievalType: "Email",
    productTagsToIncludeInKnowledge: null,
    productTagsToExcludeFromKnowledge: null,
    additionalResponseDelay: 0,
    metaAvailable: false,
  });
  const [isSync, setIsSync] = useState<boolean>(false);
  const [syncingPercentage, setSyncingPercentage] = useState<number>(0);
  const [syncingMessage, setSyncingMessage] = useState<string>("");
  const [isSyncErrorS, setIsSyncErrorS] = useState<boolean>(false);
  const [isInstallingWidget, setIsInstallingWidget] = useState<boolean>(false);
  const [isScopeUpToDate, setIsScopeUpToDate] = useState<boolean>(false);
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [news, setNews] = useState<INews[]>([]);
  const [indicators, setIndicators] = useState<IShopIndicators>({
    monthlyWizybotSales: 0,
    monthlyCharges: 0,
    monthlyOutboundCount: 0,
    monthlyAiMessageCount: 0,
    monthlyClientCount: 0,
    monthlyTicketCount: 0,
    initialBillingDate: null,
    finalBillingDate: null,
    maxInbound: 30,
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(
    globalShop.setup ? globalShop.setup.isVisible : false
  );
  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  //Internal functions
  // Use Effect Functions
  useEffect(() => {
    if (globalShop.needsSetup || globalShop.needsSync) {
      setIsLoaded(true);
      postSetup();
    } else {
      getDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded) {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  useEffect(() => {
    if (isSync) {
      setValidatorProps({
        validatorPrompt: t("SyncingS", { ns: ["home"] }),
        validatorTitle: t("Synchronization", { ns: ["home"] }),
        functionOne: () => {
          setActualStep(1);
        },
        functionOnePrompt: t("Continue", { ns: ["home"] }),
        functionTwo: () => {},
        functionTwoPrompt: "",
        functionNumber: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSync]);

  useEffect(() => {
    if (isSyncErrorS) {
      setValidatorProps({
        validatorPrompt: t("SyncingE", { ns: ["home"] }),
        validatorTitle: t("SyncingT", { ns: ["home"] }),
        functionOne: () => {
          startSyncUpdate();
        },
        functionOnePrompt: t("Retry", { ns: ["home"] }),
        functionTwo: () => {},
        functionTwoPrompt: t("Cancel", { ns: ["home"] }),
        functionNumber: 2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncErrorS]);

  useEffect(() => {
    if (!(globalShop.needsSetup || globalShop.needsSync)) {
      if (!isScopeUpToDate) {
        setValidatorProps({
          validatorPrompt: t("PermissionD", { ns: ["home"] }),
          validatorTitle: t("Permission", { ns: ["home"] }),
          functionOne: () => {
            window.location.replace(
              globalSelectedBackend +
                "/shopifyscopeupdate?shop=" +
                globalShop.domain
            );
          },
          functionOnePrompt: t("Grant", { ns: ["home"] }),
          functionTwo: () => {},
          functionTwoPrompt: t("Later", { ns: ["home"] }),
          functionNumber: 2,
        });
      } else if (indicators.monthlyAiMessageCount >= indicators.maxInbound) {
        setValidatorProps({
          validatorPrompt: t("TopUp", { ns: ["home"] }),
          validatorTitle: t("Warning", { ns: ["home"] }),
          functionOne: () => {
            navigate("/billing");
          },
          functionOnePrompt: t("UpgradeP", { ns: ["home"] }),
          functionTwo: () => {},
          functionTwoPrompt: t("Later", { ns: ["home"] }),
          functionNumber: 2,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const installShopifyWidget = async () => {
    if (await getWidgetRecurrently()) {
      setValidatorProps({
        validatorPrompt: t("Widget", { ns: ["home"] }),
        validatorTitle: t("Success", { ns: ["home"] }),
        functionOne: () => {
          window.location.reload();
        },
        functionOnePrompt: t("Submit", { ns: ["home"] }),
        functionTwo: () => {},
        functionTwoPrompt: "",
        functionNumber: 1,
      });
      setIsInstallingWidget(false);
    } else {
      await fetch(
        globalSelectedBackend + "/setups/" + globalShop.id + "/theme",
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then(async (JSONresult) => {
          window.open(
            "https://" +
              globalShop.domain +
              "/admin/themes/current/editor?context=apps&template=" +
              JSONresult.theme +
              "&activateAppId=" +
              JSONresult.chatExtensionId +
              "/chat_extension",
            "_blank"
          );
          while (!(await getWidgetRecurrently())) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
          setValidatorProps({
            validatorPrompt: t("Widget", { ns: ["home"] }),
            validatorTitle: t("Success", { ns: ["home"] }),
            functionOne: () => {
              window.location.reload();
            },
            functionOnePrompt: t("Submit", { ns: ["home"] }),
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
          setIsInstallingWidget(false);
        })
        .catch(async (err) => {
          window.open(
            "https://" +
              globalShop.domain +
              "/admin/themes/current/editor?context=apps",
            "_blank"
          );
          while (!(await getWidgetRecurrently())) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
          setValidatorProps({
            validatorPrompt: t("Widget", { ns: ["home"] }),
            validatorTitle: t("Success", { ns: ["home"] }),
            functionOne: () => {
              window.location.reload();
            },
            functionOnePrompt: t("Submit", { ns: ["home"] }),
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
          setIsInstallingWidget(false);
        });
    }
  };

  const startSync = async () => {
    setIsSyncErrorS(false);
    await fetch(globalSelectedBackend + "/setups/" + globalShop.id + "/sync", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          setValidatorProps({
            validatorPrompt: t("SyncingE", { ns: ["home"] }),
            validatorTitle: t("SyncingT", { ns: ["home"] }),
            functionOne: () => {
              startSyncUpdate();
            },
            functionOnePrompt: t("Retry", { ns: ["home"] }),
            functionTwo: () => {},
            functionTwoPrompt: t("Cancel", { ns: ["home"] }),
            functionNumber: 2,
          });
          setIsSync(false);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then(async (JSONresult) => {
        if (JSONresult.status) {
          while (true) {
            const syncStatus = await getSyncRecurrently();
            setSyncingPercentage(syncStatus.syncingPercentage);
            setSyncingMessage(syncStatus.syncingMessage);
            if (syncStatus.isSync || syncStatus.isSyncError) {
              break;
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        } else {
          setValidatorProps({
            validatorPrompt: t("SyncingE", { ns: ["home"] }),
            validatorTitle: t("SyncingT", { ns: ["home"] }),
            functionOne: () => {
              startSyncUpdate();
            },
            functionOnePrompt: t("Retry", { ns: ["home"] }),
            functionTwo: () => {},
            functionTwoPrompt: t("Cancel", { ns: ["home"] }),
            functionNumber: 2,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startSyncUpdate = async () => {
    setIsSyncErrorS(false);
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/syncupdate",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          setValidatorProps({
            validatorPrompt: t("SyncingE", { ns: ["home"] }),
            validatorTitle: t("SyncingT", { ns: ["home"] }),
            functionOne: () => {
              startSync();
            },
            functionOnePrompt: t("Retry", { ns: ["home"] }),
            functionTwo: () => {},
            functionTwoPrompt: t("Cancel", { ns: ["home"] }),
            functionNumber: 2,
          });
          setIsSync(false);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then(async (JSONresult) => {
        if (JSONresult.status) {
          while (true) {
            const syncStatus = await getSyncRecurrently();
            setSyncingPercentage(syncStatus.syncingPercentage);
            setSyncingMessage(syncStatus.syncingMessage);
            if (syncStatus.isSync || syncStatus.isSyncError) {
              break;
            }
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postSetup = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      primaryColor: setup.primaryColor,
      secondaryColor: setup.secondaryColor,
      fontColor: setup.fontColor,
      agentName: setup.agentName,
      onlinePhrase: setup.onlinePhrase,
      isCode: setup.isCode,
      isGreet: setup.isGreet,
      discountPercentage: +setup.discountPercentage,
      isCustomMessages: setup.isCustomMessages,
      initialMessage: setup.initialMessage,
      defaultMessage: setup.defaultMessage,
      productMessage: setup.productMessage,
      collectionMessage: setup.collectionMessage,
      searchMessage: setup.searchMessage,
      pagePolicyMessage: setup.pagePolicyMessage,
      companyContactInfo: setup.companyContactInfo,
      mainLanguage: setup.mainLanguage,
      side: setup.side,
      paddingBottom: +setup.paddingBottom,
      paddingSide: +setup.paddingSide,
      image: setup.image,
      isVisible: setup.isVisible,
      emailRetrievalMethod: setup.emailRetrievalMethod,
      dataRetrievalType: setup.dataRetrievalType,
      dataRetrievalCustomPrompt: setup.dataRetrievalCustomPrompt,
      emailForNotifications: setup.emailForNotifications,
      isSendNotifications: setup.isSendNotifications,
      chatbotPersonality: setup.chatbotPersonality,
      ticketNotificationType: setup.ticketNotificationType,
      hasActivationSchedule: setup.hasActivationSchedule,
      activationSchedule: setup.activationSchedule,
      activationScheduleTimezone: setup.activationScheduleTimezone,
      deactivationAction: setup.deactivationAction,
      isAI: setup.isAI,
      isRedirect: setup.isRedirect,
      redirectionLink: setup.redirectionLink,
      requestHelpAction: setup.requestHelpAction,
      customRequestHelpPrompt: setup.customRequestHelpPrompt,
      maxMessagesPerDay: +setup.maxMessagesPerDay,
      hasLimitBudget: setup.hasLimitBudget,
      limitBudget: +setup.limitBudget,
      limitBudgetAction: setup.limitBudgetAction,
      limitBudgetDefaultMessage: setup.limitBudgetDefaultMessage,
      limitBudgetRedirectionLink: setup.limitBudgetRedirectionLink,
      syncBlogs: setup.syncBlogs,
      hasInitialPolicy: setup.hasInitialPolicy,
      initialPolicyMessage: setup.initialPolicyMessage,
      ticketDataRetrievalType: setup.ticketDataRetrievalType,
      productTagsToIncludeInKnowledge: setup.productTagsToIncludeInKnowledge,
      productTagsToExcludeFromKnowledge:
        setup.productTagsToExcludeFromKnowledge,
      additionalResponseDelay: +setup.additionalResponseDelay,
    });

    await fetch(globalSelectedBackend + "/setups/" + globalShop.id, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          setValidatorProps({
            validatorPrompt: t("Error", { ns: ["home"] }) + errorJSON.message,
            validatorTitle: "Error",
            functionOne: () => {
              postSetup();
            },
            functionOnePrompt: t("Retry", { ns: ["home"] }),
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
          setIsSync(false);
          throw new Error(errorJSON.message);
        } else {
          startSync();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDashboard = async () => {
    await fetch(
      globalSelectedBackend + "/shops/" + globalShop.id + "/dashboard",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setIndicators({
          monthlyOutboundCount: +JSONresult.monthlyOutboundCount,
          monthlyAiMessageCount: +JSONresult.monthlyAiMessageCount,
          monthlyClientCount: +JSONresult.monthlyClientCount,
          monthlyTicketCount: +JSONresult.monthlyTicketCount,
          monthlyWizybotSales: +JSONresult.monthlySales,
          monthlyCharges: +JSONresult.monthlyCharges,
          initialBillingDate: JSONresult.initialBillingDate,
          finalBillingDate: JSONresult.finalBillingDate,
          maxInbound: +JSONresult.maxInbound,
        });
        setNews(JSONresult.news);
        getScopeStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getScopeStatus = async () => {
    let scopeUpToDate = false;
    await fetch(globalSelectedBackend + "/checkscopes/" + globalShop.id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          setIsScopeUpToDate(true);
        }
        setIsLoaded(true);
      })
      .catch(async (err) => {
        console.log(err);
      });
    return scopeUpToDate;
  };

  const getWidgetRecurrently = async (): Promise<boolean> => {
    let state = false;
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/widgetinstall",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          state = true;
        } else {
          state = false;
        }
      })
      .catch((err) => {
        console.log(err);
        state = false;
      });
    return state;
  };

  const getSyncRecurrently = async (): Promise<{
    needsSync: boolean;
    isSync: boolean;
    isSyncError: boolean;
    isSyncing: boolean;
    syncingPercentage: number;
    syncingMessage: string;
  }> => {
    setIsSyncErrorS(false);
    let state = {
      needsSync: true,
      isSync: false,
      isSyncError: false,
      isSyncing: true,
      syncingPercentage: 0,
      syncingMessage: "",
    };
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/syncstatus",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        state = {
          needsSync: JSONresult.needsSync,
          isSync: JSONresult.isSync,
          isSyncError: JSONresult.isSyncError,
          isSyncing: JSONresult.isSyncing,
          syncingPercentage: JSONresult.syncingPercentage,
          syncingMessage: JSONresult.syncingMessage,
        };
        if (JSONresult.isSync) {
          setTimeout(() => {
            setIsSync(true);
          }, 3000);
        }
        if (JSONresult.isSyncError) {
          setTimeout(() => {
            setIsSyncErrorS(true);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        state = {
          needsSync: true,
          isSync: false,
          isSyncError: false,
          isSyncing: true,
          syncingPercentage: 0,
          syncingMessage: "",
        };
      });
    return state;
  };

  const toogleIsVisibleStatus = async () => {
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/isvisiblestatus",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setIsVisible(JSONresult.status);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatDate2 = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        {globalShop.needsSetup || globalShop.needsSync ? (
          <div className="Home__setup__outter">
            <div className="Home__setup__inner">
              <div className="Home__setup__inner__question__outter">
                {actualStep === 0 ? (
                  <div>
                    <img
                      src={wizy_logo_blue}
                      alt="wizy_logo_blue"
                      className="Home__setup__logo"
                    />
                    <div className="Home__setup__inner__shopify__sync__description">
                      {" "}
                      <div className="loader">
                        {/* {t("SyncD", { ns: ["home"] })} */}
                        {syncingMessage}
                        &nbsp;&nbsp;
                        <span className="loader__dot">.</span>
                        &nbsp;&nbsp;
                        <span className="loader__dot">.</span>
                        &nbsp;&nbsp;
                        <span className="loader__dot">.</span>
                      </div>{" "}
                      <br />
                    </div>
                    <ProgressBar completed={syncingPercentage}></ProgressBar>
                  </div>
                ) : (
                  ""
                )}

                {actualStep === 1 ? (
                  <div>
                    <video
                      autoPlay
                      loop
                      controls
                      className="Home__setup__inner__questions__video"
                    >
                      <source
                        src={t("Link", { ns: ["home"] })}
                        type="video/mp4"
                      />
                    </video>
                    <div className="Home__setup__inner__install__button">
                      <button
                        className="Wizy__button__2"
                        style={{ width: "100%" }}
                        onClick={() => {
                          installShopifyWidget();
                          setIsInstallingWidget(true);
                        }}
                      >
                        {isInstallingWidget ? (
                          <div className="loader">
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                            &nbsp;&nbsp;
                            <span className="loader__dot">.</span>
                          </div>
                        ) : (
                          t("Install", { ns: ["home"] })
                        )}
                      </button>
                    </div>
                    <div className="Home__setup__inner__shopify__widget__description">
                      {t("InstallD", { ns: ["home"] })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {!globalShop.needsSetup && !globalShop.needsSync ? (
          <div className="Home__dashboard__outter">
            <div className="Home__dashboard__inner">
              <div className="Home__dashboard__indicators__outter">
                <div className="Home__dashboard__indicators__shop__outter">
                  <div className="Home__dashboard__indicators__shop__inner">
                    <div className="Home__dashboard__indicators__shop__inner__inner">
                      <div className="Home__dashboard__indicators__shop__information__title">
                        {t("Selected", { ns: ["home"] })}
                      </div>
                      <div className="Home__dashboard__indicators__shop__information__description">
                        {t("SelectedD", { ns: ["home"] })}
                      </div>
                      <div className="Home__dashboard__indicators__shop__information__outter">
                        <div className="Home__dashboard__indicators__shop__information__subtitle">
                          {t("ShopD", { ns: ["home"] })}
                        </div>
                        <div className="Home__dashboard__indicators__shop__information__item">
                          <div className="Home__dashboard__indicators__shop__information__item__text">
                            {globalShop.domain}
                          </div>
                        </div>
                        <div className="Home__dashboard__indicators__shop__information__subtitle">
                          {t("ShopP", { ns: ["home"] })}
                        </div>
                        <div className="Home__dashboard__indicators__shop__information__item">
                          <div className="Home__dashboard__indicators__shop__information__item__text">
                            {i18next.language === "en"
                              ? globalShop.currentPlan.name
                                  .charAt(0)
                                  .toUpperCase() +
                                globalShop.currentPlan.name.slice(1) +
                                " plan"
                              : "Plan " +
                                globalShop.currentPlan.name
                                  .charAt(0)
                                  .toUpperCase() +
                                globalShop.currentPlan.name.slice(1)}{" "}
                          </div>
                        </div>
                        <div className="Home__dashboard__indicators__shop__information__subtitle">
                          {t("AccountE", { ns: ["home"] })}
                        </div>
                        <div className="Home__dashboard__indicators__shop__information__item">
                          <div className="Home__dashboard__indicators__shop__information__item__text">
                            {globalUser.email}
                          </div>
                        </div>
                      </div>
                      <div className="Home__dashboard__indicators__shop__information__subtitle">
                        {t("Settings", { ns: ["home"] })}
                      </div>
                      <div className="Home__dashboard__insdicators__shop__information__buttons__outter">
                        <button
                          className="Wizy__button__2"
                          style={{ width: "100%" }}
                          onClick={() => {
                            navigate("/settings");
                          }}
                        >
                          {t("Manage", { ns: ["home"] })}
                        </button>
                      </div>
                      <div className="Home__dashboard__insdicators__shop__information__buttons__outter">
                        <button
                          className="Wizy__button__3"
                          style={{ width: "100%" }}
                          onClick={() => {
                            navigate("/billing");
                          }}
                        >
                          {t("Upgrade", { ns: ["home"] })}
                        </button>
                      </div>
                      <div
                        className="Home__dashboard__indicators__shop__information__subtitle"
                        style={{ marginTop: "10px" }}
                      >
                        {t("TTurn", { ns: ["home"] })}
                      </div>
                      <div className="Home__dashboard__indicators__shop__information__test__visibility__outter">
                        <div className="Home__dashboard__insdicators__shop__information__buttons__outter">
                          <button
                            className="Wizy__button__10"
                            style={{ width: "100%" }}
                            onClick={() => {
                              navigate("/test");
                            }}
                          >
                            {t("Test", { ns: ["home"] })}
                          </button>
                        </div>
                        <div className="Home__dashboard__indicators__shop__information__visibility__outter">
                          <label className="switch1">
                            <input
                              type="checkbox"
                              checked={isVisible}
                              onChange={() => {
                                toogleIsVisibleStatus();
                              }}
                            />
                            <span className="slider1 round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="Home__dashboard__indicators__indicators__outter__outter">
                    {indicators.initialBillingDate !== null &&
                    new Date(indicators.initialBillingDate) >
                      new Date("2024-03-01 00:00:00") ? (
                      <div className="Home__dashboard__indicators__date__outter">
                        <div className="Home__dashboard__indicators__inner">
                          <div className="Home__dashboard__indicators__date__title">
                            <img
                              src={wizy_calendar_filled}
                              alt="wizy_calendar_filled"
                              className="Home__dashboard__indicators__date__calendar__img"
                            />
                            <div className="Home__dashboard__indicators__date__title__text">
                              {" "}
                              Billing Cycle
                            </div>
                          </div>
                          <div className="Home__dashboard__indicators__date__dates">
                            <b>From</b>&nbsp;&nbsp;
                            {indicators.initialBillingDate !== null
                              ? formatDate2(
                                  new Date(indicators.initialBillingDate)
                                )
                              : ""}
                            &nbsp;&nbsp;<b>to</b>&nbsp;&nbsp;
                            {indicators.finalBillingDate !== null
                              ? formatDate2(
                                  new Date(indicators.finalBillingDate)
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="Home__dashboard__indicators__indicators__outter">
                      <div
                        className="Home__dashboard__indicators__indicator__outter"
                        style={{
                          height:
                            indicators.initialBillingDate !== null
                              ? "184px"
                              : "210px",
                        }}
                      >
                        <div className="Home__dashboard__indicators__indicator__inner">
                          <div className="Home__dashboard__indicators__indicator__inner__inner">
                            <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                              {indicators.monthlyWizybotSales.toFixed(2)}{" "}
                              <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                                <img
                                  src={wizy_scale_filled}
                                  alt="wizy_scale_filled"
                                  className="Home__dashboard__indicators__indicator__inner__inner__image"
                                />
                              </div>
                            </div>
                            <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                              {globalShop.currentPlan.isAnnual
                                ? t("SalesY", { ns: ["home"] })
                                : t("SalesM", { ns: ["home"] })}{" "}
                              {" ("}
                              {globalShop.shopCurrency}
                              {")"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="Home__dashboard__indicators__indicator__outter"
                        style={{
                          height:
                            indicators.initialBillingDate !== null
                              ? "184px"
                              : "210px",
                        }}
                      >
                        <div className="Home__dashboard__indicators__indicator__inner">
                          <div className="Home__dashboard__indicators__indicator__inner__inner">
                            <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                              {indicators.monthlyAiMessageCount}{" "}
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "12px",
                                  fontWeight: "200",
                                  marginLeft: "5px",
                                }}
                              >
                                /{" "}
                                {globalShop.isChargeable
                                  ? indicators.maxInbound
                                  : "Inf."}
                              </span>{" "}
                              <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                                <img
                                  src={wizy_ai_filled}
                                  alt="wizy_ai_filled"
                                  className="Home__dashboard__indicators__indicator__inner__inner__image"
                                />
                              </div>
                            </div>
                            <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                              {t("MonthlyA", { ns: ["home"] })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="Home__dashboard__indicators__indicator__outter"
                        style={{
                          height:
                            indicators.initialBillingDate !== null
                              ? "184px"
                              : "210px",
                        }}
                      >
                        <div className="Home__dashboard__indicators__indicator__inner">
                          <div className="Home__dashboard__indicators__indicator__inner__inner">
                            <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                              {indicators.monthlyClientCount}{" "}
                              <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                                <img
                                  src={wizy_clients_filled}
                                  alt="wizy_ai_filled"
                                  className="Home__dashboard__indicators__indicator__inner__inner__image"
                                />
                              </div>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "12px",
                                  fontWeight: "200",
                                  marginLeft: "5px",
                                }}
                              ></span>
                            </div>
                            <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                              {globalShop.currentPlan.isAnnual
                                ? t("YearlyC", { ns: ["home"] })
                                : t("MonthlyC", { ns: ["home"] })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="Home__dashboard__indicators__indicator__outter Home__dashboard__indicators__indicator__outtter__down"
                        style={{
                          height:
                            indicators.initialBillingDate !== null
                              ? "184px"
                              : "210px",
                        }}
                      >
                        <div className="Home__dashboard__indicators__indicator__inner">
                          <div className="Home__dashboard__indicators__indicator__inner__inner">
                            <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                              {indicators.monthlyCharges.toFixed(2)}{" "}
                              <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                                <img
                                  src={wizy_dollar_filled}
                                  alt="wizy_clients_filled"
                                  className="Home__dashboard__indicators__indicator__inner__inner__image"
                                  style={{ top: "50%" }}
                                />
                              </div>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "12px",
                                  fontWeight: "200",
                                  marginLeft: "5px",
                                }}
                              ></span>
                            </div>
                            <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                              {globalShop.currentPlan.isAnnual
                                ? t("ChargeY", { ns: ["home"] })
                                : t("ChargeM", { ns: ["home"] })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="Home__dashboard__indicators__indicator__outter Home__dashboard__indicators__indicator__outtter__down"
                        style={{
                          height:
                            indicators.initialBillingDate !== null
                              ? "184px"
                              : "210px",
                        }}
                      >
                        <div className="Home__dashboard__indicators__indicator__inner">
                          <div className="Home__dashboard__indicators__indicator__inner__inner">
                            <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                              {indicators.monthlyTicketCount}
                              <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                                <img
                                  src={wizy_ticket_filled}
                                  alt="wizy_ticket_filled"
                                  className="Home__dashboard__indicators__indicator__inner__inner__image"
                                  style={{ top: "50%" }}
                                />
                              </div>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "12px",
                                  fontWeight: "200",
                                  marginLeft: "5px",
                                }}
                              ></span>
                            </div>
                            <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                              {globalShop.currentPlan.isAnnual
                                ? t("YearlyT", { ns: ["home"] })
                                : t("MonthlyT", { ns: ["home"] })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="Home__dashboard__indicators__indicator__outter Home__dashboard__indicators__indicator__outtter__down"
                        style={{
                          height:
                            indicators.initialBillingDate !== null
                              ? "184px"
                              : "210px",
                        }}
                      >
                        <div className="Home__dashboard__indicators__indicator__inner">
                          <div className="Home__dashboard__indicators__indicator__inner__inner">
                            <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                              {indicators.monthlyOutboundCount}{" "}
                              <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                                <img
                                  src={wizy_chat_filled}
                                  alt="wizy_chat_filled"
                                  className="Home__dashboard__indicators__indicator__inner__inner__image"
                                />
                              </div>
                              <span
                                style={{
                                  color: "gray",
                                  fontSize: "12px",
                                  fontWeight: "200",
                                  marginLeft: "5px",
                                }}
                              ></span>
                            </div>
                            <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                              {globalShop.currentPlan.isAnnual
                                ? t("YearlyO", { ns: ["home"] })
                                : t("MonthlyO", { ns: ["home"] })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Home__dashboard__news__outter">
                <div className="Home__dashboard__news__title">
                  {" "}
                  {t("What", { ns: ["home"] })}
                </div>
                <div className="Home__dashboard__news__description">
                  {t("WhatD", { ns: ["home"] })}
                </div>
                <div className="Home__dashboard__news__list__outter">
                  {news.map((news, index) => {
                    return (
                      <div
                        className="Home__dashboard__news__list__item__outter"
                        key={index}
                      >
                        <div
                          className="Home__dashboard__news__list__item__inner"
                          onClick={() => {
                            window.open(news.link, "_newtab");
                          }}
                        >
                          <div className="Home__dashboard__news__list__image__outter">
                            <img
                              src={news.image}
                              alt={index + "__image"}
                              className="Home__dashboard__news__list__image"
                            />
                            <img
                              src={wizy_news_curve}
                              alt="wizy_news_curve"
                              className="Home__dashboard__news__list__curve__image"
                            />
                            <div className="Home__dashboard__news__list__image__cover"></div>
                            <div className="Home__dashboard__news__list__new__outter">
                              NEW
                            </div>
                          </div>
                          <div className="Home__dashboard__news__list__title__outter">
                            <div className="Home__dashboard__news__list__title__inner">
                              {news.title}
                            </div>
                          </div>
                          <div className="Home__dashboard__news__list__prompt__outter">
                            <div className="Home__dashboard__news__list__prompt__inner">
                              {news.prompt}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default Home;
