// Import React Dependencies
import React, { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Import Media
import wizy_home_filled from "../images/wizy_home_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";
import wizy_cart_filled from "../images/wizy_cart_filled.svg";
import wizy_clients_filled from "../images/wizy_clients_filled.svg";
import wizy_ticket_filled from "../images/wizy_ticket_filled.svg";

// Import styles
import "./styles/Bottombar.css";

// Declare types and interfaces
type BottombarProps = {
  actualPage: string;
};

// Page main functional component
const BottombarAdmin: FC<BottombarProps> = ({ actualPage }) => {
  // Use context

  // Use history
  const navigate = useNavigate();

  // Use state variables

  // References needed
  const barRefDesktop = useRef<HTMLDivElement>(null);
  const barRefMobile = useRef<HTMLDivElement>(null);

  // Internal Functions
  useEffect(() => {
    switch (actualPage) {
      case "home":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "10px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "2.5%";
        }
        break;
      case "chat":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "70px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "22.5%";
        }
        break;
      case "merchants":
        if (barRefDesktop.current !== null) {
          barRefDesktop.current.style.top = "130px";
        }
        if (barRefMobile.current !== null) {
          barRefMobile.current.style.left = "42.5%";
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualPage]);

  return (
    <React.Fragment>
      <div className="Bottombar__outter">
        <div
          ref={barRefDesktop}
          className="Bottombar__highlight__bar__desktop"
        ></div>
        <div
          ref={barRefMobile}
          className="Bottombar__highlight__bar__mobile"
        ></div>
        <div className="Bottombar__inner">
          {/* Home */}
          <div
            className={
              actualPage === "home"
                ? "Bottombar__outter__item__selected"
                : "Bottombar__outter__item"
            }
            onClick={() => {
              navigate("/homeadmin");
            }}
          >
            <img
              src={wizy_home_filled}
              alt="wizy_home_filled"
              className="Bottombar__item__image"
              style={{
                filter:
                  actualPage === "home"
                    ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                    : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
              }}
            />
          </div>

          {/* Chats */}
          <div
            className={
              actualPage === "chat"
                ? "Bottombar__outter__item__selected"
                : "Bottombar__outter__item"
            }
            onClick={() => {
              navigate("/chatadmin");
            }}
          >
            <img
              src={wizy_chat_filled}
              alt="wizy_chat_filled"
              className="Bottombar__item__image"
              style={{
                filter:
                  actualPage === "chat"
                    ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                    : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
              }}
            />
          </div>

          {/* Merchants */}
          <div
            className={
              actualPage === "merchants"
                ? "Bottombar__outter__item__selected"
                : "Bottombar__outter__item"
            }
            onClick={() => {
              navigate("/merchants");
            }}
          >
            <img
              src={wizy_cart_filled}
              alt="wizy_cart_filled"
              className="Bottombar__item__image"
              style={{
                filter:
                  actualPage === "merchants"
                    ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                    : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
              }}
            />
          </div>

          {/* Tickets */}
          <div
            className={
              actualPage === "tickets"
                ? "Bottombar__outter__item__selected"
                : "Bottombar__outter__item"
            }
  
          >
            <img
              src={wizy_ticket_filled}
              alt="wizy_ticket_filled"
              className="Bottombar__item__image"
              style={{
                filter:
                  actualPage === "tickets"
                    ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                    : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
              }}
            />
          </div>

          {/* Clients or merchants */}
          <div
            className={
              actualPage === "clients"
                ? "Bottombar__outter__item__selected"
                : "Bottombar__outter__item"
            }
 
          >
            <img
              src={wizy_clients_filled}
              alt="wizy_clients_filled"
              className="Bottombar__item__image"
              style={{
                filter:
                  actualPage === "clients"
                    ? "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(140%) contrast(101%)"
                    : "invert(98%) sepia(100%) saturate(2%) hue-rotate(261deg) brightness(70%) contrast(101%)",
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default BottombarAdmin;
