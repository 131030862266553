// Import React Dependencies
import React, { FC, useEffect, useState } from "react";
import { AppContext, IContext } from "../context/Context";

// Declare types and interfaces

// Import styles
import "./styles/TwoFa.css";

// Page main functional component
const TwoFa: FC = () => {
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  const [facebookTwoFa, setFacebookTwoFa] = useState<string>();
  const [instagramTwoFa, setInstagramTwoFa] = useState<string>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    getFacebookTwoFa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFacebookTwoFa = async () => {
    await fetch(globalSelectedBackend + "/meta/facebooktwofa", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setFacebookTwoFa(JSONresult.twoFa);
        getInstagramTwoFa();
      });
  };

  const getInstagramTwoFa = async () => {
    await fetch(globalSelectedBackend + "/meta/instagramtwofa", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setIsLoaded(true);
        setInstagramTwoFa(JSONresult.twoFa);
      });
  };

  if (isLoaded) {
    return (
      <div className="TwoFa__outter">
        <div className="TwoFa__inner">
          <div className="TwoFa__text">This is the facebook 2fa:</div>{" "}
          <div className="TwoFa__number">{facebookTwoFa}</div>
          <div className="TwoFa__text">This is the instagram 2fa:</div>{" "}
          <div className="TwoFa__number">{instagramTwoFa}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="TwoFa__outter">
        <div className="TwoFa__inner">
          <div className="TwoFa__text">This is the facebook 2fa:</div>{" "}
          <div className="TwoFa__number">{facebookTwoFa}</div>
          <div className="TwoFa__text">This is the instagram 2fa:</div>{" "}
          <div className="TwoFa__number">{instagramTwoFa}</div>
        </div>
      </div>
    );
  }
};

// Default exported function
export default TwoFa;
