// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import SettingsComponent from "../components/SettingsComponent";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_ticket_filled from "../images/wizy_ticket_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";
import wizy_clients_filled from "../images/wizy_clients_filled.svg";
import wizy_ai_filled from "../images/wizy_ai_filled.svg";
import wizy_dollar_filled from "../images/wizy_dollar_filled.svg";
import wizy_scale_filled from "../images/wizy_scale_filled.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";

// Declare types and interfaces
import { EShopStatus, IShop } from "../types/ShopType";
import { IShopIndicators } from "./Home";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { IPlan } from "../types/PlanType";
import CustomOfferManager from "../components/CustomOfferManager";
import ChatFunctionsManager from "../components/ChatFunctionsManager";
import ChildrenSelector from "../components/ChildrenSelector";
import CustomWidgetManager from "../components/CustomWidgetManager";

// Page main functional component
const MerchantSettings: FC = () => {
  // Use parameters
  const { id } = useParams<{ id: string | undefined }>();

  // Use translation
  const { t } = useTranslation(["home"]);

  // Use context
  const { globalSelectedBackend, globalUser, changePassword } =
    React.useContext(AppContext) as IContext;

  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const [globalShop, setGlobalShop] = useState<IShop>({
    id: "",
    domain: "",
    needsSetup: false,
    needsSync: false,
    needsTest: false,
    needsOnboarding: false,
    status: EShopStatus.uninstalled,
    currentPlan: emptyPlan,
    createDate: "",
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
  });
  const [indicators, setIndicators] = useState<IShopIndicators>({
    monthlyWizybotSales: 0,
    monthlyCharges: 0,
    monthlyOutboundCount: 0,
    monthlyAiMessageCount: 0,
    monthlyClientCount: 0,
    monthlyTicketCount: 0,
    initialBillingDate: null,
    finalBillingDate: null,
    maxInbound: 0,
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });

  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  //Internal functions
  // Use Effect Functions
  useEffect(() => {
    getGlobalShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ValidatorRef.current?.setApearance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  const getGlobalShop = async () => {
    await fetch(globalSelectedBackend + "/admin/merchantshop/" + id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setGlobalShop({
          id: JSONresult.id,
          domain: JSONresult.domain,
          needsSetup: JSONresult.needsSetup,
          needsSync: JSONresult.needsSync,
          needsTest: JSONresult.needsTest,
          needsOnboarding: JSONresult.needsOnboarding,
          status: JSONresult.status,
          currentPlan: JSONresult.currentPlan,
          customOfferPlan: JSONresult.customOfferPlan,
          createDate: JSONresult.createDate,
          shopCurrency: JSONresult.shopCurrency,
          shopName: JSONresult.shopName,
          shopMainDomain: JSONresult.shopMainDomain,
          parentShopId: JSONresult.parentShopId,
        });
        getDashboard();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDashboard = async () => {
    await fetch(globalSelectedBackend + "/admin/" + id + "/dashboard", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setIndicators({
          monthlyOutboundCount: +JSONresult.monthlyOutboundCount,
          monthlyAiMessageCount: +JSONresult.monthlyAiMessageCount,
          monthlyClientCount: +JSONresult.monthlyClientCount,
          monthlyTicketCount: +JSONresult.monthlyTicketCount,
          monthlyWizybotSales: +JSONresult.monthlySales,
          monthlyCharges: +JSONresult.monthlyCharges,
          initialBillingDate: JSONresult.initialBillingDate,
          finalBillingDate: JSONresult.finalBillingDate,
          maxInbound: +JSONresult.maxInbound,
        });
        getShopPlans();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShopPlans = async () => {
    await fetch(globalSelectedBackend + "/plans/shopplans/" + id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setGlobalShop((prevState) => ({
          ...prevState,
          currentPlan: JSONresult.currentPlan,
          customOfferPlan: JSONresult.customOfferPlan,
        }));
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatDate2 = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        <div className="HomeMerchantSettings__dashboard__outter">
          <div
            className="ChildrenSelector__inner"
            style={{ backgroundColor: "#eff2f6" }}
          >
            <div className="Home__dashboard__indicators__indicators__outter__outter">
              {indicators.initialBillingDate !== null &&
              new Date(indicators.initialBillingDate) >
                new Date("2024-03-01 00:00:00") ? (
                <div className="HomeMerchant__dashboard__indicators__date__outter">
                  <div className="Home__dashboard__indicators__inner">
                    <div className="Home__dashboard__indicators__date__title">
                      <img
                        src={wizy_calendar_filled}
                        alt="wizy_calendar_filled"
                        className="Home__dashboard__indicators__date__calendar__img"
                      />
                      <div className="Home__dashboard__indicators__date__title__text">
                        {" "}
                        Billing Cycle
                      </div>
                    </div>
                    <div className="Home__dashboard__indicators__date__dates">
                      <b>From</b>&nbsp;&nbsp;
                      {indicators.initialBillingDate !== null
                        ? formatDate2(new Date(indicators.initialBillingDate))
                        : ""}
                      &nbsp;&nbsp;<b>to</b>&nbsp;&nbsp;
                      {indicators.finalBillingDate !== null
                        ? formatDate2(new Date(indicators.finalBillingDate))
                        : ""}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="Home__dashboard__indicators__indicators__outter"
                style={{ marginLeft: "0px" }}
              >
                <div className="Home__dashboard__indicators__indicator__outter">
                  <div className="Home__dashboard__indicators__indicator__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__inner">
                      <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                        {indicators.monthlyWizybotSales.toFixed(2)}{" "}
                        <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                          <img
                            src={wizy_scale_filled}
                            alt="wizy_scale_filled"
                            className="Home__dashboard__indicators__indicator__inner__inner__image"
                          />
                        </div>
                      </div>
                      <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                        {t("SalesM", { ns: ["home"] })} {" ("}
                        {globalShop.shopCurrency}
                        {")"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Home__dashboard__indicators__indicator__outter">
                  <div className="Home__dashboard__indicators__indicator__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__inner">
                      <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                        {indicators.monthlyAiMessageCount}{" "}
                        <span
                          style={{
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "200",
                            marginLeft: "5px",
                          }}
                        >
                          / {indicators.maxInbound}
                        </span>{" "}
                        <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                          <img
                            src={wizy_ai_filled}
                            alt="wizy_ai_filled"
                            className="Home__dashboard__indicators__indicator__inner__inner__image"
                          />
                        </div>
                      </div>
                      <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                        {t("MonthlyA", { ns: ["home"] })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Home__dashboard__indicators__indicator__outter">
                  <div className="Home__dashboard__indicators__indicator__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__inner">
                      <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                        {indicators.monthlyClientCount}{" "}
                        <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                          <img
                            src={wizy_clients_filled}
                            alt="wizy_ai_filled"
                            className="Home__dashboard__indicators__indicator__inner__inner__image"
                          />
                        </div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "200",
                            marginLeft: "5px",
                          }}
                        ></span>
                      </div>
                      <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                        {t("MonthlyC", { ns: ["home"] })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Home__dashboard__indicators__indicator__outter Home__dashboard__indicators__indicator__outtter__down">
                  <div className="Home__dashboard__indicators__indicator__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__inner">
                      <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                        {indicators.monthlyCharges.toFixed(2)}{" "}
                        <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                          <img
                            src={wizy_dollar_filled}
                            alt="wizy_clients_filled"
                            className="Home__dashboard__indicators__indicator__inner__inner__image"
                            style={{ top: "50%" }}
                          />
                        </div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "200",
                            marginLeft: "5px",
                          }}
                        ></span>
                      </div>
                      <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                        {t("ChargeM", { ns: ["home"] })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Home__dashboard__indicators__indicator__outter Home__dashboard__indicators__indicator__outtter__down">
                  <div className="Home__dashboard__indicators__indicator__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__inner">
                      <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                        {indicators.monthlyTicketCount}
                        <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                          <img
                            src={wizy_ticket_filled}
                            alt="wizy_ticket_filled"
                            className="Home__dashboard__indicators__indicator__inner__inner__image"
                            style={{ top: "50%" }}
                          />
                        </div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "200",
                            marginLeft: "5px",
                          }}
                        ></span>
                      </div>
                      <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                        {t("MonthlyT", { ns: ["home"] })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Home__dashboard__indicators__indicator__outter Home__dashboard__indicators__indicator__outtter__down">
                  <div className="Home__dashboard__indicators__indicator__inner">
                    <div className="Home__dashboard__indicators__indicator__inner__inner">
                      <div className="Home__dashboard__indicators__indicator__inner__innner__count">
                        {indicators.monthlyOutboundCount}{" "}
                        <div className="Home__dashboard__indicators__indicator__inner__inner__image__outter">
                          <img
                            src={wizy_chat_filled}
                            alt="wizy_chat_filled"
                            className="Home__dashboard__indicators__indicator__inner__inner__image"
                          />
                        </div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "12px",
                            fontWeight: "200",
                            marginLeft: "5px",
                          }}
                        ></span>
                      </div>
                      <div className="Home__dashboard__indicators__indicator__inner__innner__name">
                        {t("MonthlyO", { ns: ["home"] })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomOfferManager shop={globalShop} />
          {globalShop.parentShopId === null ||
          globalShop.parentShopId === globalShop.id ? (
            <ChildrenSelector shop={globalShop} />
          ) : (
            ""
          )}
          <ChatFunctionsManager shop={globalShop} />
          <CustomWidgetManager shop={globalShop} />
          <SettingsComponent
            globalSelectedBackend={globalSelectedBackend}
            globalShop={globalShop}
            globalUser={globalUser}
            setValidatorProps={setValidatorProps}
            changePassword={changePassword}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Setting__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default MerchantSettings;
