// Message

export type IMessage = {
  id?: string;
  role: EMessageRole;
  name: string|null;
  content: string;
  functionCall: string|null;
  toolCalls: string|null;
  toolCallId: string|null;
  createDate: string;
  extraUIComponents: IExtraUIComponent[] | null;
  sourceType: EMessageSourceTypes;
  sourceId: string | null;
  hasMedia: boolean;
  status: EMessageStatus;
};

export type IMessageBackend = {
  id?: string;
  role: EMessageRole;
  name: string|null;
  content: string;
  functionCall: string|null;
  toolCalls: string|null;
  toolCallId: string|null;
  createDate: string;
  extraUIComponents: string | null;
  sourceType: EMessageSourceTypes;
  sourceId: string | null;
  hasMedia: boolean;
  status: EMessageStatus;
};

export enum EMessageRole {
  assistant = "assistant",
  ai = "ai",
  user = "user",
  function = "function",
  tool = "tool",
}


export enum EMessageSourceTypes {
  SHOPIFY_WIDGET = 'shopify widget',
  WHATSAPP = 'whatsapp',
  INSTAGRAM = 'instagram',
  MESSENGER = 'messenger',
}

export enum EMessageStatus {
  SENT = 'sent',
  READ = 'read',
  FAILED = 'failed',
  DELETED = 'deleted'
}

// EXTRA UI COMPONENTS

export type IExtraUIComponent = {
  type: EExtraUIComponentTypes,
  content: any
}

export enum EExtraUIComponentTypes {
  ADD_TO_CART = 'Add to cart',
  RECOMMENDATION_CAROUSEL = 'Recommendation carousel',
}

export enum EAddToCartStateActions {
  NONE = 'none',
  BOUGHT = 'bought',
  ADDED_TO_CART = 'addedtocart',
}

export type IAddToCartContent = {
  title: string;
  productId: string;
  productUrl: string;
  imageUrl: string;
  imageAltText: string;
  options: { name: string; values: string[] }[];
  infoArray: any;
  stateAction: EAddToCartStateActions;
  stateSelection: any;
}

export interface IRecommendationCarouselContent {
  recommendations: {
    title: string;
    price: string;
    imageUrl: string;
    redirectUrl: string;
  }[];
}
