import React, { useState } from "react";

// Import media

// Import styles
import "./styles/TagsInput.css";

// Declare types and interfaces
export type ITagsInputProps = {
  tagsState: [String[], React.Dispatch<React.SetStateAction<String[]>>];
  placeholder: string;
};

// Page main functional component
const TagsInput = ({ tagsState: [tags, setTags], placeholder }: ITagsInputProps) => {
  const [input, setInput] = useState<string>("");

  // Function to add tag when user press enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    if (!input.trim()) return;
    setTags([...tags, input]);
    setInput("");
  };

  // Function to remove tag when the x is pressed
  const removeTag = (index: number) => {
    setTags(tags.filter((el, i) => i !== index));
  };

  return (
    <div className="Tags__input__containers">
      {tags.map((tag, index) => (
        <div className="Tag__element">
          {tag}
          <span className="Tag__remove__button" onClick={() => removeTag(index)}>
            <svg viewBox="0 0 14 14" className="Tag__remove__icon">
              <path d="M4 4l6 6m0-6l-6 6" />
            </svg>
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          const { value } = event.currentTarget;
          setInput(value);
        }}
        value={input}
        name="input"
        type="text"
        className="Tag__input"
        placeholder={placeholder}
      />
    </div>
  );
};

// Default exported function
export default TagsInput;
