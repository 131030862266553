export type IAdditionalInfo = {
    id: string;
    type: ADDITIONAL_INFO_TYPE;
    createDate: string;
    description: string;
    associatedEntityId: string;
    selected: boolean;
  };
  
  export enum ADDITIONAL_INFO_TYPE {
    GENERALADDON = 'generaladdon',
    PRODUCTADDON = 'productaddon',
    TAG = 'tag',
    DISCOUNT = 'discount',
  }