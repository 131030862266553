// Import React Dependencies
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import Context
import { AppContext, IContext } from "../context/Context";

// Import Components
import LanguageSelector from "../components/LaguageSelector";

// Import media
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_x_outline from "../images/wizy_x_outline.svg";
import wizy_logout_filled from "../images/wizy_logout_filled.svg";


// Import styles
import "./styles/Navbar.css";
import ProfilePicture from "./ProfilePicture";

// Page main functional component
const NavbarAdmin: FC = () => {
  // Use context
  const { logOut, globalUser} = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["navbar"]);

  // Use history
  const navigate = useNavigate();

  // Use state variables
  const [isMenuHided, setIsMenuHided] = useState<boolean>(true);

  // References needed
  const sideMenu = useRef<HTMLDivElement>(null);

  // Internal Functions
  const toogleHidedMenu = () => {
    if (isMenuHided) {
      if (sideMenu.current !== null) {
        sideMenu.current.style.right = "0%";
      }
      setIsMenuHided(false);
    } else {
      if (sideMenu.current !== null) {
        sideMenu.current.style.right = "-305px";
      }
      setIsMenuHided(true);
    }
  };

  // JSX Return statement
  return (
    <React.Fragment>
      <div ref={sideMenu} className="Navbar__side__menu__outter">
        <div className="Navbar_side__menu__inner">
          <div className="Navbar__side__menu__top">
            <div className="Navbar__side__menu__language__select__outter">
              <div className="Navebar__side__menu__language__select__inner">
                <LanguageSelector side="left" color="white" />
              </div>
            </div>
            <div></div>
            <div>
              <img
                src={wizy_x_outline}
                alt="wizy_x_outline"
                className="Navbar__side__menu__x"
                onClick={toogleHidedMenu}
              />
            </div>
          </div>
          <div className="Navbar__side__menu__profile__outter">
            <div className="Navbar__side__menu__profile__picture__outter">
              <div className="Navbar__side__menu__profile__picture__inner">
                <ProfilePicture
                  firstName={globalUser.email[0]}
                  lastName={globalUser.email[1]}
                />
              </div>
            </div>
            <div className="Navebar__side__menu__email">{globalUser.email}</div>
              <div className="Navebar__side__menu__account">
                {t("AdminAccount", { ns: ["navbar"] })}
              </div>
              <div
                  className="Navbar__side__menu__icon__buttons__item"
                  onClick={() => {
                    logOut().then((response) => {
                      if (response) {
                        navigate("/");
                      } else {
                        navigate("/");
                      }
                    });
                  }}
                >
                  <img
                    src={wizy_logout_filled}
                    alt="wizy_logout_filled"
                    className="Navbar__side__menu__icon__buttons__image"
                  />
                  <div className="Navbar__side__menu__icon__buttons__text">
                    {t("Logout", { ns: ["navbar"] })}
                  </div>
                </div>
          </div>
        </div>
      </div>
      <div className="Navbar__outter">
        <div className="Navbar__inner">
          <a
            href="https://app.wizybot.com"
            style={{
              textDecoration: "none",
            }}
          >
            <div className="Navbar__logo__outter">
              <img
                src={wizy_logo_white}
                alt="wizy_logo_white"
                className="Navbar__logo__image"
              />
              Wizybot
            </div>
          </a>
          <div></div>
          <div className="Navbar__profile__outter">
            <div className="Navbar__profile__inner">
              <div className="Navbar__upgrade__button__outter">

                    <button className="Wizy__button__1" disabled>
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>
                        {t("Admin", { ns: ["navbar"] })}
                      </span>
                    </button>

              </div>
              <div
                className="Nabvar__profile__picture__outter"
                onClick={toogleHidedMenu}
              >
                <ProfilePicture
                  firstName={globalUser.email[0]}
                  lastName={globalUser.email[1]}
                />
              </div>
              <div
                className="Navbar__profile__online__dot__outer"
                onClick={toogleHidedMenu}
              >
                <div className="Navbar__profile__online__dot__inner"></div>
              </div>
            </div>
          </div>
          <div
            className="Login__navbar__language__select__outter"
            style={{
              display: "none",
            }}
          >
            <div className="Login__navabr__language__select__inner">
              <LanguageSelector side="right" color="black" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default NavbarAdmin;
