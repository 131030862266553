import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "universal-cookie";

//Import all translation files
import loginEnglish from "./Trasnlation/English/login.json";
import loginSpanish from "./Trasnlation/Spanish/login.json";
import chatSetupEnglish from "./Trasnlation/English/chatSetup.json";
import chatSetupSpanish from "./Trasnlation/Spanish/chatSetup.json";
import navbarEnglish from "./Trasnlation/English/navbar.json";
import navbarSpanish from "./Trasnlation/Spanish/navbar.json";
import billingEnglish from "./Trasnlation/English/billing.json";
import billingSpanish from "./Trasnlation/Spanish/billing.json";
import chatEnglish from "./Trasnlation/English/chat.json";
import chatSpanish from "./Trasnlation/Spanish/chat.json";
import clientsEnglish from "./Trasnlation/English/clients.json";
import clientsSpanish from "./Trasnlation/Spanish/clients.json";
import testEnglish from "./Trasnlation/English/test.json";
import testSpanish from "./Trasnlation/Spanish/test.json";
import ticketsEnglish from "./Trasnlation/English/tickets.json";
import ticketsSpanish from "./Trasnlation/Spanish/tickets.json";
import settingsEnglish from "./Trasnlation/English/settings.json";
import settingsSpanish from "./Trasnlation/Spanish/settings.json";
import homeEnglish from "./Trasnlation/English/home.json";
import homeSpanish from "./Trasnlation/Spanish/home.json";
import merchantsEnglish from "./Trasnlation/English/merchants.json";
import merchantsSpanish from "./Trasnlation/Spanish/merchants.json";
import chatAdminEnglish from "./Trasnlation/English/chatAdmin.json";
import chatAdminSpanish from "./Trasnlation/Spanish/chatAdmin.json";
import homeAdminEnglish from "./Trasnlation/English/homeAdmin.json";
import homeAdminSpanish from "./Trasnlation/Spanish/homeAdmin.json";
import dropdownEnglish from "./Trasnlation/English/dropdown.json";
import dropdownSpanish from "./Trasnlation/Spanish/dropdown.json";
import additionalInfoEnglish from "./Trasnlation/English/additionalInfo.json";
import additionalInfoSpanish from "./Trasnlation/Spanish/additionalInfo.json";
import assistantFormEnglish from "./Trasnlation/English/assistantForm.json";
import assistantFormSpanish from "./Trasnlation/Spanish/assistantForm.json";
import trainingEnglish from "./Trasnlation/English/training.json";
import trainingSpanish from "./Trasnlation/Spanish/training.json";
import chatReviewEnglish from "./Trasnlation/English/chatReview.json";
import chatReviewSpanish from "./Trasnlation/Spanish/chatReview.json";
import customOfferManagerEnglish from "./Trasnlation/English/customOfferManager.json";
import customOfferManagerSpanish from "./Trasnlation/Spanish/customOfferManager.json";
import cachedResponsesEnglish from "./Trasnlation/English/cachedResponses.json";
import cachedResponsesSpanish from "./Trasnlation/Spanish/cachedResponses.json";
import chatFunctionsManagerEnglish from "./Trasnlation/English/chatFunctionsManager.json";
import chatFunctionsManagerSpanish from "./Trasnlation/Spanish/chatFunctionsManager.json";
import approveSalesEnglish from "./Trasnlation/English/approveSales.json";
import approveSalesSpanish from "./Trasnlation/Spanish/approveSales.json";
import childrenSelectorEnglish from "./Trasnlation/English/childrenSelector.json";
import childrenSelectorSpanish from "./Trasnlation/Spanish/childrenSelector.json";
import customWidgetManagerEnglish from "./Trasnlation/English/customWidgetManager.json";
import customWidgetManagerSpanish from "./Trasnlation/Spanish/customWidgetManager.json";
import whatsAppTemplatesModalEnglish from "./Trasnlation/English/whatsAppTemplatesModal.json"
import whatsAppTemplatesModalSpanish from "./Trasnlation/Spanish/whatsAppTemplatesModal.json"

const cookies = new Cookies();

if (undefined === cookies.get("language")) {
  cookies.set("language", "en", {
    path: "/",
    maxAge: 60 * 60 * 24 * 365,
  });
}

const language = cookies.get("language");

const resources = {
  en: {
    login: loginEnglish,
    chatSetup: chatSetupEnglish,
    navbar: navbarEnglish,
    billing: billingEnglish,
    chat: chatEnglish,
    clients: clientsEnglish,
    test: testEnglish,
    tickets: ticketsEnglish,
    settings: settingsEnglish,
    home: homeEnglish,
    merchants: merchantsEnglish,
    chatAdmin: chatAdminEnglish,
    homeAdmin: homeAdminEnglish,
    dropdown: dropdownEnglish,
    additionalInfo: additionalInfoEnglish,
    assistantForm: assistantFormEnglish,
    training: trainingEnglish,
    chatReview: chatReviewEnglish,
    customOfferManager: customOfferManagerEnglish,
    customWidgetManager: customWidgetManagerEnglish,
    cachedResponses: cachedResponsesEnglish,
    chatFunctionsManager: chatFunctionsManagerEnglish,
    approveSales: approveSalesEnglish,
    childrenSelector: childrenSelectorEnglish,
    whatsAppTemplatesModal: whatsAppTemplatesModalEnglish,
  },
  es: {
    login: loginSpanish,
    chatSetup: chatSetupSpanish,
    navbar: navbarSpanish,
    billing: billingSpanish,
    chat: chatSpanish,
    clients: clientsSpanish,
    test: testSpanish,
    tickets: ticketsSpanish,
    settings: settingsSpanish,
    home: homeSpanish,
    merchants: merchantsSpanish,
    chatAdmin: chatAdminSpanish,
    homeAdmin: homeAdminSpanish,
    dropdown: dropdownSpanish,
    additionalInfo: additionalInfoSpanish,
    assistantForm: assistantFormSpanish,
    training: trainingSpanish,
    chatReview: chatReviewSpanish,
    customOfferManager: customOfferManagerSpanish,
    customWidgetManager: customWidgetManagerSpanish,
    cachedResponses: cachedResponsesSpanish,
    chatFunctionsManager: chatFunctionsManagerSpanish,
    approveSales: approveSalesSpanish,
    childrenSelector: childrenSelectorSpanish,
    whatsAppTemplatesModal: whatsAppTemplatesModalSpanish,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: language, //default language
});

export default i18next;
