// Message

import { EMessageRole } from "./MessageType";

export type IAssistantMessage = {
    id?: string;
    role: EMessageRole;
    name: string|null;
    content: string;
    functionCall: string|null;
    toolCalls: string|null;
    toolCallId: string|null;
    createDate: string;
    form: IAssistantForm | null,
  };

export type IAssistantForm = {
    type: EAssistantFormType,
    content: any
}
  
export enum EAssistantFormType {
    PERSONALITY = "Personality",
    COMPLAINT_INSTRUCTIONS = "Complaint instructions",
    ORDER_INSTRUCTIONS = "Order instructions",
    NEW_DISCOUNT = "New discount",
    NEW_GENERALADDON = "New general add on",
    NEW_PRODUCTADDON = "New product add on",
    NEW_TAG = "New tag"
}
  