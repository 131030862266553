// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import i18next from "i18next";
import Resizer from "react-image-file-resizer";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_event_filled from "../images/wizy_evnet_filled.svg";
import wizy_ai_filled from "../images/wizy_ai_filled.svg";
import wizy_shop_bag_filled from "../images/wizy_shop_bag_filled.svg";
import wizy_globe_outline from "../images/wizy_globe_outline.svg";
import wizy_shield_filled from "../images/wizy_shield_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";
import wizy_settings_filled from "../images/wizy_settings_filled.svg";
import wizy_shield_check_filled from "../images/wizy_shield_check_filled.svg";
import facebook_logo from "../images/Facebook_Logo_Secondary.png";
import wizy_chevron_down_outline from "../images/wizy_chevron_down_outline.svg";
import wizy_chevron_up_outline from "../images/wizy_chevron_up_outline.svg";

// Import styles
import "./styles/Settings.css";
import { IEvent } from "../types/EventType";
import { useNavigate } from "react-router-dom";

// Declare types and interfaces
import {
  EDeactivationAction,
  ELimitBudgetAction,
  ERequestHelpAction,
  ISetup,
} from "../types/SetupType";
import ChatSetup from "../components/ChatSetup";
import { useTranslation } from "react-i18next";
import { IShop } from "../types/ShopType";
import { IUser } from "../types/UserType";
import { IValidatorProps } from "./Validator";
import { EStandardPlansName } from "../types/PlanType";
import {
  ESourceStatus,
  IInstagramAccount,
  IMessengerAccount,
  IWhatsAppAccount,
} from "../types/MetaTypes";
import TagsInput from "./TagsInput";

export type IKnowledgeCount = {
  productCount: number;
  pageCount: number;
  policyCount: number;
  collectionCount: number;
};

type IActivationSchedule = {
  monActive: boolean;
  monOn: number;
  monOff: number;
  tueActive: boolean;
  tueOn: number;
  tueOff: number;
  wedActive: boolean;
  wedOn: number;
  wedOff: number;
  thuActive: boolean;
  thuOn: number;
  thuOff: number;
  friActive: boolean;
  friOn: number;
  friOff: number;
  satActive: boolean;
  satOn: number;
  satOff: number;
  sunActive: boolean;
  sunOn: number;
  sunOff: number;
};

const DAYS_OF_WEEK = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
const NUMBER_TO_INITIAL = ["m", "t", "w", "r", "f", "s", "u"];

export enum ESide {
  right,
  left,
}

export enum EInteract {
  greet,
  code,
}

export enum ECustom {
  custom,
  simple,
}

export enum ESettings {
  live,
  bot,
  outbound,
  account,
}

type SettingsComponentProps = {
  globalShop: IShop;
  globalSelectedBackend: string;
  globalUser: IUser;
  setValidatorProps: (validatorProps: IValidatorProps) => void;
  updateIsLoaded?: () => void;
  changePassword: (
    newPassword: string,
    confirmedPassword: string
  ) => Promise<{
    status: boolean;
    message: string;
  }>;
};

// Required to reference the FB from index.html
declare global {
  interface Window {
    FB: any;
  }
}

// Page main functional component
const SettingsComponent: FC<SettingsComponentProps> = ({
  globalShop,
  globalSelectedBackend,
  globalUser,
  setValidatorProps,
  updateIsLoaded,
  changePassword,
}) => {
  // Use translation
  const { t } = useTranslation(["settings"]);

  // Use history
  const navigate = useNavigate();

  // Use state variables
  const [isSync, setIsSync] = useState<boolean>(false);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [isSyncErrorS, setIsSyncErrorS] = useState<boolean>(false);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [setup, setSetup] = useState<ISetup>({
    primaryColor: "#161635",
    secondaryColor: "#3F7AD9",
    fontColor: "#FFFFFF",
    agentName: "",
    onlinePhrase: "",
    isCode: false,
    isGreet: true,
    discountPercentage: 1,
    isCustomMessages: false,
    initialMessage: "",
    defaultMessage: "",
    productMessage: "",
    collectionMessage: "",
    searchMessage: "",
    pagePolicyMessage: "",
    companyContactInfo: globalUser.email,
    mainLanguage: "English",
    side: "Right",
    paddingBottom: 15,
    paddingSide: 15,
    image: "Default",
    isVisible: true,
    emailRetrievalMethod: "Semantic",
    dataRetrievalType: "Email",
    dataRetrievalCustomPrompt: "",
    emailForNotifications: globalUser.email,
    isSendNotifications: true,
    chatbotPersonality: "",
    ticketNotificationType: "Daily",
    hasActivationSchedule: false,
    activationSchedule: "",
    activationScheduleTimezone: "-5",
    deactivationAction: EDeactivationAction.VISIBILITY,
    isAI: true,
    isRedirect: false,
    redirectionLink: "",
    requestHelpAction: ERequestHelpAction.CREATE_TICKET,
    customRequestHelpPrompt: "",
    maxMessagesPerDay: 25,
    hasLimitBudget: false,
    limitBudget: 5000,
    limitBudgetAction: ELimitBudgetAction.AI_OFF,
    limitBudgetDefaultMessage: "",
    limitBudgetRedirectionLink: "",
    syncBlogs: false,
    hasInitialPolicy: false,
    initialPolicyMessage: "",
    ticketDataRetrievalType: "Email",
    productTagsToIncludeInKnowledge: null,
    productTagsToExcludeFromKnowledge: null,
    additionalResponseDelay: 0,
    metaAvailable: false,
  });
  const [knowledgeCount, setKnowledgeCount] = useState<IKnowledgeCount>({
    productCount: 0,
    pageCount: 0,
    policyCount: 0,
    collectionCount: 0,
  });
  const [activeSettings, setActiveSettings] = useState<ESettings>(
    ESettings.live
  );
  const [image, setImage] = useState<File | undefined>(undefined);
  const [imageDisplay, setImageDisplay] = useState<string>("");
  const [password, setPassword] = useState<string>("Password123");

  const [activationSchedule, setActivationSchedule] =
    useState<IActivationSchedule>({
      monActive: true,
      monOn: 17,
      monOff: 9,
      tueActive: true,
      tueOn: 17,
      tueOff: 9,
      wedActive: true,
      wedOn: 17,
      wedOff: 9,
      thuActive: true,
      thuOn: 17,
      thuOff: 9,
      friActive: true,
      friOn: 17,
      friOff: 9,
      satActive: true,
      satOn: 17,
      satOff: 9,
      sunActive: true,
      sunOn: 17,
      sunOff: 9,
    });

  const [whatsAppAccounts, setWhatsAppAccounts] = useState<IWhatsAppAccount[]>(
    []
  );
  const [messengerAccounts, setMessengerAccounts] = useState<
    IMessengerAccount[]
  >([]);
  const [instagramAccounts, setInstagramAccounts] = useState<
    IInstagramAccount[]
  >([]);

  const [isAdvancedChatbotOptionsOpen, setIsAdvancedChatbotOptionsOpen] =
    useState<boolean>(false);

  const [productTagsToIncludeInKnowledge, setProductTagsToIncludeInKnowledge] =
    useState<String[]>([]);
  const [
    productTagsToExcludeFromKnowledge,
    setProductTagsToExcludeFromKnowledge,
  ] = useState<String[]>([]);

  // References needed
  let fileInputOneRef = useRef(null);

  // META SIGN UPS

  const launchWhatsAppSignUp = () => {
    window.FB.login(
      function (response: any) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          fetch(
            globalSelectedBackend +
              "/whatsappauth/" +
              code +
              "/" +
              globalShop.id,
            {
              method: "GET",
              credentials: "include",
              redirect: "follow",
            }
          )
            .then(async (response) => {
              if (!response.ok) {
                let errorJSON = await response.json();
                throw new Error(errorJSON.message);
              } else {
                return await response.json();
              }
            })
            .then((JSONresult) => {
              if (JSONresult.success) {
                setValidatorProps({
                  validatorPrompt: t("WhatsAppIntegrationSuccessD", {
                    ns: ["settings"],
                  }),
                  validatorTitle: t("WhatsAppIntegrationSuccess", {
                    ns: ["settings"],
                  }),
                  functionOne: () => {
                    window.location.reload();
                  },
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              } else {
                setValidatorProps({
                  validatorPrompt:
                    t("WhatsAppIntegrationFailD", {
                      ns: ["settings"],
                    }) +
                    " " +
                    JSONresult.error,
                  validatorTitle: "WhatsAppIntegrationFail",
                  functionOne: () => {},
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });

          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: process.env.REACT_APP_WHATSAPP_LOGIN_CONFIGURATION_ID, // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        // extras: {
        //   setup: {
        //     ... // Prefilled data can go here
        //   }
        // }
      }
    );
  };

  const launchMessengerSignUp = () => {
    window.FB.login(
      function (response: any) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          fetch(
            globalSelectedBackend +
              "/messengerauth/" +
              code +
              "/" +
              globalShop.id,
            {
              method: "GET",
              credentials: "include",
              redirect: "follow",
            }
          )
            .then(async (response) => {
              if (!response.ok) {
                let errorText = await response.text();
                let errorJSON = JSON.parse(errorText);
                throw new Error(errorJSON.message);
              } else {
                return response.text();
              }
            })
            .then((result) => JSON.parse(result))
            .then((JSONresult) => {
              if (JSONresult.status) {
                setValidatorProps({
                  validatorPrompt: t("MessengerIntegrationSuccessD", {
                    ns: ["settings"],
                  }),
                  validatorTitle: t("MessengerIntegrationSuccess", {
                    ns: ["settings"],
                  }),
                  functionOne: () => {
                    window.location.reload();
                  },
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              } else {
                setValidatorProps({
                  validatorPrompt: t("MessengerIntegrationFailD", {
                    ns: ["settings"],
                  }),
                  validatorTitle: "MessengerIntegrationFail",
                  functionOne: () => {},
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });

          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: process.env.REACT_APP_MESSENGER_LOGIN_CONFIGURATION_ID, // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        // extras: {
        //   setup: {
        //     ... // Prefilled data can go here
        //   }
        // }
      }
    );
  };

  const launchInstagramSignUp = () => {
    window.FB.login(
      function (response: any) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          fetch(
            globalSelectedBackend +
              "/instagramauth/" +
              code +
              "/" +
              globalShop.id,
            {
              method: "GET",
              credentials: "include",
              redirect: "follow",
            }
          )
            .then(async (response) => {
              if (!response.ok) {
                let errorText = await response.text();
                let errorJSON = JSON.parse(errorText);
                throw new Error(errorJSON.message);
              } else {
                return response.text();
              }
            })
            .then((result) => JSON.parse(result))
            .then((JSONresult) => {
              if (JSONresult.status) {
                setValidatorProps({
                  validatorPrompt: t("InstagramIntegrationSuccessD", {
                    ns: ["settings"],
                  }),
                  validatorTitle: t("InstagramIntegrationSuccess", {
                    ns: ["settings"],
                  }),
                  functionOne: () => {
                    window.location.reload();
                  },
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              } else {
                setValidatorProps({
                  validatorPrompt: t("InstagramIntegrationFailD", {
                    ns: ["settings"],
                  }),
                  validatorTitle: "InstagramIntegrationFail",
                  functionOne: () => {},
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });

          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: process.env.REACT_APP_INSTAGRAM_LOGIN_CONFIGURATION_ID, // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        // extras: {
        //   setup: {
        //     ... // Prefilled data can go here
        //   }
        // }
      }
    );
  };

  //Internal functions
  // Use Effect Functions
  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSync) {
      setValidatorProps({
        validatorTitle: t("Synchronization", { ns: ["home"] }),
        validatorPrompt: t("SyncingS", { ns: ["home"] }),
        functionOne: () => {
          window.location.reload();
        },
        functionOnePrompt: t("Continue", { ns: ["home"] }),
        functionTwo: () => {},
        functionTwoPrompt: "",
        functionNumber: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSync]);

  useEffect(() => {
    if (isSyncErrorS) {
      setValidatorProps({
        validatorPrompt: t("SyncingE", { ns: ["home"] }),
        validatorTitle: t("SyncingT", { ns: ["home"] }),
        functionOne: () => {
          startSync();
        },
        functionOnePrompt: t("Retry", { ns: ["home"] }),
        functionTwo: () => {},
        functionTwoPrompt: t("Cancel", { ns: ["home"] }),
        functionNumber: 2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncErrorS]);

  useEffect(() => {
    activationScheduleToString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationSchedule]);

  const getEvents = async () => {
    await fetch(globalSelectedBackend + "/shops/" + globalShop.id + "/events", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        JSONresult.forEach((event: IEvent) => {
          setEvents((prevState) => [
            ...prevState,
            {
              id: event.id,
              domain: event.domain,
              webhookData: event.webhookData,
              webhookTopic: event.webhookTopic,
              createDate: event.createDate,
            },
          ]);
        });
        getSetup();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSetup = async () => {
    await fetch(globalSelectedBackend + "/setups/" + globalShop.id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setSetup({
          primaryColor: JSONresult.primaryColor,
          secondaryColor: JSONresult.secondaryColor,
          fontColor: JSONresult.fontColor,
          agentName: JSONresult.agentName,
          onlinePhrase: JSONresult.onlinePhrase,
          isCode: JSONresult.isCode,
          isGreet: JSONresult.isGreet,
          discountPercentage: +JSONresult.discountPercentage,
          isCustomMessages: JSONresult.isCustomMessages,
          initialMessage:
            JSONresult.initialMessage === ""
              ? t("InitialM", { ns: ["settings"] })
              : JSONresult.initialMessage,
          defaultMessage:
            JSONresult.defaultMessage === ""
              ? t("DefaultM", { ns: ["settings"] })
              : JSONresult.defaultMessage,
          productMessage:
            JSONresult.productMessage === ""
              ? t("ProductM", { ns: ["settings"] })
              : JSONresult.productMessage,
          collectionMessage:
            JSONresult.collectionMessage === ""
              ? t("CollectionM", { ns: ["settings"] })
              : JSONresult.collectionMessage,
          searchMessage:
            JSONresult.searchMessage === ""
              ? t("SearchM", { ns: ["settings"] })
              : JSONresult.searchMessage,
          pagePolicyMessage:
            JSONresult.pagePolicyMessage === ""
              ? t("PageM", { ns: ["settings"] })
              : JSONresult.pagePolicyMessage,
          companyContactInfo: JSONresult.companyContactInfo,
          mainLanguage: JSONresult.mainLanguage,
          side: JSONresult.side,
          paddingBottom: +JSONresult.paddingBottom,
          paddingSide: +JSONresult.paddingSide,
          image: JSONresult.image,
          isVisible: JSONresult.isVisible,
          emailRetrievalMethod: JSONresult.emailRetrievalMethod,
          dataRetrievalType: JSONresult.dataRetrievalType,
          dataRetrievalCustomPrompt: JSONresult.dataRetrievalCustomPrompt,
          emailForNotifications:
            JSONresult.emailForNotifications === "" ||
            JSONresult.emailForNotifications === null
              ? "Default"
              : JSONresult.emailForNotifications,
          isSendNotifications: JSONresult.isSendNotifications,
          chatbotPersonality:
            JSONresult.chatbotPersonality === ""
              ? t("ChatbotP", { ns: ["settings"] })
              : JSONresult.chatbotPersonality,
          ticketNotificationType: JSONresult.ticketNotificationType,
          hasActivationSchedule: JSONresult.hasActivationSchedule,
          activationSchedule: JSONresult.activationSchedule,
          activationScheduleTimezone: JSONresult.activationScheduleTimezone,
          deactivationAction: JSONresult.deactivationAction,
          isAI: JSONresult.isAI,
          isRedirect: JSONresult.isRedirect,
          redirectionLink: JSONresult.redirectionLink,
          requestHelpAction: JSONresult.requestHelpAction,
          customRequestHelpPrompt: JSONresult.customRequestHelpPrompt,
          maxMessagesPerDay: +JSONresult.maxMessagesPerDay,
          hasLimitBudget: JSONresult.hasLimitBudget,
          limitBudget: +JSONresult.limitBudget,
          limitBudgetAction: JSONresult.limitBudgetAction,
          limitBudgetDefaultMessage: JSONresult.limitBudgetDefaultMessage,
          limitBudgetRedirectionLink: JSONresult.limitBudgetRedirectionLink,
          syncBlogs: JSONresult.syncBlogs,
          hasInitialPolicy: JSONresult.hasInitialPolicy,
          initialPolicyMessage:
            JSONresult.initialPolicyMessage === null
              ? ""
              : JSONresult.initialPolicyMessage,
          ticketDataRetrievalType: JSONresult.ticketDataRetrievalType,
          productTagsToIncludeInKnowledge:
            JSONresult.productTagsToIncludeInKnowledge,
          productTagsToExcludeFromKnowledge:
            JSONresult.productTagsToExcludeFromKnowledge,
          additionalResponseDelay: +JSONresult.additionalResponseDelay,
          metaAvailable: JSONresult.metaAvailable,
        });
        if (JSONresult.image !== "Default") {
          if (
            process.env.REACT_APP_BACKEND_GATEWAY !== "https://api.wizybot.com"
          ) {
            setImageDisplay(
              "https://s3-tjlabs-wizybot-widget-images-dev.s3.amazonaws.com/" +
                JSONresult.image +
                "?nocache=" +
                new Date()[Symbol.toPrimitive]("number")
            );
          } else {
            setImageDisplay(
              "https://s3-tjlabs-wizybot-widget-images-prod.s3.amazonaws.com/" +
                JSONresult.image +
                "?nocache=" +
                new Date()[Symbol.toPrimitive]("number")
            );
          }
        } else {
          setImageDisplay("");
        }
        if (JSONresult.activationSchedule !== "") {
          fromStringToActivationSchedule(JSONresult.activationSchedule);
        }

        setProductTagsToIncludeInKnowledge(
          JSONresult.productTagsToIncludeInKnowledge
            ? JSONresult.productTagsToIncludeInKnowledge.split(",")
            : []
        );

        setProductTagsToExcludeFromKnowledge(
          JSONresult.productTagsToExcludeFromKnowledge
            ? JSONresult.productTagsToExcludeFromKnowledge.split(",")
            : []
        );

        getKnowledgeCount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKnowledgeCount = async () => {
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/knowledgecount",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setKnowledgeCount({
          productCount: JSONresult.productCount,
          pageCount: JSONresult.pageCount,
          policyCount: JSONresult.policyCount,
          collectionCount: JSONresult.collectionCount,
        });
        getMetaAccounts();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMetaAccounts = async () => {
    await fetch(globalSelectedBackend + "/meta/accounts/" + globalShop.id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setWhatsAppAccounts(JSONresult.whatsAppAccounts);
        setMessengerAccounts(JSONresult.messengerAccounts);
        setInstagramAccounts(JSONresult.instagramAccounts);
        setIsLoaded(true);
        if (updateIsLoaded) {
          updateIsLoaded();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goBacktoFree = async () => {
    await fetch(globalSelectedBackend + "/billings/" + globalShop.id, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt: t("Free", { ns: ["settings"] }),
            validatorTitle: t("Desactivation", { ns: ["settings"] }),
            functionOne: () => {
              window.location.reload();
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: t("Error", { ns: ["settings"] }),
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error", { ns: ["settings"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  const deleteActualShop = async () => {
    await fetch(globalSelectedBackend + "/shops/" + globalShop.id, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          window.location.reload();
        } else {
          if (JSONresult.err === "app") {
            setValidatorProps({
              validatorPrompt: t("App", { ns: ["settings"] }),
              validatorTitle: "Error",
              functionOne: () => {
                manageEvent();
              },
              functionOnePrompt: t("Delete1", { ns: ["settings"] }),
              functionTwo: () => {},
              functionTwoPrompt: t("Cancel1", { ns: ["settings"] }),
              functionNumber: 2,
            });
          } else {
            setValidatorProps({
              validatorPrompt: t("Error1", { ns: ["settings"] }),
              validatorTitle: "Error",
              functionOne: () => {},
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "",
              functionNumber: 1,
            });
          }
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error1", { ns: ["settings"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
    <p></p>;
  };

  const manageEvent = () => {
    window.open(
      "https://" + globalShop.domain + "/admin/settings/apps?tab=installed",
      "_blank"
    );
  };

  const handleSetupChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setSetup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    if (
      name === "emailRetrievalMethod" &&
      value === "Mandatory" &&
      setup.dataRetrievalType === "Custom"
    ) {
      setSetup((prevState) => ({
        ...prevState,
        [name]: value,
        dataRetrievalType: "Email",
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectBoolean = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    setSetup((prevState) => ({
      ...prevState,
      [name]: value === "True" ? true : false,
    }));
  };

  const handleSetupChangeText = (
    event: React.FormEvent<HTMLTextAreaElement>,
    limit: number
  ) => {
    const { name, value } = event.currentTarget;
    if (value.split(" ").length <= limit) {
      setSetup((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCustomChange = (type: ECustom) => {
    if (type === ECustom.custom) {
      setSetup((prevState) => ({
        ...prevState,
        isCustomMessages: true,
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        isCustomMessages: false,
      }));
    }
  };

  const handleInteractChange = (type: EInteract) => {
    if (type === EInteract.greet) {
      setSetup((prevState) => ({
        ...prevState,
        isGreet: true,
        isCode: false,
      }));
      setSetup((prevState) => ({
        ...prevState,
        initialMessage: t("InitialM", { ns: ["settings"] }),
        defaultMessage: t("DefaultM", { ns: ["settings"] }),
        productMessage: t("ProductM", { ns: ["settings"] }),
        collectionMessage: t("CollectionM", { ns: ["settings"] }),
        searchMessage: t("SearchM", { ns: ["settings"] }),
        pagePolicyMessage: t("PageM", { ns: ["settings"] }),
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        isGreet: false,
        isCode: true,
      }));
      setSetup((prevState) => ({
        ...prevState,
        initialMessage: t("InitialM1", { ns: ["settings"] }),
        defaultMessage: t("DefaultM1", { ns: ["settings"] }),
        productMessage: t("ProductM1", { ns: ["settings"] }),
        collectionMessage: t("CollectionM1", { ns: ["settings"] }),
        searchMessage: t("SearchM1", { ns: ["settings"] }),
        pagePolicyMessage: t("PageM1", { ns: ["settings"] }),
      }));
    }
  };

  const handleSideChange = (type: ESide) => {
    if (type === ESide.right) {
      setSetup((prevState) => ({
        ...prevState,
        side: "Right",
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        side: "Left",
      }));
    }
  };

  const handleImageFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files === null ? undefined : e.target.files[0];
    if (file !== undefined) {
      const resizedImage = await resizeFile(file);
      if (resizedImage instanceof File) {
        setImage(resizedImage);
        setSetup((prevState) => ({
          ...prevState,
          image: globalShop.id + ".png",
        }));
        setImageDisplay(URL.createObjectURL(resizedImage).toString());
      }
    }
  };

  const handleHasActivationScheduleChange = (
    hasActivationSchedule: boolean
  ) => {
    if (hasActivationSchedule) {
      setSetup((prevState) => ({
        ...prevState,
        hasActivationSchedule: true,
        activationSchedule:
          setup.activationSchedule === "" || setup.activationSchedule === null
            ? "ma17-md09-ta17-td09-wa17-wd09-ra17-rd09-fa17-fd09-sa17-sd09-ua17-ud09"
            : setup.activationSchedule,
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        hasActivationSchedule: false,
        isAI: true,
        isRedirect: false,
      }));
    }
  };

  const handleHasLimitBudgetChange = (hasLimitBudget: boolean) => {
    if (hasLimitBudget) {
      setSetup((prevState) => ({
        ...prevState,
        hasLimitBudget: true,
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        hasLimitBudget: false,
      }));
    }
  };

  const handleSyncBlogsChange = (syncBlogs: boolean) => {
    if (syncBlogs) {
      setSetup((prevState) => ({
        ...prevState,
        syncBlogs: true,
      }));
    } else {
      setSetup((prevState) => ({
        ...prevState,
        syncBlogs: false,
      }));
    }
  };

  const handleHourPickerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    setActivationSchedule((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resizeFile = (
    file: File
  ): Promise<string | File | Blob | ProgressEvent<FileReader>> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const uploadI1 = async (e: File | undefined) => {
    if (e !== undefined) {
      await fetch(
        globalSelectedBackend + "/setups/" + globalShop.id + "/signedurl",
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          console.log(e.type.split("/")[1]);
          fetch(JSONresult.signedUrl, {
            method: "PUT",
            body: image,
            headers: {
              "Content-Type": e.type.split("/")[1],
            },
          })
            .then((response) => {
              console.log(response);
              if (response.ok) {
                setValidatorProps({
                  validatorPrompt:
                    t("Shop", { ns: ["settings"] }) +
                    globalShop.domain +
                    t("SetupU", { ns: ["settings"] }),
                  validatorTitle: t("Setup", { ns: ["settings"] }),
                  functionOne: () => {
                    window.location.reload();
                  },
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              } else {
                setValidatorProps({
                  validatorPrompt:
                    t("Error2", { ns: ["settings"] }) +
                    "Failed to upload image",
                  validatorTitle: "Error",
                  functionOne: () => {},
                  functionOnePrompt: "OK",
                  functionTwo: () => {},
                  functionTwoPrompt: "",
                  functionNumber: 1,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              setValidatorProps({
                validatorPrompt:
                  t("Error2", { ns: ["settings"] }) + "Failed to upload image",
                validatorTitle: "Error",
                functionOne: () => {},
                functionOnePrompt: "OK",
                functionTwo: () => {},
                functionTwoPrompt: "",
                functionNumber: 1,
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const resetImage = async () => {
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/resetimage",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt:
              t("Shop", { ns: ["settings"] }) +
              globalShop.domain +
              t("SetupU", { ns: ["settings"] }),
            validatorTitle: t("Setup", { ns: ["settings"] }),
            functionOne: () => {
              window.location.reload();
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt:
              t("Error2", { ns: ["settings"] }) + "Failed to upload image",
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setValidatorProps({
          validatorPrompt:
            t("Error2", { ns: ["settings"] }) + "Failed to upload image",
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  const patchSetup = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      primaryColor: setup.primaryColor,
      secondaryColor: setup.secondaryColor,
      fontColor: setup.fontColor,
      agentName: setup.agentName,
      onlinePhrase: setup.onlinePhrase,
      isCode: setup.isCode,
      isGreet: setup.isGreet,
      discountPercentage: +setup.discountPercentage,
      isCustomMessages: setup.isCustomMessages,
      initialMessage: setup.initialMessage,
      defaultMessage: setup.defaultMessage,
      productMessage: setup.productMessage,
      collectionMessage: setup.collectionMessage,
      searchMessage: setup.searchMessage,
      pagePolicyMessage: setup.pagePolicyMessage,
      companyContactInfo: setup.companyContactInfo,
      mainLanguage: setup.mainLanguage,
      side: setup.side,
      paddingBottom: +setup.paddingBottom,
      paddingSide: +setup.paddingSide,
      image: setup.image,
      isVisible: setup.isVisible,
      emailRetrievalMethod: setup.emailRetrievalMethod,
      dataRetrievalType: setup.dataRetrievalType,
      dataRetrievalCustomPrompt: setup.dataRetrievalCustomPrompt,
      emailForNotifications: setup.emailForNotifications,
      isSendNotifications: setup.isSendNotifications,
      chatbotPersonality: setup.chatbotPersonality,
      ticketNotificationType: setup.ticketNotificationType,
      hasActivationSchedule: setup.hasActivationSchedule,
      activationSchedule: setup.activationSchedule,
      activationScheduleTimezone: setup.activationScheduleTimezone,
      deactivationAction: setup.deactivationAction,
      isAI: setup.isAI,
      isRedirect: setup.isRedirect,
      redirectionLink: setup.redirectionLink,
      requestHelpAction: setup.requestHelpAction,
      customRequestHelpPrompt: setup.customRequestHelpPrompt,
      maxMessagesPerDay: +setup.maxMessagesPerDay,
      hasLimitBudget: setup.hasLimitBudget,
      limitBudget: +setup.limitBudget,
      limitBudgetAction: setup.limitBudgetAction,
      limitBudgetDefaultMessage: setup.limitBudgetDefaultMessage,
      limitBudgetRedirectionLink: setup.limitBudgetRedirectionLink,
      syncBlogs: setup.syncBlogs,
      hasInitialPolicy: setup.hasInitialPolicy,
      initialPolicyMessage: setup.initialPolicyMessage,
      ticketDataRetrievalType: setup.ticketDataRetrievalType,
      productTagsToIncludeInKnowledge:
        productTagsToIncludeInKnowledge.length > 0
          ? productTagsToIncludeInKnowledge.join(",")
          : null,
      productTagsToExcludeFromKnowledge:
        productTagsToExcludeFromKnowledge.length > 0
          ? productTagsToExcludeFromKnowledge.join(",")
          : null,
      additionalResponseDelay: +setup.additionalResponseDelay,
    });

    await fetch(globalSelectedBackend + "/setups/" + globalShop.id, {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          setValidatorProps({
            validatorPrompt:
              t("Error2", { ns: ["settings"] }) + errorJSON.message,
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
          throw new Error(errorJSON.message);
        } else {
          if (image === undefined) {
            setValidatorProps({
              validatorPrompt:
                t("Shop", { ns: ["settings"] }) +
                globalShop.domain +
                t("SetupU", { ns: ["settings"] }),
              validatorTitle: t("Setup", { ns: ["settings"] }),
              functionOne: () => {
                window.location.reload();
              },
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "",
              functionNumber: 1,
            });
          } else {
            uploadI1(image);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startSync = async () => {
    if (!isSyncing) {
      setIsSyncing(true);
      setIsSyncErrorS(false);
      await fetch(
        globalSelectedBackend + "/setups/" + globalShop.id + "/syncupdate",
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            setValidatorProps({
              validatorPrompt: t("SyncingE", { ns: ["home"] }),
              validatorTitle: t("SyncingT", { ns: ["home"] }),
              functionOne: () => {
                startSync();
              },
              functionOnePrompt: t("Retry", { ns: ["home"] }),
              functionTwo: () => {},
              functionTwoPrompt: t("Cancel", { ns: ["home"] }),
              functionNumber: 2,
            });
            setIsSyncing(false);
            setIsSync(false);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then(async (JSONresult) => {
          if (JSONresult.status) {
            while (true) {
              const syncStatus = await getSyncRecurrently();
              if (syncStatus.isSync || syncStatus.isSyncError) {
                break;
              }
              await new Promise((resolve) => setTimeout(resolve, 5000));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSyncRecurrently = async (): Promise<{
    needsSync: boolean;
    isSync: boolean;
    isSyncError: boolean;
    isSyncing: boolean;
    isProducts: boolean;
    isCollections: boolean;
    isPages: boolean;
    isPolicies: boolean;
    isCohere: boolean;
    isPinecone: boolean;
  }> => {
    let state = {
      needsSync: true,
      isSync: false,
      isSyncError: false,
      isSyncing: true,
      isProducts: false,
      isCollections: false,
      isPages: false,
      isPolicies: false,
      isCohere: false,
      isPinecone: false,
    };
    await fetch(
      globalSelectedBackend + "/setups/" + globalShop.id + "/syncstatus",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        state = {
          needsSync: JSONresult.needsSync,
          isSync: JSONresult.isSync,
          isSyncError: JSONresult.isSyncError,
          isSyncing: JSONresult.isSyncing,
          isProducts: JSONresult.isProducts,
          isCollections: JSONresult.isCollections,
          isPages: JSONresult.isPages,
          isPolicies: JSONresult.isPolicies,
          isCohere: JSONresult.isCohere,
          isPinecone: JSONresult.isPinecone,
        };
        if (JSONresult.isSync) {
          setIsSyncing(false);
          setIsSync(true);
        }
        if (JSONresult.isSyncError) {
          setIsSyncing(false);
          setIsSyncErrorS(true);
        }
        console.log(JSONresult);
      })
      .catch((err) => {
        console.log(err);
        state = {
          needsSync: true,
          isSync: false,
          isSyncError: false,
          isSyncing: true,
          isProducts: false,
          isCollections: false,
          isPages: false,
          isPolicies: false,
          isCohere: false,
          isPinecone: false,
        };
      });
    return state;
  };

  const activationScheduleToString = () => {
    const actions: String[] = [];
    for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
      const initial = NUMBER_TO_INITIAL[i];
      if (
        !!!activationSchedule[
          (DAYS_OF_WEEK[i] + "Active") as keyof typeof activationSchedule
        ]
      ) {
        actions.push(initial + "aXX-" + initial + "d00");
      } else {
        const activationNum = Number(
          activationSchedule[
            (DAYS_OF_WEEK[i] + "On") as keyof typeof activationSchedule
          ]
        );
        const activation =
          activationNum <= 9 ? "0" + activationNum : "" + activationNum;

        const deactivationNum = Number(
          activationSchedule[
            (DAYS_OF_WEEK[i] + "Off") as keyof typeof activationSchedule
          ]
        );
        const deactivation =
          deactivationNum <= 9 ? "0" + deactivationNum : "" + deactivationNum;

        actions.push(
          initial + "a" + activation + "-" + initial + "d" + deactivation
        );
      }
    }
    const activationScheduleString = actions.join("-");
    console.log(activationScheduleString);
    setSetup((prevState) => ({
      ...prevState,
      activationSchedule: activationScheduleString,
    }));
  };

  const fromStringToActivationSchedule = (activationScheduleString: string) => {
    const actions = activationScheduleString.split("-");
    for (let i = 0; i < NUMBER_TO_INITIAL.length; i++) {
      if (activationScheduleString.includes(`${NUMBER_TO_INITIAL[i]}aXX`)) {
        setActivationSchedule((prevState) => ({
          ...prevState,
          [DAYS_OF_WEEK[i] + "Active"]: false,
        }));
      } else {
        const onHour = parseInt(actions[2 * i].slice(-2));
        const offHour = parseInt(actions[2 * i + 1].slice(-2));
        setActivationSchedule((prevState) => ({
          ...prevState,
          [DAYS_OF_WEEK[i] + "Active"]: true,
          [DAYS_OF_WEEK[i] + "On"]: onHour,
          [DAYS_OF_WEEK[i] + "Off"]: offHour,
        }));
      }
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const deactivateWhatsAppAccount = async (whatsAppAccountId: string) => {
    await fetch(
      globalSelectedBackend +
        "/whatsappaccounts/deactivateai/" +
        whatsAppAccountId,
      {
        method: "PATCH",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setWhatsAppAccounts((prevWhatsAppAccounts) =>
          prevWhatsAppAccounts.map((whatsAppAccount) => {
            if (whatsAppAccount.id === JSONresult.id) {
              whatsAppAccount.isAiActive = JSONresult.isAiActive;
              return whatsAppAccount;
            } else {
              return whatsAppAccount;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateWhatsAppAccount = async (whatsAppAccountId: string) => {
    await fetch(
      globalSelectedBackend +
        "/whatsappaccounts/activateai/" +
        whatsAppAccountId,
      {
        method: "PATCH",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setWhatsAppAccounts((prevWhatsAppAccounts) =>
          prevWhatsAppAccounts.map((whatsAppAccount) => {
            if (whatsAppAccount.id === JSONresult.id) {
              whatsAppAccount.isAiActive = JSONresult.isAiActive;
              return whatsAppAccount;
            } else {
              return whatsAppAccount;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteWhatsAppAccount = async (whatsAppAccountId: string) => {
    await fetch(
      globalSelectedBackend + "/whatsappaccounts/delete/" + whatsAppAccountId,
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          const JSONResult = await response.json();
          if (JSONResult.success) {
            window.location.reload();
          } else {
            setValidatorProps({
              validatorPrompt: t("DeleteWhatsAppAccountErrorD", {
                ns: ["settings"],
              }),
              validatorTitle: "DeleteWhatsAppAccountError",
              functionOne: () => {},
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "",
              functionNumber: 1,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deactivateInstagramAccount = async (instagramAccountId: string) => {
    await fetch(
      globalSelectedBackend +
        "/instagramaccounts/deactivateai/" +
        instagramAccountId,
      {
        method: "PATCH",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setInstagramAccounts((prevInstagramAccounts) =>
          prevInstagramAccounts.map((instagramAccount) => {
            if (instagramAccount.id === JSONresult.id) {
              instagramAccount.isAiActive = JSONresult.isAiActive;
              return instagramAccount;
            } else {
              return instagramAccount;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateInstagramAccount = async (instagramAccountId: string) => {
    await fetch(
      globalSelectedBackend +
        "/instagramaccounts/activateai/" +
        instagramAccountId,
      {
        method: "PATCH",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setInstagramAccounts((prevInstagramAccounts) =>
          prevInstagramAccounts.map((instagramAccount) => {
            if (instagramAccount.id === JSONresult.id) {
              instagramAccount.isAiActive = JSONresult.isAiActive;
              return instagramAccount;
            } else {
              return instagramAccount;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteInstagramAccount = async (instagramAccountId: string) => {
    await fetch(
      globalSelectedBackend + "/instagramaccounts/delete/" + instagramAccountId,
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          const JSONResult = await response.json();
          if (JSONResult.success) {
            window.location.reload();
          } else {
            setValidatorProps({
              validatorPrompt: t("DeleteInstagramAccountErrorD", {
                ns: ["settings"],
              }),
              validatorTitle: "DeleteInstagramAccountError",
              functionOne: () => {},
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "",
              functionNumber: 1,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deactivateMessengerAccount = async (messengerAccountId: string) => {
    await fetch(
      globalSelectedBackend +
        "/messengeraccounts/deactivateai/" +
        messengerAccountId,
      {
        method: "PATCH",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setMessengerAccounts((prevMessengerAccounts) =>
          prevMessengerAccounts.map((messengerAccount) => {
            if (messengerAccount.id === JSONresult.id) {
              messengerAccount.isAiActive = JSONresult.isAiActive;
              return messengerAccount;
            } else {
              return messengerAccount;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateMessengerAccount = async (messengerAccountId: string) => {
    await fetch(
      globalSelectedBackend +
        "/messengeraccounts/activateai/" +
        messengerAccountId,
      {
        method: "PATCH",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        setMessengerAccounts((prevMessengerAccounts) =>
          prevMessengerAccounts.map((messengerAccount) => {
            if (messengerAccount.id === JSONresult.id) {
              messengerAccount.isAiActive = JSONresult.isAiActive;
              return messengerAccount;
            } else {
              return messengerAccount;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteMessengerAccount = async (messengerAccountId: string) => {
    await fetch(
      globalSelectedBackend + "/messengeraccounts/delete/" + messengerAccountId,
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          const JSONResult = await response.json();
          if (JSONResult.success) {
            window.location.reload();
          } else {
            setValidatorProps({
              validatorPrompt: t("DeleteMessengerAccountErrorD", {
                ns: ["settings"],
              }),
              validatorTitle: "DeleteMessengerAccountError",
              functionOne: () => {},
              functionOnePrompt: "OK",
              functionTwo: () => {},
              functionTwoPrompt: "",
              functionNumber: 1,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
        <div className="Settings__outter">
          <div className="Settings__inner">
            <div className="Settings__general__info__outter">
              <div className="Settings__general__events__shop">
                <div className="Settings__general__events__shop__inner">
                  <div className="Settings__general__events__shop__inner__inner">
                    <div className="Settings__general__info__shop__title">
                      {t("Events", { ns: ["settings"] })}
                    </div>
                    <div className="Settings__general__info__shop__description">
                      {t("EventsD", { ns: ["settings"] })}
                    </div>
                    <div className="Settings__general__events__list__outter">
                      {events
                        .sort(
                          (a, b) =>
                            Date.parse(b.createDate) - Date.parse(a.createDate)
                        )
                        .map((event, index) => {
                          return (
                            <div
                              key={index}
                              className="Settings__general__events__list__item__outter"
                            >
                              <div className="Settings_general__events__list__item__image__outter">
                                <img
                                  src={wizy_event_filled}
                                  alt="wizy_event_filled"
                                  className="Settings_general__events__list__item__image"
                                />
                              </div>
                              <div className="Settings__general__events__list__item__text__outter">
                                <div className="Settings__general__events__list__item__text__centered">
                                  <div className="Settings__general__events__list__item__text__inner">
                                    {t("Event", { ns: ["settings"] })}:{" "}
                                    <span style={{ fontSize: "13px" }}>
                                      {event.webhookTopic === "app_subscription"
                                        ? "Subscription Update"
                                        : event.webhookTopic ===
                                          "app/uninstalled"
                                        ? "App Uninstalled"
                                        : event.webhookTopic === "app/installed"
                                        ? "App Installed"
                                        : event.webhookTopic ===
                                          "customers/data_request"
                                        ? "Customer Data"
                                        : event.webhookTopic ===
                                          "customers/redact"
                                        ? "Customer Redact"
                                        : "Shop Redact"}
                                    </span>{" "}
                                  </div>
                                  <div className="Settings__general__events__list__item__text__inner">
                                    {event.webhookTopic ===
                                    "app_subscription" ? (
                                      <div>
                                        Plan:{" "}
                                        <span style={{ fontSize: "13px" }}>
                                          {
                                            JSON.parse(event.webhookData)
                                              .app_subscription.name
                                          }{" "}
                                          is{" "}
                                          {
                                            JSON.parse(event.webhookData)
                                              .app_subscription.status
                                          }
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="Settings__general__events__list__item__text__inner">
                                    {t("Date", { ns: ["settings"] })}:{" "}
                                    <span style={{ fontSize: "13px" }}>
                                      {new Date(
                                        Date.parse(event.createDate)
                                      ).toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="Settings__general__events__list__item__button__outter">
                                <div className="Settings__general__events__list__item__button__inner">
                                  {" "}
                                  <button
                                    className="Wizy__button__6"
                                    style={{ width: "100%" }}
                                    onClick={manageEvent}
                                  >
                                    {t("Check", { ns: ["settings"] })}
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="Settings__general__info__shop">
                <div className="Settings__general__info__shop__inner">
                  <div className="Settings__general__info__shop__inner__inner">
                    <div className="Settings__general__info__shop__title">
                      {t("Selected", { ns: ["settings"] })}
                    </div>
                    <div className="Settings__general__info__shop__description">
                      {t("SelectedD", { ns: ["settings"] })}
                    </div>
                    <div className="Settings__general__info__shop__info__outter">
                      <div className="Settings__general__info__shop__subtitle">
                        {t("ShopI", { ns: ["settings"] })}
                      </div>
                      <div className="Settings__general__info__item__outter">
                        <div className="Settings__general__info__item__outter__text">
                          {globalShop.id}
                        </div>
                      </div>
                      <div className="Settings__general__info__shop__subtitle">
                        {t("ShopD", { ns: ["settings"] })}
                      </div>
                      <div className="Settings__general__info__item__outter">
                        <div className="Settings__general__info__item__outter__text">
                          {globalShop.domain}
                        </div>
                      </div>
                      <div className="Settings__general__info__shop__subtitle">
                        {t("ShopP", { ns: ["settings"] })}
                      </div>
                      <div className="Settings__general__info__item__outter">
                        <div className="Settings__general__info__item__outter__text">
                          {i18next.language === "en"
                            ? globalShop.currentPlan.name
                                .charAt(0)
                                .toUpperCase() +
                              globalShop.currentPlan.name.slice(1) +
                              " plan"
                            : "Plan " +
                              globalShop.currentPlan.name
                                .charAt(0)
                                .toUpperCase() +
                              globalShop.currentPlan.name.slice(1)}
                        </div>
                      </div>
                      <div className="Settings__general__info__shop__subtitle">
                        {t("AccountI", { ns: ["settings"] })}
                      </div>
                      <div className="Settings__general__info__item__outter">
                        <div className="Settings__general__info__item__outter__text">
                          {globalUser.id}
                        </div>
                      </div>
                      <div className="Settings__general__info__shop__subtitle">
                        {t("AccountE", { ns: ["settings"] })}
                      </div>
                      <div className="Settings__general__info__item__outter">
                        <div className="Settings__general__info__item__outter__text">
                          {globalUser.email}
                        </div>
                      </div>
                    </div>
                    <div className="Settings__general__info__buttons__outter">
                      <button
                        className="Wizy__button__5"
                        style={{ width: "100%" }}
                        onClick={() => {
                          setValidatorProps({
                            validatorPrompt:
                              t("Sure", { ns: ["settings"] }) +
                              globalShop.domain +
                              t("ShopQ", { ns: ["settings"] }),
                            validatorTitle: t("Deactivation", {
                              ns: ["settings"],
                            }),
                            functionOne: () => {
                              deleteActualShop();
                            },
                            functionOnePrompt: "OK",
                            functionTwo: () => {},
                            functionTwoPrompt: "NO",
                            functionNumber: 2,
                          });
                        }}
                      >
                        {t("Delete", { ns: ["settings"] })}
                      </button>
                    </div>
                    <div className="Settings__general__info__buttons__outter">
                      <button
                        className="Wizy__button__3"
                        style={{ width: "100%" }}
                        onClick={() => {
                          if (
                            globalShop.currentPlan.name ===
                            EStandardPlansName.FREE
                          ) {
                            navigate("/billing");
                          } else {
                            setValidatorProps({
                              validatorPrompt: t("Sure1", { ns: ["settings"] }),
                              validatorTitle: t("Deactivation", {
                                ns: ["settings"],
                              }),
                              functionOne: () => {
                                goBacktoFree();
                              },
                              functionOnePrompt: "OK",
                              functionTwo: () => {},
                              functionTwoPrompt: "NO",
                              functionNumber: 2,
                            });
                          }
                        }}
                      >
                        {globalShop.currentPlan.name === EStandardPlansName.FREE
                          ? t("Upgrade", { ns: ["settings"] })
                          : t("Cancel", { ns: ["settings"] })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Settings__setup__info__outter">
              <div className="Settings__setup__info__inner">
                <div className="Settings__setup__buttons__outter">
                  <div></div>
                  <div className="Settings__general__info__buttons__outter">
                    <button
                      className="Wizy__button__3"
                      style={{ width: "100%" }}
                      onClick={() => {
                        navigate("/home");
                      }}
                    >
                      {t("CancelB", { ns: ["settings"] })}
                    </button>
                  </div>
                  <div></div>
                  <div className="Settings__general__info__buttons__outter">
                    <button
                      className="Wizy__button__2"
                      style={{ width: "100%" }}
                      onClick={() => {
                        patchSetup();
                      }}
                    >
                      {t("Update", { ns: ["settings"] })}
                    </button>
                  </div>
                </div>
                <div className="Settings__setup__info__inner__inner">
                  <div className="Settings__setup__info__inner__questions__select__outter">
                    <button
                      className="Wizy__button__7"
                      onClick={() => setActiveSettings(ESettings.live)}
                      style={{
                        backgroundColor:
                          activeSettings === ESettings.live ? "#cbcdd1" : "",
                      }}
                    >
                      <img
                        src={wizy_chat_filled}
                        alt="wizy_chat_filled"
                        className="Settings__setup__info__inner__questions__select__inner__image"
                        style={{
                          filter:
                            "invert(46%) sepia(19%) saturate(551%) hue-rotate(180deg) brightness(93%) contrast(94%)",
                        }}
                      />
                      {t("LiveC", { ns: ["settings"] })}
                    </button>
                    <button
                      className="Wizy__button__7"
                      onClick={() => setActiveSettings(ESettings.bot)}
                      style={{
                        backgroundColor:
                          activeSettings === ESettings.bot ? "#cbcdd1" : "",
                      }}
                    >
                      <img
                        src={wizy_ai_filled}
                        alt="wizy_shop_bag_filled"
                        className="Settings__setup__info__inner__questions__select__inner__image"
                        style={{
                          filter:
                            "invert(46%) sepia(19%) saturate(551%) hue-rotate(180deg) brightness(93%) contrast(94%)",
                        }}
                      />
                      {t("Bot", { ns: ["settings"] })}
                    </button>
                    <button
                      className="Wizy__button__7"
                      onClick={() => setActiveSettings(ESettings.outbound)}
                      style={{
                        backgroundColor:
                          activeSettings === ESettings.outbound
                            ? "#cbcdd1"
                            : "",
                      }}
                    >
                      <img
                        src={wizy_event_filled}
                        alt="wizy_event_filled"
                        className="Settings__setup__info__inner__questions__select__inner__image"
                        style={{
                          filter:
                            "invert(46%) sepia(19%) saturate(551%) hue-rotate(180deg) brightness(93%) contrast(94%)",
                        }}
                      />
                      {t("Outbound", { ns: ["settings"] })}
                    </button>
                    <button
                      className="Wizy__button__7"
                      onClick={() => setActiveSettings(ESettings.account)}
                      style={{
                        backgroundColor:
                          activeSettings === ESettings.account ? "#cbcdd1" : "",
                      }}
                    >
                      <img
                        src={wizy_settings_filled}
                        alt="wizy_settings_filled"
                        className="Settings__setup__info__inner__questions__select__inner__image"
                        style={{
                          filter:
                            "invert(46%) sepia(19%) saturate(551%) hue-rotate(180deg) brightness(93%) contrast(94%)",
                        }}
                      />
                      {t("Account", { ns: ["settings"] })}
                    </button>
                  </div>
                  <div className="Settings__setup__two__columns">
                    <div
                      className="Settings__setup__info__inner__questions"
                      style={{ marginTop: "10px" }}
                    >
                      {activeSettings === ESettings.live ? (
                        <div>
                          <div className="Wizy__input__title">
                            {" "}
                            {t("AgentN", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="agentName"
                              value={setup.agentName}
                              type="text"
                              className="Wizy__input__2"
                              onChange={handleSetupChange}
                            />
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__title">
                            {t("Phrase", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="onlinePhrase"
                              value={setup.onlinePhrase}
                              type="text"
                              className="Wizy__input__2"
                              onChange={handleSetupChange}
                            />
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg3", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__title">
                            {t("CompanyL", { ns: ["home"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <select
                              className="Wizy__input__select__1"
                              name="mainLanguage"
                              onChange={handleSelectChange}
                              value={setup.mainLanguage}
                            >
                              <option value="English">
                                {t("English", { ns: ["home"] })}
                              </option>
                              <option value="Spanish">
                                {t("Spanish", { ns: ["home"] })}
                              </option>
                              <option value="Portuguese">
                                {t("Portuguese", { ns: ["home"] })}
                              </option>
                              <option value="French">
                                {t("French", { ns: ["home"] })}
                              </option>
                              <option value="German">
                                {t("German", { ns: ["home"] })}
                              </option>
                              <option value="Italian">
                                {t("Italian", { ns: ["home"] })}
                              </option>
                            </select>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg8", { ns: ["home"] })}
                          </div>
                          <div className="Wizy__input__2__columns">
                            <div>
                              <div className="Wizy__input__title">
                                {t("PrimaryC", { ns: ["settings"] })}
                              </div>
                              <div className="Wizy__input__2__outter">
                                <input
                                  name="primaryColor"
                                  value={setup.primaryColor}
                                  type="color"
                                  className="Wizy__input__color"
                                  onChange={handleSetupChange}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="Wizy__input__title">
                                {t("SecondaryC", { ns: ["settings"] })}
                              </div>
                              <div className="Wizy__input__2__outter">
                                <input
                                  name="secondaryColor"
                                  value={setup.secondaryColor}
                                  type="color"
                                  className="Wizy__input__color"
                                  onChange={handleSetupChange}
                                />
                              </div>
                              <div className="Wizy__input__prompt__1">
                                {t("GradientC", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div className="Wizy__input__title">
                            {t("FontC", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="fontColor"
                              value={setup.fontColor}
                              type="color"
                              className="Wizy__input__color"
                              onChange={handleSetupChange}
                            />
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("GradientC", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__title">
                            {t("ChatSide", { ns: ["settings"] })}
                          </div>
                          <div className="Settings__setup__info__inner__side__outter">
                            <div className="Settings__setup__info__inner__side___innner__left">
                              <div className="Settings__setup__info__inner__side___innner__left__inner">
                                <div className=" Settings__setup__info__inner__side___innner__left__title">
                                  {" "}
                                  {t("Left", { ns: ["settings"] })}
                                </div>
                                <div style={{ paddingRight: "5px" }}>
                                  <svg
                                    width="20"
                                    height="4"
                                    viewBox="0 0 20 4"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2ZM18 4C19.1046 4 20 3.10457 20 2C20 0.895431 19.1046 0 18 0C16.8954 0 16 0.895431 16 2C16 3.10457 16.8954 4 18 4Z"></path>
                                  </svg>
                                </div>
                              </div>
                              <div className="Settings__setup__info__inner__side___innner__left__circle">
                                <div
                                  className="Wizy__input__option__circle__outter"
                                  onClick={() => {
                                    handleSideChange(ESide.left);
                                  }}
                                >
                                  <div
                                    className={
                                      setup.side === "Left"
                                        ? "Wizy__input__option__circle__inner__selected"
                                        : "Wizy__input__option__circle__inner"
                                    }
                                  ></div>
                                  {setup.side === "Left" ? (
                                    <div className="Wizy__input__option__circle__filled"></div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <svg
                                width="122"
                                height="90"
                                viewBox="0 0 122 70"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M108 63.5H109.5V62V5C109.5 3.067 107.933 1.5 106 1.5H16C14.067 1.5 12.5 3.067 12.5 5V62V63.5H14H108Z"
                                  fill="white"
                                ></path>
                                <path
                                  d="M108 63.5H109.5V62V5C109.5 3.067 107.933 1.5 106 1.5H16C14.067 1.5 12.5 3.067 12.5 5V62V63.5H14H108Z"
                                  stroke="#0D1726"
                                  strokeWidth="3"
                                ></path>
                                <path
                                  d="M0 65H122V68C122 69.1046 121.105 70 120 70H2C0.895428 70 0 69.1046 0 68V65Z"
                                  fill="#ACB8CB"
                                ></path>
                                <path
                                  d="M54 65H72V65C72 66.1046 71.1046 67 70 67H56C54.8954 67 54 66.1046 54 65V65Z"
                                  fill="#8796AF"
                                ></path>
                                <rect
                                  width="120"
                                  height="28"
                                  transform="translate(0 21)"
                                  fill="white"
                                ></rect>
                              </svg>
                            </div>{" "}
                            <div className="Settings__setup__info__inner__side___innner__right">
                              <div className="Settings__setup__info__inner__side___innner__right__inner">
                                <div>
                                  <svg
                                    width="20"
                                    height="4"
                                    viewBox="0 0 20 4"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2ZM18 4C19.1046 4 20 3.10457 20 2C20 0.895431 19.1046 0 18 0C16.8954 0 16 0.895431 16 2C16 3.10457 16.8954 4 18 4Z"></path>
                                  </svg>
                                </div>
                                <div className="Settings__setup__info__inner__side___innner__right__title">
                                  {" "}
                                  {t("Right", { ns: ["settings"] })}
                                </div>
                              </div>
                              <div className="Settings__setup__info__inner__side___innner__right__circle">
                                <div
                                  className="Wizy__input__option__circle__outter"
                                  onClick={() => {
                                    handleSideChange(ESide.right);
                                  }}
                                >
                                  <div
                                    className={
                                      setup.side === "Right"
                                        ? "Wizy__input__option__circle__inner__selected"
                                        : "Wizy__input__option__circle__inner"
                                    }
                                  ></div>
                                  {setup.side === "Right" ? (
                                    <div className="Wizy__input__option__circle__filled"></div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg10", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__columns">
                            <div>
                              <div className="Wizy__input__title">
                                {t("PaddingB", { ns: ["settings"] })}
                              </div>
                              <div className="Wizy__input__2__outter">
                                <input
                                  name="paddingBottom"
                                  value={setup.paddingBottom}
                                  type="text"
                                  className="Wizy__input__2"
                                  onChange={handleSetupChange}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="Wizy__input__title">
                                {t("PaddingS", { ns: ["settings"] })}
                              </div>
                              <div className="Wizy__input__2__outter">
                                <input
                                  name="paddingSide"
                                  value={setup.paddingSide}
                                  type="text"
                                  className="Wizy__input__2"
                                  onChange={handleSetupChange}
                                />
                              </div>
                              <div className="Wizy__input__prompt__1">
                                {t("Eg11", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div className="Wizy__input__title">
                            {t("ChatV", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <select
                              className="Wizy__input__select__1"
                              name="isVisible"
                              onChange={handleSelectBoolean}
                              value={setup.isVisible ? "True" : "False"}
                            >
                              <option value="True">
                                {t("Visible", { ns: ["settings"] })}
                              </option>
                              <option value="False">
                                {t("NVisible", { ns: ["settings"] })}
                              </option>
                            </select>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg12", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__title">
                            {t("ChatI", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              onChange={handleImageFileChange}
                              ref={fileInputOneRef}
                              type="file"
                              accept="image/*"
                              name="consumptionPointImage"
                            />
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg13", { ns: ["settings"] })}
                          </div>
                          <div className="Settings__general__info__buttons__outter">
                            <button
                              className="Wizy__button__3"
                              style={{ width: "100%" }}
                              onClick={resetImage}
                            >
                              {t("ResetI", { ns: ["settings"] })}
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {activeSettings === ESettings.bot ? (
                        <div>
                          {" "}
                          <div className="Wizy__input__title">
                            {t("CompanyC", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__textarea__outter">
                            <textarea
                              name="companyContactInfo"
                              value={setup.companyContactInfo}
                              className="Wizy__textarea"
                              placeholder={t("CompanyCD", {
                                ns: ["settings"],
                              })}
                              onChange={(event) => {
                                handleSetupChangeText(event, 20);
                              }}
                            ></textarea>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Length", { ns: ["settings"] })}:{" "}
                            {setup.companyContactInfo === ""
                              ? 0
                              : setup.companyContactInfo.split(" ").length}{" "}
                            / 20
                          </div>
                          <div className="Wizy__input__title">
                            {t("ChatP", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__textarea__outter">
                            <textarea
                              name="chatbotPersonality"
                              value={setup.chatbotPersonality}
                              className="Wizy__textarea"
                              placeholder={t("ChatbotP", {
                                ns: ["settings"],
                              })}
                              style={{
                                height: "100px",
                              }}
                              onChange={(event) => {
                                handleSetupChangeText(event, 100);
                              }}
                            ></textarea>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Length", { ns: ["settings"] })}:{" "}
                            {setup.chatbotPersonality === ""
                              ? 0
                              : setup.chatbotPersonality.split(" ").length}{" "}
                            / 100
                          </div>
                          {/* Data retrieval method */}
                          <div className="Wizy__input__title">
                            {t("EmailR", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <select
                              className="Wizy__input__select__1"
                              name="emailRetrievalMethod"
                              onChange={handleSelectChange}
                              value={setup.emailRetrievalMethod}
                            >
                              <option value="Mandatory">
                                {t("EmailRM", { ns: ["settings"] })}
                              </option>
                              <option value="Semantic">
                                {t("EmailRS", { ns: ["settings"] })}
                              </option>
                              <option value="None">
                                {t("EmailRN", { ns: ["settings"] })}
                              </option>
                            </select>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg15", { ns: ["settings"] })}
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height:
                                setup.emailRetrievalMethod !== "None"
                                  ? "100px"
                                  : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("DataR", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <select
                                className="Wizy__input__select__1"
                                name="dataRetrievalType"
                                onChange={handleSelectChange}
                                value={setup.dataRetrievalType}
                              >
                                <option value="Email">
                                  {t("DataRE", { ns: ["settings"] })}
                                </option>
                                <option value="Phone">
                                  {t("DataRP", { ns: ["settings"] })}
                                </option>
                                <option value="Both">
                                  {t("DataRB", { ns: ["settings"] })}
                                </option>
                                {setup.emailRetrievalMethod !== "Mandatory" ? (
                                  <option value="Custom">
                                    {t("DataRC", { ns: ["settings"] })}
                                  </option>
                                ) : (
                                  ""
                                )}
                              </select>
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg20", { ns: ["settings"] })}
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height:
                                setup.dataRetrievalType === "Custom"
                                  ? "100px"
                                  : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("DataRetrievalCustomPrompt", {
                                ns: ["settings"],
                              })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <input
                                name="dataRetrievalCustomPrompt"
                                value={setup.dataRetrievalCustomPrompt}
                                type="text"
                                className="Wizy__input__2"
                                onChange={handleSetupChange}
                                placeholder={t(
                                  "DataRetrievalCustomPromptPlaceholder",
                                  { ns: ["settings"] }
                                )}
                              />
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg29", { ns: ["settings"] })}
                            </div>{" "}
                          </div>
                          {/* Request help action */}
                          <div className="Wizy__input__title">
                            {t("RequestHelp", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <select
                              className="Wizy__input__select__1"
                              name="requestHelpAction"
                              onChange={handleSelectChange}
                              value={setup.requestHelpAction}
                            >
                              <option value={ERequestHelpAction.CREATE_TICKET}>
                                {t("CreateTicket", { ns: ["settings"] })}
                              </option>
                              <option value={ERequestHelpAction.CUSTOM}>
                                {t("Custom", { ns: ["settings"] })}
                              </option>
                            </select>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg27", { ns: ["settings"] })}
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height:
                                setup.requestHelpAction ===
                                ERequestHelpAction.CUSTOM
                                  ? "100px"
                                  : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("CustomRequestHelpPrompt", {
                                ns: ["settings"],
                              })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <input
                                name="customRequestHelpPrompt"
                                value={setup.customRequestHelpPrompt}
                                type="text"
                                className="Wizy__input__2"
                                onChange={handleSetupChange}
                                placeholder={t(
                                  "CustomInstructionsPlaceholder",
                                  { ns: ["settings"] }
                                )}
                              />
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg28", { ns: ["settings"] })}
                            </div>{" "}
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height:
                                setup.requestHelpAction !==
                                ERequestHelpAction.CUSTOM
                                  ? "100px"
                                  : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("TicketDataR", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <select
                                className="Wizy__input__select__1"
                                name="ticketDataRetrievalType"
                                onChange={handleSelectChange}
                                value={setup.ticketDataRetrievalType}
                              >
                                <option value="Email">
                                  {t("TicketDataRE", { ns: ["settings"] })}
                                </option>
                                <option value="Phone">
                                  {t("TicketDataRP", { ns: ["settings"] })}
                                </option>
                                <option value="Both">
                                  {t("TicketDataRB", { ns: ["settings"] })}
                                </option>
                              </select>
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg36", { ns: ["settings"] })}
                            </div>
                          </div>
                          <div className="Settings__setup__info__knowledge__button">
                            <button
                              className="Wizy__button__6"
                              style={{ width: "100%" }}
                              onClick={() => {
                                startSync();
                              }}
                            >
                              {isSyncing ? (
                                <div className="loader">
                                  &nbsp;&nbsp;
                                  <span className="loader__dot">.</span>
                                  &nbsp;&nbsp;
                                  <span className="loader__dot">.</span>
                                  &nbsp;&nbsp;
                                  <span className="loader__dot">.</span>
                                </div>
                              ) : (
                                t("Sync", { ns: ["settings"] })
                              )}
                            </button>
                          </div>
                          <div className="Settings__setup__info__knowledge__indicators__outter">
                            <div className="Settings__setup__info__knowledge__indicator__outter">
                              <div className="Settings__setup__info__knowledge__indicator">
                                <div className="Settings__setup__info__knowledge__indicator__inner">
                                  <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                    {knowledgeCount.productCount}
                                    <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                      <img
                                        src={wizy_shop_bag_filled}
                                        alt="wizy_ai_filled"
                                        className="Settings__setup__info__knowledge__indicator__inner__image"
                                      />
                                    </div>
                                    <span
                                      style={{
                                        color: "gray",
                                        fontSize: "12px",
                                        fontWeight: "200",
                                        marginLeft: "5px",
                                      }}
                                    ></span>
                                  </div>
                                  <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                    {t("Products", { ns: ["settings"] })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Settings__setup__info__knowledge__indicator__outter">
                              <div className="Settings__setup__info__knowledge__indicator">
                                <div className="Settings__setup__info__knowledge__indicator__inner">
                                  <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                    {knowledgeCount.pageCount}
                                    <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                      <img
                                        src={wizy_globe_outline}
                                        alt="wizy_ai_filled"
                                        className="Settings__setup__info__knowledge__indicator__inner__image"
                                      />
                                    </div>
                                    <span
                                      style={{
                                        color: "gray",
                                        fontSize: "12px",
                                        fontWeight: "200",
                                        marginLeft: "5px",
                                      }}
                                    ></span>
                                  </div>
                                  <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                    {t("Pages", { ns: ["settings"] })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Settings__setup__info__knowledge__indicator__outter">
                              <div className="Settings__setup__info__knowledge__indicator">
                                <div className="Settings__setup__info__knowledge__indicator__inner">
                                  <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                    {knowledgeCount.policyCount}
                                    <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                      <img
                                        src={wizy_shield_filled}
                                        alt="wizy_ai_filled"
                                        className="Settings__setup__info__knowledge__indicator__inner__image"
                                      />
                                    </div>
                                    <span
                                      style={{
                                        color: "gray",
                                        fontSize: "12px",
                                        fontWeight: "200",
                                        marginLeft: "5px",
                                      }}
                                    ></span>
                                  </div>
                                  <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                    {t("Policies", { ns: ["settings"] })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Settings__setup__info__knowledge__indicator__outter">
                              <div className="Settings__setup__info__knowledge__indicator">
                                <div className="Settings__setup__info__knowledge__indicator__inner">
                                  <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                    {knowledgeCount.collectionCount}
                                    <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                      <img
                                        src={wizy_shop_bag_filled}
                                        alt="wizy_ai_filled"
                                        className="Settings__setup__info__knowledge__indicator__inner__image"
                                      />
                                    </div>
                                    <span
                                      style={{
                                        color: "gray",
                                        fontSize: "12px",
                                        fontWeight: "200",
                                        marginLeft: "5px",
                                      }}
                                    ></span>
                                  </div>
                                  <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                    {t("Collections", { ns: ["settings"] })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ADVANCED CHATBOT OPTIONS */}
                          <div className="Settings__chatbot__advanced__options__title__container">
                            <div className="Settings__chatbot__advanced__options__title__column">
                              <div className="Settings__chatbot__advanced__options__title">
                                {t("AdvancedOptions", { ns: ["settings"] })}
                              </div>
                            </div>
                            <div className="Settings__chatbot__advanced__options__title__column">
                              <div className="Settings__chatbot__advanced__options__title__line"></div>
                            </div>
                            <div
                              className="Settings__chatbot__advanced__options__title__column"
                              onClick={() =>
                                setIsAdvancedChatbotOptionsOpen((prev) => !prev)
                              }
                            >
                              <img
                                className="Settings__chatbot__advanced__options__chevron__image"
                                src={
                                  isAdvancedChatbotOptionsOpen
                                    ? wizy_chevron_up_outline
                                    : wizy_chevron_down_outline
                                }
                                alt=""
                              ></img>
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: isAdvancedChatbotOptionsOpen
                                ? "750px"
                                : "0px",
                            }}
                          >
                            {/* Max Messages per day */}
                            <div className="Wizy__input__title">
                              {t("MaxMessagesPerDay", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <input
                                name="maxMessagesPerDay"
                                value={setup.maxMessagesPerDay}
                                type="text"
                                className="Wizy__input__2"
                                onChange={handleSetupChange}
                              />
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg26", { ns: ["settings"] })}
                            </div>
                            {/* Sync Blogs */}
                            <div className="Wizy__input__title">
                              {t("syncBlogs", {
                                ns: ["settings"],
                              })}
                            </div>
                            <div
                              className="Wizy__input__option__outter"
                              onClick={() => {
                                handleSyncBlogsChange(false);
                              }}
                            >
                              <div
                                className={
                                  !setup.syncBlogs
                                    ? "Wizy__input__option__optioned"
                                    : "Wizy__input__option"
                                }
                              >
                                <div className="Wizy__input__option__circle__outter">
                                  <div
                                    className={
                                      !setup.syncBlogs
                                        ? "Wizy__input__option__circle__inner__selected"
                                        : "Wizy__input__option__circle__inner"
                                    }
                                  ></div>
                                  {!setup.syncBlogs ? (
                                    <div className="Wizy__input__option__circle__filled"></div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="Wizy__input__option__text">
                                  {t("No", { ns: ["settings"] })}
                                </div>
                              </div>
                            </div>
                            <div
                              className="Wizy__input__option__outter"
                              onClick={() => {
                                handleSyncBlogsChange(true);
                              }}
                            >
                              <div
                                className={
                                  setup.syncBlogs
                                    ? "Wizy__input__option__optioned"
                                    : "Wizy__input__option"
                                }
                              >
                                <div className="Wizy__input__option__circle__outter">
                                  <div
                                    className={
                                      setup.syncBlogs
                                        ? "Wizy__input__option__circle__inner__selected"
                                        : "Wizy__input__option__circle__inner"
                                    }
                                  ></div>
                                  {setup.syncBlogs ? (
                                    <div className="Wizy__input__option__circle__filled"></div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="Wizy__input__option__text">
                                  {t("Yes", { ns: ["settings"] })}
                                </div>
                              </div>
                            </div>
                            {/* Initial policy existance */}
                            <div className="Wizy__input__title">
                              {t("InitialP", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <select
                                className="Wizy__input__select__1"
                                name="hasInitialPolicy"
                                onChange={handleSelectBoolean}
                                value={
                                  setup.hasInitialPolicy ? "True" : "False"
                                }
                              >
                                <option value="True">
                                  {t("InitialPP", { ns: ["settings"] })}
                                </option>
                                <option value="False">
                                  {t("InitialPN", { ns: ["settings"] })}
                                </option>
                              </select>
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg34", { ns: ["settings"] })}
                            </div>
                            <div
                              className="Settings__setup__info__hideable__question"
                              style={{
                                height: setup.hasInitialPolicy
                                  ? "100px"
                                  : "0px",
                              }}
                            >
                              <div className="Wizy__input__title">
                                {t("InitialPM", { ns: ["settings"] })}
                              </div>
                              <div className="Wizy__input__2__outter">
                                <input
                                  name="initialPolicyMessage"
                                  value={setup.initialPolicyMessage}
                                  type="text"
                                  className="Wizy__input__2"
                                  onChange={handleSetupChange}
                                  placeholder={t("InitialPMPH", {
                                    ns: ["settings"],
                                  })}
                                />
                              </div>
                              <div className="Wizy__input__prompt__1">
                                {t("Eg35", { ns: ["settings"] })}
                              </div>
                            </div>
                            {/* Only include products with the following tag */}
                            <div className="Wizy__input__title">
                              {t("ProductTagsToIncludeInKnowledge", {
                                ns: ["settings"],
                              })}
                            </div>
                            <TagsInput
                              tagsState={[
                                productTagsToIncludeInKnowledge,
                                setProductTagsToIncludeInKnowledge,
                              ]}
                              placeholder={t(
                                "ProductTagsToIncludeInKnowledgePlaceholder",
                                {
                                  ns: ["settings"],
                                }
                              )}
                            ></TagsInput>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg37", { ns: ["settings"] })}
                            </div>
                            {/* Exclude products with the following tag */}
                            <div className="Wizy__input__title">
                              {t("ProductTagsToExcludeFromKnowledge", {
                                ns: ["settings"],
                              })}
                            </div>
                            <TagsInput
                              tagsState={[
                                productTagsToExcludeFromKnowledge,
                                setProductTagsToExcludeFromKnowledge,
                              ]}
                              placeholder={t(
                                "ProductTagsToExcludeFromKnowledgePlaceholder",
                                {
                                  ns: ["settings"],
                                }
                              )}
                            ></TagsInput>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg38", { ns: ["settings"] })}
                            </div>
                            {/* Additional Response Delay */}
                            <div className="Wizy__input__title">
                              {t("AdditionalResponseDelay", {
                                ns: ["settings"],
                              })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <input
                                name="additionalResponseDelay"
                                value={setup.additionalResponseDelay}
                                type="text"
                                className="Wizy__input__2"
                                onChange={handleSetupChange}
                              />
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg39", { ns: ["settings"] })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {activeSettings === ESettings.outbound ? (
                        <div>
                          <div className="Wizy__input__title">
                            {t("CustomM", { ns: ["settings"] })}
                          </div>
                          <div
                            className="Wizy__input__option__outter"
                            onClick={() => {
                              handleCustomChange(ECustom.custom);
                            }}
                          >
                            <div
                              className={
                                setup.isCustomMessages
                                  ? "Wizy__input__option__optioned"
                                  : "Wizy__input__option"
                              }
                            >
                              <div className="Wizy__input__option__circle__outter">
                                <div
                                  className={
                                    setup.isCustomMessages
                                      ? "Wizy__input__option__circle__inner__selected"
                                      : "Wizy__input__option__circle__inner"
                                  }
                                ></div>
                                {setup.isCustomMessages ? (
                                  <div className="Wizy__input__option__circle__filled"></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Wizy__input__option__text">
                                {t("CustomS", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div
                            className="Wizy__input__option__outter"
                            onClick={() => {
                              handleCustomChange(ECustom.simple);
                            }}
                          >
                            <div
                              className={
                                !setup.isCustomMessages
                                  ? "Wizy__input__option__optioned"
                                  : "Wizy__input__option"
                              }
                            >
                              <div className="Wizy__input__option__circle__outter">
                                <div
                                  className={
                                    !setup.isCustomMessages
                                      ? "Wizy__input__option__circle__inner__selected"
                                      : "Wizy__input__option__circle__inner"
                                  }
                                ></div>
                                {!setup.isCustomMessages ? (
                                  <div className="Wizy__input__option__circle__filled"></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Wizy__input__option__text">
                                {t("SimpleS", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div className="Wizy__input__title">
                            {t("Interact", { ns: ["settings"] })}
                          </div>
                          <div
                            className="Wizy__input__option__outter"
                            onClick={() => {
                              handleInteractChange(EInteract.code);
                            }}
                          >
                            <div
                              className={
                                setup.isCode
                                  ? "Wizy__input__option__optioned"
                                  : "Wizy__input__option"
                              }
                            >
                              <div className="Wizy__input__option__circle__outter">
                                <div
                                  className={
                                    setup.isCode
                                      ? "Wizy__input__option__circle__inner__selected"
                                      : "Wizy__input__option__circle__inner"
                                  }
                                ></div>
                                {setup.isCode ? (
                                  <div className="Wizy__input__option__circle__filled"></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Wizy__input__option__text">
                                {t("Discount", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div
                            className="Wizy__input__option__outter"
                            onClick={() => {
                              handleInteractChange(EInteract.greet);
                            }}
                          >
                            <div
                              className={
                                setup.isGreet
                                  ? "Wizy__input__option__optioned"
                                  : "Wizy__input__option"
                              }
                            >
                              <div className="Wizy__input__option__circle__outter">
                                <div
                                  className={
                                    setup.isGreet
                                      ? "Wizy__input__option__circle__inner__selected"
                                      : "Wizy__input__option__circle__inner"
                                  }
                                ></div>
                                {setup.isGreet ? (
                                  <div className="Wizy__input__option__circle__filled"></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Wizy__input__option__text">
                                {t("Greet", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{ height: setup.isCode ? "100px" : "0px" }}
                          >
                            <div className="Wizy__input__title">
                              {t("Percentage", { ns: ["settings"] })} (%)
                            </div>
                            <div className="Wizy__input__2__outter">
                              <input
                                name="discountPercentage"
                                value={setup.discountPercentage}
                                type="text"
                                className="Wizy__input__2"
                                onChange={handleSetupChange}
                              />
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg2", { ns: ["settings"] })}
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: !setup.isCustomMessages ? "145px" : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("Initial", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__textarea__outter">
                              <textarea
                                name="initialMessage"
                                value={setup.initialMessage}
                                className="Wizy__textarea"
                                onChange={(event) => {
                                  handleSetupChangeText(event, 40);
                                }}
                              ></textarea>
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Length", { ns: ["settings"] })}:{" "}
                              {setup.initialMessage === ""
                                ? 0
                                : setup.initialMessage.split(" ").length}{" "}
                              / 40
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: setup.isCustomMessages ? "145px" : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("DefaultMP", { ns: ["settings"] })}
                            </div>
                            <textarea
                              name="defaultMessage"
                              value={setup.defaultMessage}
                              className="Wizy__textarea"
                              onChange={(event) => {
                                handleSetupChangeText(event, 40);
                              }}
                            ></textarea>
                            <div className="Wizy__input__prompt__1">
                              {t("Length", { ns: ["settings"] })}:{" "}
                              {setup.defaultMessage === ""
                                ? 0
                                : setup.defaultMessage.split(" ").length}{" "}
                              / 40
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: setup.isCustomMessages ? "145px" : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("ProductMP", { ns: ["settings"] })}
                            </div>

                            <textarea
                              name="productMessage"
                              value={setup.productMessage}
                              className="Wizy__textarea"
                              onChange={(event) => {
                                handleSetupChangeText(event, 40);
                              }}
                            ></textarea>
                            <div className="Wizy__input__prompt__1">
                              {t("Length", { ns: ["settings"] })}:{" "}
                              {setup.productMessage === ""
                                ? 0
                                : setup.productMessage.split(" ").length}{" "}
                              / 40
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: setup.isCustomMessages ? "145px" : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("CollectionMP", { ns: ["settings"] })}
                            </div>

                            <textarea
                              name="collectionMessage"
                              value={setup.collectionMessage}
                              className="Wizy__textarea"
                              onChange={(event) => {
                                handleSetupChangeText(event, 40);
                              }}
                            ></textarea>
                            <div className="Wizy__input__prompt__1">
                              {t("Length", { ns: ["settings"] })}:{" "}
                              {setup.collectionMessage === ""
                                ? 0
                                : setup.collectionMessage.split(" ")
                                    .length}{" "}
                              / 40
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: setup.isCustomMessages ? "145px" : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("SearchMP", { ns: ["settings"] })}
                            </div>
                            <textarea
                              name="searchMessage"
                              value={setup.searchMessage}
                              className="Wizy__textarea"
                              onChange={(event) => {
                                handleSetupChangeText(event, 40);
                              }}
                            ></textarea>
                            <div className="Wizy__input__prompt__1">
                              {t("Length", { ns: ["settings"] })}:{" "}
                              {setup.searchMessage === ""
                                ? 0
                                : setup.searchMessage.split(" ").length}{" "}
                              / 40
                            </div>
                          </div>
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height: setup.isCustomMessages ? "145px" : "0px",
                            }}
                          >
                            <div className="Wizy__input__title">
                              {t("PageMP", { ns: ["settings"] })}
                            </div>
                            <textarea
                              name="pagePolicyMessage"
                              value={setup.pagePolicyMessage}
                              className="Wizy__textarea"
                              onChange={(event) => {
                                handleSetupChangeText(event, 40);
                              }}
                            ></textarea>
                            <div className="Wizy__input__prompt__1">
                              {t("Length", { ns: ["settings"] })}:{" "}
                              {setup.pagePolicyMessage === ""
                                ? 0
                                : setup.pagePolicyMessage.split(" ")
                                    .length}{" "}
                              / 40
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {activeSettings === ESettings.account ? (
                        <div>
                          <div className="Wizy__input__title">
                            {t("EmailN", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="emailForNotifications"
                              value={setup.emailForNotifications}
                              type="text"
                              className="Wizy__input__2"
                              onChange={handleSetupChange}
                            />
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg17", { ns: ["settings"] })}
                          </div>{" "}
                          <div className="Wizy__input__title">
                            {t("EmailNS", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <select
                              className="Wizy__input__select__1"
                              name="isSendNotifications"
                              onChange={handleSelectBoolean}
                              value={
                                setup.isSendNotifications ? "True" : "False"
                              }
                            >
                              <option value="True">
                                {t("EmailNST", { ns: ["settings"] })}
                              </option>
                              <option value="False">
                                {t("EmailNSF", { ns: ["settings"] })}
                              </option>
                            </select>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg18", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__title">
                            {t("TicketNS", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <select
                              className="Wizy__input__select__1"
                              name="ticketNotificationType"
                              onChange={handleSelectChange}
                              value={setup.ticketNotificationType}
                            >
                              <option value="Daily">
                                {t("TicketND", { ns: ["settings"] })}
                              </option>
                              <option value="Every">
                                {t("TicketNE", { ns: ["settings"] })}
                              </option>
                              <option value="None">
                                {t("TicketNN", { ns: ["settings"] })}
                              </option>
                            </select>
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg21", { ns: ["settings"] })}
                          </div>
                          {/* Activation schedule settings */}
                          <div className="Wizy__input__title">
                            {t("isActivationScheduleTitle", {
                              ns: ["settings"],
                            })}
                          </div>
                          <div
                            className="Wizy__input__option__outter"
                            onClick={() => {
                              handleHasActivationScheduleChange(false);
                            }}
                          >
                            <div
                              className={
                                !setup.hasActivationSchedule
                                  ? "Wizy__input__option__optioned"
                                  : "Wizy__input__option"
                              }
                            >
                              <div className="Wizy__input__option__circle__outter">
                                <div
                                  className={
                                    !setup.hasActivationSchedule
                                      ? "Wizy__input__option__circle__inner__selected"
                                      : "Wizy__input__option__circle__inner"
                                  }
                                ></div>
                                {!setup.hasActivationSchedule ? (
                                  <div className="Wizy__input__option__circle__filled"></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Wizy__input__option__text">
                                {t("ActiveAllTime", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          <div
                            className="Wizy__input__option__outter"
                            onClick={() => {
                              handleHasActivationScheduleChange(true);
                            }}
                          >
                            <div
                              className={
                                setup.hasActivationSchedule
                                  ? "Wizy__input__option__optioned"
                                  : "Wizy__input__option"
                              }
                            >
                              <div className="Wizy__input__option__circle__outter">
                                <div
                                  className={
                                    setup.hasActivationSchedule
                                      ? "Wizy__input__option__circle__inner__selected"
                                      : "Wizy__input__option__circle__inner"
                                  }
                                ></div>
                                {setup.hasActivationSchedule ? (
                                  <div className="Wizy__input__option__circle__filled"></div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Wizy__input__option__text">
                                {t("SomeHours", { ns: ["settings"] })}
                              </div>
                            </div>
                          </div>
                          {/* ACTIVATION SCHEDULE FORM */}
                          <div
                            className="Settings__setup__info__hideable__question"
                            style={{
                              height:
                                setup.hasActivationSchedule &&
                                setup.deactivationAction ===
                                  EDeactivationAction.REDIRECT
                                  ? "950px"
                                  : setup.hasActivationSchedule
                                  ? "850px"
                                  : "0px",
                            }}
                          >
                            {/* Timezone selection */}
                            <div className="Wizy__input__title">
                              {t("TimezoneTitle", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <select
                                className="Wizy__input__select__1"
                                name="activationScheduleTimezone"
                                onChange={handleSelectChange}
                                value={setup.activationScheduleTimezone}
                              >
                                {Array.from(Array(14 - -12 + 1).keys())
                                  .map((x) => x + -12)
                                  .map((hour) => (
                                    <option value={hour.toString()}>
                                      {`(GMT${hour >= 0 ? "+" + hour : hour})`}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg23", { ns: ["settings"] })}
                            </div>
                            {/* Action selection */}
                            <div className="Wizy__input__title">
                              {t("DeactivationAction", { ns: ["settings"] })}
                            </div>
                            <div className="Wizy__input__2__outter">
                              <select
                                className="Wizy__input__select__1"
                                name="deactivationAction"
                                onChange={handleSelectChange}
                                value={setup.deactivationAction}
                              >
                                <option value={EDeactivationAction.VISIBILITY}>
                                  {t("ActionVisibility", { ns: ["settings"] })}
                                </option>
                                <option value={EDeactivationAction.AI}>
                                  {t("ActionAI", { ns: ["settings"] })}
                                </option>
                                <option value={EDeactivationAction.REDIRECT}>
                                  {t("ActionRedirect", { ns: ["settings"] })}
                                </option>
                              </select>
                            </div>
                            <div className="Wizy__input__prompt__1">
                              {t("Eg25", { ns: ["settings"] })}
                            </div>
                            <div
                              className="Settings__setup__info__hideable__question"
                              style={{
                                height:
                                  setup.deactivationAction ===
                                  EDeactivationAction.REDIRECT
                                    ? "100px"
                                    : "0px",
                              }}
                            >
                              <div className="Wizy__input__title">
                                {t("RedirectionLink", { ns: ["settings"] })}
                              </div>
                              <div className="Wizy__input__2__outter">
                                <input
                                  name="redirectionLink"
                                  value={setup.redirectionLink}
                                  type="text"
                                  className="Wizy__input__2"
                                  onChange={handleSetupChange}
                                />
                              </div>
                              <div className="Wizy__input__prompt__1">
                                {t("Eg24", { ns: ["settings"] })}
                              </div>{" "}
                            </div>
                            {/* Hour pickers */}
                            {DAYS_OF_WEEK.map((day) => (
                              <>
                                <div className="Settings__activation__schedile__day__title">
                                  {t(day, { ns: ["settings"] })}
                                </div>
                                <div className="Settings__activation__schedule__day">
                                  {/* Activate togle */}
                                  <div>
                                    <label className="switch Settings__activtion__switch">
                                      <input
                                        type="checkbox"
                                        // If you worry about the exclamation marks and keyof check:
                                        // https://stackoverflow.com/questions/20093613/typescript-conversion-to-boolean
                                        // https://stackoverflow.com/questions/62438346/how-to-dynamically-access-object-property-in-typescript
                                        checked={
                                          !!activationSchedule[
                                            (day +
                                              "Active") as keyof typeof activationSchedule
                                          ]
                                        }
                                        onChange={() => {
                                          setActivationSchedule(
                                            (prevState) => ({
                                              ...prevState,
                                              [day + "Active"]:
                                                !!!activationSchedule[
                                                  (day +
                                                    "Active") as keyof typeof activationSchedule
                                                ],
                                            })
                                          );
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                  {/* Turn off label */}
                                  <div className="Settings__activation__turn__title">
                                    {t("TurnOff", { ns: ["settings"] })}
                                  </div>
                                  {/* Turn off picker */}
                                  <div>
                                    <select
                                      className="Wizy__input__select__1"
                                      name={day + "Off"}
                                      value={activationSchedule[
                                        (day +
                                          "Off") as keyof typeof activationSchedule
                                      ].toString()}
                                      disabled={
                                        !!!activationSchedule[
                                          (day +
                                            "Active") as keyof typeof activationSchedule
                                        ]
                                      }
                                      onChange={handleHourPickerChange}
                                    >
                                      {Array.from(Array(24).keys()).map(
                                        (hour) => (
                                          <option value={hour}>
                                            {hour}
                                            {":00"}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  {/* Turn on label */}
                                  <div className="Settings__activation__turn__title">
                                    {t("TurnOn", { ns: ["settings"] })}
                                  </div>
                                  {/* Turn on picker */}
                                  <div>
                                    <select
                                      className="Wizy__input__select__1"
                                      name={day + "On"}
                                      value={activationSchedule[
                                        (day +
                                          "On") as keyof typeof activationSchedule
                                      ].toString()}
                                      disabled={
                                        !!!activationSchedule[
                                          (day +
                                            "Active") as keyof typeof activationSchedule
                                        ]
                                      }
                                      onChange={handleHourPickerChange}
                                    >
                                      {Array.from(Array(24).keys()).map(
                                        (hour) => (
                                          <option value={hour}>
                                            {hour}
                                            {":00"}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          {/* BUDGET LIMIT */}
                          {globalShop.currentPlan.name !==
                            EStandardPlansName.FREE &&
                          globalShop.currentPlan.name !==
                            EStandardPlansName.FROZEN ? (
                            <>
                              {/* Budget limit toggle */}
                              <div className="Wizy__input__title">
                                {t("hasLimitBudgetTitle", {
                                  ns: ["settings"],
                                })}
                              </div>
                              <div
                                className="Wizy__input__option__outter"
                                onClick={() => {
                                  handleHasLimitBudgetChange(false);
                                }}
                              >
                                <div
                                  className={
                                    !setup.hasLimitBudget
                                      ? "Wizy__input__option__optioned"
                                      : "Wizy__input__option"
                                  }
                                >
                                  <div className="Wizy__input__option__circle__outter">
                                    <div
                                      className={
                                        !setup.hasLimitBudget
                                          ? "Wizy__input__option__circle__inner__selected"
                                          : "Wizy__input__option__circle__inner"
                                      }
                                    ></div>
                                    {!setup.hasLimitBudget ? (
                                      <div className="Wizy__input__option__circle__filled"></div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="Wizy__input__option__text">
                                    {t("No", { ns: ["settings"] })}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="Wizy__input__option__outter"
                                onClick={() => {
                                  handleHasLimitBudgetChange(true);
                                }}
                              >
                                <div
                                  className={
                                    setup.hasLimitBudget
                                      ? "Wizy__input__option__optioned"
                                      : "Wizy__input__option"
                                  }
                                >
                                  <div className="Wizy__input__option__circle__outter">
                                    <div
                                      className={
                                        setup.hasLimitBudget
                                          ? "Wizy__input__option__circle__inner__selected"
                                          : "Wizy__input__option__circle__inner"
                                      }
                                    ></div>
                                    {setup.hasLimitBudget ? (
                                      <div className="Wizy__input__option__circle__filled"></div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="Wizy__input__option__text">
                                    {t("Yes", { ns: ["settings"] })}
                                  </div>
                                </div>
                              </div>
                              {/* Budget limit number */}
                              <div
                                className="Settings__setup__info__hideable__question"
                                style={{
                                  height: setup.hasLimitBudget
                                    ? "200px"
                                    : "0px",
                                }}
                              >
                                <div className="Wizy__input__title">
                                  {t("limitBudgetTitle", { ns: ["settings"] })}
                                </div>
                                <div className="Wizy__input__2__outter">
                                  <input
                                    name="limitBudget"
                                    value={setup.limitBudget}
                                    type="text"
                                    className="Wizy__input__2"
                                    onChange={handleSetupChange}
                                  />
                                </div>
                                <div className="Wizy__input__prompt__1">
                                  {t("Eg30", { ns: ["settings"] })}
                                </div>
                                {/* Action selection */}
                                <div className="Wizy__input__title">
                                  {t("limitBudgetActionTitle", {
                                    ns: ["settings"],
                                  })}
                                </div>
                                <div className="Wizy__input__2__outter">
                                  <select
                                    className="Wizy__input__select__1"
                                    name="limitBudgetAction"
                                    onChange={handleSelectChange}
                                    value={setup.limitBudgetAction}
                                  >
                                    <option value={ELimitBudgetAction.AI_OFF}>
                                      {t("AiOff", { ns: ["settings"] })}
                                    </option>
                                    <option
                                      value={ELimitBudgetAction.WIDGET_OFF}
                                    >
                                      {t("WidgetOff", { ns: ["settings"] })}
                                    </option>
                                    <option
                                      value={ELimitBudgetAction.DEFAULT_MESSAGE}
                                    >
                                      {t("DefaultMessage", {
                                        ns: ["settings"],
                                      })}
                                    </option>
                                    <option value={ELimitBudgetAction.REDIRECT}>
                                      {t("Redirect", { ns: ["settings"] })}
                                    </option>
                                  </select>
                                </div>
                                <div className="Wizy__input__prompt__1">
                                  {t("Eg31", { ns: ["settings"] })}
                                </div>
                              </div>
                              {/* Budget limit default message */}
                              <div
                                className="Settings__setup__info__hideable__question"
                                style={{
                                  height:
                                    setup.limitBudgetAction ===
                                    ELimitBudgetAction.DEFAULT_MESSAGE
                                      ? "100px"
                                      : "0px",
                                }}
                              >
                                <div className="Wizy__input__title">
                                  {t("limitBudgetDefaultMessageTitle", {
                                    ns: ["settings"],
                                  })}
                                </div>
                                <div className="Wizy__input__2__outter">
                                  <input
                                    name="limitBudgetDefaultMessage"
                                    value={setup.limitBudgetDefaultMessage}
                                    type="text"
                                    className="Wizy__input__2"
                                    onChange={handleSetupChange}
                                  />
                                </div>
                                <div className="Wizy__input__prompt__1">
                                  {t("Eg32", { ns: ["settings"] })}
                                </div>
                              </div>
                              {/* Budget limit redirect link */}
                              <div
                                className="Settings__setup__info__hideable__question"
                                style={{
                                  height:
                                    setup.limitBudgetAction ===
                                    ELimitBudgetAction.REDIRECT
                                      ? "100px"
                                      : "0px",
                                }}
                              >
                                <div className="Wizy__input__title">
                                  {t("limitBudgetRedirectionLinkTitle", {
                                    ns: ["settings"],
                                  })}
                                </div>
                                <div className="Wizy__input__2__outter">
                                  <input
                                    name="limitBudgetRedirectionLink"
                                    value={setup.limitBudgetRedirectionLink}
                                    type="text"
                                    className="Wizy__input__2"
                                    onChange={handleSetupChange}
                                  />
                                </div>
                                <div className="Wizy__input__prompt__1">
                                  {t("Eg33", { ns: ["settings"] })}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* INTEGRATIONS */}
                          {setup.metaAvailable ? (
                            <>
                              <div
                                className="Wizy__input__title"
                                style={{
                                  display: "block",
                                  marginBottom: "20px",
                                }}
                              >
                                {t("Channels", {
                                  ns: ["settings"],
                                })}
                              </div>
                              {/* WhatsApp */}
                              <div
                                className="Wizy__input__title"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                {t("WhatsApp", {
                                  ns: ["settings"],
                                })}
                              </div>
                              {whatsAppAccounts.length > 0 ? (
                                <>
                                  {whatsAppAccounts.map((whatsAppAccount) => {
                                    return (
                                      <>
                                        <div className="Settings__meta__account__details__grid">
                                          <div className="Settings__meta__account__details__column">
                                            <div className="Settings__general__info__shop__subtitle">
                                              {whatsAppAccount.verifiedName}
                                            </div>
                                            <div className="Settings__general__info__item__outter__text">
                                              {whatsAppAccount.displayPhone}
                                            </div>
                                          </div>
                                          {whatsAppAccount.status ===
                                          ESourceStatus.ACTIVE ? (
                                            <>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  {whatsAppAccount.isAiActive ? (
                                                    <button
                                                      className="Wizy__button__3"
                                                      onClick={() =>
                                                        deactivateWhatsAppAccount(
                                                          whatsAppAccount.id
                                                        )
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {t("Deactivate", {
                                                        ns: ["settings"],
                                                      })}
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="Wizy__button__2"
                                                      onClick={() =>
                                                        activateWhatsAppAccount(
                                                          whatsAppAccount.id
                                                        )
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {t("Activate", {
                                                        ns: ["settings"],
                                                      })}
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__5"
                                                    onClick={() =>
                                                      setValidatorProps({
                                                        validatorPrompt: t(
                                                          "DeleteWhatsAppConfirmationD",
                                                          {
                                                            ns: ["settings"],
                                                          }
                                                        ),
                                                        validatorTitle: t(
                                                          "DeleteWhatsAppConfirmation",
                                                          {
                                                            ns: ["settings"],
                                                          }
                                                        ),
                                                        functionOne: () => {
                                                          deleteWhatsAppAccount(
                                                            whatsAppAccount.id
                                                          );
                                                        },
                                                        functionOnePrompt: "OK",
                                                        functionTwo: () => {},
                                                        functionTwoPrompt: "NO",
                                                        functionNumber: 2,
                                                      })
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    {t("DeleteIntegration", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : whatsAppAccount.status ===
                                            ESourceStatus.DELETED ? (
                                            <>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__2"
                                                    onClick={
                                                      launchWhatsAppSignUp
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    {t("Reestablish", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__3"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    {t("Deleted", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div>
                                    <div
                                      className="Settings__meta__account__details__column"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <button
                                        className="Facebook__button"
                                        style={{ width: "100%" }}
                                        onClick={launchWhatsAppSignUp}
                                      >
                                        <div className="Settings__facebook__button__inner__container">
                                          <div className="Settings__facebook__button__inner__column">
                                            <img
                                              className="Settings__facebook__button__inner__image"
                                              src={facebook_logo}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />{" "}
                                          </div>
                                          <div className="Settings__facebook__button__inner__column">
                                            <div className="Settings__facebook__button__inner__text">
                                              {t("AddNumber", {
                                                ns: ["settings"],
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div
                                    className="Settings__meta__account__details__column"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="Facebook__button"
                                      style={{ width: "100%" }}
                                      onClick={launchWhatsAppSignUp}
                                    >
                                      <div className="Settings__facebook__button__inner__container">
                                        <div className="Settings__facebook__button__inner__column">
                                          <img
                                            className="Settings__facebook__button__inner__image"
                                            src={facebook_logo}
                                            alt=""
                                            style={{ height: "20px" }}
                                          />{" "}
                                        </div>
                                        <div className="Settings__facebook__button__inner__column">
                                          <div className="Settings__facebook__button__inner__text">
                                            {t("ContinueWithFacebook", {
                                              ns: ["settings"],
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="Wizy__input__prompt__1">
                                    {t("Eg40", { ns: ["settings"] })}
                                  </div>
                                </div>
                              )}
                              {/* INSTAGRAM */}
                              <div
                                className="Wizy__input__title"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                {t("Instagram", {
                                  ns: ["settings"],
                                })}
                              </div>
                              {instagramAccounts.length > 0 ? (
                                <>
                                  {instagramAccounts.map((instagramAccount) => {
                                    return (
                                      <>
                                        <div className="Settings__meta__account__details__grid">
                                          <div className="Settings__meta__account__details__column">
                                            <div className="Settings__general__info__shop__subtitle">
                                              {t("Username", {
                                                ns: ["settings"],
                                              })}
                                            </div>
                                            <div className="Settings__general__info__item__outter__text">
                                              {"@" +
                                                instagramAccount.igUsername}
                                            </div>
                                          </div>
                                          {instagramAccount.status ===
                                          ESourceStatus.ACTIVE ? (
                                            <>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  {instagramAccount.isAiActive ? (
                                                    <button
                                                      className="Wizy__button__3"
                                                      onClick={() =>
                                                        deactivateInstagramAccount(
                                                          instagramAccount.id
                                                        )
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {t("Deactivate", {
                                                        ns: ["settings"],
                                                      })}
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="Wizy__button__2"
                                                      onClick={() =>
                                                        activateInstagramAccount(
                                                          instagramAccount.id
                                                        )
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {t("Activate", {
                                                        ns: ["settings"],
                                                      })}
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__5"
                                                    onClick={() =>
                                                      setValidatorProps({
                                                        validatorPrompt: t(
                                                          "DeleteInstagramConfirmationD",
                                                          {
                                                            ns: ["settings"],
                                                          }
                                                        ),
                                                        validatorTitle: t(
                                                          "DeleteInstagramConfirmation",
                                                          {
                                                            ns: ["settings"],
                                                          }
                                                        ),
                                                        functionOne: () => {
                                                          deleteInstagramAccount(
                                                            instagramAccount.id
                                                          );
                                                        },
                                                        functionOnePrompt: "OK",
                                                        functionTwo: () => {},
                                                        functionTwoPrompt: "NO",
                                                        functionNumber: 2,
                                                      })
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    {t("DeleteIntegration", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : instagramAccount.status ===
                                            ESourceStatus.DELETED ? (
                                            <>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__2"
                                                    onClick={
                                                      launchInstagramSignUp
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    {t("Reestablish", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__3"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    {t("Deleted", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}

                                  <div>
                                    <div
                                      className="Settings__meta__account__details__column"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <button
                                        className="Facebook__button"
                                        style={{ width: "100%" }}
                                        onClick={launchInstagramSignUp}
                                      >
                                        <div className="Settings__facebook__button__inner__container">
                                          <div className="Settings__facebook__button__inner__column">
                                            <img
                                              className="Settings__facebook__button__inner__image"
                                              src={facebook_logo}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />{" "}
                                          </div>
                                          <div className="Settings__facebook__button__inner__column">
                                            <div className="Settings__facebook__button__inner__text">
                                              {t("AddAccount", {
                                                ns: ["settings"],
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div
                                    className="Settings__meta__account__details__column"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="Facebook__button"
                                      style={{ width: "100%" }}
                                      onClick={launchInstagramSignUp}
                                    >
                                      <div className="Settings__facebook__button__inner__container">
                                        <div className="Settings__facebook__button__inner__column">
                                          <img
                                            className="Settings__facebook__button__inner__image"
                                            src={facebook_logo}
                                            alt=""
                                            style={{ height: "20px" }}
                                          />{" "}
                                        </div>
                                        <div className="Settings__facebook__button__inner__column">
                                          <div className="Settings__facebook__button__inner__text">
                                            {t("ContinueWithFacebook", {
                                              ns: ["settings"],
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="Wizy__input__prompt__1">
                                    {t("Eg41", { ns: ["settings"] })}
                                  </div>
                                </div>
                              )}
                              {/* MESSENGER */}
                              <div
                                className="Wizy__input__title"
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                {t("Messenger", {
                                  ns: ["settings"],
                                })}
                              </div>
                              {messengerAccounts.length > 0 ? (
                                <>
                                  {messengerAccounts.map((messengerAccount) => {
                                    return (
                                      <>
                                        <div className="Settings__meta__account__details__grid">
                                          <div className="Settings__meta__account__details__column">
                                            <div className="Settings__general__info__shop__subtitle">
                                              {t("PageName", {
                                                ns: ["settings"],
                                              })}
                                            </div>
                                            <div className="Settings__general__info__item__outter__text">
                                              {messengerAccount.pageName}
                                            </div>
                                          </div>
                                          {messengerAccount.status ===
                                          ESourceStatus.ACTIVE ? (
                                            <>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  {messengerAccount.isAiActive ? (
                                                    <button
                                                      className="Wizy__button__3"
                                                      onClick={() =>
                                                        deactivateMessengerAccount(
                                                          messengerAccount.id
                                                        )
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {t("Deactivate", {
                                                        ns: ["settings"],
                                                      })}
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="Wizy__button__2"
                                                      onClick={() =>
                                                        activateMessengerAccount(
                                                          messengerAccount.id
                                                        )
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {t("Activate", {
                                                        ns: ["settings"],
                                                      })}
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__5"
                                                    onClick={() =>
                                                      setValidatorProps({
                                                        validatorPrompt: t(
                                                          "DeleteMessengerConfirmationD",
                                                          {
                                                            ns: ["settings"],
                                                          }
                                                        ),
                                                        validatorTitle: t(
                                                          "DeleteMessengerConfirmation",
                                                          {
                                                            ns: ["settings"],
                                                          }
                                                        ),
                                                        functionOne: () => {
                                                          deleteMessengerAccount(
                                                            messengerAccount.id
                                                          );
                                                        },
                                                        functionOnePrompt: "OK",
                                                        functionTwo: () => {},
                                                        functionTwoPrompt: "NO",
                                                        functionNumber: 2,
                                                      })
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    {t("DeleteIntegration", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : messengerAccount.status ===
                                            ESourceStatus.DELETED ? (
                                            <>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__2"
                                                    onClick={
                                                      launchMessengerSignUp
                                                    }
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    {t("Reestablish", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="Settings__meta__account__details__column">
                                                <div className="Settings__meta__account__details__column__inner">
                                                  <button
                                                    className="Wizy__button__3"
                                                    style={{
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                    disabled={true}
                                                  >
                                                    {t("Deleted", {
                                                      ns: ["settings"],
                                                    })}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div>
                                    <div
                                      className="Settings__meta__account__details__column"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <button
                                        className="Facebook__button"
                                        style={{ width: "100%" }}
                                        onClick={launchMessengerSignUp}
                                      >
                                        <div className="Settings__facebook__button__inner__container">
                                          <div className="Settings__facebook__button__inner__column">
                                            <img
                                              className="Settings__facebook__button__inner__image"
                                              src={facebook_logo}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />{" "}
                                          </div>
                                          <div className="Settings__facebook__button__inner__column">
                                            <div className="Settings__facebook__button__inner__text">
                                              {t("AddPage", {
                                                ns: ["settings"],
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div
                                    className="Settings__meta__account__details__column"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <button
                                      className="Facebook__button"
                                      style={{ width: "100%" }}
                                      onClick={launchMessengerSignUp}
                                    >
                                      <div className="Settings__facebook__button__inner__container">
                                        <div className="Settings__facebook__button__inner__column">
                                          <img
                                            className="Settings__facebook__button__inner__image"
                                            src={facebook_logo}
                                            alt=""
                                            style={{ height: "20px" }}
                                          />{" "}
                                        </div>
                                        <div className="Settings__facebook__button__inner__column">
                                          <div className="Settings__facebook__button__inner__text">
                                            {t("ContinueWithFacebook", {
                                              ns: ["settings"],
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="Wizy__input__prompt__1">
                                    {t("Eg42", { ns: ["settings"] })}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <div
                            className="Wizy__input__title"
                            style={{ marginTop: "20px" }}
                          >
                            {t("AccountP", { ns: ["settings"] })}
                          </div>
                          <div className="Wizy__input__2__outter">
                            <input
                              name="password"
                              value={password}
                              type="password"
                              className="Wizy__input__2"
                              onChange={(
                                event: React.FormEvent<HTMLInputElement>
                              ) => {
                                setPassword(event.currentTarget.value);
                              }}
                            />
                          </div>
                          <div className="Wizy__input__prompt__1">
                            {t("Eg19", { ns: ["settings"] })}
                          </div>
                          <div
                            className="Settings__general__info__buttons__outter"
                            style={{ marginBottom: "20px" }}
                          >
                            <button
                              className="Wizy__button__3"
                              style={{ width: "100%" }}
                              onClick={() => {
                                if (password !== "Password123") {
                                  changePassword(password, password).then(
                                    (response) => {
                                      if (response.status) {
                                        setValidatorProps({
                                          validatorPrompt:
                                            t("Shop", { ns: ["settings"] }) +
                                            globalShop.domain +
                                            t("PasswordU", {
                                              ns: ["settings"],
                                            }),
                                          validatorTitle: t("Setup", {
                                            ns: ["settings"],
                                          }),
                                          functionOne: () => {
                                            window.location.reload();
                                          },
                                          functionOnePrompt: "OK",
                                          functionTwo: () => {},
                                          functionTwoPrompt: "",
                                          functionNumber: 1,
                                        });
                                      } else {
                                        setValidatorProps({
                                          validatorPrompt: t("PasswordE", {
                                            ns: ["settings"],
                                          }),
                                          validatorTitle: "Error",
                                          functionOne: () => {},
                                          functionOnePrompt: "OK",
                                          functionTwo: () => {},
                                          functionTwoPrompt: "",
                                          functionNumber: 1,
                                        });
                                      }
                                    }
                                  );
                                } else {
                                  setValidatorProps({
                                    validatorPrompt: t("PasswordE", {
                                      ns: ["settings"],
                                    }),
                                    validatorTitle: "Error",
                                    functionOne: () => {},
                                    functionOnePrompt: "OK",
                                    functionTwo: () => {},
                                    functionTwoPrompt: "",
                                    functionNumber: 1,
                                  });
                                }
                              }}
                            >
                              {t("ChangeP", { ns: ["settings"] })}
                            </button>
                          </div>
                          {globalShop.currentPlan.name !== "free" ? (
                            <div className="Settings__billing__plan__outter">
                              <div className="Settings__billing__plan__inner">
                                <div className="Setings__billing__plan__inner__info">
                                  <div className="Settings__billing__plan__title">
                                    {t(
                                      capitalizeFirstLetter(
                                        globalShop.currentPlan.name
                                      ),
                                      {
                                        ns: ["billing"],
                                      }
                                    )}
                                  </div>
                                  <div className="Settings__billing__plan__description">
                                    {globalShop.currentPlan.isCustom
                                      ? globalShop.currentPlan.showcaseText
                                      : t(
                                          capitalizeFirstLetter(
                                            globalShop.currentPlan.name
                                          ) + "D",
                                          {
                                            ns: ["billing"],
                                          }
                                        )}
                                  </div>
                                  <div className="Settings__billing__plan__attributes__outter">
                                    <div className="Settings__billing__plan__attribute">
                                      <div className="Settings__billing__plan__attribute__image__outter">
                                        <img
                                          src={wizy_shield_check_filled}
                                          alt="wizy_shop_bag_filled"
                                          className="Settings__billing__plan__attribute__image"
                                        />
                                      </div>
                                      <div className="Settings__billing__plan__attrinute__text">
                                        {t("UpTo", { ns: ["billing"] })}{" "}
                                        {
                                          globalShop.currentPlan
                                            .messagesPerMonth
                                        }{" "}
                                        {t("Inbound", { ns: ["billing"] })}
                                      </div>
                                    </div>
                                    <div className="Settings__billing__plan__attribute">
                                      <div className="Settings__billing__plan__attribute__image__outter">
                                        <img
                                          src={wizy_shield_check_filled}
                                          alt="wizy_shop_bag_filled"
                                          className="Settings__billing__plan__attribute__image"
                                        />
                                      </div>
                                      <div className="Settings__billing__plan__attrinute__text">
                                        {t("Unlimited", { ns: ["billing"] })}{" "}
                                        {t("Outbound", { ns: ["billing"] })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Settings__billing__plan__attributes__outter">
                                    <div className="Settings__billing__plan__attribute">
                                      <div className="Settings__billing__plan__attribute__image__outter">
                                        <img
                                          src={wizy_shield_check_filled}
                                          alt="wizy_shop_bag_filled"
                                          className="Settings__billing__plan__attribute__image"
                                        />
                                      </div>
                                      <div className="Settings__billing__plan__attrinute__text">
                                        {t("Unlimited", { ns: ["billing"] })}{" "}
                                        {t("Clients", { ns: ["billing"] })}
                                      </div>
                                    </div>
                                    <div className="Settings__billing__plan__attribute">
                                      <div className="Settings__billing__plan__attribute__image__outter">
                                        <img
                                          src={wizy_shield_check_filled}
                                          alt="wizy_shop_bag_filled"
                                          className="Settings__billing__plan__attribute__image"
                                        />
                                      </div>
                                      <div className="Settings__billing__plan__attrinute__text">
                                        {t("LiveM", { ns: ["billing"] })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Settings__billing__plan__attributes__outter">
                                    <div className="Settings__billing__plan__attribute">
                                      <div className="Settings__billing__plan__attribute__image__outter">
                                        <img
                                          src={wizy_shield_check_filled}
                                          alt="wizy_shop_bag_filled"
                                          className="Settings__billing__plan__attribute__image"
                                        />
                                      </div>
                                      <div className="Settings__billing__plan__attrinute__text">
                                        {t("Unlimited", { ns: ["billing"] })}{" "}
                                        {t("Tickets", { ns: ["billing"] })}
                                      </div>
                                    </div>
                                    <div className="Settings__billing__plan__attribute">
                                      <div className="Settings__billing__plan__attribute__image__outter">
                                        <img
                                          src={wizy_shield_check_filled}
                                          alt="wizy_shop_bag_filled"
                                          className="Settings__billing__plan__attribute__image"
                                        />
                                      </div>
                                      <div className="Settings__billing__plan__attrinute__text">
                                        {t("LiveC", { ns: ["billing"] })}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="Settings__billing__plan__inner__price">
                                  <div className="Settings__billing__plan__inner__price__inner">
                                    <div className="Settings__billing__plan__price__value">
                                      <div className="Settings__billing__plan__price__value__inner">
                                        {globalShop.currentPlan.monthlyPrice}
                                      </div>
                                      <div className="Billing__plan__currency__outter">
                                        <div className="Billing__plan__currency__inner">
                                          USD
                                        </div>
                                        <div className="Billing__plan__period__inner">
                                          {globalShop.currentPlan.isAnnual
                                            ? t("Yearly", { ns: ["billing"] })
                                            : t("Monthly", { ns: ["billing"] })}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Settings__billing__plan__price__description">
                                      {globalShop.currentPlan.name ===
                                        EStandardPlansName.FREE ||
                                      globalShop.currentPlan.name ===
                                        EStandardPlansName.FROZEN
                                        ? t("FreePD", { ns: ["billing"] })
                                        : t("AdditionalMessagePricing", {
                                            ns: ["billing"],
                                          })
                                            .replace(
                                              "{additionalMessagePrice}",
                                              globalShop.currentPlan.additionalMessagePrice.toString()
                                            )
                                            .replace(
                                              "{messagesPerMonth}",
                                              globalShop.currentPlan.messagesPerMonth.toString()
                                            )}
                                    </div>
                                    <div className="Settings__general__info__buttons__outter">
                                      <button
                                        className="Wizy__button__3"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                          if (
                                            globalShop.currentPlan.name ===
                                            EStandardPlansName.FREE
                                          ) {
                                            navigate("/billing");
                                          } else {
                                            setValidatorProps({
                                              validatorPrompt: t("Sure1", {
                                                ns: ["settings"],
                                              }),
                                              validatorTitle: t(
                                                "Deactivation",
                                                {
                                                  ns: ["settings"],
                                                }
                                              ),
                                              functionOne: () => {
                                                goBacktoFree();
                                              },
                                              functionOnePrompt: "OK",
                                              functionTwo: () => {},
                                              functionTwoPrompt: "NO",
                                              functionNumber: 2,
                                            });
                                          }
                                        }}
                                      >
                                        {t("ManageP", { ns: ["settings"] })}
                                      </button>
                                    </div>
                                    <div className="Settings__general__info__buttons__outter">
                                      <button
                                        className="Wizy__button__5"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                          setValidatorProps({
                                            validatorPrompt:
                                              t("Sure", { ns: ["settings"] }) +
                                              globalShop.domain +
                                              t("ShopQ", { ns: ["settings"] }),
                                            validatorTitle: t("Deactivation", {
                                              ns: ["settings"],
                                            }),
                                            functionOne: () => {
                                              deleteActualShop();
                                            },
                                            functionOnePrompt: "OK",
                                            functionTwo: () => {},
                                            functionTwoPrompt: "NO",
                                            functionNumber: 2,
                                          });
                                        }}
                                      >
                                        {t("Delete", { ns: ["settings"] })}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="Settings__setup__info__knowledge__indicators__outter">
                              <div className="Settings__setup__info__knowledge__indicator__outter">
                                <div className="Settings__setup__info__knowledge__indicator">
                                  <div className="Settings__setup__info__knowledge__indicator__inner">
                                    <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                      {knowledgeCount.productCount}
                                      <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                        <img
                                          src={wizy_shop_bag_filled}
                                          alt="wizy_ai_filled"
                                          className="Settings__setup__info__knowledge__indicator__inner__image"
                                        />
                                      </div>
                                      <span
                                        style={{
                                          color: "gray",
                                          fontSize: "12px",
                                          fontWeight: "200",
                                          marginLeft: "5px",
                                        }}
                                      ></span>
                                    </div>
                                    <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                      {t("Products", { ns: ["settings"] })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Settings__setup__info__knowledge__indicator__outter">
                                <div className="Settings__setup__info__knowledge__indicator">
                                  <div className="Settings__setup__info__knowledge__indicator__inner">
                                    <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                      {knowledgeCount.pageCount}
                                      <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                        <img
                                          src={wizy_globe_outline}
                                          alt="wizy_ai_filled"
                                          className="Settings__setup__info__knowledge__indicator__inner__image"
                                        />
                                      </div>
                                      <span
                                        style={{
                                          color: "gray",
                                          fontSize: "12px",
                                          fontWeight: "200",
                                          marginLeft: "5px",
                                        }}
                                      ></span>
                                    </div>
                                    <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                      {t("Pages", { ns: ["settings"] })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Settings__setup__info__knowledge__indicator__outter">
                                <div className="Settings__setup__info__knowledge__indicator">
                                  <div className="Settings__setup__info__knowledge__indicator__inner">
                                    <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                      {knowledgeCount.policyCount}
                                      <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                        <img
                                          src={wizy_shield_filled}
                                          alt="wizy_ai_filled"
                                          className="Settings__setup__info__knowledge__indicator__inner__image"
                                        />
                                      </div>
                                      <span
                                        style={{
                                          color: "gray",
                                          fontSize: "12px",
                                          fontWeight: "200",
                                          marginLeft: "5px",
                                        }}
                                      ></span>
                                    </div>
                                    <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                      {t("Policies", { ns: ["settings"] })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="Settings__setup__info__knowledge__indicator__outter">
                                <div className="Settings__setup__info__knowledge__indicator">
                                  <div className="Settings__setup__info__knowledge__indicator__inner">
                                    <div className="Settings__setup__info__knowledge__indicator__innner__count">
                                      {knowledgeCount.collectionCount}
                                      <div className="Settings__setup__info__knowledge__indicator__inner__image__outter">
                                        <img
                                          src={wizy_shop_bag_filled}
                                          alt="wizy_ai_filled"
                                          className="Settings__setup__info__knowledge__indicator__inner__image"
                                        />
                                      </div>
                                      <span
                                        style={{
                                          color: "gray",
                                          fontSize: "12px",
                                          fontWeight: "200",
                                          marginLeft: "5px",
                                        }}
                                      ></span>
                                    </div>
                                    <div className="Settings__setup__info__knowledge__indicator__innner__name">
                                      {t("Collections", { ns: ["settings"] })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="Settings__setup__info__inner__chat">
                      <ChatSetup
                        primaryColor={setup.primaryColor}
                        secondaryColor={setup.secondaryColor}
                        fontColor={setup.fontColor}
                        agentName={setup.agentName}
                        onlinePhrase={setup.onlinePhrase}
                        mainLanguage={setup.mainLanguage}
                        image={imageDisplay}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Setting__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default SettingsComponent;
