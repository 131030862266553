// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { AppContext, IContext } from "../context/Context";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

// Import media
import wizy_shop_bag_filled from "../images/wizy_shop_bag_filled.svg";
import wizy_loader from "../images/wizy_loader.gif";
import wizy_chat_profile from "../images/wizy_chat_profile.png";
import wizy_curvy_border from "../images/wizy_curvy_border.png";
import wizy_logo_blue from "../images/wizy_logo_blue.svg";

import "./styles/Test.css";
import { useTranslation } from "react-i18next";
import { EShopStatus, IShop } from "../types/ShopType";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { IPlan } from "../types/PlanType";
import ShopifyWidgetWrapper from "../components/ShopifyWidgetWrapper";

// Import media

// Page main functional component
const TestAdmin: FC = () => {
  // Use parameters
  const { id } = useParams<{ id: string | undefined }>();

  // Use history
  const navigate = useNavigate();

  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  // Use state variables
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const [globalShop, setGlobalShop] = useState<IShop>({
    id: "",
    domain: "",
    needsSetup: false,
    needsSync: false,
    needsTest: false,
    needsOnboarding: false,
    status: EShopStatus.uninstalled,
    currentPlan: emptyPlan,
    createDate: "",
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
  });
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });

  const cookies = new Cookies();

  // References needed
  const ValidatorRef = useRef<IValidator>(null);

  // Use translation
  const { t } = useTranslation(["test"]);

  //Internal functions
  // Use Effect Functions
  useEffect(() => {
    getGlobalShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validatorProps.validatorPrompt !== "") {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  const getGlobalShop = async () => {
    await fetch(globalSelectedBackend + "/admin/merchantshop/" + id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setGlobalShop({
          id: JSONresult.id,
          domain: JSONresult.domain,
          needsSetup: JSONresult.needsSetup,
          needsSync: JSONresult.needsSync,
          needsTest: JSONresult.needsTest,
          needsOnboarding: JSONresult.needsOnboarding,
          status: JSONresult.status,
          currentPlan: JSONresult.currentPlan,
          createDate: JSONresult.createDate,
          shopCurrency: JSONresult.shopCurrency,
          setup: JSONresult.setup,
          shopName: JSONresult.shopName,
          shopMainDomain: JSONresult.shopMainDomain,
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteClient = async () => {
    const clientCookie = cookies.get("WIZY_CLIENT_ADMIN_" + globalShop.domain);
    await fetch(globalSelectedBackend + "/clients/" + clientCookie, {
      method: "DELETE",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt: t("Success", { ns: ["clients"] }),
            validatorTitle: t("Validation", { ns: ["clients"] }),
            functionOne: () => {
              cookies.remove("WIZY_CLIENT_ADMIN_" + globalShop.domain, {
                path: "/",
              });
              window.location.reload();
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: t("Error", { ns: ["clients"] }),
            validatorTitle: "Error",
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error", { ns: ["clients"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  if (isLoaded) {
    // JSX Return statement
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        <div className="Test__outter">
          <div
            className="Test__back__outter"
            onClick={() => {
              navigate("/homeadmin");
            }}
          >
            {t("Back", { ns: ["test"] })}
          </div>
          <div
            className="Test__reset__outter"
            onClick={() => {
              setValidatorProps({
                validatorPrompt: t("DeletionD", {
                  ns: ["clients"],
                }),
                validatorTitle: t("Deletion", {
                  ns: ["clients"],
                }),
                functionOne: () => {
                  deleteClient();
                },
                functionOnePrompt: "OK",
                functionTwo: () => {},
                functionTwoPrompt: "NO",
                functionNumber: 2,
              });
            }}
          >
            {t("Reset", { ns: ["test"] })}
          </div>

          <div className="Test__banner__outter">
            <div className="Test__banner__inner">
              <div className="Test__shop__outter">
                <div className="Test__shop__image__outter">
                  <img
                    src={wizy_shop_bag_filled}
                    alt="wizy_shop_bag_filled"
                    className="Test__shop__image"
                  />
                </div>
                <div className="Test__shop__text">{globalShop.domain}</div>
              </div>
            </div>
          </div>

          <div className="Test__content__outter">
            <div className="Test__content__inner"></div>
            <div className="Test__content__inner"></div>
            <div className="Test__content__inner"></div>
          </div>
          <div className="Test__content__outter">
            <div className="Test__content__inner"></div>
            <div className="Test__content__inner"></div>
            <div className="Test__content__inner"></div>
          </div>
          <div className="Test__bottom__outter">
            <div className="Test__bottom__inner"></div>
          </div>
          <div>
            <ShopifyWidgetWrapper
              domain={globalShop.domain}
              isTest={true}
              isAdmin={true}
              isRelative={false}
              stylesPathInner="../ShopifyWidgetInner.css"
              stylesPathOutter="../ShopifyWidgetOutter.css"
              ipRegistryKey={
                process.env.REACT_APP_CLIENT_INFO_KEY || "tdk18ncwdb68ukmd"
              }
              globalSelectedBackend={globalSelectedBackend}
              chatProfileImage={wizy_chat_profile}
              curvyBorderImage={wizy_curvy_border}
              wizyLogoImage={wizy_logo_blue}
              shopifyRootPath={null}
              shopifyCurrentPath={null}
              isDashboard={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Setting__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

// Default exported function
export default TestAdmin;
