// Import React Dependencies
import { FC, useEffect, useState } from "react";
import React from "react";

// Import styles
import "./styles/ChatFunctionsManager.css";
import { IShop } from "../types/ShopType";
import { useTranslation } from "react-i18next";

import wizy_loader from "../images/wizy_loader.gif";
import { AppContext, IContext } from "../context/Context";

// Declare types and interfaces
export type IChatFunctionsManagerProps = {
  shop: IShop;
};

export type IChatFunction = {
  id: string;
  handler: string;
};

// Page main functional component
const ChatFunctionsManager: FC<IChatFunctionsManagerProps> = ({ shop }) => {
  const { t } = useTranslation(["chatFunctionsManager"]);
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [defaultChatFunctions, setDefaultChatFunctions] = useState<
    IChatFunction[]
  >([]);
  const [customChatFunctions, setCustomChatFunctions] = useState<
    IChatFunction[]
  >([]);
  const [selectedChatFunctions, setSelectedChatFunctions] = useState<
    IChatFunction[]
  >([]);

  // Use Effect Functions
  useEffect(() => {
    getDefaultChatFunctions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionToggle = (selectedChatFunction: IChatFunction) => {
    const wasSelected = selectedChatFunctions
      .map((chatFunction) => chatFunction.id)
      .includes(selectedChatFunction.id);
    if (wasSelected) {
      setSelectedChatFunctions((prevSelected) =>
        prevSelected.filter(
          (chatFunction) => chatFunction.id !== selectedChatFunction.id
        )
      );
    } else {
      setSelectedChatFunctions((prevSelected) => [
        ...prevSelected,
        selectedChatFunction,
      ]);
    }
  };

  const getDefaultChatFunctions = async () => {
    await fetch(globalSelectedBackend + "/chatfunctions/default", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult);
        setDefaultChatFunctions([]);
        JSONresult.forEach((chatFunction: IChatFunction) => {
          setDefaultChatFunctions((previous) => [
            ...previous,
            {
              id: chatFunction.id,
              handler: chatFunction.handler,
            },
          ]);
        });
        getCustomChatFunctions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCustomChatFunctions = async () => {
    await fetch(globalSelectedBackend + "/chatfunctions/custom", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setCustomChatFunctions([]);
        JSONresult.forEach((chatFunction: IChatFunction) => {
          setCustomChatFunctions((previous) => [
            ...previous,
            {
              id: chatFunction.id,
              handler: chatFunction.handler,
            },
          ]);
        });
        getShopChatFunctions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShopChatFunctions = async () => {
    await fetch(globalSelectedBackend + "/chatfunctions/shop/" + shop.id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setSelectedChatFunctions([]);
        JSONresult.forEach((chatFunction: IChatFunction) => {
          setSelectedChatFunctions((previous) => [
            ...previous,
            {
              id: chatFunction.id,
              handler: chatFunction.handler,
            },
          ]);
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateShopChatFunctions = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      chatFunctionIds: selectedChatFunctions.map(
        (chatFunction) => chatFunction.id
      ),
    });

    await fetch(globalSelectedBackend + "/chatfunctions/shop/" + shop.id, {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // JSX Return statement
  if (isLoaded) {
    return (
      <React.Fragment>
      <div
          style={{ backgroundColor: "#eff2f6" }}
        >
          <div
            className="ChildrenSelector__inner"
            style={{ backgroundColor: "#eff2f6" }}
          >
            <div
              className="ChildrenSelector__inner__inner"
              style={{ marginLeft: "0px" }}
            >
              <div className="ChildrenSelector__selector__outter__outter">
                <div className="ChildrenSelector__selector__outter__inner">
                  {/* Title */}
                  <div className="ChildrenSelector__title">
                    {t("ChatFunctions", { ns: ["chatFunctionsManager"] })}
                  </div>
                  {/* Description */}
                  <div className="ChildrenSelector__subtitle">
                    {t("SelectFunctions", { ns: ["chatFunctionsManager"] })}
                  </div>
                  <div className="ChatFunctionsManager__grid__outter">
                    {/* Default functions */}
                    <div className="ChatFunctionsManager__grid__col">
                      <div className="ChildrenSelector__billing__selector__inner__inner__title">
                        {t("DefaultFunctions", {
                          ns: ["chatFunctionsManager"],
                        })}
                      </div>
                      <div className="ChatFunctionsManager__dropdown">
                        <div className="ChatFunctionsManager__dropdown__options">
                          {defaultChatFunctions
                            .sort((a, b) => {
                              if (a.handler < b.handler) {
                                return -1;
                              }
                              if (a.handler > b.handler) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((chatFunction, index) => {
                              return (
                                <div
                                  className={
                                    selectedChatFunctions
                                      .map(
                                        (selectedChatFunction) =>
                                          selectedChatFunction.id
                                      )
                                      .includes(chatFunction.id)
                                      ? "ChatFunctionsManager__dropdown__option__selected"
                                      : "ChatFunctionsManager__dropdown__option"
                                  }
                                  key={chatFunction.id}
                                  onClick={() => {
                                    handleSelectionToggle(chatFunction);
                                  }}
                                >
                                  {chatFunction.handler}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {/* Custom functions */}
                    <div className="ChatFunctionsManager__grid__col">
                      <div className="ChildrenSelector__billing__selector__inner__inner__title">
                        {t("CustomFunctions", {
                          ns: ["chatFunctionsManager"],
                        })}
                      </div>
                      <div className="ChatFunctionsManager__dropdown">
                        <div className="ChatFunctionsManager__dropdown__options">
                          {customChatFunctions
                            .sort((a, b) => {
                              if (a.handler < b.handler) {
                                return -1;
                              }
                              if (a.handler > b.handler) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((chatFunction, index) => {
                              return (
                                <div
                                  className={
                                    selectedChatFunctions
                                      .map(
                                        (selectedChatFunction) =>
                                          selectedChatFunction.id
                                      )
                                      .includes(chatFunction.id)
                                      ? "ChatFunctionsManager__dropdown__option__selected"
                                      : "ChatFunctionsManager__dropdown__option"
                                  }
                                  key={chatFunction.id}
                                  onClick={() => {
                                    handleSelectionToggle(chatFunction);
                                  }}
                                >
                                  {chatFunction.handler}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {/* Selected functions */}
                    <div className="ChatFunctionsManager__grid__col">
                      <div className="ChildrenSelector__billing__selector__inner__inner__title">
                        {t("SelectedFunctions", {
                          ns: ["chatFunctionsManager"],
                        })}
                      </div>
                      <div className="ChatFunctionsManager__dropdown">
                        <div className="ChatFunctionsManager__dropdown__options">
                          {selectedChatFunctions
                            .sort((a, b) => {
                              if (a.handler < b.handler) {
                                return -1;
                              }
                              if (a.handler > b.handler) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((chatFunction, index) => {
                              return (
                                <div
                                  className="ChatFunctionsManager__dropdown__option"
                                  key={"selected_" + chatFunction.id}
                                  onClick={() => {}}
                                >
                                  {chatFunction.handler}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ChatFunctionsManager__button__outter">
                    <button
                      className="Wizy__button__2"
                      style={{ width: "150px", height: "40px" }}
                      onClick={() => {
                        updateShopChatFunctions();
                      }}
                    >
                      {t("Update", {
                        ns: ["chatFunctionsManager"],
                      })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div style={{ backgroundColor: "#eff2f6" }}>
        <div
          className="ChildrenSelector__inner"
          style={{ backgroundColor: "#eff2f6" }}
        >
          <div
            className="CustomOffer__inner__inner"
            style={{ marginLeft: "0px" }}
          >
            <div className="Home__dashboard__indicators__indicator__outter">
              <div className="Home__dashboard__indicators__indicator__inner">
                <div className="Home__dashboard__indicators__indicator__inner__inner">
                  <img
                    src={wizy_loader}
                    className="ProtectedRoute__loader"
                    alt="ProtectedRoute__loader"
                    id="ProtectedRoute__loader"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// Default exported function
export default ChatFunctionsManager;
