export type IPlan = {
  id: string;
  name: string;
  messagesPerMonth: number;
  monthlyPrice: number;
  additionalMessagePrice: number;
  showcaseText: string;
  isCustom: boolean;
  isAnnual: boolean;
};

export enum EStandardPlansName {
  FREE = "free",
  FROZEN = "frozen",
  PAYG = "payg",
  STARTER = "starter",
  BASIC = "basic",
  PLUS = "plus",
  ADVANCED = "advanced",
}
