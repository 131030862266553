// Import React Dependencies
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";

// Import media
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";
import wizy_mail_filled from "../images/wizy_mail_filled.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_shield_filled from "../images/wizy_shield_filled.svg";
import wizy_user_filled from "../images/wizy_user_filled.png";
import wizy_location_filled from "../images/wizy_location_filled.svg";
import wizy_computer_filled from "../images/wizy_computer_filled.svg";
import wizy_phone_filled from "../images/wizy_phone_filled.svg";
import wizy_globe_outline from "../images/wizy_globe_outline.svg";
import wizy_profile_filled from "../images/wizy_profile_filled.svg";
import wizy_logo_white from "../images/wizy_logo_white.svg";
import wizy_mg_outline from "../images/wizy_mg_outline.svg";
import wizy_whatsapp_icon from "../images/wizy_whatsapp_icon.svg";
import wizy_instagram_icon from "../images/wizy_instagram_icon.svg";
import wizy_messenger_icon from "../images/wizy_messenger_icon.svg";
import wizy_shopify_icon from "../images/wizy_shopify_icon.svg";

// Import styles
import "./styles/Chat.css";

// Declare types and interfaces
import { IClient } from "../types/ClientType";
import {
  EExtraUIComponentTypes,
  EMessageRole,
  EMessageSourceTypes,
  EMessageStatus,
  IMessage,
} from "../types/MessageType";
import { AppContext, IContext } from "../context/Context";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { useTranslation } from "react-i18next";
import Pagination, { IPageMeta } from "../components/Pagination";
import Add2CartLiveChat from "../components/Add2CartLiveChat";
import RecommendationCarouselLiveChat from "../components/RecommendationCarouselLiveChat";
import { EMainLanguage } from "../components/ShopifyWidget";
import SalesFormCard from "../components/SalesFormCard";
import {
  ESourceStatus,
  IInstagramAccount,
  IMessengerAccount,
  IWhatsAppAccount,
} from "../types/MetaTypes";
import WhatsAppTemplatesModal from "../components/WhatsAppTemplatesModal";
import ChatMedia from "../components/ChatMedia";

enum EChatOutter {
  normal,
  client,
  showcase,
}

type IFilter = {
  text: string;
  initialDate: string;
  finalDate: string;
  isSale: boolean;
};

export type IConversation = {
  messages: IMessage[];
  sourceType: EMessageSourceTypes;
  sourceId: string | null;
  selected: boolean;
  status: ESourceStatus;
};

export type ISource = {
  sourceId: string | null;
  sourceTitle: string;
  status: ESourceStatus;
};

// Page main functional component
const Chat: FC = () => {
  // Use history
  const navigate = useNavigate();

  // Use context
  const { globalShop, globalSelectedBackend } = React.useContext(
    AppContext
  ) as IContext;

  // Use translation
  const { t } = useTranslation(["chat"]);

  const [searchParams, setSearchParams] = useSearchParams();

  // Use state variables
  const [clients, setClients] = useState<IClient[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<IClient>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    computer: "",
    ipAddress: "",
    createDate: "",
    lastMessageDate: "",
    lastMessage: "",
    subscriptionState: false,
    isClientRead: false,
    isShopRead: false,
    isAiEnabled: false,
    websocketId: "",
    selected: false,
  });
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [sources, setSources] = useState<ISource[]>([]);
  const [socket, setSocket] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [init, setInit] = useState<string>("");
  const [newClient, setNewClient] = useState<IClient>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    computer: "",
    ipAddress: "",
    createDate: "",
    lastMessageDate: "",
    lastMessage: "",
    subscriptionState: false,
    isClientRead: false,
    isShopRead: false,
    isAiEnabled: false,
    websocketId: "",
    selected: false,
  });
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [clientSelected, setClientSelected] = useState<boolean>(false);
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [filter, setFilter] = useState<IFilter>({
    text: "",
    initialDate: "",
    finalDate: "",
    isSale: false,
  });
  const [filterChange, setFilterChange] = useState<IFilter>({
    text: "",
    initialDate: "",
    finalDate: "",
    isSale: false,
  });

  const [isWhatsAppTemplatesModalOpen, setIsWhatsAppTemplatesModalOpen] =
    useState<boolean>(false);

  // References needed
  const ChatOutterRef = useRef<HTMLDivElement>(null);
  const ValidatorRef = useRef<IValidator>(null);

  // Internal Functions
  // Use Effect Functions

  // Internal Functions
  useEffect(() => {
    setSocket(
      io(
        globalSelectedBackend +
          "/chat?domain=" +
          globalShop.domain +
          "&registered=false&&type=shop",
        {
          autoConnect: false,
          transports: ["websocket"],
          transportOptions: {
            websocket: {
              extraHeaders: {
                "Sec-WebSocket-Key": "dGhlIHNhbXBsZSBub25jZQ==",
                "Sec-WebSocket-Version": "13",
                "Sec-WebSocket-Protocol": "chat, superchat",
              },
            },
          },
        }
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getClients(pageMeta.take, pageMeta.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (isLoaded) {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  useEffect(() => {
    function onConnect() {}

    function onDisconnect() {}

    function onMessageEvent(value: any) {
      setNewClient({
        id: value.client.id,
        firstName: value.client.firstName,
        lastName: value.client.lastName,
        email: value.client.email,
        phone: value.client.phone,
        location: value.client.location,
        computer: value.client.computer,
        ipAddress: value.client.ipAddress,
        createDate: value.client.createDate,
        lastMessageDate: value.client.lastMessageDate,
        lastMessage: value.client.lastMessage,
        subscriptionState: value.client.subscriptionState,
        isClientRead: value.client.isClientRead,
        isShopRead: value.client.isShopRead,
        isAiEnabled: value.client.isAiEnabled,
        websocketId: value.client.websocketId,
        messages: value.client.messages,
        selected: false,
        sales: value.client.sales,
      });
    }

    function onInit(value: string) {
      setInit(value);
    }

    if (isLoaded) {
      socket.connect();

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("messagesShop", onMessageEvent);
      socket.on("init", onInit);

      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("messagesShop", onMessageEvent);
        socket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      updateShop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  useEffect(() => {
    if (isLoaded) {
      setClients((previous) => {
        const updatedItems = [...previous];
        updatedItems[
          clients.findIndex((client: IClient) => {
            return selectedClient.id === client.id;
          })
        ].isShopRead = true;
        return updatedItems;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    if (pageMeta.page === 1) {
      if (isLoaded) {
        const index = clients.findIndex((client: IClient) => {
          return client.id === newClient.id;
        });
        if (index !== -1) {
          if (selectedClient.id === newClient.id) {
            setClients((previous) => {
              const updatedItems = [...previous];
              updatedItems[index].messages = newClient.messages;
              updatedItems[index].lastMessage = newClient.lastMessage;
              updatedItems[index].lastMessageDate = newClient.lastMessageDate;
              updatedItems[index].isShopRead = true;
              return updatedItems;
            });
            if (newClient.messages !== undefined) {
              const newMessages = newClient.messages.map(
                (backendMessage): IMessage => {
                  return {
                    id: backendMessage.id,
                    role: backendMessage.role,
                    name: backendMessage.name,
                    content: backendMessage.content,
                    functionCall: backendMessage.functionCall,
                    toolCalls: backendMessage.toolCalls,
                    toolCallId: backendMessage.toolCallId,
                    createDate: backendMessage.createDate,
                    extraUIComponents: backendMessage.extraUIComponents
                      ? JSON.parse(backendMessage.extraUIComponents)
                      : null,
                    sourceType: backendMessage.sourceType,
                    sourceId: backendMessage.sourceId,
                    hasMedia: backendMessage.hasMedia,
                    status: backendMessage.status,
                  };
                }
              );
              const newConversations =
                getConversationsFromMessages(newMessages);
              setConversations(newConversations);
            }
            socket.emit("shopRead", {
              clientId: newClient.id,
              shopDomain: globalShop.domain,
            });
          } else {
            setClients((previous) => {
              const updatedItems = [...previous];
              updatedItems[index].messages = newClient.messages;
              updatedItems[index].lastMessage = newClient.lastMessage;
              updatedItems[index].lastMessageDate = newClient.lastMessageDate;
              updatedItems[index].isShopRead = false;
              return updatedItems;
            });
          }
        } else {
          setClients((previous) => [
            ...previous,
            {
              id: newClient.id,
              firstName: newClient.firstName,
              lastName: newClient.lastName,
              email: newClient.email,
              phone: newClient.phone,
              location: newClient.location,
              computer: newClient.computer,
              ipAddress: newClient.ipAddress,
              createDate: newClient.createDate,
              lastMessageDate: newClient.lastMessageDate,
              lastMessage: newClient.lastMessage,
              subscriptionState: newClient.subscriptionState,
              isClientRead: newClient.isClientRead,
              isShopRead: newClient.isShopRead,
              isAiEnabled: newClient.isAiEnabled,
              websocketId: newClient.websocketId,
              messages: newClient.messages,
              selected: false,
              sales: newClient.sales,
            },
          ]);
        }
      }
    } else {
      if (isLoaded) {
        const index = clients.findIndex((client: IClient) => {
          return client.id === newClient.id;
        });
        if (index !== -1) {
          if (selectedClient.id === newClient.id) {
            setClients((previous) => {
              const updatedItems = [...previous];
              updatedItems[index].messages = newClient.messages;
              updatedItems[index].lastMessage = newClient.lastMessage;
              updatedItems[index].lastMessageDate = newClient.lastMessageDate;
              updatedItems[index].isShopRead = true;
              return updatedItems;
            });
            if (newClient.messages !== undefined) {
              const newMessages = newClient.messages.map(
                (backendMessage): IMessage => {
                  return {
                    id: backendMessage.id,
                    role: backendMessage.role,
                    name: backendMessage.name,
                    content: backendMessage.content,
                    functionCall: backendMessage.functionCall,
                    toolCalls: backendMessage.toolCalls,
                    toolCallId: backendMessage.toolCallId,
                    createDate: backendMessage.createDate,
                    extraUIComponents: backendMessage.extraUIComponents
                      ? JSON.parse(backendMessage.extraUIComponents)
                      : null,
                    sourceType: backendMessage.sourceType,
                    sourceId: backendMessage.sourceId,
                    hasMedia: backendMessage.hasMedia,
                    status: backendMessage.status,
                  };
                }
              );
              const newConversations =
                getConversationsFromMessages(newMessages);
              setConversations(newConversations);
            }
            socket.emit("shopRead", {
              clientId: newClient.id,
              shopDomain: globalShop.domain,
            });
          } else {
            setClients((previous) => {
              const updatedItems = [...previous];
              updatedItems[index].messages = newClient.messages;
              updatedItems[index].lastMessage = newClient.lastMessage;
              updatedItems[index].lastMessageDate = newClient.lastMessageDate;
              updatedItems[index].isShopRead = false;
              return updatedItems;
            });
          }
        } else {
          if (selectedClient.id === newClient.id) {
            if (newClient.messages !== undefined) {
              const newMessages = newClient.messages.map(
                (backendMessage): IMessage => {
                  return {
                    id: backendMessage.id,
                    role: backendMessage.role,
                    name: backendMessage.name,
                    content: backendMessage.content,
                    functionCall: backendMessage.functionCall,
                    toolCalls: backendMessage.toolCalls,
                    toolCallId: backendMessage.toolCallId,
                    createDate: backendMessage.createDate,
                    extraUIComponents: backendMessage.extraUIComponents
                      ? JSON.parse(backendMessage.extraUIComponents)
                      : null,
                    sourceType: backendMessage.sourceType,
                    sourceId: backendMessage.sourceId,
                    hasMedia: backendMessage.hasMedia,
                    status: backendMessage.status,
                  };
                }
              );
              const newConversations =
                getConversationsFromMessages(newMessages);
              setConversations(newConversations);
            }
            socket.emit("shopRead", {
              clientId: newClient.id,
              shopDomain: globalShop.domain,
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClient]);

  useEffect(() => {
    getClients(pageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getClients = async (take: number, page: number) => {
    if (searchParams.get("ticketId")) {
      let searchURL =
        globalSelectedBackend +
        "/clients/clientofticket/" +
        searchParams.get("ticketId");
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setClients([
            {
              id: JSONresult.id,
              firstName: JSONresult.firstName,
              lastName: JSONresult.lastName,
              email: JSONresult.email,
              phone: JSONresult.phone,
              location: JSONresult.location,
              computer: JSONresult.computer,
              ipAddress: JSONresult.ipAddress,
              createDate: JSONresult.createDate,
              lastMessageDate: JSONresult.lastMessageDate,
              lastMessage: JSONresult.lastMessage,
              subscriptionState: JSONresult.subscriptionState,
              isClientRead: JSONresult.isClientRead,
              isShopRead: JSONresult.isShopRead,
              isAiEnabled: JSONresult.isAiEnabled,
              websocketId: JSONresult.websocketId,
              messages: JSONresult.messages,
              selected: false,
              sales: JSONresult.sales,
            },
          ]);
          handleClientSelect({
            id: JSONresult.id,
            firstName: JSONresult.firstName,
            lastName: JSONresult.lastName,
            email: JSONresult.email,
            phone: JSONresult.phone,
            location: JSONresult.location,
            computer: JSONresult.computer,
            ipAddress: JSONresult.ipAddress,
            createDate: JSONresult.createDate,
            lastMessageDate: JSONresult.lastMessageDate,
            lastMessage: JSONresult.lastMessage,
            subscriptionState: JSONresult.subscriptionState,
            isClientRead: JSONresult.isClientRead,
            isShopRead: JSONresult.isShopRead,
            isAiEnabled: JSONresult.isAiEnabled,
            websocketId: JSONresult.websocketId,
            messages: JSONresult.messages,
            selected: false,
            sales: JSONresult.sales,
          });
          setSearchParams();
          getSources();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (searchParams.get("clientId")) {
      let searchURL =
        globalSelectedBackend +
        "/clients/clientwithmessages/" +
        searchParams.get("clientId");
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setClients([
            {
              id: JSONresult.id,
              firstName: JSONresult.firstName,
              lastName: JSONresult.lastName,
              email: JSONresult.email,
              phone: JSONresult.phone,
              location: JSONresult.location,
              computer: JSONresult.computer,
              ipAddress: JSONresult.ipAddress,
              createDate: JSONresult.createDate,
              lastMessageDate: JSONresult.lastMessageDate,
              lastMessage: JSONresult.lastMessage,
              subscriptionState: JSONresult.subscriptionState,
              isClientRead: JSONresult.isClientRead,
              isShopRead: JSONresult.isShopRead,
              isAiEnabled: JSONresult.isAiEnabled,
              websocketId: JSONresult.websocketId,
              messages: JSONresult.messages,
              selected: false,
              sales: JSONresult.sales,
            },
          ]);
          handleClientSelect({
            id: JSONresult.id,
            firstName: JSONresult.firstName,
            lastName: JSONresult.lastName,
            email: JSONresult.email,
            phone: JSONresult.phone,
            location: JSONresult.location,
            computer: JSONresult.computer,
            ipAddress: JSONresult.ipAddress,
            createDate: JSONresult.createDate,
            lastMessageDate: JSONresult.lastMessageDate,
            lastMessage: JSONresult.lastMessage,
            subscriptionState: JSONresult.subscriptionState,
            isClientRead: JSONresult.isClientRead,
            isShopRead: JSONresult.isShopRead,
            isAiEnabled: JSONresult.isAiEnabled,
            websocketId: JSONresult.websocketId,
            messages: JSONresult.messages,
            selected: false,
            sales: JSONresult.sales,
          });
          setSearchParams();
          getSources();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let searchURL =
        globalSelectedBackend +
        "/clients/withmessages/" +
        globalShop.id +
        "/shop/?take=" +
        take.toString() +
        "&page=" +
        page.toString();
      // Add filter queries to URL
      if (filter?.initialDate && filter.finalDate) {
        searchURL +=
          "&initialDate=" +
          filter.initialDate +
          "&finalDate=" +
          filter.finalDate;
      }
      if (filter?.text) {
        searchURL += "&text=" + filter.text;
      }
      searchURL += "&isSale=" + filter.isSale;
      await fetch(searchURL, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      })
        .then(async (response) => {
          if (!response.ok) {
            let errorText = await response.text();
            let errorJSON = JSON.parse(errorText);
            throw new Error(errorJSON.message);
          } else {
            return response.text();
          }
        })
        .then((result) => JSON.parse(result))
        .then((JSONresult) => {
          setClients([]);
          JSONresult.data.forEach((client: IClient) => {
            setClients((previous) => [
              ...previous,
              {
                id: client.id,
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                phone: client.phone,
                location: client.location,
                computer: client.computer,
                ipAddress: client.ipAddress,
                createDate: client.createDate,
                lastMessageDate: client.lastMessageDate,
                lastMessage: client.lastMessage,
                subscriptionState: client.subscriptionState,
                isClientRead: client.isClientRead,
                isShopRead: client.isShopRead,
                isAiEnabled: client.isAiEnabled,
                websocketId: client.websocketId,
                messages: client.messages,
                selected: false,
                sales: client.sales,
              },
            ]);
          });
          setPageMeta({
            itemCount: JSONresult.meta.itemCount,
            pageCount: JSONresult.meta.pageCount,
            page: parseInt(JSONresult.meta.page),
            take: parseInt(JSONresult.meta.take),
            hasNextPage: JSONresult.meta.hasNextPage,
            hasPreviousPage: JSONresult.meta.hasPreviousPage,
          });
          getSources();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSources = async () => {
    await fetch(globalSelectedBackend + "/meta/accounts/" + globalShop.id, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.json();
        }
      })
      .then((JSONresult) => {
        const newSources: ISource[] = [
          {
            sourceId: null,
            sourceTitle: globalShop.shopMainDomain,
            status: ESourceStatus.ACTIVE,
          },
        ];
        (JSONresult.whatsAppAccounts as IWhatsAppAccount[]).forEach(
          (whatsAppAccount) => {
            newSources.push({
              sourceId: whatsAppAccount.id,
              sourceTitle: whatsAppAccount.verifiedName,
              status: whatsAppAccount.status,
            });
          }
        );
        (JSONresult.instagramAccounts as IInstagramAccount[]).forEach(
          (instagramAccount) => {
            newSources.push({
              sourceId: instagramAccount.id,
              sourceTitle: instagramAccount.igUsername,
              status: instagramAccount.status,
            });
          }
        );
        (JSONresult.messengerAccounts as IMessengerAccount[]).forEach(
          (messengerAccount) => {
            newSources.push({
              sourceId: messengerAccount.id,
              sourceTitle: messengerAccount.pageName,
              status: messengerAccount.status,
            });
          }
        );
        setSources(newSources);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChatGrid = (state: EChatOutter) => {
    switch (state) {
      case EChatOutter.normal:
        if (ChatOutterRef.current !== null) {
          ChatOutterRef.current.style.gridTemplateColumns = "0px 1fr 0px";
        }
        break;
      case EChatOutter.client:
        if (ChatOutterRef.current !== null) {
          ChatOutterRef.current.style.gridTemplateColumns = "320px 1fr 0px";
        }
        break;
      case EChatOutter.showcase:
        if (ChatOutterRef.current !== null) {
          ChatOutterRef.current.style.gridTemplateColumns = "0px 1fr 320px";
        }
        break;
      default:
        console.log("Upps");
    }
  };

  const handleClientSelect = (client: IClient) => {
    setSelectedClient({
      id: client.id,
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      phone: client.phone,
      location: client.location,
      computer: client.computer,
      ipAddress: client.ipAddress,
      createDate: client.createDate,
      lastMessageDate: client.lastMessageDate,
      lastMessage: client.lastMessage,
      subscriptionState: client.subscriptionState,
      isClientRead: client.isClientRead,
      isShopRead: client.isShopRead,
      isAiEnabled: client.isAiEnabled,
      websocketId: client.websocketId,
      messages: client.messages,
      selected: false,
      sales: client.sales,
    });
    if (window.innerWidth < 651) {
      handleChatGrid(EChatOutter.normal);
    }
    if (client.messages !== undefined) {
      const newMessages = client.messages.map((backendMessage): IMessage => {
        return {
          id: backendMessage.id,
          role: backendMessage.role,
          name: backendMessage.name,
          content: backendMessage.content,
          functionCall: backendMessage.functionCall,
          toolCalls: backendMessage.toolCalls,
          toolCallId: backendMessage.toolCallId,
          createDate: backendMessage.createDate,
          extraUIComponents: backendMessage.extraUIComponents
            ? JSON.parse(backendMessage.extraUIComponents)
            : null,
          sourceType: backendMessage.sourceType,
          sourceId: backendMessage.sourceId,
          hasMedia: backendMessage.hasMedia,
          status: backendMessage.status,
        };
      });
      const newConversations = getConversationsFromMessages(newMessages);
      setConversations(newConversations);
      if (socket) {
        socket.emit("shopRead", {
          clientId: client.id,
          shopDomain: globalShop.domain,
        });
      }
    }
    setClientSelected(true);
  };

  const getConversationsFromMessages = (
    messages: IMessage[]
  ): IConversation[] => {
    // Group messages by unique sourceIds, including when sourceId is null
    const groupedMessages: { [key: string]: IMessage[] } = messages.reduce(
      (acc, message) => {
        const key = message.sourceId === null ? "null" : message.sourceId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(message);
        return acc;
      },
      {} as { [key: string]: IMessage[] }
    );
    // Build conversations with messages inside each conversation sorted
    const conversations: IConversation[] = [];
    for (const sourceId in groupedMessages) {
      conversations.push({
        sourceId: sourceId === "null" ? null : sourceId,
        messages: groupedMessages[sourceId].sort(
          (a, b) => Date.parse(a.createDate) - Date.parse(b.createDate)
        ),
        sourceType: groupedMessages[sourceId][0].sourceType,
        selected: false,
        status:
          sourceId === "null"
            ? ESourceStatus.ACTIVE
            : sources.find((source) => source.sourceId === sourceId)!.status,
      });
    }
    // Sort conversations by which has the most recent last message
    conversations.sort(
      (a, b) =>
        Date.parse(b.messages[b.messages.length - 1].createDate) -
        Date.parse(a.messages[a.messages.length - 1].createDate)
    );
    // Set the conversation with the most recent message as selected
    if (conversations[0]) {
      conversations[0].selected = true;
    }

    return conversations;
  };

  const handleMessageSend = () => {
    if (!selectedClient.isAiEnabled && selectedClient.id !== "") {
      if (newMessage !== "") {
        // Get selected conversation
        const selectedConversation = conversations.find(
          (conversation) => conversation.selected
        );
        if (selectedConversation) {
          // Send message to backend
          socket.emit("messagesShop", {
            content: newMessage,
            clientId: selectedClient.id,
            shopDomain: globalShop.domain,
            sourceType: selectedConversation.sourceType,
            sourceId: selectedConversation.sourceId,
          });
          // Update last message in the client entity of the selected client
          setClients((previous) => {
            const updatedItems = [...previous];
            updatedItems[
              clients.findIndex((client: IClient) => {
                return selectedClient.id === client.id;
              })
            ].lastMessage = newMessage;
            return updatedItems;
          });
          // Add new message to the selected conversation
          const sentMessage: IMessage = {
            content: newMessage,
            createDate: new Date(
              Date.parse(
                selectedConversation.messages.slice(-1)[0].createDate
              ) + 500
            ).toISOString(),
            role: EMessageRole.assistant,
            name: null,
            functionCall: null,
            toolCalls: null,
            toolCallId: null,
            extraUIComponents: [],
            sourceType: selectedConversation.sourceType,
            sourceId: selectedConversation.sourceId,
            hasMedia: false,
            status: EMessageStatus.SENT,
          };
          setConversations((prevConversations) =>
            prevConversations.map((prevConversation) =>
              prevConversation.selected
                ? {
                    ...prevConversation,
                    messages: [...prevConversation.messages, sentMessage],
                  }
                : prevConversation
            )
          );
          // Erase typed message from input bar
          setNewMessage("");
        }
      }
    } else {
      setValidatorProps({
        validatorPrompt: t("Error", { ns: ["chat"] }),
        validatorTitle: t("ErrorT", { ns: ["chat"] }),
        functionOne: () => {},
        functionOnePrompt: "OK",
        functionTwo: () => {},
        functionTwoPrompt: "",
        functionNumber: 1,
      });
    }
  };

  const updateShop = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      websocketId: init,
    });
    await fetch(
      globalSelectedBackend + "/shops/" + globalShop.id + "/websocketid",
      {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toogleAiStatus = async () => {
    await fetch(
      globalSelectedBackend + "/clients/" + selectedClient.id + "/aistatus",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        setClients((previous) => {
          const updatedItems = [...previous];
          updatedItems[
            clients.findIndex((client: IClient) => {
              return selectedClient.id === client.id;
            })
          ].isAiEnabled = JSONresult.status;
          return updatedItems;
        });
        setSelectedClient((prevState) => ({
          ...prevState,
          isAiEnabled: JSONresult.status,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatTime = (milliseconds: number) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 1) {
      return days + "d";
    } else if (hours >= 1) {
      return hours + "h";
    } else if (minutes >= 1) {
      return minutes + "m";
    } else {
      return seconds + "s";
    }
  };

  const formatDate = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${hours}:${minutes} ${year}-${month}-${day}`;
  };

  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const convertToHTMLLink = (text: string): JSX.Element => {
    const urlPattern = /\[([^[\]]+)\]\(((https?|mailto):\/\/\S+|mailto:\S+)\)/g;
    const replacedLinkText = text.replace(
      urlPattern,
      (match, anchorText, url) =>
        `<a href="${url}" target="_top">${anchorText}</a>`
    );
    const boldPattern = /\*\*([^\*]+)\*\*/g;
    const replacedLinkBoldText = replacedLinkText.replace(
      boldPattern,
      (match, boldText) => `<strong>${boldText}</strong>`
    );
    const doubleLineBreakPattern = /\n\s*\n/g;
    const replacedLinkBoldLineText = replacedLinkBoldText.replace(
      doubleLineBreakPattern,
      "\n"
    );
    return (
      <div
        dangerouslySetInnerHTML={{ __html: replacedLinkBoldLineText }}
        style={{ whiteSpace: "pre-line" }}
      />
    );
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConversationSelection = (conversation: IConversation) => {
    setConversations((prevConversations) =>
      prevConversations.map((prevConversation) =>
        prevConversation.sourceId === conversation.sourceId
          ? { ...prevConversation, selected: true }
          : { ...prevConversation, selected: false }
      )
    );
  };

  const shouldSendWhatsAppTemplate = (
    conversations: IConversation[]
  ): boolean => {
    let response = false;
    const selectedConversation = conversations.find(
      (conversation) => conversation.selected
    );
    // If there is a selected conversation
    if (selectedConversation) {
      // If the selected conversation is through WhatsApp
      if (selectedConversation.sourceType === EMessageSourceTypes.WHATSAPP) {
        // If the WhatsApp account is active
        if (
          sources.find((x) => x.sourceId === selectedConversation.sourceId)
            ?.status === ESourceStatus.ACTIVE
        ) {
          const lastCustomerMessageDate = new Date(
            selectedConversation.messages
              .filter((message) => message.role === EMessageRole.user)
              .slice(-1)[0].createDate
          );
          const windowEnd = new Date(
            lastCustomerMessageDate.getTime() + 24 * 60 * 60 * 1000
          );
          const now = new Date();
          // If the last customer message was sent in the last 24 hours
          if (now > windowEnd) {
            response = true;
          }
        }
      }
    }
    return response;
  };

  const isNotBlank = (value: any): boolean => {
    return value !== null && value !== undefined && value !== "";
  };

  const CONVERSATION_ICON_IMAGES: { [key in EMessageSourceTypes]: string } = {
    [EMessageSourceTypes.SHOPIFY_WIDGET]: wizy_shopify_icon,
    [EMessageSourceTypes.WHATSAPP]: wizy_whatsapp_icon,
    [EMessageSourceTypes.INSTAGRAM]: wizy_instagram_icon,
    [EMessageSourceTypes.MESSENGER]: wizy_messenger_icon,
  };

  const CONVERSATION_ICON_COLOR_CLASS: {
    [key in EMessageSourceTypes]: string;
  } = {
    [EMessageSourceTypes.SHOPIFY_WIDGET]: "shopify-color",
    [EMessageSourceTypes.WHATSAPP]: "whatsapp-color",
    [EMessageSourceTypes.INSTAGRAM]: "instagram-color",
    [EMessageSourceTypes.MESSENGER]: "messenger-color",
  };

  // JSX Return statement
  return (
    <React.Fragment>
      <Validator
        ref={ValidatorRef}
        functionNumber={validatorProps.functionNumber}
        functionOne={validatorProps.functionOne}
        functionOnePrompt={validatorProps.functionOnePrompt}
        functionTwo={validatorProps.functionTwo}
        functionTwoPrompt={validatorProps.functionTwoPrompt}
        validatorTitle={validatorProps.validatorTitle}
        validatorPrompt={validatorProps.validatorPrompt}
      />
      {/* WhatsApp Templates Modal */}
      {isWhatsAppTemplatesModalOpen ? (
        <WhatsAppTemplatesModal
          setIsOpen={setIsWhatsAppTemplatesModalOpen}
          selectedClientState={[selectedClient, setSelectedClient]}
          clientsState={[clients, setClients]}
          conversationsState={[conversations, setConversations]}
          setValidatorProps={setValidatorProps}
        />
      ) : (
        ""
      )}
      <div ref={ChatOutterRef} className="Chat__outter">
        <div className="Chat__clients__outter">
          <div
            className="Chat__clients__outter__close"
            onClick={() => {
              handleChatGrid(EChatOutter.normal);
            }}
          >
            {" "}
            <img
              src={wizy_hide_side_menu_outline}
              alt="wizy_hide_side_menu_outline"
              className="Chat__clients__outter__close__image"
            />
          </div>
          <div className="Chat__clients__inner">
            {/* Filters */}
            <div className="Clients__filters__title">
              {t("Filters", { ns: ["chat"] })}
            </div>
            <div className="Clients__filters__description">
              {t("FiltersD", { ns: ["chat"] })}
            </div>
            <div className="Merchants__filters__fields__outter">
              {/* Text filter */}
              <div className="Wizy__input__title">
                {t("Email", { ns: ["chat"] })}
              </div>
              <div className="Wizy__input__1__outter">
                <input
                  name="text"
                  value={filterChange.text}
                  type="text"
                  className="Wizy__input__1"
                  onChange={handleFilterChange}
                />
                <img
                  src={wizy_mg_outline}
                  alt="wizy_mg_outline"
                  className="Wizy__input__1__image"
                />
              </div>
              <div className="Wizy__input__prompt__1">
                {t("Eg", { ns: ["chat"] })}
              </div>

              {/* Date filtering */}

              <div className="Wizy__input__title">
                {t("Date", { ns: ["chat"] })}
              </div>
              <input
                type="date"
                value={filterChange.initialDate}
                name="initialDate"
                className="Wizy__input__date"
                style={{ marginTop: "10px" }}
                onChange={handleFilterChange}
              />
              <div className="Wizy__input__prompt__1">
                {t("DateI", { ns: ["chat"] })}
              </div>
              <input
                type="date"
                value={filterChange.finalDate}
                name="finalDate"
                className="Wizy__input__date"
                onChange={handleFilterChange}
              />
              <div className="Wizy__input__prompt__1">
                {t("DateF", { ns: ["chat"] })}
              </div>
              {/* Filter by sale */}

              <div className="Wizy__input__title">
                {t("Sales", { ns: ["chat"] })}
              </div>
              <div className="Chat__filter__sale__selection__container">
                <div>
                  <div className="Chat__filter__sale__selection__column__inner ">
                    {t("All", { ns: ["chat"] })}
                  </div>
                </div>
                <div>
                  <div className="Chat__filter__sale__selection__column__inner">
                    <label className="switch4">
                      <input
                        type="checkbox"
                        checked={filterChange.isSale}
                        onChange={() => {
                          setFilterChange((prevState) => ({
                            ...prevState,
                            isSale: !prevState.isSale,
                          }));
                        }}
                      />
                      <span className="slider4 round"></span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="Chat__filter__sale__selection__column__inner">
                    {t("WithSale", { ns: ["chat"] })}
                  </div>
                </div>
              </div>
            </div>

            {/* Apply button */}
            <div className="Merchants__apply__button__outter">
              <button
                className="Wizy__button__2"
                style={{ width: "100%" }}
                onClick={() => {
                  setSearchParams();
                  setFilter(filterChange);
                }}
              >
                {t("Apply", { ns: ["chat"] })}
              </button>
            </div>

            {/* Reset button */}
            <div className="Merchants__apply__button__outter">
              <button
                className="Wizy__button__3"
                style={{ width: "100%" }}
                onClick={() => {
                  setSearchParams();
                  setFilter({
                    text: "",
                    initialDate: "",
                    finalDate: "",
                    isSale: false,
                  });
                  setFilterChange({
                    text: "",
                    initialDate: "",
                    finalDate: "",
                    isSale: false,
                  });
                }}
              >
                {t("Reset", { ns: ["chat"] })}
              </button>
            </div>
            <div className="Chat__clients__title">Chats</div>
            <div className="Clients__filters__description">
              {t("ChatsD", { ns: ["chat"] })}
            </div>

            {/* Pagination */}
            <div className="Chat__clients__pagination__outter">
              <Pagination
                pageMeta={pageMeta}
                getElements={getClients}
              ></Pagination>
            </div>

            <div className="Chat__clients__outter__outter">
              {clients
                .sort(
                  (a: IClient, b: IClient) =>
                    Date.parse(b.lastMessageDate) -
                    Date.parse(a.lastMessageDate)
                )
                .map((client, index) => {
                  return (
                    <div
                      key={index}
                      className="Chat__client__outter"
                      style={
                        selectedClient.id === client.id
                          ? { backgroundColor: "#eceef1" }
                          : {}
                      }
                      onClick={() => {
                        handleClientSelect(client);
                      }}
                    >
                      <div className="Chat__client__inner">
                        <div className="Chat__client__inner__bubble">
                          {!isNotBlank(client.email)
                            ? "N"
                            : client.email[0].toUpperCase()}
                          <div
                            className="Chat__client__inner__bubble__notification"
                            style={{ opacity: !client.isShopRead ? "1" : "0" }}
                          ></div>
                        </div>
                        <div className="Chat__client__inner__information">
                          <div className="Chat__client__inner__email">
                            {!isNotBlank(client.email)
                              ? t("NewClient", { ns: ["chat"] })
                              : client.email}
                          </div>
                          <div className="Chat__client__inner__message">
                            <div className="Chat__client__inner__message__bubble"></div>
                            <div className="Chat__client__inner__message__inner">
                              {client.lastMessage}
                            </div>
                          </div>
                        </div>
                        <div className="Chat__client__inner__time">
                          {formatTime(
                            Date.now() - Date.parse(client.lastMessageDate)
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="Chat__chat__outter">
          <div className="Chat__chat__inner">
            <div className="Chat__navbar__outter">
              <div
                className="Chat__navbar__clients__outter__open"
                onClick={() => {
                  handleChatGrid(EChatOutter.client);
                }}
              >
                {" "}
                <img
                  src={wizy_hide_side_menu_outline}
                  alt="wizy_hide_side_menu_outline"
                  className="Chat__navbar__clients__outter__open__image"
                />
              </div>
              <div className="Chat__navbar__inner">
                <div
                  className="Chat__client__outter"
                  onClick={() => {
                    handleChatGrid(EChatOutter.showcase);
                  }}
                >
                  <div
                    className="Chat__client__inner"
                    style={
                      selectedClient.sales &&
                      selectedClient.sales.filter(
                        (sale) =>
                          sale.isSale &&
                          sale.isApproved &&
                          sale.orderNumber !== null
                      ).length > 0
                        ? {
                            gridTemplateColumns:
                              "40px calc(100% - 140px) 80px 20px",
                          }
                        : {}
                    }
                  >
                    <div className="Chat__client__inner__bubble">
                      {(!isNotBlank(selectedClient.email)
                        ? clientSelected
                          ? t("NewClient", { ns: ["chat"] })
                          : t("Select", { ns: ["chat"] })
                        : selectedClient.email)[0].toUpperCase()}
                      <div
                        className="Chat__client__inner__bubble__notification"
                        style={{
                          opacity: "0",
                        }}
                      ></div>
                    </div>
                    <div className="Chat__client__inner__information">
                      <div className="Chat__client__inner__email">
                        {!isNotBlank(selectedClient.email)
                          ? clientSelected
                            ? t("NewClient", { ns: ["chat"] })
                            : t("Select", { ns: ["chat"] })
                          : selectedClient.email}
                      </div>
                      <div className="Chat__client__inner__message">
                        <div className="Chat__client__inner__message__bubble"></div>
                        <div className="Chat__client__inner__message__inner">
                          {t("ErrorT", { ns: ["chat"] })}
                        </div>
                      </div>
                    </div>
                    {selectedClient.sales &&
                    selectedClient.sales.filter(
                      (sale) =>
                        sale.isSale &&
                        sale.isApproved &&
                        sale.orderNumber !== null
                    ).length > 0 ? (
                      <div className="Chat__client__inner__sale__banner">
                        <button
                          className="Wizy__button__10"
                          style={{ width: "100%" }}
                        >
                          {t("Sale!", { ns: ["chat"] })}
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="Chat__client__ai__button__outter">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={selectedClient.isAiEnabled}
                      onChange={() => {
                        toogleAiStatus();
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            {conversations.length > 0 &&
            sources.filter((source) => source.status === ESourceStatus.ACTIVE)
              .length > 1 ? (
              <div className="Chat__conversation__tabs__outter">
                <div className="Chat__conversation__tabs__inner">
                  {conversations.map((conversation) => (
                    <div
                      className="Chat__conversation__tab__outter"
                      onClick={() => handleConversationSelection(conversation)}
                    >
                      <div
                        className={
                          conversation.selected
                            ? "Chat__conversation__tab__selected"
                            : "Chat__conversation__tab"
                        }
                      >
                        <div className="Chat__conversation__tab__icon__container">
                          <div className="Chat__conversation__tab__icon">
                            <img
                              src={
                                CONVERSATION_ICON_IMAGES[
                                  conversation.sourceType
                                ]
                              }
                              alt="wizy_conversation_icon"
                              className={
                                conversation.selected
                                  ? CONVERSATION_ICON_COLOR_CLASS[
                                      conversation.sourceType
                                    ]
                                  : "unselected-color"
                              }
                            />
                          </div>
                        </div>
                        <div className="Chat__conversation__tab__title__container">
                          <div className="Chat__conversation__tab__title">
                            {
                              sources.find(
                                (x) => x.sourceId === conversation.sourceId
                              )?.sourceTitle
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="Chat__conversation__tabs__outter"></div>
            )}

            <div className="Chat__messages__outter">
              <div className="Chat__messages__inner">
                <div>
                  {conversations
                    .find((conversation) => conversation.selected)
                    ?.messages.filter((message: IMessage) => {
                      if (
                        (message.role === EMessageRole.ai ||
                          message.role === EMessageRole.assistant ||
                          message.role === EMessageRole.user) &&
                        (isNotBlank(message.content) || message.hasMedia)
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                    .map((message, index) => {
                      return (
                        <div key={index} className="Chat__message__outter">
                          <div className="Chat__message__inner">
                            <div className="Chat__message__inner__bubble">
                              {message.role === EMessageRole.assistant ? (
                                <img
                                  src={wizy_profile_filled}
                                  alt="wizy_profile_filled"
                                  className="Chat__message__inner__bubble__image"
                                />
                              ) : message.role === EMessageRole.ai ? (
                                <img
                                  src={wizy_logo_white}
                                  alt="wizy_logo_white"
                                  className="Chat__message__inner__bubble__image__white"
                                />
                              ) : (
                                (!isNotBlank(selectedClient.email)
                                  ? clientSelected
                                    ? t("NewClient", { ns: ["chat"] })
                                    : t("Select", { ns: ["chat"] })
                                  : selectedClient.email)[0].toUpperCase()
                              )}
                            </div>
                            <div className="Chat__message__inner__information">
                              <div className="Chat__message__inner__email">
                                {message.role === EMessageRole.assistant
                                  ? t("You", { ns: ["chat"] })
                                  : message.role === EMessageRole.ai
                                  ? "Wizybot"
                                  : !isNotBlank(selectedClient.email)
                                  ? clientSelected
                                    ? t("NewClient", { ns: ["chat"] })
                                    : t("Select", { ns: ["chat"] })
                                  : selectedClient.email}{" "}
                                &nbsp;
                                <span
                                  style={{
                                    opacity: "0.5",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {formatDate(new Date(message.createDate))}
                                </span>
                                {message.role === EMessageRole.ai &&
                                index > 1 ? (
                                  <span className="Chat__improve__button__container">
                                    <button
                                      className="Wizy__button__11"
                                      onClick={() => {
                                        navigate("/training/" + message.id);
                                      }}
                                    >
                                      {t("CorrectMessage", { ns: ["chat"] })}
                                    </button>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="Chat__message__inner__message">
                                {message.status === EMessageStatus.DELETED ? (
                                  <div className="Chat__message__inner__message__failed__inner">
                                    {t("MessageDeleted", { ns: ["chat"] })}
                                  </div>
                                ) : (
                                  <>
                                    {message.content ? (
                                      <div className="Chat__message__inner__message__inner">
                                        {convertToHTMLLink(message.content)}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {message.extraUIComponents ? (
                                      message.extraUIComponents.length > 0 ? (
                                        message.extraUIComponents.map(
                                          (extraUIComponent, i) => {
                                            if (
                                              extraUIComponent.type ===
                                              EExtraUIComponentTypes.ADD_TO_CART
                                            ) {
                                              return (
                                                <Add2CartLiveChat
                                                  content={
                                                    extraUIComponent.content
                                                  }
                                                  language={
                                                    globalShop.setup
                                                      ? globalShop.setup
                                                          .mainLanguage
                                                      : EMainLanguage.ENGLISH
                                                  }
                                                />
                                              );
                                            } else if (
                                              extraUIComponent.type ===
                                              EExtraUIComponentTypes.RECOMMENDATION_CAROUSEL
                                            ) {
                                              return (
                                                <RecommendationCarouselLiveChat
                                                  content={
                                                    extraUIComponent.content
                                                  }
                                                  language={
                                                    globalShop.setup
                                                      ? globalShop.setup
                                                          .mainLanguage
                                                      : EMainLanguage.ENGLISH
                                                  }
                                                />
                                              );
                                            } else return <></>;
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {message.hasMedia && message.id ? (
                                      <ChatMedia
                                        messageId={message.id}
                                        language={
                                          globalShop.setup?.mainLanguage
                                            ? globalShop.setup?.mainLanguage
                                            : EMainLanguage.ENGLISH
                                        }
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    {message.status ===
                                    EMessageStatus.FAILED ? (
                                      <div className="Chat__message__inner__message__failed__inner">
                                        {t("MessageFailed", { ns: ["chat"] })}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {selectedClient.id !== "" ? (
              conversations.find((conversation) => conversation.selected)
                ?.status === ESourceStatus.ACTIVE ? (
                shouldSendWhatsAppTemplate(conversations) ? (
                  <div className="Chat__whatsapp__template__banner__outter">
                    <div className="Chat__whatsapp__template__banner__text__outter">
                      <div className="Chat__whatsapp__template__banner__text__inner">
                        {t("WhatsAppWindowExceeded", { ns: ["chat"] })}
                      </div>
                    </div>
                    <div className="Chat__whatsapp__template__banner__button__outter">
                      <div className="Chat__whatsapp__template__banner__button__inner">
                        <button
                          className="Wizy__button__10"
                          onClick={() => setIsWhatsAppTemplatesModalOpen(true)}
                        >
                          {t("Send Template", { ns: ["chat"] })}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="Chat__pannel__outter">
                    <div className="Chat__pannel__input__outter">
                      <div className="Chat__pannel__input__inner">
                        <div className="Wizy__input__4__outter">
                          <input
                            name="message"
                            value={newMessage}
                            type="text"
                            className="Wizy__input__4"
                            placeholder={t("Enter", { ns: ["chat"] })}
                            onChange={(
                              event: React.FormEvent<HTMLInputElement>
                            ) => {
                              setNewMessage(event.currentTarget.value);
                            }}
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (event.key === "Enter") {
                                handleMessageSend();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="Chat__pannel__send__button__outter"
                      onClick={() => {
                        handleMessageSend();
                      }}
                    >
                      <div className="Chat__pannel__send__button">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ChanSetup__send__button__image"
                        >
                          <path
                            d="M17.4472 9.10556C17.786 9.27495 18 9.62122 18 9.99999C18 10.3788 17.786 10.725 17.4472 10.8944L3.44721 17.8944C3.09251 18.0718 2.66653 18.0228 2.36136 17.7695C2.0562 17.5162 1.92953 17.1066 2.03848 16.7253L3.46704 11.7253C3.5897 11.296 3.98209 11 4.42857 11L9 11C9.55229 11 10 10.5523 10 10C10 9.44771 9.55229 9 9 9H4.42857C3.98209 9 3.58971 8.70402 3.46705 8.27472L2.03848 3.27471C1.92953 2.8934 2.0562 2.48374 2.36136 2.23048C2.66653 1.97722 3.09251 1.92821 3.44721 2.10556L17.4472 9.10556Z"
                            fill={"#FFFFfF"}
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="Chat__source__deleted__banner__outter">
                  <div className="Chat__source__deleted__banner__text__outter">
                    <div className="Chat__source__deleted__banner__text__inner">
                      {t("SourceDeleted", { ns: ["chat"] })}
                    </div>
                  </div>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="Chat__showcase__outter">
          <div
            className="Chat__showcase__outter__close"
            onClick={() => {
              handleChatGrid(EChatOutter.normal);
            }}
          >
            <img
              src={wizy_hide_side_menu_outline}
              alt="wizy_hide_side_menu_outline"
              className="Chat__showcase__outter__close__image"
            />
          </div>
          <div className="Chat__showcase__inner">
            <div className="Chat__showcase__information__outter">
              <div className="Chat__showcase__infotmation__item__outter">
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_user_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.firstName === ""
                      ? t("NoName", { ns: ["chat"] })
                      : selectedClient.firstName}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_user_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.lastName === ""
                      ? t("NoLastname", { ns: ["chat"] })
                      : selectedClient.lastMessage}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_mail_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {!isNotBlank(selectedClient.email)
                      ? clientSelected
                        ? t("NewClient", { ns: ["chat"] })
                        : t("Select", { ns: ["chat"] })
                      : selectedClient.email}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_phone_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.phone === ""
                      ? t("NoPhone", { ns: ["chat"] })
                      : selectedClient.phone}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_location_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.location === ""
                      ? t("NoLocation", { ns: ["chat"] })
                      : selectedClient.location}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_computer_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.computer === ""
                      ? t("NoComputer", { ns: ["chat"] })
                      : selectedClient.computer}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_globe_outline}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.ipAddress === ""
                      ? t("NoIp", { ns: ["chat"] })
                      : selectedClient.ipAddress}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_calendar_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.createDate === ""
                      ? t("NoCreation", { ns: ["chat"] })
                      : formatDate2(new Date(selectedClient.createDate))}
                  </div>
                </div>
                <div className="Chat__showcase__infotmation__item__inner">
                  <div>
                    <img
                      src={wizy_shield_filled}
                      alt="wizy_add_outline"
                      className="Chat__showcase__information__item__image"
                    />
                  </div>
                  <div className="Chat__showcase__information__item__text">
                    {selectedClient.subscriptionState
                      ? t("Subed", { ns: ["chat"] })
                      : t("Unsubed", { ns: ["chat"] })}
                  </div>
                </div>
              </div>
            </div>
            {new Date() > new Date("2024-07-01") &&
            selectedClient.sales &&
            selectedClient.sales.filter(
              (sale) =>
                sale.isSale && sale.isApproved && sale.orderNumber !== null
            ).length > 0 ? (
              <div className="Chat__showcase__information__outter">
                <SalesFormCard
                  selectedClientState={[selectedClient, setSelectedClient]}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default Chat;
