// Import React Dependencies
import React, { useImperativeHandle, forwardRef } from "react";

// Import styles
import "./styles/Validator.css";

// Declare types and interfaces
export type IValidatorProps = {
  validatorTitle: string;
  validatorPrompt: string;
  functionOne: () => void;
  functionOnePrompt: string;
  functionTwo: () => void;
  functionTwoPrompt: string;
  functionNumber: number;
};

export interface IValidator {
  setApearance: () => void;
}

// Page main functional component
const Validator = forwardRef<IValidator, IValidatorProps>(
  (
    {
      validatorPrompt,
      validatorTitle,
      functionOne,
      functionOnePrompt,
      functionTwo,
      functionTwoPrompt,
      functionNumber,
    },
    ref
  ) => {
    // Internal functions
    useImperativeHandle(ref, () => ({
      setApearance() {
        apear();
      },
    }));
    const disapear = () => {
      (
        document.getElementById("Validator__outter") as HTMLFormElement
      ).style.display = "none";
    };

    const apear = () => {
      (
        document.getElementById("Validator__outter") as HTMLFormElement
      ).style.display = "block";
    };
    // JSX Return statement
    return (
      <React.Fragment>
        <div className="Validator__outter" id="Validator__outter">
          <div className="Validator__box">
            <div className="Validator__title">{validatorTitle}</div>
            <div className="Validator__info__description">
              {validatorPrompt}
            </div>
            <div className="Validator__buttons__outter">
              <button
                className="Wizy__button__2"
                onClick={() => {
                  if (functionOne !== undefined) {
                    functionOne();
                  }
                  disapear();
                }}
              >
                {functionOnePrompt}
              </button>
              {functionNumber > 1 ? (
                <div className="Validator__buttond__separator"></div>
              ) : (
                ""
              )}
              {functionNumber > 1 ? (
                <button
                  className="Wizy__button__3"
                  onClick={() => {
                    if (functionTwo !== undefined) {
                      functionTwo();
                    }
                    disapear();
                  }}
                >
                  {functionTwoPrompt}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

// Default exported function
export default Validator;
