// Shop

import { IPlan } from "./PlanType";
import { ISetup } from "./SetupType";

export type IShop = {
  id: string;
  domain: string;
  shopName: string;
  shopMainDomain: string;
  needsSetup: boolean;
  needsSync: boolean;
  needsTest: boolean;
  needsOnboarding: boolean;
  status: EShopStatus;
  currentPlan: IPlan;
  customOfferPlan?: IPlan;
  createDate: string;
  shopCurrency: string;
  setup?: ISetup;
  selected?: boolean;
  isWidgetInstalled?: boolean;
  isChargeable?: boolean;
  storeMessageEmbeddings?: boolean;
  parentShopId?: string;
};

export enum EShopStatus {
  installed = "installled",
  uninstalled = "uninstalled",
}
