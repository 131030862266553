// User

import { IShop } from "./ShopType";

export type IUser = {
  id: string;
  email: string;
  type: EType | null;
  isFirstTime: boolean;
  shops?: IShop[] | null;
};

export enum EGender {
  none = "none",
  female = "female",
  male = "male",
}

export enum EType {
  ADMINISTRATOR = "administrator",
  SHOP = "shop",
  SUPER_ADMINISTRATOR = "super_administrator",
}
