// Import React Dependencies
import React, { FC } from "react";
import { AppContext, IContext } from "../context/Context";

// Import Components
import Navbar from "./Navbar";
import Bottombar from "./Bottombar";

// Import styles
import "./styles/Layout.css";

// Declare types and interfaces
interface LayoutProps {
  children: React.ReactNode;
  actualPage: string;
}

// Page main functional component
const Layout: FC<LayoutProps> = (props) => {
  // Use context
  const { globalShop } = React.useContext(AppContext) as IContext;
  // JSX Return statement
  return (
    <React.Fragment>
      <Navbar />
      <div
        className="Layout__web"
        style={{
          gridTemplateColumns:
            globalShop.needsSetup || globalShop.needsSync
              ? "0px calc(100%)"
              : "60px calc(100% - 60px)",
          paddingTop:
            globalShop.needsSetup || globalShop.needsSync ? "0px" : "60px",
        }}
      >
        <div>
          <Bottombar actualPage={props.actualPage} />
        </div>
        <div>{props.children}</div>
      </div>
    </React.Fragment>
  );
};

// Default exported function
export default Layout;
