import React from "react";

// Import media
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";

// Import styles
import "./styles/Pagination.css";

// Declare types and interfaces
export type IPageMeta = {
    itemCount: number;
    pageCount: number;
    page: number;
    take: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };

export type IPaginationProps = {
    pageMeta: IPageMeta;
    getElements: (take:number, page:number) => Promise<void>;
}

export const DOTS = -42;

export const range = (start:number, end:number) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
        start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
    };

export const getPaginationRange = (page:number, pageCount:number) => {
    const siblingCount = 1;
    const totalPageNumbers = siblingCount + 5;
    /*
    Case 1:
    If the number of pages is less than the page numbers we want to show in our
    paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= pageCount) {
        return range(1, pageCount);
    }else{
        /*
        Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(page - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            page + siblingCount,
            pageCount
        );
        /*
        We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.
        Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = pageCount;
        /*
        Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, pageCount];
        }
        /*
        Case 3: No right dots to show, but left dots to be shown
        */
        else if (shouldShowLeftDots && !shouldShowRightDots) {
        
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
            pageCount - rightItemCount + 1,
            pageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }
        /*
        Case 4: Both left and right dots to be shown
        */
        else {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }
    

};


// Page main functional component
const Pagination = (
    {
        pageMeta,
        getElements,
    }:IPaginationProps)=> {

        if (pageMeta.pageCount < 2){
            return null
        } else {
            const paginationRange = getPaginationRange(pageMeta.page, pageMeta.pageCount);
            return (
                <React.Fragment>
                    <ul className="pagination-container">
                        {/* Left navigation arrow */}
                        <li>
                            <button className="Wizy__button__8"
                            onClick={() => getElements(pageMeta.take, pageMeta.page - 1)}
                            disabled={!pageMeta.hasPreviousPage}>
                                <img className="previous-page-arrow"
                                    src={wizy_hide_side_menu_outline}
                                    alt="Previous Page"
                                />
                            </button>
                        </li> 
                        {/* Pagination buttons */}
                        {paginationRange.map(pageNumber => {
                            // If the pageItem is a DOT, render dots
                            if (pageNumber === DOTS) {
                                return <li>&#8230;</li>;
                            // else render pagination buttons
                            }else{
                                return (
                                    <li>
                                        <button className={pageMeta.page === pageNumber ? "Wizy__button__9":"Wizy__button__8"} 
                                        onClick={() => getElements(pageMeta.take, pageNumber)}>
                                            {pageNumber}
                                        </button>
                                    </li>
                                )
                            }
                            
                        })}
                        {/* Right navigation arrow */}
                        <li>
                            <button className="Wizy__button__8"
                            onClick={() => getElements(pageMeta.take, pageMeta.page + 1)}
                            disabled={!pageMeta.hasNextPage}>
                                <img className="next-page-arrow"
                                    src={wizy_hide_side_menu_outline}
                                    alt="Next Page"
                                />
                            </button>
                        </li>
                    </ul>
                </React.Fragment>
            );
        }
        
    }
  
  // Default exported function
  export default Pagination;