// Declare types and interfaces
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IClient } from "../types/ClientType";
import { AppContext, IContext } from "../context/Context";

import wizy_loader from "../images/wizy_loader.gif";

import "./styles/SalesFormCard.css";

export type ISalesFormCardProps = {
  selectedClientState: [IClient, React.Dispatch<React.SetStateAction<IClient>>];
  updateSelectedClientApprovedSales?: () => Promise<void>;
};

type IShopifyOrderData = {
  orderName: string;
  customer: string;
  lineItems: { name: string; quantity: number; price: string }[];
  createdAt: string;
  totalPrice: string;
};

const SalesFormCard: FC<ISalesFormCardProps> = ({
  selectedClientState: [selectedClient, setSelectedClient],
  updateSelectedClientApprovedSales,
}: ISalesFormCardProps) => {
  const { t } = useTranslation(["approveSales"]);
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [shopifyOrdersData, setShopifyOrdersData] = useState<
    IShopifyOrderData[]
  >([]);

  useEffect(() => {
    getShopifyOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const bool2String = (isApprovedBool: boolean | null): string => {
    const isApprovedString =
      isApprovedBool === null
        ? t("Pending", { ns: ["approveSales"] })
        : isApprovedBool === false
        ? t("Rejected", { ns: ["approveSales"] })
        : t("Approved", { ns: ["approveSales"] });
    return isApprovedString;
  };

  const string2Boolean = (isApprovedString: string): boolean | null => {
    const isApprovedBool =
      isApprovedString === t("Pending", { ns: ["approveSales"] })
        ? null
        : isApprovedString === t("Rejected", { ns: ["approveSales"] })
        ? false
        : true;
    return isApprovedBool;
  };

  const handleApprovedSalesChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    const updatedSales = selectedClient.sales
      ? selectedClient.sales.map((sale) => {
          if (name === sale.id) {
            const updatedSale = sale;
            updatedSale.isApproved = string2Boolean(value);
            return updatedSale;
          } else {
            return sale;
          }
        })
      : undefined;
    setSelectedClient((prevState) => ({ ...prevState, sales: updatedSales }));
  };

  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const getShopifyOrdersData = async () => {
    await fetch(
      globalSelectedBackend + "/sale/shopifyordersdata/" + selectedClient.id,
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult);
        setShopifyOrdersData(JSONresult.orders);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoaded) {
    return (
      <div className="ChatReview__showcase__information__item__outter">
        {selectedClient.sales ? (
          selectedClient.sales
            .filter(
              (sale) =>
                sale.isSale &&
                sale.orderNumber !== null &&
                (updateSelectedClientApprovedSales ? true : sale.isApproved)
            )
            .map((sale) => {
              const shopifyOrderData = shopifyOrdersData.find((orderData) =>
                orderData
                  ? orderData.orderName === sale.orderNumber.toString()
                  : false
              );
              if (shopifyOrderData === undefined) {
                return (
                  <div className="SalesFormCard__showcase__information__item__inner">
                    <div className="Wizy__input__title">
                      <b>
                        {t("Order", { ns: ["approveSales"] }) +
                          " " +
                          sale.orderNumber}
                      </b>
                    </div>
                    {updateSelectedClientApprovedSales ? (
                      <>
                        <div className="Wizy__input__title">
                          <b>{t("Status: ", { ns: ["approveSales"] })}</b>
                        </div>
                        <div className="ChatReview__toggle__outter">
                          <select
                            className="Wizy__input__select__1"
                            style={{
                              width: "80px",
                              height: "30px",
                              padding: "0px",
                            }}
                            name={sale.id}
                            onChange={handleApprovedSalesChange}
                            value={bool2String(sale.isApproved)}
                          >
                            <option value={bool2String(null)}>
                              {bool2String(null)}
                            </option>
                            <option value={bool2String(true)}>
                              {bool2String(true)}
                            </option>
                            <option value={bool2String(false)}>
                              {bool2String(false)}
                            </option>
                          </select>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              } else {
                return (
                  <div className="SalesFormCard__showcase__information__item__inner">
                    <div className="Wizy__input__title">
                      <b>
                        {t("Order", { ns: ["approveSales"] }) +
                          " " +
                          sale.orderNumber}
                      </b>
                    </div>
                    <div></div>
                    <div className="Wizy__input__title">
                      <b>{t("Total Price: ", { ns: ["approveSales"] })}</b>
                      {shopifyOrderData.totalPrice}
                    </div>
                    <div></div>
                    <div className="Wizy__input__title">
                      <b>{t("Date: ", { ns: ["approveSales"] })}</b>
                      {formatDate2(new Date(shopifyOrderData.createdAt))}
                    </div>
                    <div></div>
                    <div className="Wizy__input__title">
                      <b>{t("Customer: ", { ns: ["approveSales"] })}</b>
                      {shopifyOrderData.customer}
                    </div>
                    <div className="Wizy__input__title">
                      <b>{t("Products: ", { ns: ["approveSales"] })}</b>
                    </div>
                    {shopifyOrderData.lineItems.map((lineItem) => (
                      <>
                        <div className="Wizy__input__title">
                          {lineItem.name +
                            " (" +
                            t("Quantity: ", { ns: ["approveSales"] }) +
                            lineItem.quantity +
                            " - " +
                            t("Price: ", { ns: ["approveSales"] }) +
                            lineItem.price +
                            ")"}
                        </div>
                        <div></div>
                      </>
                    ))}
                    {updateSelectedClientApprovedSales ? (
                      <>
                        <div className="Wizy__input__title">
                          <b>{t("Status: ", { ns: ["approveSales"] })}</b>
                        </div>
                        <div className="ChatReview__toggle__outter">
                          <select
                            className="Wizy__input__select__1"
                            style={{
                              width: "100px",
                              height: "30px",
                              padding: "0px",
                            }}
                            name={sale.id}
                            onChange={handleApprovedSalesChange}
                            value={bool2String(sale.isApproved)}
                          >
                            <option value={bool2String(null)}>
                              {bool2String(null)}
                            </option>
                            <option value={bool2String(true)}>
                              {bool2String(true)}
                            </option>
                            <option value={bool2String(false)}>
                              {bool2String(false)}
                            </option>
                          </select>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
            })
        ) : (
          <></>
        )}
        {/* Submit quality form */}
        {updateSelectedClientApprovedSales ? (
          <div className="ChatReview__submit__button">
            <button
              className="Wizy__button__2"
              style={{ width: "100%" }}
              onClick={() => {
                updateSelectedClientApprovedSales();
              }}
            >
              {t("Submit", { ns: ["approveSales"] })}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

export default SalesFormCard;
