// Import React dependencies
import React, { FC, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext, IContext } from "../context/Context";
import { useTranslation } from "react-i18next";

// Import media
import wizy_loader from "../images/wizy_loader.gif";
import wizy_hide_side_menu_outline from "../images/wizy_hide_side_menu_outline.svg";
import wizy_clients_filled from "../images/wizy_clients_filled.svg";
import wizy_add_outline from "../images/wizy_add_outline.svg";
import wizy_arrow_right_tail_outline from "../images/wizy_arrow_right_tail_outline.svg";
import wizy_export_filled from "../images/wizy_export_filled.svg";
import wizy_globe_outline from "../images/wizy_globe_outline.svg";
import wizy_calendar_filled from "../images/wizy_calendar_filled.svg";
import wizy_tag_filled from "../images/wizy_tag_filled.svg";
import wizy_ai_filled from "../images/wizy_ai_filled.svg";
import wizy_dollar_filled from "../images/wizy_dollar_filled.svg";
import wizy_download_filled from "../images/wizy_download_filled.svg";
import wizy_chat_filled from "../images/wizy_chat_filled.svg";

// Import styles
import "./styles/Merchants.css";

// Declare types and interfaces
import { IShop, EShopStatus } from "../types/ShopType";
import Validator, {
  IValidator,
  IValidatorProps,
} from "../components/Validator";
import { EStandardPlansName, IPlan } from "../types/PlanType";
import Pagination, { IPageMeta } from "../components/Pagination";

enum EMerchantsOutter {
  normal,
  filter,
  showcase,
}

type IFilter = {
  text: string;
  isInstalled: boolean;
  isUninstalled: boolean;
  isFree: boolean;
  isPayg: boolean;
  isStarter: boolean;
  isBasic: boolean;
  isPlus: boolean;
  isAdvanced: boolean;
  isCustom: boolean;
  isFrozen: boolean;
  isWidgetInstalled: boolean;
  isWidgetUninstalled: boolean;
  isChargeable: boolean;
  isNotChargeable: boolean;
  activeWebhooks: boolean;
  inactiveWebhooks: boolean;
  initialDate: string;
  finalDate: string;
};

const Merchants: FC = () => {
  // Use history
  const navigate = useNavigate();

  // Use context
  const { globalSelectedBackend } = React.useContext(AppContext) as IContext;

  // Use translation
  const { t } = useTranslation(["merchants"]);

  // Use state variables
  const [merchants, setMerchants] = useState<IShop[]>([]);
  const emptyPlan: IPlan = {
    id: "",
    name: "",
    messagesPerMonth: 0,
    monthlyPrice: 0,
    additionalMessagePrice: 0,
    showcaseText: "",
    isCustom: false,
    isAnnual: false,
  };
  const [selectedMerchant, setSelectedMerchant] = useState<IShop>({
    id: "",
    domain: "",
    needsSetup: false,
    needsSync: false,
    needsTest: false,
    needsOnboarding: false,
    status: EShopStatus.uninstalled,
    currentPlan: emptyPlan,
    createDate: "",
    selected: false,
    isWidgetInstalled: false,
    shopCurrency: "USD",
    shopName: "",
    shopMainDomain: "",
    isChargeable: false,
  });
  const [filterChange, setFilterChange] = useState<IFilter>({
    text: "",
    isInstalled: true,
    isUninstalled: true,
    isFree: true,
    isPayg: true,
    isStarter: true,
    isBasic: true,
    isPlus: true,
    isAdvanced: true,
    isFrozen: true,
    isCustom: true,
    isWidgetInstalled: true,
    isWidgetUninstalled: true,
    isChargeable: true,
    isNotChargeable: true,
    activeWebhooks: true,
    inactiveWebhooks: true,
    initialDate: "",
    finalDate: "",
  });
  const [filter, setFilter] = useState<IFilter>({
    text: "",
    isInstalled: true,
    isUninstalled: true,
    isFree: true,
    isPayg: true,
    isStarter: true,
    isBasic: true,
    isPlus: true,
    isAdvanced: true,
    isFrozen: true,
    isCustom: true,
    isWidgetInstalled: true,
    isWidgetUninstalled: true,
    isChargeable: true,
    isNotChargeable: true,
    activeWebhooks: true,
    inactiveWebhooks: true,
    initialDate: "",
    finalDate: "",
  });
  const [allMerchantsState, setAllMerchantsState] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [validatorProps, setValidatorProps] = useState<IValidatorProps>({
    validatorPrompt: "",
    validatorTitle: "",
    functionOne: () => {},
    functionOnePrompt: "",
    functionTwo: () => {},
    functionTwoPrompt: "",
    functionNumber: 2,
  });
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    itemCount: 0,
    pageCount: 0,
    page: 1,
    take: 50,
    hasNextPage: false,
    hasPreviousPage: false,
  });

  // References needed
  const ValidatorRef = useRef<IValidator>(null);
  const MerchantsOutterRef = useRef<HTMLDivElement>(null);

  // Use effect functions
  useEffect(() => {
    getMerchants(pageMeta.take, pageMeta.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMerchants(pageMeta.take, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (isLoaded) {
      ValidatorRef.current?.setApearance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorProps]);

  // Internal functions
  const getMerchants = async (take: number, page: number) => {
    // URL to fetch tickets with shop id and pagination queries
    let searchURL =
      globalSelectedBackend +
      "/admin/merchants/?take=" +
      take.toString() +
      "&page=" +
      page.toString();
    // Add date filters
    if (filter?.initialDate && filter.finalDate) {
      searchURL +=
        "&initialDate=" + filter.initialDate + "&finalDate=" + filter.finalDate;
    }
    // Add text filters
    if (filter?.text) {
      searchURL += "&text=" + filter.text;
    }
    // Add installation and widget installation filters
    searchURL +=
      "&isInstalled=" +
      filter.isInstalled +
      "&isUninstalled=" +
      filter.isUninstalled +
      "&isWidgetInstalled=" +
      filter.isWidgetInstalled +
      "&isWidgetUninstalled=" +
      filter.isWidgetUninstalled;
    // Add plan filters
    searchURL +=
      "&isFree=" +
      filter.isFree +
      "&isPayg=" +
      filter.isPayg +
      "&isStarter=" +
      filter.isStarter +
      "&isBasic=" +
      filter.isBasic +
      "&isPlus=" +
      filter.isPlus +
      "&isAdvanced=" +
      filter.isAdvanced +
      "&isCustom=" +
      filter.isCustom +
      "&isFrozen=" +
      filter.isFrozen;
    // Add payment filters
    searchURL +=
      "&isChargeable=" +
      filter.isChargeable +
      "&isNotChargeable=" +
      filter.isNotChargeable;
     // Add payment filters
     searchURL +=
     "&activeWebhooks=" +
     filter.activeWebhooks +
     "&inactiveWebhooks=" +
     filter.inactiveWebhooks;
    // Search existing shops and take only the ones we need
    await fetch(searchURL, {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult);
        setMerchants([]);
        JSONresult.data.forEach((merchant: IShop) => {
          setMerchants((previous) => [
            ...previous,
            {
              id: merchant.id,
              domain: merchant.domain,
              needsSetup: merchant.needsSetup,
              needsSync: merchant.needsSync,
              needsTest: merchant.needsTest,
              needsOnboarding:  merchant.needsOnboarding,
              status: merchant.status,
              currentPlan: merchant.currentPlan,
              customOfferPlan: merchant.customOfferPlan,
              createDate: merchant.createDate,
              selected: false,
              isWidgetInstalled: merchant.isWidgetInstalled,
              shopCurrency: merchant.shopCurrency,
              shopMainDomain: merchant.shopMainDomain,
              shopName: merchant.shopName,
              isChargeable: merchant.isChargeable,
            },
          ]);
        });
        setPageMeta({
          itemCount: JSONresult.meta.itemCount,
          pageCount: JSONresult.meta.pageCount,
          page: parseInt(JSONresult.meta.page),
          take: parseInt(JSONresult.meta.take),
          hasNextPage: JSONresult.meta.hasNextPage,
          hasPreviousPage: JSONresult.meta.hasPreviousPage,
        });
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMerchantsGrid = (state: EMerchantsOutter) => {
    switch (state) {
      case EMerchantsOutter.normal:
        if (MerchantsOutterRef.current !== null) {
          MerchantsOutterRef.current.style.gridTemplateColumns = "0px 1fr 0px";
        }
        break;
      case EMerchantsOutter.filter:
        if (MerchantsOutterRef.current !== null) {
          MerchantsOutterRef.current.style.gridTemplateColumns =
            "320px 1fr 0px";
        }
        break;
      case EMerchantsOutter.showcase:
        if (MerchantsOutterRef.current !== null) {
          MerchantsOutterRef.current.style.gridTemplateColumns =
            "0px 1fr 320px";
        }
        break;
      default:
        console.log("Upps");
    }
  };

  const handleMerchantSelect = (merchant: IShop) => {
    setSelectedMerchant(merchant);
  };

  const handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilterChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMerchantToogle = (id: string, newValue: boolean) => {
    setMerchants((prevData) => {
      return prevData.map((obj) => {
        if (obj.id === id) {
          return { ...obj, selected: newValue };
        }
        return obj;
      });
    });
  };

  const handleMerchantToogleAll = (newValue: boolean) => {
    setMerchants((prevData) => {
      return prevData.map((obj) => {
        return { ...obj, selected: newValue };
      });
    });
    setAllMerchantsState(newValue);
  };

  const formatDate2 = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const downloadCohort = async () => {
    await fetch(globalSelectedBackend + "/admin/cohortcsv", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          const blob = await response.blob(); // blob just as yours
          const href = await URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.download = "cohort.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadChurn = async () => {
    await fetch(globalSelectedBackend + "/admin/churncsv", {
      method: "GET",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        if (JSONresult.sendingEmail) {
          setValidatorProps({
            validatorPrompt: t("ChurnMailD", { ns: ["merchants"] }),
            validatorTitle: t("ChurnMailT", { ns: ["merchants"] }),
            functionOne: () => {},
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: "Sofia, calmate, se está mandando al correo",
            validatorTitle: "Calma",
            functionOne: () => {},
            functionOnePrompt: "Está bien",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStats = async () => {
    await fetch(globalSelectedBackend + "/admin/updatestats", {
      method: "PATCH",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSync = async () => {
    await fetch(globalSelectedBackend + "/admin/updatesyncallshops", {
      method: "PATCH",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateFlags = async () => {
    await fetch(globalSelectedBackend + "/admin/allresynctrue", {
      method: "PATCH",
      credentials: "include",
      redirect: "follow",
    })
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const attachWebhooks = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    await fetch(
      globalSelectedBackend +
        "/admin/" +
        selectedMerchant.id +
        "/attachwebhooks",
      {
        method: "PATCH",
        headers: myHeaders,
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dettachWebhooks = async () => {
    await fetch(
      globalSelectedBackend +
        "/admin/" +
        selectedMerchant.id +
        "/detachwebhooks",
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteShopInformation = async () => {
    await fetch(
      globalSelectedBackend +
        "/admin/" +
        selectedMerchant.id +
        "/deleteshopinformation",
      {
        method: "DELETE",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult);
        if (JSONresult.status) {
          setValidatorProps({
            validatorPrompt: t("DeleteSIPS", { ns: ["merchants"] }),
            validatorTitle: t("DeleteSIT", { ns: ["merchants"] }),
            functionOne: () => {
              window.location.reload();
            },
            functionOnePrompt: "OK",
            functionTwo: () => {},
            functionTwoPrompt: "",
            functionNumber: 1,
          });
        } else {
          setValidatorProps({
            validatorPrompt: t("DeleteSIPE", { ns: ["merchants"] }),
            validatorTitle: t("DeleteSIT", { ns: ["merchants"] }),
            functionOne: () => {
              deleteShopInformation();
            },
            functionOnePrompt: t("Retry", { ns: ["merchants"] }),
            functionTwo: () => {},
            functionTwoPrompt: t("Cancel", { ns: ["merchants"] }),
            functionNumber: 2,
          });
        }
      })
      .catch((err) => {
        setValidatorProps({
          validatorPrompt: t("Error2", { ns: ["billing"] }),
          validatorTitle: "Error",
          functionOne: () => {},
          functionOnePrompt: "OK",
          functionTwo: () => {},
          functionTwoPrompt: "",
          functionNumber: 1,
        });
      });
  };

  const toogleChargeableStatus = async () => {
    await fetch(
      globalSelectedBackend +
        "/admin/" +
        selectedMerchant.id +
        "/ischargeablestatus",
      {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          let errorText = await response.text();
          let errorJSON = JSON.parse(errorText);
          throw new Error(errorJSON.message);
        } else {
          return response.text();
        }
      })
      .then((result) => JSON.parse(result))
      .then((JSONresult) => {
        console.log(JSONresult.status);
        setSelectedMerchant((prevState) => ({
          ...prevState,
          isChargeable: JSONresult.status,
        }));
        const index = merchants.findIndex(
          (merchant: IShop) => merchant.id === selectedMerchant.id
        );
        if (index !== -1) {
          const updatedItems = [...merchants];
          updatedItems[index].isChargeable = JSONresult.status;
          setMerchants(updatedItems);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoaded) {
    return (
      <React.Fragment>
        <Validator
          ref={ValidatorRef}
          functionNumber={validatorProps.functionNumber}
          functionOne={validatorProps.functionOne}
          functionOnePrompt={validatorProps.functionOnePrompt}
          functionTwo={validatorProps.functionTwo}
          functionTwoPrompt={validatorProps.functionTwoPrompt}
          validatorTitle={validatorProps.validatorTitle}
          validatorPrompt={validatorProps.validatorPrompt}
        />
        <div ref={MerchantsOutterRef} className="Merchants__outter">
          <div className="Merchants__filters__outter">
            <div
              className="Merchants__filters__outter__close"
              onClick={() => {
                handleMerchantsGrid(EMerchantsOutter.normal);
              }}
            >
              {" "}
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="Merchants__filters__outter__close__image"
              />
            </div>

            <div className="Merchants__filters__inner">
              <div className="Merchants__filters__title">
                {t("Filters", { ns: ["merchants"] })}
              </div>
              <div className="Merchants__filters__description">
                {t("FiltersD", { ns: ["merchants"] })}
              </div>
              <div className="Merchants__filters__fields__outter">
                {/* Text filter */}
                <div className="Wizy__input__title">
                  {t("Text", { ns: ["merchants"] })}
                </div>
                <div className="Wizy__input__1__outter">
                  <input
                    name="text"
                    value={filterChange.text}
                    type="text"
                    className="Wizy__input__1"
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="Wizy__input__prompt__1">
                  {t("Eg", { ns: ["merchants"] })}
                </div>

                {/* Installation filters */}
                <div className="Wizy__input__title">
                  {" "}
                  {t("Status", { ns: ["merchants"] })}
                </div>
                {/* Installed */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isInstalled"
                    checked={filterChange.isInstalled}
                    onChange={() => {
                      if (filterChange.isInstalled) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isInstalled: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isInstalled: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Installed", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Uninstalled */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isUninstalled"
                    checked={filterChange.isUninstalled}
                    onChange={() => {
                      if (filterChange.isUninstalled) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isUninstalled: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isUninstalled: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Uninstalled", { ns: ["merchants"] })}
                  </div>
                </div>

                {/* Plan filters */}
                <div className="Wizy__input__title">
                  {" "}
                  {t("Plan", { ns: ["merchants"] })}
                </div>
                {/* Free */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isFree"
                    checked={filterChange.isFree}
                    onChange={() => {
                      if (filterChange.isFree) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isFree: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isFree: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Free", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Pay-as-you-go */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isFree"
                    checked={filterChange.isPayg}
                    onChange={() => {
                      if (filterChange.isPayg) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isPayg: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isPayg: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Payg", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Starter */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isStarter"
                    checked={filterChange.isStarter}
                    onChange={() => {
                      if (filterChange.isStarter) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isStarter: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isStarter: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Starter", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Basic */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isBasic"
                    checked={filterChange.isBasic}
                    onChange={() => {
                      if (filterChange.isBasic) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isBasic: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isBasic: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Basic", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Plus */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isPlus"
                    checked={filterChange.isPlus}
                    onChange={() => {
                      if (filterChange.isPlus) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isPlus: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isPlus: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Plus", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Advanced */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isAdvanced"
                    checked={filterChange.isAdvanced}
                    onChange={() => {
                      if (filterChange.isAdvanced) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isAdvanced: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isAdvanced: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Advanced", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Advanced */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isCustom"
                    checked={filterChange.isCustom}
                    onChange={() => {
                      if (filterChange.isCustom) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isCustom: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isCustom: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Custom", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Frozen */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isFrozen"
                    checked={filterChange.isFrozen}
                    onChange={() => {
                      if (filterChange.isFrozen) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isFrozen: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isFrozen: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Frozen", { ns: ["merchants"] })}
                  </div>
                </div>

                {/* Widget filters */}
                <div className="Wizy__input__title">
                  {" "}
                  {t("WidgetInstallation", { ns: ["merchants"] })}
                </div>
                {/* Installed */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isWidgetInstalled"
                    checked={filterChange.isWidgetInstalled}
                    onChange={() => {
                      if (filterChange.isWidgetInstalled) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isWidgetInstalled: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isWidgetInstalled: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Installed", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Uninstalled */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isWidgetUninstalled"
                    checked={filterChange.isWidgetUninstalled}
                    onChange={() => {
                      if (filterChange.isWidgetUninstalled) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isWidgetUninstalled: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isWidgetUninstalled: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Uninstalled", { ns: ["merchants"] })}
                  </div>
                </div>

                {/* Charge filters */}
                <div className="Wizy__input__title">
                  {" "}
                  {t("ChargeableFilter", { ns: ["merchants"] })}
                </div>
                {/* Installed */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isChargeable"
                    checked={filterChange.isChargeable}
                    onChange={() => {
                      if (filterChange.isChargeable) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isChargeable: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isChargeable: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("Chargeable", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Uninstalled */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="isNotChargeable"
                    checked={filterChange.isNotChargeable}
                    onChange={() => {
                      if (filterChange.isNotChargeable) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isNotChargeable: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          isNotChargeable: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("NotChargeable", { ns: ["merchants"] })}
                  </div>
                </div>

                {/* Webhooks filters */}
                <div className="Wizy__input__title">
                  {" "}
                  {t("WebhooksFilters", { ns: ["merchants"] })}
                </div>
                {/* Installed */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="activeWebhooks"
                    checked={filterChange.activeWebhooks}
                    onChange={() => {
                      if (filterChange.activeWebhooks) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          activeWebhooks: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          activeWebhooks: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("ActiveWebhooks", { ns: ["merchants"] })}
                  </div>
                </div>
                {/* Uninstalled */}
                <div className="Wizy__input__checkbox__outter">
                  <input
                    type="checkbox"
                    name="inactiveWebhooks"
                    checked={filterChange.inactiveWebhooks}
                    onChange={() => {
                      if (filterChange.inactiveWebhooks) {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          inactiveWebhooks: false,
                        }));
                      } else {
                        setFilterChange((prevState) => ({
                          ...prevState,
                          inactiveWebhooks: true,
                        }));
                      }
                    }}
                  />
                  <div className="Wizy__input__checkbox__text">
                    {" "}
                    {t("InactiveWebhooks", { ns: ["merchants"] })}
                  </div>
                </div>

                {/* Date filtering */}
                <div className="Wizy__input__title">
                  {t("Date", { ns: ["clients"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.initialDate}
                  name="initialDate"
                  className="Wizy__input__date"
                  style={{ marginTop: "10px" }}
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateI", { ns: ["clients"] })}
                </div>
                <input
                  type="date"
                  value={filterChange.finalDate}
                  name="finalDate"
                  className="Wizy__input__date"
                  onChange={handleFilterChange}
                />
                <div className="Wizy__input__prompt__1">
                  {t("DateF", { ns: ["clients"] })}
                </div>

                {/* Apply button */}
                <div className="Merchants__apply__button__outter">
                  <button
                    className="Wizy__button__2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setFilter(filterChange);
                      handleMerchantToogleAll(false);
                    }}
                  >
                    {t("Apply", { ns: ["clients"] })}
                  </button>
                </div>

                {/* Reset button */}
                <div className="Merchants__apply__button__outter">
                  <button
                    className="Wizy__button__3"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setFilter({
                        text: "",
                        isInstalled: true,
                        isUninstalled: true,
                        isFree: true,
                        isPayg: true,
                        isStarter: true,
                        isBasic: true,
                        isPlus: true,
                        isAdvanced: true,
                        isCustom: true,
                        isFrozen: true,
                        isWidgetInstalled: true,
                        isWidgetUninstalled: true,
                        isChargeable: true,
                        isNotChargeable: true,
                        activeWebhooks: true,
                        inactiveWebhooks: true,
                        initialDate: "",
                        finalDate: "",
                      });
                      setFilterChange({
                        text: "",
                        isInstalled: true,
                        isUninstalled: true,
                        isFree: true,
                        isPayg: true,
                        isStarter: true,
                        isBasic: true,
                        isPlus: true,
                        isAdvanced: true,
                        isCustom: true,
                        isFrozen: true,
                        isWidgetInstalled: true,
                        isWidgetUninstalled: true,
                        isChargeable: true,
                        isNotChargeable: true,
                        activeWebhooks: true,
                        inactiveWebhooks: true,
                        initialDate: "",
                        finalDate: "",
                      });
                      handleMerchantToogleAll(false);
                    }}
                  >
                    {t("Reset", { ns: ["clients"] })}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* List Column */}
          <div className="Merchants__list__outter">
            <div className="Merchants__list__inner">
              {/* List Title : Merchants */}
              <div className="Merchants__list__title__outter">
                <div className="Merchants__list__title__inner">
                  <div className="Merchants__list__title__inner__inner">
                    {/* Merchants title and description */}
                    <div className="Ticket__list__title__description">
                      <div className="Merchants__list__title__inner__text__title">
                        {t("Merchants", { ns: ["merchants"] })}
                      </div>
                      <div className="Merchants__list__title__inner__text__description">
                        {t("MerchantsD", { ns: ["merchants"] })}
                      </div>
                    </div>
                    {/* Arrow */}
                    <div className="Merchants__list__arrow__outter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        preserveAspectRatio="none"
                      >
                        <polygon
                          points="29 66 28 66 38 33 28 0 29 0 39 33 29 66"
                          fill="#e6e9ed"
                        ></polygon>
                      </svg>
                    </div>
                    {/* Open filters button */}
                    <div
                      className="Merchants__list__add__filters__outter"
                      onClick={() => {
                        handleMerchantsGrid(EMerchantsOutter.filter);
                      }}
                    >
                      <img
                        src={wizy_add_outline}
                        alt="wizy_add_outline"
                        className="Merchants__list__add__filters__image"
                      />
                      {t("Filters", { ns: ["clients"] })}
                    </div>
                  </div>
                </div>
              </div>
              {/* List contents: The card that starts with actions */}
              <div className="Merchants__list__list__outter">
                <div className="Merchants__list__list__header__outter">
                  <div className="Merchants__list__list__header__inner">
                    <div className="Merchants__list__list__title__description">
                      <div className="Merchants__list__list__title__inner__text__title">
                        {t("Actions", { ns: ["merchants"] })}
                      </div>
                      <div className="Merchants__list__list__selected__outter">
                        <div className="Merchants__list__list__selected__number">
                          {
                            merchants.filter((val: IShop) => {
                              if (val.selected) {
                                return val;
                              }
                              return false;
                            }).length
                          }
                        </div>
                        {t("Selected", { ns: ["merchants"] })}
                      </div>
                    </div>
                    <div className="Merchants__list__list__arrow__outter">
                      <img
                        src={wizy_arrow_right_tail_outline}
                        alt="wizy_arrow_right_tail_outline"
                        className="Merchants__list__list__arrow__image"
                      />
                    </div>
                    <div
                      className="Merchants__list__list__export__outter"
                      onClick={downloadCohort}
                    >
                      <img
                        src={wizy_export_filled}
                        alt="wizy_export_filled"
                        className="Merchants__list__list__export__image"
                        style={{
                          filter:
                            "invert(27%) sepia(79%) saturate(2624%) hue-rotate(211deg) brightness(97%) contrast(110%)",
                        }}
                      />{" "}
                      <div
                        className="Merchants__list__list__export__text"
                        style={{
                          color: "#0566ff",
                        }}
                      >
                        {t("Cohort", { ns: ["merchants"] })}
                      </div>
                    </div>
                    <div
                      className="Merchants__list__list__export__outter"
                      onClick={downloadChurn}
                    >
                      <img
                        src={wizy_export_filled}
                        alt="wizy_export_filled"
                        className="Merchants__list__list__export__image"
                        style={{
                          filter:
                            "invert(27%) sepia(79%) saturate(2624%) hue-rotate(211deg) brightness(97%) contrast(110%)",
                        }}
                      />{" "}
                      <div
                        className="Merchants__list__list__export__text"
                        style={{
                          color: "#0566ff",
                        }}
                      >
                        {t("Churn", { ns: ["merchants"] })}
                      </div>
                    </div>
                    <div
                      className="Merchants__list__list__export__outter"
                      onClick={() => {
                        setValidatorProps({
                          validatorPrompt: t("WidgetP", { ns: ["merchants"] }),
                          validatorTitle: t("WidgetT", {
                            ns: ["merchants"],
                          }),
                          functionOne: () => {
                            updateStats();
                          },
                          functionOnePrompt: t("OK", { ns: ["merchants"] }),
                          functionTwo: () => {},
                          functionTwoPrompt: t("NO", { ns: ["merchants"] }),
                          functionNumber: 2,
                        });
                      }}
                    >
                      <img
                        src={wizy_chat_filled}
                        alt="wizy_export_filled"
                        className="Merchants__list__list__export__image"
                        style={{
                          filter:
                            "invert(27%) sepia(79%) saturate(2624%) hue-rotate(211deg) brightness(97%) contrast(110%)",
                        }}
                      />{" "}
                      <div
                        className="Merchants__list__list__export__text"
                        style={{
                          color: "#0566ff",
                        }}
                      >
                        {t("UpdateWidgets", { ns: ["merchants"] })}
                      </div>
                    </div>
                    <div
                      className="Merchants__list__list__export__outter"
                      onClick={() => {
                        setValidatorProps({
                          validatorPrompt: t("FlagsP", { ns: ["merchants"] }),
                          validatorTitle: t("FlagsT", {
                            ns: ["merchants"],
                          }),
                          functionOne: async () => {
                            await updateFlags();
                            setValidatorProps({
                              validatorPrompt: t("SyncP", {
                                ns: ["merchants"],
                              }),
                              validatorTitle: t("SyncT", {
                                ns: ["merchants"],
                              }),
                              functionOne: () => {
                                updateSync();
                              },
                              functionOnePrompt: t("OK", {
                                ns: ["merchants"],
                              }),
                              functionTwo: () => {},
                              functionTwoPrompt: t("NO", {
                                ns: ["merchants"],
                              }),
                              functionNumber: 2,
                            });
                          },
                          functionOnePrompt: t("OK", { ns: ["merchants"] }),
                          functionTwo: async () => {
                            setValidatorProps({
                              validatorPrompt: t("SyncP", {
                                ns: ["merchants"],
                              }),
                              validatorTitle: t("SyncT", {
                                ns: ["merchants"],
                              }),
                              functionOne: () => {
                                updateSync();
                              },
                              functionOnePrompt: t("OK", {
                                ns: ["merchants"],
                              }),
                              functionTwo: () => {},
                              functionTwoPrompt: t("NO", {
                                ns: ["merchants"],
                              }),
                              functionNumber: 2,
                            });
                          },
                          functionTwoPrompt: t("NO", { ns: ["merchants"] }),
                          functionNumber: 2,
                        });
                      }}
                    >
                      <img
                        src={wizy_ai_filled}
                        alt="wizy_export_filled"
                        className="Merchants__list__list__export__image"
                        style={{
                          filter:
                            "invert(27%) sepia(79%) saturate(2624%) hue-rotate(211deg) brightness(97%) contrast(110%)",
                        }}
                      />{" "}
                      <div
                        className="Merchants__list__list__export__text"
                        style={{
                          color: "#0566ff",
                        }}
                      >
                        {t("RSync", { ns: ["merchants"] })}
                      </div>
                    </div>
                    {/* Pagination */}
                    <div className="Clients__list__list__pagination__outter">
                      <Pagination
                        pageMeta={pageMeta}
                        getElements={getMerchants}
                      ></Pagination>
                    </div>
                  </div>
                </div>

                {/* Table titles */}
                <div className="Merchants__list__list__titles__outter">
                  <div className="Merchants__list__list__titles__inner">
                    <div
                      className="Merchants__list__list__titles__inner__inner"
                      style={{ paddingLeft: "10px" }}
                    >
                      <input
                        type="checkbox"
                        name="isNotCommercialized"
                        checked={allMerchantsState}
                        onChange={() => {
                          handleMerchantToogleAll(!allMerchantsState);
                        }}
                      />
                    </div>
                    <div className="Merchants__list__list__titles__inner__inner">
                      {t("Domain", { ns: ["merchants"] })}
                    </div>
                    <div className="Merchants__list__list__titles__inner__inner">
                      {t("Status", { ns: ["merchants"] })}
                    </div>
                    <div
                      className="Merchants__list__list__titles__inner__inner"
                      id="Merchants__date__field"
                    >
                      {t("CreateDate", { ns: ["merchants"] })}
                    </div>
                    <div
                      className="Merchants__list__list__titles__inner__inner"
                      id="Merchants__plan__field"
                    >
                      {t("Plan", { ns: ["merchants"] })}
                    </div>
                    <div
                      className="Merchants__list__list__titles__inner__inner"
                      id="Merchants__widget__field"
                    >
                      {t("Widget", { ns: ["merchants"] })}
                    </div>
                  </div>
                </div>
                <div className="Merchants__list__list__items__outter">
                  {merchants
                    .sort(
                      (a: IShop, b: IShop) =>
                        Date.parse(b.createDate) - Date.parse(a.createDate)
                    )
                    .map((merchant, index) => {
                      return (
                        <div
                          className="Merchants__list__list__item__outter"
                          onClick={() => {
                            handleMerchantSelect(merchant);
                            handleMerchantsGrid(EMerchantsOutter.showcase);
                          }}
                          key={index}
                        >
                          <div className="Merchants__list__list__item__inner">
                            <div
                              className="Merchants__list__list__item__inner__inner"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                type="checkbox"
                                name="isNotCommercialized"
                                checked={merchant.selected}
                                onChange={() => {
                                  handleMerchantToogle(
                                    merchant.id,
                                    !merchant.selected
                                  );
                                }}
                              />
                            </div>
                            <div className="Merchants__list__list__code__inner__inner">
                              <img
                                src={wizy_clients_filled}
                                alt="wizy_clients_filled"
                                className="Merchants__list__list__code__inner__inner__image"
                              />
                              <div className="Merchants__list__list__code__inner__inner__text">
                                {merchant.domain === ""
                                  ? t("NoDomain", { ns: ["merchants"] })
                                  : merchant.domain}
                              </div>
                            </div>

                            <div className="Merchants__list__list__code__inner__inner">
                              <div className="Merchants__list__list__code__inner__inner__text">
                                {merchant.status === EShopStatus.installed
                                  ? t("Installed", { ns: ["merchants"] })
                                  : t("Uninstalled", { ns: ["merchants"] })}
                              </div>
                            </div>
                            <div
                              className="Merchants__list__list__code__inner__inner"
                              id="Merchants__date__field"
                            >
                              <div className="Merchants__list__list__code__inner__inner__text">
                                {formatDate2(new Date(merchant.createDate))}
                              </div>
                            </div>
                            <div
                              className="Merchants__list__list__code__inner__inner"
                              id="Merchants__plan__field"
                            >
                              <div className="Merchants__list__list__code__inner__inner__text">
                                {merchant.currentPlan.name ===
                                EStandardPlansName.FREE
                                  ? t("Free", { ns: ["merchants"] })
                                  : merchant.currentPlan.name ===
                                    EStandardPlansName.PAYG
                                  ? t("Payg", { ns: ["merchants"] })
                                  : merchant.currentPlan.name ===
                                    EStandardPlansName.STARTER
                                  ? t("Starter", { ns: ["merchants"] })
                                  : merchant.currentPlan.name ===
                                    EStandardPlansName.BASIC
                                  ? t("Basic", { ns: ["merchants"] })
                                  : merchant.currentPlan.name ===
                                    EStandardPlansName.PLUS
                                  ? t("Plus", { ns: ["merchants"] })
                                  : merchant.currentPlan.name ===
                                    EStandardPlansName.ADVANCED
                                  ? t("Advanced", { ns: ["merchants"] })
                                  : merchant.currentPlan.isCustom
                                  ? t("Custom", { ns: ["merchants"] })
                                  : merchant.currentPlan.name ===
                                    EStandardPlansName.FROZEN
                                  ? t("Frozen", { ns: ["merchants"] })
                                  : "?"}
                              </div>
                            </div>
                            <div
                              className="Merchants__list__list__code__inner__inner"
                              id="Merchants__widget__field"
                            >
                              <div className="Merchants__list__list__code__inner__inner__text">
                                {merchant.isWidgetInstalled
                                  ? t("Installed", { ns: ["merchants"] })
                                  : t("Uninstalled", { ns: ["merchants"] })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          {/* Showcase of selected merchant */}
          <div className="Merchants__showcase__outter">
            <div
              className="Merchants__showcase__outter__close"
              onClick={() => {
                handleMerchantsGrid(EMerchantsOutter.normal);
              }}
            >
              <img
                src={wizy_hide_side_menu_outline}
                alt="wizy_hide_side_menu_outline"
                className="Merchants__showcase__outter__close__image"
              />
            </div>
            <div className="Merchants__showcase__inner">
              <div className="Merchants__showcase__information__outter">
                <div className="Merchants__showcase__infotmation__item__outter">
                  {/* Domain */}
                  <div className="Merchants__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_globe_outline}
                        alt="wizy_add_outline"
                        className="Merchants__showcase__information__item__image"
                      />
                    </div>
                    <div className="Merchants__showcase__information__item__text">
                      <div className="Wizy__input__6__outter">
                        {selectedMerchant.domain}
                      </div>
                    </div>
                  </div>
                  {/* Create date */}
                  <div className="Merchants__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_calendar_filled}
                        alt="wizy_add_outline"
                        className="Merchants__showcase__information__item__image"
                      />
                    </div>
                    <div className="Merchants__showcase__information__item__text">
                      {selectedMerchant.createDate === ""
                        ? t("NoCreation", { ns: ["merchants"] })
                        : formatDate2(new Date(selectedMerchant.createDate))}
                    </div>
                  </div>
                  {/* Status */}
                  <div className="Merchants__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_download_filled}
                        alt="wizy_add_outline"
                        className="Merchants__showcase__information__item__image"
                      />
                    </div>
                    <div className="Merchants__showcase__information__item__text">
                      <div className="Wizy__input__6__outter">
                        {selectedMerchant.status === EShopStatus.installed
                          ? t("ShowcaseStatusInstalled", { ns: ["merchants"] })
                          : t("ShowcaseStatusUninstalled", {
                              ns: ["merchants"],
                            })}
                      </div>
                    </div>
                  </div>
                  {/* Plan */}
                  <div className="Merchants__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_tag_filled}
                        alt="wizy_add_outline"
                        className="Merchants__showcase__information__item__image"
                      />
                    </div>
                    <div className="Merchants__showcase__information__item__text">
                      <div className="Wizy__input__6__outter">
                        {selectedMerchant.currentPlan.name ===
                        EStandardPlansName.FREE
                          ? t("Free", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.name ===
                            EStandardPlansName.PAYG
                          ? t("Payg", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.name ===
                            EStandardPlansName.STARTER
                          ? t("Starter", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.name ===
                            EStandardPlansName.BASIC
                          ? t("Basic", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.name ===
                            EStandardPlansName.PLUS
                          ? t("Plus", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.name ===
                            EStandardPlansName.ADVANCED
                          ? t("Advanced", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.isCustom
                          ? t("Custom", { ns: ["merchants"] })
                          : selectedMerchant.currentPlan.name ===
                            EStandardPlansName.FROZEN
                          ? t("Frozen", { ns: ["merchants"] })
                          : "?"}
                      </div>
                    </div>
                  </div>
                  {/* Widget */}
                  <div className="Merchants__showcase__infotmation__item__inner">
                    <div>
                      <img
                        src={wizy_ai_filled}
                        alt="wizy_add_outline"
                        className="Merchants__showcase__information__item__image"
                      />
                    </div>
                    <div className="Merchants__showcase__information__item__text">
                      <div className="Wizy__input__6__outter">
                        {selectedMerchant.isWidgetInstalled
                          ? t("ShowcaseWidgetInstalled", { ns: ["merchants"] })
                          : t("ShowcaseWidgetUninstalled", {
                              ns: ["merchants"],
                            })}
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    className="Merchants__showcase__infotmation__item__inner"
                    style={{ marginBottom: "5px" }}
                  >
                    <div>
                      <img
                        src={wizy_dollar_filled}
                        alt="wizy_dollar_filled"
                        className="Merchants__showcase__information__item__image"
                        style={{ paddingTop: "7px" }}
                      />
                    </div>
                    <div className="Merchants__showcase__information__item__text">
                      <div className="Wizy__input__6__outter">
                        <div className="">
                          <label className="switch1">
                            <input
                              type="checkbox"
                              checked={selectedMerchant.isChargeable}
                              onChange={() => {
                                toogleChargeableStatus();
                              }}
                            />
                            <span className="slider3 round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate("/merchantsettings/" + selectedMerchant.id);
                  }}
                >
                  {t("MerchantSettings", { ns: ["merchants"] })}
                </button>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate("/cachedresponses/" + selectedMerchant.id);
                  }}
                >
                  {t("CachedResponses", { ns: ["merchants"] })}
                </button>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{ width: "100%" }}
                  onClick={() => {
                    attachWebhooks();
                  }}
                >
                  {t("AttachW", { ns: ["merchants"] })}
                </button>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__3"
                  style={{ width: "100%" }}
                  onClick={() => {
                    dettachWebhooks();
                  }}
                >
                  {t("DettachW", { ns: ["merchants"] })}
                </button>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__5"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setValidatorProps({
                      validatorPrompt: t("DeleteSIP", { ns: ["merchants"] }),
                      validatorTitle: t("DeleteSIT", {
                        ns: ["merchants"],
                      }),
                      functionOne: () => {
                        deleteShopInformation();
                      },
                      functionOnePrompt: t("OK", { ns: ["merchants"] }),
                      functionTwo: () => {},
                      functionTwoPrompt: t("NO", { ns: ["merchants"] }),
                      functionNumber: 2,
                    });
                  }}
                >
                  {t("DeleteSI", { ns: ["merchants"] })}
                </button>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__10"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigate("/testadmin/" + selectedMerchant.id);
                  }}
                >
                  {t("MerchantTest", { ns: ["merchants"] })}
                </button>
              </div>
              <div className="Merchants__apply__button__outter">
                <button
                  className="Wizy__button__2"
                  style={{ width: "100%" }}
                  onClick={() => {
                    window.open(
                      globalSelectedBackend +
                        "/admin/" +
                        selectedMerchant.id +
                        "/merchantlogin",
                      "_self"
                    );
                  }}
                >
                  {t("Login", { ns: ["merchants"] })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="Home__setup__outter">
        <img
          src={wizy_loader}
          className="ProtectedRoute__loader"
          alt="ProtectedRoute__loader"
          id="ProtectedRoute__loader"
        />
      </div>
    );
  }
};

export default Merchants;
